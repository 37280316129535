import React, { useState } from 'react';
import { canProjectBeCirculated, canProjectBeShared } from '../../common/project';

import CopyLinkButton from '../common/CopyLinkButton';

import { getTranslation } from '../../helpers/getLanguage';

const ProjectsDashboardTopRow = ({ project, getProjectInfo, modalOpen, setModalOpen, canEdit, userId }) => {
    const [isPending, setIsPending] = useState(false);

    const showCirculateButton = canProjectBeCirculated(project, userId);
    const showShareButton = canProjectBeShared(project);

    const handleRequestEnd = () => {
        setIsPending(false);
        getProjectInfo(project.id);
    };

    return (
        <>
            <div className='width-100 flex flex-wrap justify-space-between'>
                {canEdit && (
                    <div className='flex mb-10'>
                        <div className='flex'>
                            <button
                                className='dashboard-project-button team-button flex align-center justify-space-between'
                                onClick={() => setModalOpen(!modalOpen)}
                            >
                                <span>{getTranslation('PAGE_DASHBOARD_EDIT_PROJECT_BUTTON')}</span>
                                <div className='button-triangle-icon'></div>
                            </button>

                            <CopyLinkButton
                                projectId={project.id}
                                mode='circulate'
                                disabled={isPending}
                                itemDisabled={!showCirculateButton}
                                onRequestStart={() => setIsPending(true)}
                                onSuccess={handleRequestEnd}
                                onError={handleRequestEnd}
                                containerClass='circulate-button'
                                tooltipPosition='left'
                            />

                            <CopyLinkButton
                                projectId={project.id}
                                mode='share'
                                disabled={isPending}
                                itemDisabled={!showShareButton}
                                onRequestStart={() => setIsPending(true)}
                                onSuccess={handleRequestEnd}
                                onError={handleRequestEnd}
                                tooltipPosition='left'
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ProjectsDashboardTopRow;
