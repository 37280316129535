import React from 'react';

const ProgressDiagram = ({ dependencies, redirectToMatrix }) => {
    const blocks = new Array(25);
    const fillIndex = Math.floor(((dependencies.explored / dependencies.total) * 100) / 4);

    const blockClass = 'progress-diagram-block';
    const blockActiveClass = blockClass + ' active';

    for (let i = 0; i < fillIndex; i++) {
        blocks.push(<div key={i} className={blockActiveClass}></div>);
    }

    for (let i = fillIndex; i < 25; i++) {
        blocks.push(<div key={i} className={blockClass}></div>);
    }

    return (
        <div className="flex align-center pointer" onClick={() => redirectToMatrix()}>
            <span className="progress-diagram-label">{dependencies.total}</span>
            <div className="progress-diagram-container flex flex-wrap">{blocks}</div>
        </div>
    );
};

export default ProgressDiagram;
