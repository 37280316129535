export const errorMessages = [
  {
    code: 10201,
    message: "ERROR_MESSAGE_10201",
  },
  {
    code: 30250,
    message: "ERROR_MESSAGE_30250",
  },
  {
    code: 30251,
    message: "ERROR_MESSAGE_30251",
  },
  {
    code: 30252,
    message: "ERROR_MESSAGE_30252",
  },
  {
    code: 30253,
    message: "ERROR_MESSAGE_30253",
  },
  {
    code: 30254,
    message: "ERROR_MESSAGE_30254",
  },
  {
    code: 30300,
    message: "ERROR_MESSAGE_30300",
  },
  {
    code: 30306,
    message: "ERROR_MESSAGE_30306",
  },
  {
    code: 30307,
    message: "ERROR_MESSAGE_30307",
  },
  {
    code: 30330,
    message: "ERROR_MESSAGE_30330",
  },
];
