import React from 'react';
import CloseIcon from '../../../assets/images/close-icon.svg';
import CustomNumberInput from '../../inputs/CustomNumberInput';
import Dialog from '../Dialog';

export default function SingleDropdownDialog({
    onClick,
    handleCloseModal,
    titleText,
    buttonText,
    value = 1,
    onChange,
    minValue,
    maxValue,
    customLabels,
}) {
    return (
        <Dialog>
            <div className='information-modal flex-column overflow-visible'>
                <div className='flex align-center justify-space-between width-100'>
                    <span className='manage-user-label'>{titleText}</span>
                    <img src={CloseIcon} alt='' className='pointer' onClick={handleCloseModal} />
                </div>

                <div className='flex-center'>
                    <div className='information-modal-button-contener flex-column align-center '>
                        <div className='folder-input-container  flex justify-center'>
                            <CustomNumberInput
                                customLabels={customLabels}
                                minValue={minValue}
                                maxValue={maxValue}
                                value={value}
                                onChange={onChange}
                            />
                        </div>
                        <button className='information-modal-button width-100' onClick={onClick}>
                            <span>{buttonText}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
