import { GET_ALL_USERS, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_ERROR } from '../actions/types';

const initialState = {
    payload: [],
    errors: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_USERS:
            return { ...initialState, isLoading: true };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                errors: null,
                isLoading: false,
            };
        case GET_ALL_USERS_ERROR:
            return {
                ...state,
                payload: [],
                errors: action.payload,
                isLoading: false,
            };
        default:
            return state;
    }
}
