import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

export const suggestComponents = async (projectName, projectId, language, onSuccess, onError) => {
    axios
        .get(`${baseApiUrl}/ai/components/${projectName}`, { params: { projectId, language } })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const suggestSteps = async (projectName, projectId, isCustomer, language, onSuccess, onError) => {
    axios
        .get(`${baseApiUrl}/ai/steps/${projectName}`, { params: { isCustomer, projectId, language } })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const suggestAttributes = async (
    { projectName, componentName, componentId, projectType, language },
    onSuccess,
    onError
) => {
    axios
        .get(`${baseApiUrl}/ai/attributes/${projectName}`, { params: { componentName, componentId, projectType, language } })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const getGeneratedNamesForIdea = async (
    { projectName, projectId, descriptionOfIdea, language }, 
    onSuccess, 
    onError
) => {

    axios
        .get(`${baseApiUrl}/ai/names-ideas/${projectName}?projectId=${projectId}`, { params: { descriptionOfIdea, language }  })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
}

export const getGeneratedBenefits = async (
    { projectName, projectId, descriptionOfIdea, language }, 
    onSuccess, 
    onError
) => {
    axios
        .get(`${baseApiUrl}/ai/benefits/${projectName}`, { params: { projectId, descriptionOfIdea, language }  })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
}

export const getGeneratedChallenges = async (
    { projectName, projectId, descriptionOfIdea, language }, 
    onSuccess, 
    onError
) => {
    axios
        .get(`${baseApiUrl}/ai/challenges/${projectName}`, { params: { projectId, descriptionOfIdea, language }  })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
}

export const getGeneratedImage = async (
    { descriptionOfIdea, language }, 
    onSuccess, 
    onError
) => {
    axios
        .get(`${baseApiUrl}/ai/image-ideas`, { params: { descriptionOfIdea, language }  })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
}

export const getGeneratedImageForProject = async (
    { descriptionOfProject, language }, 
    onSuccess, 
    onError
) => {
    axios
        .get(`${baseApiUrl}/ai/image-project`, { params: { descriptionOfProject, language }  })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
}

export const getAnswer = async (
    { prompt, type, componentName, typeName,  descriptionOfIdea }, 
    onSuccess, 
    onError
) => {
    axios
        .post(`${baseApiUrl}/ai`, { prompt, type, componentName, typeName, descriptionOfIdea })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const getAnswerWithImage = async (
    { prompt, type, descriptionOfIdea, descriptionOfProject }, 
    onSuccess, 
    onError
) => {
    axios
        .post(`${baseApiUrl}/ai/image`, { prompt, type, descriptionOfIdea, descriptionOfProject })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const getContradiction = async ({ prompt, N, type, typeName }, onSuccess, onError) => {
    axios
        .post(`${baseApiUrl}/ai/generating-contradiction`, { prompt, type, typeName, N })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const getContradictionSolving = async (
    { prompt, type, solutionMethod, typeName, selectedContadiction },
    onSuccess,
    onError
) => {
    axios
        .post(`${baseApiUrl}/ai/solving-contradiction`, {
            prompt,
            type,
            solutionMethod,
            typeName,
            selectedContadiction,
        })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};