import React from 'react';

import Importance from './Importance';
import CardModal from '../../dialogs/CardModal';

import { ReactComponent as AttributeIcon } from '../../../assets/images/attributes-icon-longer.svg';
import { ReactComponent as AttributeIconWider } from '../../../assets/images/attribute-icon-big.svg';
import MenuIcon from '../../../assets/images/menu-icon.svg';
import HiddenIcon from '../../../assets/images/hidden-component-icon.svg';

import { Draggable } from 'react-beautiful-dnd';
import { getInitials } from '../../../helpers/getInitials';

import TranslateTemplate from '../../common/TranslateTemplate';

const AttributeCard = ({
    compact,
    attribute,
    handleModalOpen,
    handleDelete,
    handleDuplicate,
    handleDisable,
    activeAttribute,
    setAttributeId,
    isSorted,
    index,
}) => {
    const menuItems = [
        {
            value: 'PAGE_COMPONENT_MENU_ITEM_EDIT_ATTRIBUTE',
            action: () => handleModalOpen('attribute', { element: attribute }, true),
        },
        {
            value: 'PAGE_COMPONENT_MENU_DUPLICATE_ATTRIBUTE',
            action: () => handleDuplicate('attribute', attribute),
        },
        {
            value: attribute.disabled
                ? 'PAGE_COMPONENT_MENU_ITEM_ENABLE_ATTRIBUTE'
                : 'PAGE_COMPONENT_MENU_ITEM_DISABLE_ATTRIBUTE',
            action: () => handleDisable('attribute', attribute),
        },
        {
            value: 'PAGE_COMPONENT_MENU_ITEM_DELETE_ATTRIBUTE',
            action: () => handleDelete('attribute', attribute.id),
        },
    ];

    const activeClass = attribute.disabled ? ' disabled' : '';

    const handleClick = () => {
        handleModalOpen('attribute', { element: attribute }, true);
        setAttributeId(attribute.id);
    };

    return (
        <>
            {compact && (
                <>
                    <Draggable draggableId={attribute.id} index={isSorted ? index : attribute.sortOrder}>
                        {(provided) => (
                            <div
                                className={
                                    'attribute-card compact flex align-center pointer' + activeClass + activeAttribute
                                }
                                onClick={handleClick}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <div className='attribute-card-header-compact flex justify-space-between align-center width-100'>
                                    <div className='flex align-center attribute-card-title-container compact'>
                                        <AttributeIconWider style={{ color: '#838fdb' }} />
                                        <span className='attribute-card-title'>{attribute.name}</span>
                                    </div>

                                    <div className='flex align-center'>
                                        <Importance value={attribute.importance} compact={compact} />

                                        <CardModal items={menuItems} offsetX={-180}>
                                            <button className='component-card-menu-button compact flex-center'>
                                                <img className='menu-icon' src={MenuIcon} alt=''></img>
                                            </button>
                                        </CardModal>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Draggable>
                </>
            )}
            {!compact && (
                <>
                    <Draggable draggableId={attribute.id} index={isSorted ? index : attribute.sortOrder}>
                        {(provided) => (
                            <div
                                className={
                                    'attribute-card pointer flex-column justify-space-between' +
                                    activeClass +
                                    activeAttribute
                                }
                                onClick={handleClick}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                            >
                                <div className='flex-column attribute-card-top'>
                                    <div className='attribute-card-header flex justify-space-between'>
                                        <div className='flex attribute-card-title-container'>
                                            <AttributeIcon style={{ color: '#838fdb' }} />
                                            <span className='attribute-card-title'>{attribute.name}</span>
                                        </div>

                                        <div className='flex align-center card-menu-buttons-container'>
                                            {attribute.disabled && (
                                                <img className='link-icon' src={HiddenIcon} alt='' />
                                            )}
                                            <CardModal items={menuItems} offsetX={-180}>
                                                <button className='component-card-menu-button flex-center'>
                                                    <img className='menu-icon' src={MenuIcon} alt=''></img>
                                                </button>
                                            </CardModal>
                                        </div>
                                    </div>

                                    <div className='attribute-card-description-container flex'>
                                        <span className='attribute-card-description'>{attribute.description}</span>
                                    </div>
                                </div>

                                <div className='attribute-card-bottom flex justify-space-between'>
                                    <Importance value={attribute.importance} compact={compact} />
                                    <TranslateTemplate as='div' className='component-card-initials flex-center'>
                                        {getInitials(attribute.createdBy || '')}
                                    </TranslateTemplate>
                                </div>
                            </div>
                        )}
                    </Draggable>
                </>
            )}
        </>
    );
};

export default AttributeCard;
