import React from 'react';

import MoreIcon from '../../../assets/images/more-icon-gray.svg';

import CustomCheckbox from '../../inputs/CustomCheckbox';
import CardModal from '../../dialogs/CardModal';
import TeamLabel from './TeamLabel';
import CustomScrollbar from '../../common/CustomScrollbar';

import TranslateTemplate from '../../common/TranslateTemplate'

const UsersRow = ({
    rows,
    getDate,
    handleSelectUser,
    selectedUsers,
    editMode,
    handleChangeTeam,
    handleUserDelete,
    canChangeTeam,
    containerHeight,
    usersTableRef
}) => {
    const getInitials = fullname => {
        const words = (fullname || '').trim().split(' ');
        if (words.length === 1) {
            return words[0].slice(0, 2).toUpperCase();
        } else {
            return `${words[0].charAt(0)}${words[1].charAt(0)}`.toUpperCase();
        }
    };

    const rowElements = rows.map((row, index) => {
        const showControls = (editMode || (!editMode && canChangeTeam && row.team && row.id)) && index >= 0;

        const menuItems = !showControls
            ? []
            : [
                { value: 'MENU_ITEM_DELETE_USER', action: () => handleUserDelete(row) },
                { value: 'MENU_ITEM_CHANGE_TEAM', action: () => handleChangeTeam(row) }
            ];

        return (
            <div key={row.index} className="users-row flex align-center">
                <div className="users-row-color-indicator"></div>
                <div className="users-row-checkbox flex-center">
                    <CustomCheckbox
                        customClass="users-checkbox"
                        handleChange={() => handleSelectUser(row.index)}
                        checked={selectedUsers[row.index]}
                        disabled={!editMode}
                    />
                </div>
                <div className="users-row-initials flex align-center padding-0">
                    <div className="users-row-initials-block flex-center">
                        <TranslateTemplate as='span'>
                            {getInitials(row.fullname)}
                        </TranslateTemplate>
                    </div>
                </div>
                <div className="users-row-fullname flex align-center">
                    <span className="users-row-full-name-text">{row.fullname}</span>
                </div>
                <div className="users-row-username flex align-center">
                    <span className="users-row-text">{row.email}</span>
                </div>
                <div className="users-row-team flex align-center">
                    <TeamLabel team={row.team} />
                </div>
                <div className="users-row-date flex align-center padding-0">
                    <span className="users-row-text">{getDate(row.joined)}</span>
                </div>

                {showControls && (
                    <CardModal items={menuItems} offsetX={-165}>
                        <button className="more-button">
                            <img src={MoreIcon} alt=""></img>
                        </button>
                    </CardModal>
                )}
                {!showControls && <div className="menu-button-placeholder"></div>}
            </div>
        );
    });

    const usersTableStyle = {};
    containerHeight && (usersTableStyle.height = containerHeight);

    return (
        <div className="users-rows-container" ref={usersTableRef} style={usersTableStyle}>
            <CustomScrollbar right={-15} dependencies={[rows, containerHeight]}>
                {rowElements}
            </CustomScrollbar>
        </div>
    );
};

export default UsersRow;
