import React, { useRef, useEffect } from 'react';
import { getTranslation } from '../../../helpers/getLanguage';

import DropdownElem from './DropdownElem';

const Dropdown = ({ handleOpenDetails, replacementsMatrixList, setOpenIdea, component }) => {
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('keydown', handleKeyDown, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    });

    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setOpenIdea(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setOpenIdea(false);
        }
    };

    return (
        <div className='cell-dropdown flex-column align-center' ref={ref}>
            {replacementsMatrixList.map((details, index) => (
                <DropdownElem
                    key={index}
                    index={index}
                    details={details}
                    handleOpenDetails={handleOpenDetails}
                    isLastAndDisabled={replacementsMatrixList.length - 1 === index && component.disabled}
                />
            ))}
            {!component.disabled ? (
                <button className='open-details-button medium flex align-center' onClick={() => handleOpenDetails()}>
                    <span>{getTranslation('ADD_NEW_IDEA_BUTTON')}</span>
                    <div className='button-triangle-icon' />
                </button>
            ) : (
                <span className='cant-create-idea'>{getTranslation('CANT_CREATE_NEW_IDEA')}</span>
            )}
        </div>
    );
};

export default Dropdown;
