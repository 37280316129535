import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.css';

import { setRecoveryEmail } from '../../actions/passwordRecoveryActions';
import { registerUser, resetRegistration, setLanguage } from '../../actions/authActions';
import { validateField } from '../../common/validation';

import CustomLoginInput from '../inputs/CustomLoginInput';
import LoginLayout from '../common/LoginLayout';
import CustomCheckbox from '../inputs/CustomCheckbox';
import ConfirmationModal from '../dialogs/ConfirmationModal';
import BottomLinks from '../Login/BottomLinks';
import ReCAPTCHA from 'react-google-recaptcha';

import { getTranslation } from '../../helpers/getLanguage';

import { languageVersion } from '../../constants/language';

const initialCredentials = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
};

const initialErrors = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    terms: false,
    captcha: false
};

const CONFIRMATION_MESSAGE = 'LOGIN_PAGE_CONFIRMATION_MESSAGE_CHECK_EMAIL';
const REDIRECT_URL = '/profile?tab=1&modal=subscription';
const ERROR_CODE_FIELDS = {
    10130: 'email',
    10131: 'password',
    10132: 'name',
    10133: 'lastName',
    10102: 'email',
    10103: 'password',
    10104: 'password',
    10105: 'confirmPassword',
    10106: 'email',
};

const SignUp = ({
    registerUser,
    resetRegistration,
    setRecoveryEmail,
    history,
    isAuthenticated,
    registration,
    language,
    setLanguage,
}) => {
    const [credentials, setCredentials] = useState(initialCredentials);
    const [errors, setErrors] = useState(initialErrors);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [token, setToken] = useState(null);
    const { errors: authError, payload } = registration;

    useEffect(() => {
        if (payload) {
            setModalOpen(true);
        } else if (authError) {
            const field = ERROR_CODE_FIELDS[authError.code];
            if (field) setErrors((errors) => ({ ...errors, [field]: authError.message }));
        }
        return () => setErrors({ ...initialErrors });
    }, [authError, payload]);

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        }
    }, [isAuthenticated, history]);

    useEffect(() => {
        if (payload && payload.success) {
            setModalOpen(true);
        }
    }, [payload, resetRegistration]);

    const handleCloseConfirmation = () => {
        setModalOpen(false);
        resetRegistration();
        history.push(REDIRECT_URL);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleSetErrors = (e, value) => {
        const { name } = e.target || e;
        setErrors((errors) => ({ ...errors, [name]: value }));
    };

    const handleSubmit = () => {
        let hasErrors = false;

        if (!token) {
            setErrors((errors) => ({ ...errors, captcha: true }));
        }

        for (let key in credentials) {
            const message = validateField({ name: key, value: credentials[key] }, handleSetErrors);
            message.length && (hasErrors = true);
        }

        if (credentials.confirmPassword && credentials.password !== credentials.confirmPassword) {
            setErrors((errors) => ({ ...errors, confirmPassword: getTranslation('LOGIN_PAGE_PASSWORDS_NOT_MATCH') }));
            return;
        }

        if (!acceptedTerms) {
            setErrors((errors) => ({ ...errors, terms: true }));
            return;
        }

        setToken(null);

        const userLanguage = language || 'en';
        !hasErrors && registerUser({ language: userLanguage, ...credentials, 'g-recaptcha-response': token });
    };

    const handleRecoveryClick = () => {
        setRecoveryEmail(credentials.email);
        history.push('/recover-password');
    };

    const handleLoginClick = () => {
        history.push('/login');
    };

    const handleCheckboxClick = (value) => {
        setAcceptedTerms(value);
        setErrors((errors) => ({ ...errors, terms: false }));
    };

    const handleSubmitEnter = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const changeLanguage = (language) => {
        setLanguage(language);
    };

    const onChange = (token) => {
        setToken(token);
    };

    return (
        <LoginLayout>
            <div className='login-form width-100 flex-column align-center'>
                <span className='login-title mb-20'>{getTranslation('LOGIN_PAGE_SIGN_UP')}</span>
                <div className='login-text mb-20'>
                    <span>{getTranslation('LOGIN_PAGE_HAVE_ACCOUNT')} </span>
                    <span className='underlined pointer' onClick={handleLoginClick}>
                        {getTranslation('LOGIN_PAGE_LOG_IN')}
                    </span>
                </div>
                <CustomLoginInput
                    containerClass='mb-20'
                    name='name'
                    placeholder={getTranslation('CUSTOM_INPUT_NAME')}
                    onBlur={(e) => validateField(e, handleSetErrors)}
                    errorMessage={errors.name}
                    value={credentials.name}
                    onChange={handleChange}
                    onKeyDown={handleSubmitEnter}
                />
                <CustomLoginInput
                    containerClass='mb-20'
                    name='lastName'
                    placeholder={getTranslation('CUSTOM_INPUT_LAST_NAME')}
                    onBlur={(e) => validateField(e, handleSetErrors)}
                    errorMessage={errors.lastName}
                    value={credentials.lastName}
                    onChange={handleChange}
                    onKeyDown={handleSubmitEnter}
                />
                <CustomLoginInput
                    containerClass='mb-20'
                    name='email'
                    placeholder={getTranslation('CUSTOM_INPUT_EMAIL_ADDRESS')}
                    onBlur={(e) => validateField(e, handleSetErrors)}
                    errorMessage={errors.email}
                    value={credentials.email}
                    onChange={handleChange}
                    onKeyDown={handleSubmitEnter}
                />
                <CustomLoginInput
                    containerClass='mb-20'
                    name='password'
                    type='password'
                    placeholder={getTranslation('LOGIN_PAGE_INPUT_PASSWORD')}
                    onBlur={(e) => validateField(e, handleSetErrors)}
                    errorMessage={errors.password}
                    value={credentials.password}
                    onChange={handleChange}
                    onKeyDown={handleSubmitEnter}
                />
                <CustomLoginInput
                    containerClass='mb-20'
                    name='confirmPassword'
                    type='password'
                    placeholder={getTranslation('LOGIN_PAGE_CONFIRM_PASSWORD')}
                    onBlur={(e) => validateField(e, handleSetErrors)}
                    errorMessage={errors.confirmPassword}
                    value={credentials.confirmPassword}
                    onChange={handleChange}
                    onKeyDown={handleSubmitEnter}
                />
                <div className='flex login-text mb-25 width-100'>
                    <CustomCheckbox
                        checked={acceptedTerms}
                        handleChange={handleCheckboxClick}
                        hasErrors={errors.terms}
                    />
                    <span
                        className={'pointer terms-and-conditions' + (errors.terms ? ' red' : '')}
                        onClick={() => handleCheckboxClick(!acceptedTerms)}
                    >
                        {getTranslation('I_AGREE_TO')}
                        <a
                            className='underlined'
                            href='https://omnivati.com/terms-of-services/'
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={(e) => e.stopPropagation()}
                        >
                            {getTranslation('TERMS_OF_SERVICES')}
                        </a>
                        {getTranslation('AND')}
                        <a
                            className='underlined'
                            href='https://omnivati.com/privacy-policy/'
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={(e) => e.stopPropagation()}
                        >
                            {getTranslation('PRIVACY_POLICY')}
                        </a>
                    </span>
                </div>
                <div className='custom-captcha'>
                    <ReCAPTCHA
                        className={errors.captcha ? 'captcha-error' : ''}
                        sitekey={process.env.REACT_APP_SECRET_KEY}
                        onChange={onChange}
                    />

                    {errors.captcha && (
                        <div className='custom-login-input-errors flex-start'>
                            <span>{getTranslation('VALIDATE_ERROR_MESSAGE_REQUIRED')}</span>
                        </div>
                    )}
                </div>
                <button className='login-button mb-20' onClick={handleSubmit} disabled={registration.loading}>
                    <span>{getTranslation('LOGIN_PAGE_SIGN_UP')}</span>
                </button>
                <BottomLinks
                    handleRecoveryClick={handleRecoveryClick}
                    supportText='LOGIN_PAGE_BOTTOM_LINKS_SUPPORT_TEXT'
                />
                <div className='language-sign-up'>
                    <div className='language-title' aria-label="English" onClick={() => changeLanguage('en')}>
                        {languageVersion.ENGLISH}
                    </div>
                    <div className='language-title' aria-label="Chinese" onClick={() => changeLanguage('cn')}>
                        {languageVersion.CHINESE}
                    </div>
                    <div className='language-title' aria-label="Spanish" onClick={() => changeLanguage('es')}>
                        {languageVersion.SPANISH}
                    </div>
                </div>
            </div>
            {modalOpen && (
                <ConfirmationModal
                    closeOnClickOutside={false}
                    closeOnEscape={false}
                    hideCloseIcon
                    closeDialog={handleCloseConfirmation}
                    message={CONFIRMATION_MESSAGE}
                    buttonText={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    onConfirm={handleCloseConfirmation}
                />
            )}
        </LoginLayout>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    registration: state.auth.registration,
    language: state.auth.userInfo.language,
});

const mapDispatchToProps = {
    registerUser,
    setRecoveryEmail,
    resetRegistration,
    setLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
