import React from 'react';
import CloseIcon from '../../../assets/images/close-icon.svg';
import CustomInput from '../../inputs/CustomInput';
import Dialog from '../Dialog';
import './styles.css';

function SingleInputDialog({ value, onChange, onClick, handleCloseModal, titleText, label, buttonText, errorMessage }) {
    return (
        <Dialog>
            <div className='information-modal flex-column'>
                <div className='flex align-center justify-space-between width-100'>
                    <span className='manage-user-label '>{titleText}</span>
                    <img src={CloseIcon} alt='' className='pointer' onClick={handleCloseModal} />
                </div>

                <div className='flex-center'>
                    <div className='information-modal-button-contener flex-column align-center '>
                        <div className='folder-input-container'>
                            <CustomInput
                                value={value}
                                onChange={onChange}
                                label={label}
                                containerClass='folder-input-label'
                                errorMessage={errorMessage}
                            />
                        </div>
                        <button
                            className='information-modal-button width-100'
                            onClick={onClick}
                            disabled={!!errorMessage}
                        >
                            <span>{buttonText}</span>
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}

export default React.memo(SingleInputDialog);
