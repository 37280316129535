import React from 'react';
import { getTranslation } from '../../../../helpers/getLanguage';

const ExpandItem = ({ compact, activeClass, setPreference }) => {
    return (
        <div
            className={'sidenav-item flex align-center position-relative profile pointer' + activeClass}
            style={{ marginBottom: '5px' }}
            onClick={() => setPreference({ sidenavCollapsed: !compact })}
        >
            <div className={'expand-icon position-relative flex-center' + activeClass}>
                <div className='expand-icon-triangle'> </div>
            </div>

            {!compact && (
                <div className='flex align-center'>
                    <span className='sidenav-title flex'>{getTranslation('NAV_MENU_COLLAPSE')}</span>
                </div>
            )}

            {compact && (
                <div className='sidenav-item-tooltip flex-center'>
                    <span>{getTranslation('NAV_MENU_EXPAND')}</span>
                </div>
            )}
        </div>
    );
};

export default ExpandItem;
