import React from 'react';

import { getTranslation } from '../../../helpers/getLanguage';

const TextareaInfo = ({ values, label, autoResize = false }) => {
    const handleRef = ref => {
        if (autoResize && ref) {
            ref.style.height = 'auto';
            ref.style.height = `${ref.scrollHeight + 4}px`;
        }
    };
    return (
        <div className="flex-column mb-20">
            <span className="custom-textarea-label-default">{getTranslation(label)}</span>
            <textarea
                ref={handleRef}
                defaultValue={values}
                name="emails"
                placeholder={getTranslation('INFORMATION_MODAL_TEXTAREA_PLACEHOLDER')}
                className={'custom-textarea'}
            />
        </div>
    );
};

export default TextareaInfo;
