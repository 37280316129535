import {
    GET_PREFABS_URL,
    GET_PREFABS_URL_SUCCESS,
    GET_PREFABS_URL_ERROR,
    SET_PREFAB_URL,
    SET_PREFAB_URL_SUCCESS,
    SET_PREFAB_URL_ERROR,
} from '../actions/types';

const initialState = {
    values: {
        internalComponent: { label: '' },
        externalComponent: { label: '' },
        internalSteps: { label: '' },
        attributeProductService: { label: '' },
        attributeStepsCustomer: { label: '' },
        attributeStepsCompany: { label: '' },
        internalStepsCustomer: { label: '' },
        internalStepsCompany: { label: '' },
    },
    loading: false,
    error: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PREFAB_URL_SUCCESS:
            return {
                ...state,
                values: {
                    ...state.values,
                    [action.payload.name]: action.payload.response,
                },
                loading: false,
            };
        case SET_PREFAB_URL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: true,
            };
        case SET_PREFAB_URL:
            return {
                ...state,
                loading: true,
            };
        case GET_PREFABS_URL_SUCCESS:
            return {
                ...state,
                values: {
                    ...state.values,
                    attributeProductService:
                        action.payload.find(({ name }) => name === 'attributeProductService') ||
                        initialState.values.attributeProductService,
                        attributeStepsCustomer:
                        action.payload.find(({ name }) => name === 'attributeStepsCustomer') ||
                        initialState.values.attributeStepsCustomer,
                        attributeStepsCompany:
                        action.payload.find(({ name }) => name === 'attributeStepsCompany') ||
                        initialState.values.attributeStepsCompany,
                    externalComponent:
                        action.payload.find(({ name }) => name === 'externalComponent') ||
                        initialState.values.externalComponent,
                    internalStepsCompany:
                        action.payload.find(({ name }) => name === 'internalStepsCompany') ||
                        initialState.values.internalStepsCompany,
                    internalStepsCustomer:
                        action.payload.find(({ name }) => name === 'internalStepsCustomer') ||
                        initialState.values.internalStepsCustomer,
                    internalComponent:
                        action.payload.find(({ name }) => name === 'internalComponent') ||
                        initialState.values.internalComponent,
                },
                error: null,
                loading: false,
            };
        case GET_PREFABS_URL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: true,
            };
        case GET_PREFABS_URL:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
}
