import React from 'react';
import './styles.css';

import SearchIcon from '../../../assets/images/search.svg';

const CustomSearchInput = ({
    value,
    name,
    onChange,
    placeholder = '',
    containerClass = '',
    inputClass = '',
    imageClassName,
    onClick,
    onKeyDown,
    disabledButton = false,
}) => {
    const emptyStateClass = !value.length ? ' empty ' : ' ';

    return (
        <div className={'custom-search-input width-100 ' + containerClass}>
            <input
                className={'custom-search-input-input width-100' + emptyStateClass + inputClass}
                name={name}
                placeholder={placeholder}
                value={value}
                onKeyDown={onKeyDown}
                onChange={onChange}
            />
            <button disabled={disabledButton} style={{ border: 0, width: 0, padding: 0 }} onClick={onClick}>
                <img src={SearchIcon} className={`pointer ${imageClassName}`} alt=''></img>
            </button>
        </div>
    );
};

export default CustomSearchInput;
