import React from 'react';
import './styles.css';

const CircularProgress = ({ r, cx, cy, strokeWidth, progress, children, classNameBody }) => {
    const circumference = 2 * 3.14 * r;
    const offset = circumference * ((100 - progress) / 100);

    return (
        <div className="progress-circle">
            <div className={`progress-circle__body ${classNameBody}`}>
                {children}
            </div>
            <div className="progress-circle__circles">
                <svg>
                    <circle
                        r={r}
                        cy={cy}
                        cx={cx}
                        strokeWidth={strokeWidth}
                        className="progress-circle__background-dashes"
                    >
                    </circle>
                    <circle
                        r={r}
                        cy={cy}
                        cx={cx}
                        strokeWidth={strokeWidth}
                        className="progress-circle__progress-dashes"
                        strokeDasharray={circumference + 'px'}
                        strokeDashoffset={offset + 'px'}
                    >
                    </circle>
                </svg>
            </div>
        </div>
    )
};

export default CircularProgress;