import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

const baseUrl = `${baseApiUrl}/plans`;

export const createNewPlanSubscription = async (data, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/add`, data)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const getActualPlans = async (onSuccess, onError) => {
    axios
        .get(`${baseUrl}/actual`)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const getAllPlans = async (onSuccess, onError) => {
    axios
        .get(baseUrl)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const hidePlan = async (id, onSuccess, onError) => {
    axios
        .delete(`${baseUrl}/soft/${id}`)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const showPlan = async (id, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/revoke/${id}`)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const addDescriptionPlan = async ({ planId, message }, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/add-description/${planId}`, { message })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const changeDescriptionPlan = async ({ descriptionId, message }, onSuccess, onError) => {
    axios
        .patch(`${baseUrl}/change-description/${descriptionId}`, { message })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const removeDescriptionPlan = async (descriptionId, onSuccess, onError) => {
    axios
        .delete(`${baseUrl}/remove-description/${descriptionId}`)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const reorderDescription = async (ids, onSuccess, onError) => {
    axios
        .get(`${baseUrl}/reorder-description`, null, { params: { ids } })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};
