import React from 'react';
import './styles.css';
import CheckmarkIcon from '../../../assets/images/checkmark.svg';

import { getTranslation } from '../../../helpers/getLanguage';

const CustomRadioInput = ({
    value,
    options,
    setValue,
    showBorder = false,
    transparent = false,
    canUnset = true,
    styleRadioInput = {},
    textClass,
    className,
}) => {
    const border = showBorder ? ' show-radio-border' : '';

    const handleItemClick = (option) => {
        if (option.disabled) return;

        const selectedValue = option.value;
        if (canUnset && value === selectedValue) {
            setValue(null);
        } else {
            setValue(selectedValue);
        }
    };

    const selectOptions = options.map((option, index) => {
        const isActive = option.value === value;
        const activeClass = isActive ? (transparent ? ' active transparent' : ' active') : '';
        const disabledClass = option.disabled ? ' disabled' : '';

        return (
            <div
                key={index}
                className={`custom-radio-input flex align-center pointer ${border} ${activeClass} ${disabledClass} ${className}`}
                style={{
                    ...styleRadioInput,
                    backgroundColor: option.backgroundColor ? option.backgroundColor : 'none',
                }}
                onClick={() => handleItemClick(option)}
            >
                {option.FirstImage ? (
                    <option.FirstImage style={{ color: !isActive ? '#888da8' : '#3e6fd9' }} />
                ) : isActive ? (
                    <img src={CheckmarkIcon} alt='or' />
                ) : (
                    <div className='radio-button-icon'></div>
                )}
                <span
                    className={textClass}
                    style={{
                        color: option.textColor ? option.textColor : 'none',
                    }}
                >
                    {getTranslation(option.label)}
                </span>
                {option.Image && <option.Image style={{ width: 21, marginLeft: 10, color: '#CC4B4F' }} />}
            </div>
        );
    });
    return <div className='flex width-100 flex-wrap'>{selectOptions}</div>;
};

export default CustomRadioInput;
