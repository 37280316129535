import React from 'react';

import CustomToggle from '../inputs/CustomToggle';
import CustomSearchInput from '../inputs/CustomSearchInput';
import ViewTutorialButtonWithModal from '../common/ViewTutorialButtonWithModal';

import { getTranslation } from '../../helpers/getLanguage';

const SubtractionHeader = ({ inputValues, handleChange, isSorted, setSorted, sortLabel }) => {
    return (
        <div className='eureka-header-container width-100'>
            <div className='flex-column eureka-header-container-tutorial'>
                <div style={{ flexGrow: 1, marginBottom: '10px' }}>
                    <ViewTutorialButtonWithModal name='Eureka' />
                </div>
                <span className='components-dashboard-title'>{getTranslation('PAGE_EUREKA_TITLE')}</span>
            </div>

            <div className='flex eureka-header-container-settings'>
                <CustomSearchInput
                    name='search'
                    placeholder={getTranslation('EUREKA_PAGE_SEARCH_IDEAS')}
                    value={inputValues.search}
                    onChange={handleChange}
                    containerClass='search-input-container-subtraction-components mb-10'
                />

                <div className='toggle-container flex align-center'>
                    <CustomToggle
                        value={isSorted}
                        setValue={(e) => setSorted(e)}
                        label={sortLabel}
                        labelClass='custom-toggle-label-default sort-toggle'
                        left
                    />
                </div>
            </div>
        </div>
    );
};

export default SubtractionHeader;
