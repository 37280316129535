import React from 'react';
import { getTranslation } from "../../helpers/getLanguage"

const InfoBoard = ({ navigateToProfileSubscription, userName }) => {
    return (
        <div className="info-container mb-20">
            <span>
                {getTranslation("DEAR")} {userName}, {getTranslation("ACCESS_TO_PROJECT_INFO")}{' '}
                <span className="pointer underlined" onClick={navigateToProfileSubscription}>
                    {getTranslation("SUBSCRIPTION_LOWER_CASE")}
                </span>
                .
            </span>
        </div>
    );
};

export default InfoBoard;
