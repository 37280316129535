import React from 'react';
import Dialog from '../Dialog';
import DotFlashing from '../../common/DotFlashing';
import CloseIcon from '../../../assets/images/close-icon.svg';
import './styles.css';

const MessageModal = ({
    closeDialog,
    header,
    message,
}) => {

    return (
        <Dialog>
            <div className="flex-column message-modal">
                <div className="flex message-modal-header">
                    <h2>{header}</h2>
                    <img src={CloseIcon} alt="" className="pointer" onClick={closeDialog} />
                </div>

                <div className="flex-center message-modal-text">
                    <DotFlashing textLoader={message} />
                </div>
            </div>
        </Dialog>
    );
};

export default MessageModal;
