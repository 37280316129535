import React from 'react';

import CustomCheckbox from '../../inputs/CustomCheckbox';
import ExpandedRow from './ExpandedRow';
import TranslateTemplate from '../../common/TranslateTemplate';

import ConsumerBenefit from '../../../assets/images/consumer-benefit.svg';
import CompanyBenefit from '../../../assets/images/company-benefit.svg';
import OtherBenefit from '../../../assets/images/other-benefit.svg';

import { ReactComponent as CellIcon } from '../../../assets/images/cells-icon.svg';
import { ReactComponent as Minus } from '../../../assets/images/subtraction-icon-blue.svg';
import { ReactComponent as Arrow } from '../../../assets/images/replacement-report-icon.svg';
import { ReactComponent as SubtractionIcon } from '../../../assets/images/nav-subtraction.svg';
import { ReactComponent as ReplacementIcon } from '../../../assets/images/nav-replacement.svg';
import { ReactComponent as MultiplicationIcon } from '../../../assets/images/nav-multiplication.svg';
import { ReactComponent as EurekaIcon } from '../../../assets/images/eureka-icon-blue.svg';
import { ReactComponent as DetailsMatrixIcon } from '../../../assets/images/nav-attribute-dependency.svg';
import { getTranslation } from '../../../helpers/getLanguage';
import { getInitials } from '../../../helpers/getInitials';

const ideasIcons = {
    dependency: <DetailsMatrixIcon width={26} />,
    subtraction: <SubtractionIcon width={26} />,
    replacement: <ReplacementIcon width={26} />,
    multiplication: <MultiplicationIcon width={26} />,
    eureka: <EurekaIcon width={26} />,
};

const colors = {
    1: '#ffcd67',
    2: '#f9ae9f',
    3: {
        1: '#daf1e1',
        2: '#b5e3c3',
        3: '#91d5a5',
        4: '#6cc687',
        5: '#5ac57a',
    },
};

const colorClass = {
    1: 'yellow',
    2: 'red',
    3: 'green',
    4: 'white'
};

const benefitInfo = {
    Consumer: { name: 'Consumer', icon: ConsumerBenefit },
    Company: { name: 'Company', icon: CompanyBenefit },
    Other: { name: 'Other', icon: OtherBenefit },
};

const ReportRow = ({
    row,
    canEdit,
    currentLanguage,
    handleSelectIdea,
    selected,
    handleExpandIdea,
    expanded,
    handleOpenDetails,
    handleOpenSubtractionsDetails,
    handleOpenReplacementsDetails,
    handleOpenMultiplicationsDetails,
    handleOpenEurekaDetails,
    handleRedirectToMatrix,
    handleRedirectToSubtraction,
    handleRedirectToMultiplication,
    handleRedirectToEureka,
    handleRedirectToReplacement,
    currentIdea,
}) => {
    const ideaAbbreviation =
        row.type === 'dependency'
            ? 'AD'
            : row.type === 'subtraction'
            ? 'S'
            : row.type === 'replacement'
            ? 'R'
            : row.type === 'multiplication'
            ? 'M'
            : 'EU';
    const iconWithAbbreviation = (
        <div className='reports-row-method flex'>
            {ideasIcons[row.type]}
            <span className='reports-row-text abbreviation'>{ideaAbbreviation}</span>
        </div>
    );

    const buttons = (
        <>
            {row.type === 'dependency' && (
                <>
                    <button
                        className='print-report-button edit flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpen(row);
                        }}
                    >
                        <CellIcon />
                        <span>{getTranslation('BUTTON_EDIT')}</span>
                    </button>
                    <button
                        className='print-report-button matrix flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRedirectToMatrix();
                        }}
                    >
                        <CellIcon />
                        <span>{getTranslation('BUTTON_MATRIX_VIEW')}</span>
                    </button>
                </>
            )}
            {row.type === 'subtraction' && (
                <>
                    <button
                        className='print-report-button edit flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpen(row);
                        }}
                    >
                        <Minus />
                        <span>{getTranslation('BUTTON_EDIT')}</span>
                    </button>
                    <button
                        className='print-report-button matrix flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRedirectToSubtraction();
                        }}
                    >
                        <Minus />
                        <span className='compact-row'>{getTranslation('BUTTON_SUBTRACTION_VIEW')}</span>
                    </button>
                </>
            )}
            {row.type === 'replacement' && (
                <>
                    <button
                        className='print-report-button edit flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpen(row);
                        }}
                    >
                        <Arrow />
                        <span>{getTranslation('BUTTON_EDIT')}</span>
                    </button>
                    <button
                        className='print-report-button matrix flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRedirectToReplacement();
                        }}
                    >
                        <Arrow />
                        <span className='compact-row letter-spacing-compact'>
                            {getTranslation('BUTTON_REPLACEMENT_VIEW')}
                        </span>
                    </button>
                </>
            )}
            {row.type === 'multiplication' && (
                <>
                    <button
                        className='print-report-button edit flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpen(row);
                        }}
                    >
                        <MultiplicationIcon style={{ height: '16px', lineHeight: '16px' }} />
                        <span>{getTranslation('BUTTON_EDIT')}</span>
                    </button>
                    <button
                        className='print-report-button multiplication flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRedirectToMultiplication();
                        }}
                    >
                        <MultiplicationIcon style={{ height: '16px', lineHeight: '16px' }} />
                        <span className='compact-row letter-spacing-compact'>
                            {getTranslation('BUTTON_MULTIPLICATION_VIEW')}
                        </span>
                    </button>
                </>
            )}
            {row.type === 'eureka' && (
                <>
                    <button
                        className='print-report-button edit flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleOpen(row);
                        }}
                    >
                        <EurekaIcon style={{ height: '17px', lineHeight: '17px' }} />
                        <span>{getTranslation('BUTTON_EDIT')}</span>
                    </button>
                    <button
                        style={{ width: '100px' }}
                        className='print-report-button matrix flex'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleRedirectToEureka();
                        }}
                    >
                        <EurekaIcon style={{ height: '17px', lineHeight: '17px' }} />
                        <span className='compact-row letter-spacing-compact'>
                            {getTranslation('BUTTON_EUREKA_VIEW')}
                        </span>
                    </button>
                </>
            )}
        </>
    );

    const getRating = () => {
        const rating = row.rating || 0;
        const feasibleRating = row.feasibleRating || 0;

        if (!row?.feasibleRating || row.feasibleRating < 0) {
            return Math.min(rating, 5);
        }

        const sum = rating + feasibleRating;
        return Math.round(sum / 2);
    };

    const getStatusColor = () => {
        if (row.status < 3) {
            return colors[row.status];
        }
        return colors[row.status][row.rating];
    };

    const getColorDropdownElem = () => {
        if (row.status === 3) {
            return colorClass[3];
        };

        if (row.feasibleRating === 0 || row.rating === 0) {
            return colorClass[2];
        };

        if (row.status === 1) {
            return colorClass[1];
        };

        return colorClass[4];
    };

    const handleOpen = (row) => {
        if (row.type === 'subtraction') {
            handleOpenSubtractionsDetails(row);
        } else if (row.type === 'dependency') {
            handleOpenDetails(row);
        } else if (row.type === 'replacement') {
            handleOpenReplacementsDetails(row);
        } else if (row.type === 'eureka') {
            handleOpenEurekaDetails(row);
        } else if (row.type === 'multiplication') {
            handleOpenMultiplicationsDetails(row);
        }
    };

    const getDate = () => {
        const createdDate = new Date(row.created);
        const day = createdDate.getDate();
        const month = createdDate.getMonth() + 1;
        const year = createdDate.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const isExpanded = expanded[row.index];
    const activeClass = isExpanded ? ' expanded' : '';
    const rightAttribute =
        !row.remoteComponent && !row.idMultiplicationComponent && row.description === undefined
            ? row.rowStatus?.locked
                ? row.firstAttribute
                : `${row.firstAttribute} – ${row.firstComponent}`
            : '';
    const leftAttribute =
        !row.remoteComponent && !row.idMultiplicationComponent && row.description === undefined
            ? row.colStatus?.locked
                ? row.secondAttribute
                : `${row.secondAttribute} – ${row.secondComponent}`
            : '';
    const allBenefitsCount = row.benefits.filter((benefit) => benefit.target.length > 0).length;
    const isShowBenefits = allBenefitsCount > 0 ? allBenefitsCount : null;
    const componentName = row.idReplacingComponent
        ? row.replacingComponent
        : row.idRemoteComponent
        ? row.remoteComponent
        : row.idMultiplicationComponent
        ? row.multiplicationComponent
        : '';

    return (
        <>
            <div
                className={'reports-row flex align-center pointer ' + getColorDropdownElem() + activeClass}
                onClick={() => handleExpandIdea(row.index, !expanded[row.index])}
            >
                <div className='reports-row-color-rating' style={{ backgroundColor: getStatusColor() }}></div>
                <div className='reports-row-checkbox flex-center'>
                    <CustomCheckbox
                        checked={selected[row.index]}
                        handleChange={() => handleSelectIdea(row.index)}
                        customClass='reports-checkbox'
                    />
                </div>
                <div className='reports-row-rating justify-content-start'>
                    {row.status === 3 && row.rating > 0 && (
                        <div
                            className='report-idea-score flex flex-center'
                            style={{ backgroundColor: getStatusColor() }}
                        >
                            <span>{getRating()}</span>
                        </div>
                    )}
                </div>
                <div className='reports-row-name flex'>
                    <span className='reports-row-text'>{row.name}</span>
                </div>
                {iconWithAbbreviation}
                <div className='border-report border-left-report' />
                <div className='reports-row-component flex'>
                    <span className='reports-row-text'>{componentName}</span>
                </div>
                <div className='border-report border-right-report' />
                <div className='reports-row-first-attribute flex'>
                    <span className='reports-row-text'>{leftAttribute}</span>
                </div>
                <div className='reports-row-second-attribute flex'>
                    <span className='reports-row-text'>{rightAttribute}</span>
                </div>
                <div className='border-report border-right-report' />
                <div className='reports-row-benefit flex'>
                    <span className='reports-row-text benefits-count'>{isShowBenefits}</span>
                </div>
                <div className='reports-row-created flex'>
                    <span className='reports-row-text'>{getDate()}</span>
                </div>
                <div className='reports-row-created-by flex'>
                    <div className='reports-row-initials flex-center'>
                        <TranslateTemplate as='span'>{getInitials(row.createdBy || '')}</TranslateTemplate>
                    </div>
                </div>
                {canEdit && <div className='reports-row-buttons-container flex align-center'>{buttons}</div>}
            </div>

            {isExpanded && (
                <ExpandedRow
                    currentIdea={currentIdea}
                    row={row}
                    currentLanguage={currentLanguage}
                    getStatusColor={getStatusColor}
                    colorClass={colorClass}
                    benefitInfo={benefitInfo}
                />
            )}
        </>
    );
};

export default ReportRow;
