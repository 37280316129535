import React from 'react';
import './styles.css';

import example1 from './images/1.png';
import example2 from './images/2.png';

import logo from '../Omnivati-Logo-Tagline.jpg';

const FrequentlyAskedQuestion = () => {
    return (
        <div className="terms-of-service-container">
            <div className="logo">
                <img src={logo} alt="" />
            </div>

            <p className="center article-title">
                <b>Frequently Asked Question</b>
            </p>

            <p className="article-title">
                <b>1. Access</b>
            </p>

            <ul>
                <p className="underlined">
                    <b>1.1 I have forgotten my password. </b>
                </p>
                <ul>
                    <p>
                        <b>Answer:</b>
                    </p>
                    <p>
                        You may easily regenerate a new password by clicking the <b>"Forgot password?"</b> link in the
                        login page{' '}
                        <u>
                            <a href="https://app.omnivati.com/login" target="_blank" rel="noopener noreferrer">
                                (https://app.omnivati.com/login)
                            </a>
                        </u>
                        .
                    </p>
                </ul>

                <p className="underlined">
                    <b>1.2 I am trying to log-in using the promo codes but am unable to log-in </b>
                </p>
                <ul>
                    <p>
                        <b>Answer:</b>
                    </p>
                    <p>
                        Promo codes are not log-in passwords. They are used to enable you to create an active
                        subscription in the Profile window. To log-in, use the password you had created during sig-up.
                        If you cannot remember your password, you may easily regenerate a new password by clicking the{' '}
                        <b>"Forgot password?"</b> link in the login page{' '}
                        <u>
                            <a href="https://app.omnivati.com/login" target="_blank" rel="noopener noreferrer">
                                (https://app.omnivati.com/login)
                            </a>
                        </u>
                        .
                    </p>
                </ul>

                <p className="underlined">
                    <b>1.3 Everything is inactive </b>
                </p>
                <ul>
                    <p>
                        <b>Answer:</b>
                    </p>
                    <p>
                        Since you are able to log-in, let’s ensure that you are an active subscriber. If the platform
                        does not take you automatically to the payment section, let’s ensure that you are an active
                        subscriber.
                    </p>
                    <p>
                        If the platform does not take you automatically to the payment section, let’s ensure that you
                        are an active subscriber.
                    </p>
                    <p>
                        <b>(A)</b> You can easily check your subscription status as follows:
                    </p>
                    <p>
                        <b>(I)</b> Go to your <b>"Profile"</b> window by clicking on your name in the bottom left-hand
                        corner,
                        <br />
                        <b>(II)</b> Click the <b>"Edit profile"</b> button <br />
                        <b>(III)</b> In the <b>"My Details"</b> tab, click the <b>"View Billing"</b> button. This will
                        show you if you have an active subscription and its duration.
                        <br />
                    </p>
                    <p>
                        <b>(B)</b> If you do not have an active subscription:
                    </p>
                    <p>
                        <b>(I)</b> Go to your <b>"Profile"</b> page by clicking your name in the bottom left-hand
                        corner, and then
                        <br />
                        <b>(II)</b> Click the <b>"Edit profile"</b> button and <br />
                        <b>(III)</b> Click the <b>"Subscription"</b> tab, and then <br />
                        <b>(IV)</b> Click the <b>"Order subscription/Enter code"</b> button circled in red below <br />
                    </p>
                    <div className="image-container-faq">
                        <img src={example1} alt=""></img>
                    </div>
                    <p>
                        <b>(V)</b> In the <b>"Subscription Plan"</b> window, please enter your promotion code and click{' '}
                        <b>"apply"</b>. If you don’t have a promotion code, you can continue to payment by clicking the{' '}
                        <b>"Continue without code"</b> button.
                    </p>
                    <div className="image-container-faq">
                        <img src={example2} alt=""></img>
                    </div>
                    <p>
                        <b>(VI)</b> Click Ok and you may start using the Omnivati platform.
                    </p>
                </ul>
            </ul>

            <p className="article-title">
                <b>2. Projects</b>
            </p>

            <ul>
                <p className="underlined">
                    <b>2.1 I am trying to open a new project but am unable to </b>
                </p>
                <ul>
                    <p>
                        <b>Answer:</b>
                    </p>
                    <p>
                        You will need to ensure that you have an active subscription. <br />
                        Please see in this FAQ: <br />
                        Section: Access <br />
                        Question: <b>"Everything is inactive"</b> question. <br />
                    </p>
                </ul>

                <p className="underlined">
                    <b>2.2 I have been invited to join a project but am unable to do so </b>
                </p>
                <ul>
                    <p>
                        <b>Answer:</b>
                    </p>
                    <p>
                        <b>Firstly, have you signed-up to Omnivati and are you able to login?</b>
                        <br />
                        If this is not the case, please Sign-up and login as follows:
                        <br />
                        <b>(I)</b> Signup - Signup using an email account at:{' '}
                        <u>
                            <a href="https://app.omnivati.com/signup" target="_blank" rel="noopener noreferrer">
                                (https://app.omnivati.com/signup)
                            </a>
                        </u>
                        <br />
                        <b>(II)</b> Login - After signup and confirming you email login at:{' '}
                        <u>
                            <a href="https://app.omnivati.com/login" target="_blank" rel="noopener noreferrer">
                                (https://app.omnivati.com/login)
                            </a>
                        </u>
                    </p>
                    <p>
                        <b>(A)</b> You can easily check your subscription status as follows:
                    </p>
                    <p>
                        <b>Next, if you are able to log-in, let’s ensure that you are an active subscriber.</b>
                        <br />
                        <b>(I)</b> Go to your <b>"Profile"</b> window by clicking on your name in the bottom left-hand
                        corner, then
                        <br />
                        <b>(II)</b> Click the <b>"Edit profile"</b> button <br />
                        <b>(III)</b> In the <b>"My Details"</b> tab, click the <b>"View Billing" </b>button. This will
                        show you if you have an active subscription and its duration. <br />
                    </p>
                    <p>(B) If you do not have an active subscription: </p>
                    <p>
                        <b>(I)</b> Go to your <b>"Profile"</b> page by clicking your name in the bottom left-hand
                        corner, and then
                        <br />
                        <b>(II)</b> Click the <b>"Edit profile"</b> button and <br />
                        <b>(III)</b> Click the <b>"Subscription"</b> tab, and then <br />
                        <b>(IV)</b> Click the <b>"Order subscription/Enter code"</b> button circled in red below
                    </p>
                    <div className="image-container-faq">
                        <img src={example1} alt=""></img>
                    </div>
                    <p>
                        <b>(V)</b> In the <b>"Subscription Plan"</b> window, please enter your promotion code and click{' '}
                        <b>"Apply Code"</b>. If you don’t have a promotion code, you can continue to payment by clicking
                        the <b>"Continue without code"</b> button.
                    </p>
                    <div className="image-container-faq">
                        <img src={example2} alt=""></img>
                    </div>
                    <p>
                        <b>(VI)</b> Click <b>Ok</b> and you may start using the Omnivati platform.
                    </p>
                    <p>
                        <b>Next, to see the project that you have been invited to</b>
                        <br />
                        Click the URL link that you have been given, and access the project.
                    </p>
                </ul>
            </ul>
        </div>
    );
};

export default FrequentlyAskedQuestion;
