import React from 'react';
import './styles.css';

import CheckmarkIcon from '../../../assets/images/checkmark.svg';

import { getTranslation } from '../../../helpers/getLanguage';

const InvitationInput = ({ value, handleChange, containerClass = '', options }) => {
    const selectOptions = options.map((option, index) => {
        const isActive = option.value === value;
        const activeClass = isActive ? ' active' : '';

        if (option.options && !option.options.length) {
            return null;
        }

        return (
            <div
                key={index}
                className={'invitation-input flex-column pointer align-center' + activeClass + ` ${containerClass}`}
                onClick={() => handleChange(option.value)}
            >
                <div className="flex-column align-center invitation-title-container">
                    {isActive ? (
                        <img src={CheckmarkIcon} alt="" />
                    ) : (
                        <div className="invitation-radio-button-icon"></div>
                    )}
                    <span className="invitation-input-text">{getTranslation(option.label)}</span>
                </div>
            </div>
        );
    });

    return <>{selectOptions}</>;
};

export default InvitationInput;
