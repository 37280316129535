export const isProjectEditable = (project, userId) => {
    return (
        !project.teamId ||
        (project.sharingType === 'share' && project.userId === userId) ||
        project.ownerId === userId ||
        (project.sharingType === 'circulate' && project.userId === userId)
    );
};

export const canProjectBeShared = (project) => {
    return !project.sharingType || project.sharingType === 'share';
};

export const canProjectBeCirculated = (project) => {
    return !project.sharingType || project.sharingType === 'circulate';
};
