import React from 'react';
import './styles.css';

import Projects from './projects/Projects';
import ProjectInvitations from './invitations';
import DashboardTabsHeader from './DashboardTabsHeader';

const tabLabels = [
    'PAGE_ALL_PROJECTS_TAB_LABEL_ACTIVE',
    'PAGE_ALL_PROJECTS_TAB_LABEL_INVITED',
    'PAGE_ALL_PROJECTS_TAB_LABEL_ARCHIVED',
];

const DashboardTabs = ({
    activeTab,
    setActiveTab,
    projects,
    archivedProjects,
    invitations,
    projectModalOpen,
    setProjectModalOpen,
    handleInvitationClick,
    match,
    history,
    filters,
    handleModalOpen,
    handleDuplicate,
    handleDelete,
    handleArchive,
    isProjectArchived,
    userStatus,
    userId,
    projectsReady,
    handleImport,
    userFeatures,
    folders,
    moveProjectToFolder,
    moveProjectOutOfFolder,
    renameFolder,
    removeFolder,
    archiveFolder,
}) => {
    return (
        <>
            <DashboardTabsHeader
                labels={tabLabels}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                activeProjects={projects.length}
                invitedProjects={invitations.length}
                archivedProjects={archivedProjects.length}
            />

            {activeTab === 0 && (
                <Projects
                    projects={projects}
                    projectModalOpen={projectModalOpen}
                    setProjectModalOpen={setProjectModalOpen}
                    history={history}
                    filters={filters}
                    handleModalOpen={handleModalOpen}
                    handleDuplicate={handleDuplicate}
                    handleDelete={handleDelete}
                    handleArchive={handleArchive}
                    isProjectArchived={isProjectArchived}
                    userStatus={userStatus}
                    userId={userId}
                    handleImport={handleImport}
                    projectsReady={projectsReady}
                    userFeatures={userFeatures}
                    {...{
                        folders,
                        moveProjectToFolder,
                        moveProjectOutOfFolder,
                        renameFolder,
                        activeTab,
                        removeFolder,
                        match,
                        archiveFolder,
                    }}
                />
            )}
            {activeTab === 1 && (
                <ProjectInvitations
                    invitations={invitations}
                    handleInvitationClick={handleInvitationClick}
                    filters={filters}
                    userStatus={userStatus}
                />
            )}
            {activeTab === 2 && (
                <Projects
                    projects={archivedProjects}
                    projectModalOpen={projectModalOpen}
                    setProjectModalOpen={setProjectModalOpen}
                    history={history}
                    handleImport={handleImport}
                    filters={filters}
                    handleModalOpen={handleModalOpen}
                    handleDuplicate={handleDuplicate}
                    handleDelete={handleDelete}
                    handleArchive={handleArchive}
                    isProjectArchived={isProjectArchived}
                    archived
                    userStatus={userStatus}
                    folders={[]}
                    activeTab={activeTab}
                    {...{
                        folders,
                        moveProjectToFolder,
                        moveProjectOutOfFolder,
                        renameFolder,
                        activeTab,
                        removeFolder,
                        match,
                        archiveFolder,
                    }}
                />
            )}
        </>
    );
};

export default DashboardTabs;
