import React, { useState, useEffect, useRef } from 'react';
import ConfirmationModal from '../../dialogs/ConfirmationModal';
import { getTranslation } from '../../../helpers/getLanguage';
import { getUrlFromConfig } from '../../../helpers/getUrlFromConfig';
import CloseIcon from '../../../assets/images/close-icon.svg';
import RedArrowIcon from '../../../assets/images/red-arrow.svg';
import './styles.css';

export const ImagesCarousel = ({
    openImageModal,
    imagesLibrary,
    currentImageId,
    width = 300,
    selectCurrentDependencyImageFromLibrary,
    startIndex,
    removeImageFromLibrary,
}) => {
    const [carouselIndex, setCarouselIndex] = useState(startIndex);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [currentDeletingImageId, setCurrentDeletingImageId] = useState('');

    const carouselRef = useRef(null);

    useEffect(() => {
        const slider = carouselRef.current;
        slider.scrollTo({
            left: carouselIndex * 80,
            behavior: 'smooth',
        });
    }, [carouselIndex]);

    const handleSelect = (selectedIndex) => {
        if (selectedIndex >= imagesLibrary.length) {
            setCarouselIndex(0);
            selectCurrentDependencyImageFromLibrary(0);
            return;
        }

        if (selectedIndex < 0) {
            setCarouselIndex(imagesLibrary.length - 1);
            selectCurrentDependencyImageFromLibrary(imagesLibrary.length - 1);
            return;
        }
        selectCurrentDependencyImageFromLibrary(selectedIndex);
        setCarouselIndex(selectedIndex);
    };

    const isNeedToShowArrows = imagesLibrary.length > 1;

    const handleCrossClick = (id) => {
        setIsConfirmationModalOpen(true);
        setCurrentDeletingImageId(id);
    };

    return (
        <div className='carousel-container'>
            <div className='arrows'>
                {isNeedToShowArrows && (
                    <div className='arrow' onClick={() => handleSelect(carouselIndex - 1)}>
                        <img src={RedArrowIcon} alt='>' className='arrow__image arrow__image_left' />
                    </div>
                )}

                <div
                    className='carousel-container__images-container'
                    style={{ width: imagesLibrary.length > Math.floor(width/90) ? width : 90 * imagesLibrary.length }}
                    ref={carouselRef}
                >
                    {imagesLibrary.map((image, index) => (
                        <div key={image.id ?? index} className='carousel-container__image-container'>
                            <img
                                className={`carousel-container__slider-image ${
                                    currentImageId === image.id ? 'carousel-container__slider-image_selected' : ''
                                }`}
                                src={image.imageSource ?? getUrlFromConfig(`images/${image.id}`)}
                                alt={image.originalName}
                                onClick={() => openImageModal(image.imageSource ?? image)}
                            />
                            <button
                                onClick={() => handleCrossClick(image.id)}
                                className='carousel-container__image-deletion-button'
                            >
                                <img src={CloseIcon} alt='x' />
                            </button>
                        </div>
                    ))}
                </div>

                {isNeedToShowArrows && (
                    <div className='arrow' onClick={() => handleSelect(carouselIndex + 1)}>
                        <img src={RedArrowIcon} alt='>' className='arrow__image' />
                    </div>
                )}
            </div>

            {isConfirmationModalOpen && (
                <ConfirmationModal
                    closeOnClickOutside={false}
                    closeDialog={() => setIsConfirmationModalOpen(false)}
                    message={getTranslation("IMAGE_DELETING_CONFIRM")}
                    buttonText={getTranslation("CONFIRM_MODAL_DELETE_BUTTON_TEXT")}
                    messageWidth={400}
                    onConfirm={() => {
                        setCarouselIndex(removeImageFromLibrary(currentDeletingImageId));
                        setIsConfirmationModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};
