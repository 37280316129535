import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Loader from '../common/Loader';
import ChangeUserNameModal from '../dialogs/ChangeUserNameModal';
import Profile from './Profile';
import ChangeUserPassword from '../dialogs/ChangeUserPasswordModal';
import ManageSubscription from './ManageSubscription';

import { getUserRoles } from '../../actions/authActions';
import { getProjects, setGeneratedOpenAIRequests } from '../../actions/projectActions';
import { getUserProfile } from '../../actions/authActions';
import { getTranslation } from '../../helpers/getLanguage';
import { getSubscriptionInfo } from '../../actions/stripeActions';
import classNames from 'classnames';

import styles from './MyAccount.module.css';

import './styles.css';

const initialModalOpen = {
    editName: false,
    changePassword: false,
};

const MyAccount = ({
    getProjects,
    projects,
    history,
    auth,
    isLoading,
    numberOpenAIRequests,
    language,
    subscription,
    getSubscriptionInfo,
    setGeneratedOpenAIRequests,
    getUserRoles,
    getUserProfile
}) => {
    const [modalOpen, setModalOpen] = useState(initialModalOpen);
    const projectsLength = projects.length;

    useEffect(() => {
        if (!projectsLength) getProjects();
    }, [projectsLength, getProjects]);

    useEffect(() => {
        getSubscriptionInfo();
    }, []);

    const handleCloseModal = (modal) => {
        setModalOpen({ ...modalOpen, [modal]: false });
    };

    const setModeOpenModal = (e) => {
        setModalOpen(e);
    };

    const openEditNameModal = () => {
        setModeOpenModal((modalOpen) => ({ ...modalOpen, editName: true }));
    };

    const openChangePasswordModal = () => {
        setModeOpenModal((modalOpen) => ({ ...modalOpen, changePassword: true }));
    };

    return (
        <>
            <div>
                <div className='profile-wrapper'>
                    <div className='profile-header-top flex'>
                        <span className='profile-header-title'>{getTranslation('USER_PROFILE_TITLE')}</span>
                    </div>
                    <hr />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className={classNames('flex')}>
                            <Profile
                                user={auth.user}
                                openEditNameModal={openEditNameModal}
                                openChangePasswordModal={openChangePasswordModal}
                            />
                            <div className={styles['line']} />
                            <ManageSubscription
                                getUserRoles={getUserRoles}
                                subscriptions={subscription.subscriptions}
                                language={language}
                                history={history}
                                numberOpenAIRequests={numberOpenAIRequests}
                                getSubscriptionInfo={getSubscriptionInfo}
                                setGeneratedOpenAIRequests={setGeneratedOpenAIRequests}
                                getUserProfile={getUserProfile}
                            />
                        </div>
                    )}
                </div>
            </div>

            {modalOpen.editName && (
                <ChangeUserNameModal closeDialog={() => handleCloseModal('editName')} modalOpen={modalOpen} />
            )}

            {modalOpen.changePassword && (
                <ChangeUserPassword
                    handleCloseDialog={() => handleCloseModal('changePassword')}
                    modalOpen={modalOpen}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    projects: state.project.projects,
    isLoading: state.project.projectsLoading,
    language: state.auth.userInfo.language,
    numberOpenAIRequests: state.project.openAI.numberRequests,
    subscription: state.subscription,
});

export default connect(mapStateToProps, { getProjects, getSubscriptionInfo, getUserRoles, setGeneratedOpenAIRequests, getUserProfile })(
    MyAccount
);
