import React, { useState, useRef } from 'react';
import GenerateNamesModal from '../../GenerateNamesModal';
import ImageModal from '../../ImageModal';
import ErrorMessageModal from '../../ErrorMessageModal';
import { ImagesCarousel } from '../../../inputs/ImagesCarousel';
import GenerateImageModal from '../../GenerateImageModal';
import CustomTextarea from '../../../inputs/CustomTextarea';
import ImageUploader from '../../../inputs/ImageUploader';
import CustomInput from '../../../inputs/CustomInput';
import { getTranslation } from '../../../../helpers/getLanguage';
import { ReactComponent as AIGenerateIcon } from '../../../../assets/images/ai-generate-icon.svg';

const DescribeNewProduct = ({
    eureka,
    selectCurrentEurekaImageFromLibrary,
    removeImageFromLibrary,
    potentialGeneratedImageUrl,
    handleAcceptPotentialImage,
    handleChange,
    uploadInfo,
    newGeneratedImage,
    handleCancelClick,
    handleFileSelect,
    isLoadingImage,
    handleGenerateNames,
    handleGenerateImage,
    openGenerateNameModal,
    closeGenerateNamesModal,
    suggestedNamesForIdeas,
    suggestedLoading,
    addIdeasName,
    isOpenGenerateImageModal,
    closeDialogGenerateImage,
    generatedImage,
}) => {
    const [isOpenImage, setIsOpenImage] = useState(false);

    const [currentModalImage, setCurrentModalImage] = useState(null);

    const [error, setError] = useState({
        openErrorModal: false,
        message: '',
        headerError: '',
    });

    const closeErrorWindow = () => {
        setError({
            openErrorModal: false,
            message: '',
            headerError: '',
        });
    };
    const openImageModal = (newImage) => {
        setCurrentModalImage(newImage);
        setIsOpenImage(true);
    };
    const fileSelectRef = useRef(null);

    const validateFile = (file) => {
        if (!file || file.size > 2097152) {
            setError({
                openErrorModal: true,
                message: 'Uploaded file is too big, max image size is 2 MB',
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            return false;
        }
        return true;
    };

    const handleFileUpload = (event) => {
        const file = event.target ? event.target.files[0] : event[0];
        if (!validateFile(file)) return;
        const reader = new FileReader();
        reader.onload = (e) => {
            const buffer = e.target.result;
            const imageUrl = `data:image/png;base64,${Buffer.from(buffer).toString('base64')}`;
            handleFileSelect(file, imageUrl);
        };
        reader.readAsArrayBuffer(file);
    };

    const isImagesLibraryFull = eureka.imagesLibrary.length > 9;

    const setMaxImageLibraryError = () => {
        setError({
            openErrorModal: true,
            message: (
                <p className='flex-column mt-25px'>
                    <span style={{ margin: '0' }}>{getTranslation('IMAGE_LIBRARY_MAX_SIZE_ERROR_IMAGES_COUNT')}</span>
                    <span>{getTranslation('IMAGE_LIBRARY_MAX_SIZE_ERROR_DELETE')}</span>
                </p>
            ),
            headerError: getTranslation('IMAGE_LIMIT_REACHED'),
        });
    };

    return (
        <div className='flex eureka-modal-container'>
            <CustomTextarea
                value={eureka.description}
                onChange={handleChange}
                name='description'
                label={getTranslation('EUREKA_MODAL_DESCRIBE_YOUR_IDEA')}
                containerClass='flex-column mt-30'
                textareaClass='bgc-white eureka-modal-container__describe'
                height={377}
                maxHeight={377}
            />

            <div className='flex-column' style={{ marginTop: '-35px' }}>
                <button
                    className={`eureka-modal__button-ai eureka-modal__button-ai_name-idea 
                        ${!eureka.description ? ' eureka-modal__button-ai_disabled' : ''}
                    `}
                    onClick={handleGenerateNames}
                >
                    <AIGenerateIcon style={{ marginRight: '10px', width: '18px' }} />
                    <span>{getTranslation('GENERATE_IDEAS_NAMES_BUTTON')}</span>
                </button>
                <CustomInput
                    labelClass='eureka-modal-container__label'
                    value={eureka.name}
                    onChange={handleChange}
                    name='name'
                    label={getTranslation('RATE_IDEA_CUSTOM_INPUT')}
                    containerClass='flex-column eureka-modal-input'
                    customInputClass='bgc-white eureka-modal-container__name'
                />

                <div style={{ marginTop: '-25px' }}>
                    {eureka.imagesLibrary.length > 0 && (
                        <>
                            <input
                                type='file'
                                ref={fileSelectRef}
                                style={{ display: 'none' }}
                                onChange={(e) => handleFileUpload(e)}
                            />
                            <button
                                className={`eureka-modal__upload-image-button eureka-modal__button-ai
                                        ${
                                            isImagesLibraryFull &&
                                            'eureka-modal__upload-image-button_disabled'
                                        }`}
                                onClick={() =>
                                    isImagesLibraryFull ? setMaxImageLibraryError() : fileSelectRef.current.click()
                                }
                            >
                                {getTranslation('UPLOAD_IMAGES')}
                            </button>
                        </>
                    )}
                    <button
                        className={`eureka-modal__button-ai 
                                eureka-modal__button-ai_image 
                                ${(isImagesLibraryFull || !eureka.description.trim()) && 'eureka-modal__button-ai_image-disabled'}`}
                        onClick={() => (isImagesLibraryFull ? setMaxImageLibraryError() : handleGenerateImage())}
                        style={{ left: eureka.imagesLibrary.length ? '10px' : '93px' }}
                    >
                        <p>
                            <AIGenerateIcon style={{ marginRight: '10px', width: '18px' }} />
                            <span>{getTranslation('GENERATE_IMAGES_BUTTON')}</span>
                        </p>
                        <p>{getTranslation('GENERATE_IMAGES_BUTTON_AI_CREDITS')}</p>
                    </button>
                    {eureka.imagesLibrary.length ? (
                        <ImagesCarousel
                            openImageModal={openImageModal}
                            imagesLibrary={eureka.imagesLibrary}
                            currentImageId={eureka.image.id}
                            selectCurrentDependencyImageFromLibrary={selectCurrentEurekaImageFromLibrary}
                            startIndex={eureka.imagesLibrary.findIndex((image) => image.id === eureka.image.id)}
                            removeImageFromLibrary={removeImageFromLibrary}
                        />
                    ) : (
                        <ImageUploader
                            label={getTranslation('DESCRIBE_NEW_DEPENDENCY_ADD_SKETCH')}
                            uploadInfo={uploadInfo}
                            handleCancelClick={handleCancelClick}
                            handleFileSelect={handleFileSelect}
                            displayPreview
                            isLoadingImage={isLoadingImage}
                            height={290}
                            maxHeight={290}
                            currentProjectImageId={eureka.image.id}
                            openImage={() => setIsOpenImage(true)}
                            generatedImage={generatedImage}
                        />
                    )}
                </div>
            </div>

            {openGenerateNameModal && (
                <GenerateNamesModal
                    closeGenerateNamesModal={closeGenerateNamesModal}
                    suggestedNamesForIdeas={suggestedNamesForIdeas}
                    suggestedLoading={suggestedLoading}
                    addIdeasName={addIdeasName}
                />
            )}

            {error.openErrorModal && (
                <ErrorMessageModal
                    message={error.message}
                    closeModalWindow={closeErrorWindow}
                    textButton={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    handleButton={closeErrorWindow}
                    header={error.headerError}
                />
            )}

            {isOpenImage && (
                <ImageModal
                    closeModal={() => setIsOpenImage(false)}
                    imageData={currentModalImage}
                    altImage={getTranslation('IMAGE_MODAL_ALT_SKETCH_IDEA')}
                />
            )}

            {isOpenGenerateImageModal && (
                <GenerateImageModal
                    handleAcceptPotentialImage={handleAcceptPotentialImage}
                    closeModalWindow={closeDialogGenerateImage}
                    header={getTranslation('OPEN_AI_LOADER_HEADER_IMAGE')}
                    message={getTranslation('OPEN_AI_LOADER_IMAGE')}
                    imageData={potentialGeneratedImageUrl}
                    suggestedLoading={suggestedLoading}
                />
            )}
        </div>
    );
};

export default DescribeNewProduct;
