import React, { useState } from 'react';
import Dialog from '../Dialog';
import './styles.css';
import { connect } from 'react-redux';

import { validateField } from '../../../common/validation';
import { changeUserName } from '../../../api/users';
import { updateAuthToken } from '../../../actions/authActions';

import CustomInput from '../../inputs/CustomInput';
import CloseIcon from '../../../assets/images/close-icon.svg';

import { getTranslation } from '../../../helpers/getLanguage';

const initialErrors = {
    name: '',
    lastName: '',
    password: ''
};

const ChangeUserNameModal = ({ closeDialog, auth, updateAuthToken }) => {
    const [fields, setFields] = useState({
        name: auth.user.name,
        lastName: auth.user.lastName,
        password: ''
    });
    const [errors, setErrors] = useState(initialErrors);
    const [isPending, setIsPending] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const handleEditClick = () => {
        const params = {
            name: fields.name.trim(),
            lastName: fields.lastName.trim(),
            password: fields.password.trim()
        };

        const errorMessages = {};
        for (let key in params) {
            const message = validateField({ name: key, value: params[key] });
            if (message.length) {
                errorMessages[key] = message;
            }
        }

        setErrors({ ...errors, ...errorMessages });
        if (Object.keys(errorMessages).length) return;

        const onSuccess = response => {
            updateAuthToken(response.token);
            setIsPending(false);
            closeDialog();
        };

        const onError = error => {
            console.error(error);
            setIsPending(false);
            if (error.response && error.response.data.code === '10105') {
                setErrors({ ...errors, password: error.response.data.message });
            }
        };

        setIsPending(true);
        changeUserName(params, onSuccess, onError);
    };

    const handleSetErrors = (e, value) => {
        const { name } = e.target || e;
        setErrors(errors => ({ ...errors, [name]: value }));
    };

    const handleCloseDialog = () => {
        closeDialog && closeDialog();
    };

    const handleSubmitEnterEditUserName = event => {
        if (!isPending && event.key === 'Enter') {
            handleEditClick();
        }
    };

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
            <div className="username-dialog-wrapper">
                <div className="username-dialog-header flex">
                    <div className="flex align-center">
                        <span className="username-dialog-title">{getTranslation("USERNAME_DIALOG_TITLE")}</span>
                    </div>
                    <img src={CloseIcon} alt="" className="pointer" onClick={() => closeDialog()} />
                </div>
                <div className="username-dialog-body flex-column">
                    <CustomInput
                        autoFocus={true}
                        value={fields.name}
                        label={getTranslation("USERNAME_DIALOG_FIRST_NAME")}
                        name="name"
                        containerClass="flex-column mb-20"
                        onChange={handleChange}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.name}
                        onKeyDown={handleSubmitEnterEditUserName}
                    />

                    <CustomInput
                        value={fields.lastName}
                        label={getTranslation("USERNAME_DIALOG_LAST_NAME")}
                        name="lastName"
                        containerClass="flex-column mb-20"
                        onChange={handleChange}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.lastName}
                        onKeyDown={handleSubmitEnterEditUserName}
                        autoComplete="new-password"
                    />

                    <CustomInput
                        containerClass="flex-column mb-20"
                        value={fields.password}
                        onChange={handleChange}
                        name="password"
                        type="password"
                        label={getTranslation("USERNAME_DIALOG_CONFIRM_PASSWORD")}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.password}
                        onKeyDown={handleSubmitEnterEditUserName}
                        autoComplete="new-password"
                    />
                    <div className="username-dialog-buttons">
                        <button
                            className="username-dialog-button dark-blue-btn"
                            disabled={isPending}
                            onClick={handleEditClick}
                        >
                            {getTranslation("USERNAME_DIALOG_BUTTON_SAVE")}
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { updateAuthToken })(ChangeUserNameModal);
