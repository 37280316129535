import React from 'react';
import { connect } from 'react-redux';
import { copyTextToClipboard } from '../../../../../common/copyTextToClipboard';

import { getPromoCodes } from '../../../../../actions/promoCodes';
import {
    duplicatePromoCode,
    disablePromoCode,
    fetchPromoCode,
    deletePromoCode,
    archivePromoCode
} from '../../../../../api/promoCodes';
import { getDate, getPrice, getDiscount, getBinaryAnswer, getLicenses, getDuration } from './helpers';
import MoreIcon from '../../../../../assets/images/more-icon-gray.svg';

import CustomScrollbar from '../../../../common/CustomScrollbar';
import CardModal from '../../../../dialogs/CardModal';
import TableCell from './TableCell';

import { getTranslation } from '../../../../../helpers/getLanguage';

const PromoCodesTableBody = ({ rows, containerHeight, containerRef, getPromoCodes, openPromoCodeModal }) => {
    const handleFetchPromoCode = id => {
        const params = { id };
        const onSuccess = code => copyTextToClipboard(code);
        const onError = error => console.error(error);

        fetchPromoCode({ params }, onSuccess, onError);
    };

    const callApi = (id, api) => {
        const params = { id };
        api(params, getPromoCodes);
    };

    const handleDuplicatePromoCode = id => callApi(id, duplicatePromoCode);
    const handleDisablePromoCode = id => callApi(id, disablePromoCode);
    const handleArchivePromoCode = id => callApi(id, archivePromoCode);
    const handleDeletePromoCode = id => callApi(id, deletePromoCode);

    const handleEditPromoCode = promoCode => openPromoCodeModal(promoCode);

    const getMenuItems = row => {
        const { canEdit } = row;
        const disableItemText = row.isActive ? getTranslation("MENU_ITEM_COPY_CODE") : getTranslation("MENU_ITEM_ENABLE_CODE");
        const archiveItemText = row.isArchived ? getTranslation("MENU_ITEM_SHOW_CODE") : getTranslation("MENU_ITEM_HIDE_CODE");
        return [
            { value: getTranslation("MENU_ITEM_COPY_CODE"), action: () => handleFetchPromoCode(row.id) },
            { value: getTranslation("MENU_ITEM_DUPLICATE_CODE"), action: () => handleDuplicatePromoCode(row.id) },
            { value: getTranslation("MENU_ITEM_EDIT_CODE"), action: () => handleEditPromoCode(row), hidden: !canEdit },
            { value: disableItemText, action: () => handleDisablePromoCode(row.id) },
            { value: archiveItemText, action: () => handleArchivePromoCode(row.id) },
            { value: getTranslation("MENU_ITEM_DELETE_CODE"), action: () => handleDeletePromoCode(row.id), hidden: !canEdit }
        ];
    };

    const rowElements = rows.map((row, index) => {
        const menuItems = getMenuItems(row);
        const disabledClass = !row.isActive ? ' disabled' : '';

        return (
            <div
                key={index}
                className={'promo-codes-row pointer flex align-center' + disabledClass}
                onClick={() => handleEditPromoCode(row)}
            >
                <TableCell value={getDate(row.date)} containerClass="promo-codes-header-row-date" />
                <TableCell
                    containerClass="promo-codes-header-row-created-by"
                    textClass="promo-codes-row-fullname-text"
                    value={row.createdBy}
                />
                <TableCell containerClass="promo-codes-header-row-customer" value={row.customer} />
                <TableCell containerClass="promo-codes-header-row-licenses" value={getLicenses(row)} />
                <TableCell containerClass="promo-codes-header-row-duration-count" value={row.durationCount} />
                <TableCell containerClass="promo-codes-header-row-duration-type" value={getDuration(row)} />
                <TableCell containerClass="promo-codes-header-row-price" value={getPrice(row.price)} />
                <TableCell containerClass="promo-codes-header-row-discount" value={getDiscount(row.discount)} />
                <TableCell containerClass="promo-codes-header-row-comments" value={row.comments} />
                <TableCell containerClass="promo-codes-header-row-code" value={row.code} />
                <TableCell containerClass="promo-codes-header-row-auto-renewal" value={getBinaryAnswer(row.isActive)} />
                <div className="promo-codes-button-container">
                    <CardModal items={menuItems} offsetX={-165} maxHeight={134}>
                        <button className="promo-codes-menu-button">
                            <img className="disable-pointer-events" src={MoreIcon} alt=""></img>
                        </button>
                    </CardModal>
                </div>
            </div>
        );
    });
    const usersTableStyle = {};
    containerHeight && (usersTableStyle.height = containerHeight);

    return (
        <div className="promo-codes-rows-container" ref={containerRef} style={usersTableStyle}>
            <CustomScrollbar right={-15} dependencies={[rows, containerHeight]}>
                {rowElements}
            </CustomScrollbar>
        </div>
    );
};

export default connect(null, { getPromoCodes })(PromoCodesTableBody);
