import React from 'react';
import { getTranslation } from '../../../../helpers/getLanguage';

const SortableCell = ({ name, label, sort, handleSortChange, className = 'users-header-row-default' }) => {
    const isActive = name === (sort && sort.field);
    const pointerClass = sort && handleSortChange ? ' pointer' : '';
    const activeClass = isActive ? ' active' : '';

    const handleClick = (value) => {
        handleSortChange && handleSortChange(value, 'users', true);
    };

    return (
        <div className={'flex align-center ' + className}>
            <div className='flex align-center width-100'>
                <span className={'users-text' + pointerClass + activeClass} onClick={() => handleClick(name)}>
                    {label}
                </span>
                {isActive && <div className={'users-triangle-icon' + (sort.asc ? ' asc' : '')}></div>}
            </div>
        </div>
    );
};

const UsersTableHeader = ({ sort, handleSortChange }) => {
    return (
        <div className='users-header-container-header'>
            <div className='users-header-row-header flex align-center'>
                <SortableCell
                    name='date'
                    label={getTranslation('SORTABLE_CELL_DATA_CREATED')}
                    className='users-header-row-date'
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name='fullName'
                    label={getTranslation('SORTABLE_CELL_FULL_NAME')}
                    className='users-header-row-created-by'
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name='email'
                    label={getTranslation('SORTABLE_CELL_EMAIL')}
                    className='users-header-row-email'
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name='subscription'
                    label={getTranslation('SORTABLE_CELL_SUBSCRIPTION')}
                    className='users-header-row-subscription'
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
            </div>
        </div>
    );
};

export default UsersTableHeader;
