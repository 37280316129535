import React from 'react';
import SupportModal from '../../SupportModal';

import { getTranslation } from '../../../../helpers/getLanguage';

const SupportItem = ({ compact, activeClass }) => {
    return (
        <SupportModal>
            <div className={'sidenav-item flex align-center position-relative profile pointer' + activeClass}>
                <div className="support-icon flex-center">
                    <span>?</span>
                </div>
                {!compact && (
                    <div className="flex align-center">
                        <span className="sidenav-title">{getTranslation("NAV_MENU_SUPPORT")}</span>
                    </div>
                )}
                {compact && (
                    <div className="sidenav-item-tooltip flex-center">
                        <span>{getTranslation("NAV_MENU_SUPPORT")}</span>
                    </div>
                )}
            </div>
        </SupportModal>
    );
};

export default SupportItem;
