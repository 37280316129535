import React from 'react';
import './styles.css';

const ContradictionsTable = ({
    contradctions,
    openEditModal,
    setCurrentContradictionSolvingId,
    setCurrentContradictionId,
    setCurrentContradictionSolvingType,
}) => {
    return (
        <div className='contradiction-matrix-table'>
            <table>
                {contradctions.map(
                    (
                        {
                            id,
                            contradictionTitle,
                            contradictionRequirements,
                            attributeDependencySolvingId,
                            taskUnificationSolvingId,
                        },
                        index
                    ) => (
                        <>
                            <tr>
                                <td className='contradiction_name_table_cell'>
                                    {`Contradiction ${index + 1}: `}
                                    <span className='editable_content'>
                                        {contradictionTitle}
                                    </span>
                                </td>
                                <td>Attribute Dependency</td>
                                <td>Task Unification</td>
                            </tr>
                            <tr>
                                <td className='contradiction_requirements_table_cell'>
                                    <ul className='flex flex-column'>
                                        {contradictionRequirements.map((requirement) => (
                                            <li className='editable_content'>
                                                <strong>{requirement?.split(':')[0]}</strong>:{requirement?.split(':')[1]}
                                            </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    <button
                                        className={`contradiction_idea_btn ${
                                            attributeDependencySolvingId && 'contradiction_idea_btn_not_finished'
                                        }`}
                                        onClick={() => {
                                            setCurrentContradictionId(id);
                                            openEditModal();
                                            setCurrentContradictionSolvingId(attributeDependencySolvingId);
                                            setCurrentContradictionSolvingType('attributeDependency');
                                        }}
                                    >
                                        {attributeDependencySolvingId ? 'Open idea' : 'Create idea'}
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className={`contradiction_idea_btn ${
                                            taskUnificationSolvingId && 'contradiction_idea_btn_not_finished'
                                        }`}
                                        onClick={() => {
                                            setCurrentContradictionId(id);
                                            openEditModal();
                                            setCurrentContradictionSolvingId(taskUnificationSolvingId);
                                            setCurrentContradictionSolvingType('taskUnification');
                                        }}
                                    >
                                        {taskUnificationSolvingId ? 'Open idea' : 'Create idea'}
                                    </button>
                                </td>
                            </tr>
                        </>
                    )
                )}
            </table>
        </div>
    );
};

export default ContradictionsTable;
