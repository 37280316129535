import React from 'react';
import classnames from 'classnames';

import './styles.css';

const CustomTextarea = ({
    value,
    name,
    onChange,
    placeholder = '',
    containerClass = '',
    label = '',
    height = 0,
    labelClass = 'custom-textarea-label-default',
    autoResize = false,
    errorMessage = '',
    textareaClass = '',
    isIssue = false,
    disabled,
    emptyClassDisable,
    maxHeight = 0,
}) => {
    const styling = {};
    height && (styling.height = height);
    maxHeight && (styling.maxHeight = maxHeight);
    const emptyStateClass = !value.length && !emptyClassDisable ? 'empty ' : '';

    const handleRef = (ref) => {
        if (autoResize && ref) {
            ref.style.height = 'auto';
            ref.style.height = isIssue ? `${ref.scrollHeight - 10}px` : `${ref.scrollHeight - 10}px`;
        }
    };

    return (
        <div className={containerClass}>
            {label.length > 0 && <span className={labelClass}>{label}</span>}
            <textarea
                ref={handleRef}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                className={classnames('custom-textarea', { empty: emptyStateClass }) + ' ' + textareaClass}
                style={styling}
                disabled={disabled}
            />
            {errorMessage.length > 0 && (
                <div className='custom-input-errors flex-center'>
                    <span>{errorMessage}</span>
                </div>
            )}
        </div>
    );
};

export default CustomTextarea;
