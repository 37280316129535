import { UPDATE_CURRENT_SOCKET } from '../actions/types';

const initialState = {
    current: null, // <- Should be socket
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_CURRENT_SOCKET:
            return {
                ...state,
                current: action.payload,
            };
        default:
            return state;
    }
}
