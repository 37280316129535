const binaryTextColors = {
    red: '#f1666a',
    black: '#242c5c',
    default: '#000000',
};

const colors = {
    default: '#f7f8fa',
    blue: '#242c5c',
    gray: '#cdd0d2',
    0: '#ffffff',
    1: '#ffcd67',
    2: '#f9ae9f',
    3: {
        1: '#daf1e1',
        2: '#b5e3c3',
        3: '#91d5a5',
        4: '#6cc687',
        5: '#47b869',
    },
};

const getBinaryCellText = ({ rating, existingDependency, isIdeaFeasible }) => {
    if ([null, undefined].includes(existingDependency)) {
        return { value: '', color: binaryTextColors.default };
    }

    if (existingDependency === false) {
        if (isIdeaFeasible === true && rating > 0) {
            return { value: '1', color: binaryTextColors.red };
        } else {
            return { value: '0', color: binaryTextColors.black };
        }
    }

    if (existingDependency === true) {
        if (isIdeaFeasible === true && rating > 0) {
            return { value: '0', color: binaryTextColors.red };
        } else {
            return { value: '1', color: binaryTextColors.black };
        }
    }

    return { value: '', color: binaryTextColors.default };
};

export const getBinaryCellState = ({ rating, reversed, existingDependency, isIdeaFeasible }) => {
    const cellState = getBinaryCellText({ rating, existingDependency, isIdeaFeasible });
    const reversedCellState = getBinaryCellText(reversed);

    return { ...cellState, reversed: reversedCellState };
};

export const getCellText = (cellDetails) => {
    let greenIdeaCount = 0;

    cellDetails.forEach((details) => {
        if (details.status === 3) greenIdeaCount++;
        if (!!details.reversed && details.reversed.status === 3) greenIdeaCount++;
    });

    return greenIdeaCount ? String(greenIdeaCount) : '';
};

export const getCellState = (cellDetails) => {
    let reversed = {};

    const sortDetails = (details) => {
        const green = details.filter((x) => x.status === 3).sort((a, b) => (a.rating > b.rating ? -1 : 1));
        const yellow = details.filter((x) => x.status === 1);
        const red = details.filter((x) => x.status === 2);

        return [...green, ...yellow, ...red];
    };

    const sortReversedDetails = (details) => {
        const green = details
            .filter((x) => !!x.reversed && x.reversed.status === 3)
            .sort((a, b) => a.reversed.rating - b.reversed.rating);
        const yellow = details.filter((x) => !!x.reversed && x.reversed.status === 1);
        const red = details.filter((x) => !!x.reversed && x.reversed.status === 2);

        return [...green, ...yellow, ...red];
    };

    if (cellDetails.length) {
        const idea = sortDetails(cellDetails)[0];
        const reverseIdea = sortReversedDetails(cellDetails)[0];

        if (reverseIdea) {
            reversed = {
                status: reverseIdea.reversed.status,
                rating: reverseIdea.reversed.rating,
                feasibleRating: reverseIdea.reversed.feasibleRating,
                existingDependency: reverseIdea.reversed.dependency,
                isIdeaFeasible: reverseIdea.reversed.typeDependency,
            };
        }

        if (idea) {
            return {
                status: idea.status,
                rating: idea.rating,
                feasibleRating: idea.feasibleRating,
                existingDependency: idea.dependency,
                isIdeaFeasible: idea.typeDependency,
                reversed,
            };
        }
    }

    return { status: 0, rating: 0, feasibleRating: 0, reversed };
};

export const getColor = ({ status, rating = 1, reversed, rowId, columnId, filters, detailsMatrix, binaryMode }) => {
    const reversedStatus = reversed.status || 0;
    const reversedRating = reversed.rating || 1;

    const checkIdeasStatus = (status === 2 && reversedStatus === 1) || (reversedStatus === 2 && status === 1);
    const currentStatus = checkIdeasStatus ? 1 : Math.max(status, reversedStatus);

    const getCellColor = () => {
        if (!reversedStatus || [1, 2].includes(reversedStatus)) {
            if (currentStatus < 3 && currentStatus >= 0) {
                return colors[currentStatus];
            }

            if (currentStatus >= 3) {
                const ratingValue = rating > 5 ? 5 : rating;
                return colors[3][ratingValue];
            }
        }

        if ([0, 1, 2].includes(status) && reversedStatus === 3) {
            const ratingValue = reversedRating > 5 ? 5 : reversedRating;
            return colors[3][ratingValue];
        }

        if (status === 3 && reversedStatus === 3) {
            const maxRating = rating > reversedRating ? rating : reversedRating;
            const ratingValue = maxRating > 5 ? 5 : maxRating;
            return colors[3][ratingValue];
        }

        return colors[0];
    };

    if (filters.ideas !== '') {
        const filterStatus = Number(filters.ideas);

        switch (filterStatus) {
            case 3:
                if (status !== filterStatus && reversedStatus !== filterStatus) {
                    return colors.default;
                }
                break;

            case 2:
            case 1:
                if (status !== filterStatus || reversedStatus === 3) {
                    return colors.default;
                }
                break;

            case 0:
                if (status !== filterStatus) {
                    return colors.default;
                }
                break;

            default:
                console.warn("Couldn't find status", filterStatus);
                return colors.default;
        }
    }

    if (filters.filterBy || filters.search) {
        const foundElem = detailsMatrix.find(
            (el) =>
                (el.idAttributeRow === rowId && el.idAttributeCol === columnId) ||
                (el.idAttributeRow === columnId && el.idAttributeCol === rowId)
        );
        if (!foundElem) {
            return colors.default;
        }

        if (filters.filterBy !== '') {
            const benefits = foundElem.potentialBenefitsOfDependency;
            const reversedBenefits = foundElem.reversed && foundElem.reversed.potentialBenefitsOfDependency;

            if (!benefits && !reversedBenefits) {
                return colors.default;
            }

            const hasFilteredBenefit =
                benefits && benefits.filter((benefit) => benefit.target.includes(filters.filterBy)).length > 0;
            const hasReversedBenefit =
                reversedBenefits &&
                reversedBenefits.filter((benefit) => benefit.target.includes(filters.filterBy)).length > 0;

            if (!hasFilteredBenefit && !hasReversedBenefit) {
                return colors.default;
            }
        }

        if (filters.search !== '') {
            const isMatch = (value) => {
                if (!value) return false;
                return value.toLowerCase().includes(filters.search.toLowerCase());
            };

            if (
                !isMatch(foundElem.dependencyName) &&
                !isMatch(foundElem.reversed && foundElem.reversed.dependencyName) &&
                !isMatch(foundElem.comments) &&
                !isMatch(foundElem.reversed && foundElem.reversed.comments)
            ) {
                return colors.default;
            }
        }
    }

    return binaryMode ? colors[0] : getCellColor();
};
