import React, { useState, useRef } from 'react';
import GenerateImageModal from '../../GenerateImageModal';
import ImageModal from '../../ImageModal';
import ErrorMessageModal from '../../ErrorMessageModal';
import { ImagesCarousel } from '../../../inputs/ImagesCarousel';
import GenerateNamesModal from '../../GenerateNamesModal';
import CustomTextarea from '../../../inputs/CustomTextarea';
import ImageUploader from '../../../inputs/ImageUploader';
import CustomInput from '../../../inputs/CustomInput';
import { getTranslation } from '../../../../helpers/getLanguage';
import { ReactComponent as AIGenerateIcon } from '../../../../assets/images/ai-generate-icon.svg';

const DescribeNewProduct = ({
    selectCurrentSubtractionImageFromLibrary,
    removeImageFromLibrary,
    subtraction,
    potentialGeneratedImageUrl,
    handleAcceptPotentialImage,
    handleChange,
    uploadInfo,
    handleCancelClick,
    handleFileSelect,
    isLoadingImage,
    productType,
    header,
    handleGenerateNames,
    handleGenerateImage,
    openGenerateNameModal,
    closeGenerateNamesModal,
    suggestedNamesForIdeas,
    suggestedLoading,
    addIdeasName,
    isOpenGenerateImageModal,
    closeDialogGenerateImage,
    generatedImage,
}) => {
    const [isOpenImage, setIsOpenImage] = useState(false);

    const [currentModalImage, setCurrentModalImage] = useState(null);

    const [error, setError] = useState({
        openErrorModal: false,
        message: '',
        headerError: '',
    });

    const closeErrorWindow = () => {
        setError({
            openErrorModal: false,
            message: '',
            headerError: '',
        });
    };

    const openImageModal = (newImage) => {
        setCurrentModalImage(newImage);
        setIsOpenImage(true);
    };

    const fileSelectRef = useRef(null);

    const getSubtractionDescription = (header) => {
        return (
            <p className='subtraction-description-describe'>
                <span style={{ marginRight: '10px' }}>{`${getTranslation(
                    'SUBTRACTION_TAB_DESCRIBE_NEW_PRODUCT_LABEL'
                )} ${productType}`}</span>
                {getTranslation('SUBTRACTION_DESCRIPTION_WITHOUT_THE')}
                <u>{header.component}</u>
                {productType === 'process' && `${getTranslation('SUBTRACTION_MODAL_HEADER_STEP')}`}
            </p>
        );
    };

    const validateFile = (file) => {
        if (!file || file.size > 2097152) {
            setError({
                openErrorModal: true,
                message: 'Uploaded file is too big, max image size is 2 MB',
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            return false;
        }
        return true;
    };

    const handleFileUpload = (event) => {
        const file = event.target ? event.target.files[0] : event[0];
        if (!validateFile(file)) return;
        const reader = new FileReader();
        reader.onload = (e) => {
            const buffer = e.target.result;
            const imageUrl = `data:image/png;base64,${Buffer.from(buffer).toString('base64')}`;
            handleFileSelect(file, imageUrl);
        };
        reader.readAsArrayBuffer(file);
    };

    const isImagesLibraryFull = subtraction.imagesLibrary.length > 9;

    const setMaxImageLibraryError = () => {
        setError({
            openErrorModal: true,
            message: (
                <p className='flex-column mt-25px'>
                    <span style={{ margin: '0' }}>{getTranslation('IMAGE_LIBRARY_MAX_SIZE_ERROR_IMAGES_COUNT')}</span>
                    <span>{getTranslation('IMAGE_LIBRARY_MAX_SIZE_ERROR_DELETE')}</span>
                </p>
            ),
            headerError: getTranslation('IMAGE_LIMIT_REACHED'),
        });
    };

    return (
        <>
            <div>{getSubtractionDescription(header)}</div>

            <div className='flex subtraction-modal-container'>
                <CustomTextarea
                    value={subtraction.comment}
                    onChange={handleChange}
                    height={287}
                    maxHeight={287}
                    name='comment'
                    label={getTranslation('SUBTRACTION_TAB_DESCRIBE_DESCRIPTION')}
                    containerClass='flex-column subtraction-modal-input'
                    textareaClass='bgc-white subtraction-modal-container__describe'
                />

                <div className='flex-column' style={{ marginTop: '-36px' }}>
                    <button
                        className='subtraction-modal__button-ai subtraction-modal__button-ai_name-idea'
                        onClick={handleGenerateNames}
                    >
                        <AIGenerateIcon style={{ marginRight: '10px', width: '18px' }} />
                        <span>{getTranslation('GENERATE_IDEAS_NAMES_BUTTON')}</span>
                    </button>
                    <CustomInput
                        labelClass='subtraction-modal-container__label'
                        value={subtraction.ideaName}
                        onChange={handleChange}
                        name='ideaName'
                        label={getTranslation('RATE_IDEA_CUSTOM_INPUT')}
                        containerClass='flex-column subtraction-modal-input'
                        customInputClass='bgc-white subtraction-modal-container__name'
                    />

                    <div style={{ marginTop: '-30px' }}>
                        {subtraction.imagesLibrary.length > 0 && (
                            <>
                                <input
                                    type='file'
                                    ref={fileSelectRef}
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(e)}
                                />
                                <button
                                    className={`subtraction-modal__upload-image-button subtraction-modal__button-ai 
                                        ${isImagesLibraryFull && 'subtraction-modal__upload-image-button_disabled'}`}
                                    onClick={() =>
                                        isImagesLibraryFull ? setMaxImageLibraryError() : fileSelectRef.current.click()
                                    }
                                >
                                    {getTranslation("UPLOAD_IMAGES")}
                                </button>
                            </>
                        )}
                        <button
                            className={`subtraction-modal__button-ai 
                                subtraction-modal__button-ai_image 
                                ${isImagesLibraryFull && 'subtraction-modal__button-ai_image-disabled'}`}
                            onClick={() => (isImagesLibraryFull ? setMaxImageLibraryError() : handleGenerateImage())}
                            style={{ left: subtraction.imagesLibrary.length ? '10px' : '95px' }}
                        >
                            <p>
                                <AIGenerateIcon style={{ marginRight: '10px', width: '18px' }} />
                                <span>{getTranslation('GENERATE_IMAGES_BUTTON')}</span>
                            </p>
                            <p>{getTranslation('GENERATE_IMAGES_BUTTON_AI_CREDITS')}</p>
                        </button>
                        {subtraction.imagesLibrary.length ? (
                            <ImagesCarousel
                                openImageModal={openImageModal}
                                imagesLibrary={subtraction.imagesLibrary}
                                currentImageId={subtraction.image.id}
                                selectCurrentDependencyImageFromLibrary={selectCurrentSubtractionImageFromLibrary}
                                startIndex={subtraction.imagesLibrary.findIndex(
                                    (image) => image.id === subtraction.image.id
                                )}
                                removeImageFromLibrary={removeImageFromLibrary}
                            />
                        ) : (
                            <ImageUploader
                                label={getTranslation('DESCRIBE_NEW_SUBTRACTION_ADD_SKETCH')}
                                uploadInfo={uploadInfo}
                                handleCancelClick={handleCancelClick}
                                handleFileSelect={handleFileSelect}
                                displayPreview
                                isLoadingImage={isLoadingImage}
                                height={200}
                                maxHeight={200}
                                currentProjectImageId={subtraction.image.id}
                                openImage={() => setIsOpenImage(true)}
                                generatedImage={generatedImage}
                            />
                        )}
                    </div>
                </div>
            </div>

            {openGenerateNameModal && (
                <GenerateNamesModal
                    closeGenerateNamesModal={closeGenerateNamesModal}
                    suggestedNamesForIdeas={suggestedNamesForIdeas}
                    suggestedLoading={suggestedLoading}
                    addIdeasName={addIdeasName}
                />
            )}

            {error.openErrorModal && (
                <ErrorMessageModal
                    message={error.message}
                    closeModalWindow={closeErrorWindow}
                    textButton={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    handleButton={closeErrorWindow}
                    header={error.headerError}
                />
            )}

            {isOpenImage && (
                <ImageModal
                    closeModal={() => setIsOpenImage(false)}
                    imageData={currentModalImage}
                    altImage={getTranslation('IMAGE_MODAL_ALT_SKETCH_IDEA')}
                />
            )}

            {isOpenGenerateImageModal && (
                <GenerateImageModal
                    handleAcceptPotentialImage={handleAcceptPotentialImage}
                    closeModalWindow={closeDialogGenerateImage}
                    header={getTranslation('OPEN_AI_LOADER_HEADER_IMAGE')}
                    message={getTranslation('OPEN_AI_LOADER_IMAGE')}
                    imageData={potentialGeneratedImageUrl}
                    suggestedLoading={suggestedLoading}
                />
            )}
        </>
    );
};

export default DescribeNewProduct;
