import React from 'react';
import { getTranslation } from '../../../../../helpers/getLanguage';

const SortableCell = ({ name, label, sort, handleSortChange, className = 'promo-codes-header-row-default' }) => {
    const isActive = name === (sort && sort.field);
    const pointerClass = sort && handleSortChange ? ' pointer' : '';
    const activeClass = isActive ? ' active' : '';

    const handleClick = value => {
        handleSortChange && handleSortChange(value, 'promoCodes');
    };

    return (
        <div className={'flex align-center ' + className}>
            <div className="flex align-center width-100">
                <span className={'promo-codes-text' + pointerClass + activeClass} onClick={() => handleClick(name)}>
                    {label}
                </span>
                {isActive && <div className={'promo-codes-triangle-icon' + (sort.asc ? ' asc' : '')}></div>}
            </div>
        </div>
    );
};

const PromoCodesTableHeader = ({ sort, handleSortChange }) => {
    return (
        <div className="promo-codes-header-container-header">
            <div className="promo-codes-header-row-header flex align-center">
                <SortableCell
                    name="date"
                    label={getTranslation("SORTABLE_CELL_DATA_CREATED")}
                    className="promo-codes-header-row-date"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="createdBy"
                    label={getTranslation("SORTABLE_CELL_CREATED_BY")}
                    className="promo-codes-header-row-created-by"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="customer"
                    label={getTranslation("SORTABLE_CELL_CUSTOMER")}
                    className="promo-codes-header-row-customer"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="licenses"
                    label={getTranslation("SORTABLE_CELL_LICENSES")}
                    className="promo-codes-header-row-licenses"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="durationCount"
                    label={getTranslation("SORTABLE_CELL_DURATION_COUNT")}
                    className="promo-codes-header-row-duration-count"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="durationType"
                    label={getTranslation("SORTABLE_CELL_DURATION_TYPE")}
                    className="promo-codes-header-row-duration-type"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="price"
                    label={getTranslation("SORTABLE_CELL_PRICE")}
                    className="promo-codes-header-row-price"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="discount"
                    label={getTranslation("SORTABLE_CELL_DISCOUNT")}
                    className="promo-codes-header-row-discount"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="comments"
                    label={getTranslation("SORTABLE_CELL_COMMENTS")}
                    className="promo-codes-header-row-comments"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="code"
                    label={getTranslation("SORTABLE_CELL_CODE")}
                    className="promo-codes-header-row-code"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
                <SortableCell
                    name="isActive"
                    label={getTranslation("SORTABLE_CELL_IS_ACTIVE")}
                    className="promo-codes-header-row-auto-renewal"
                    sort={sort}
                    handleSortChange={handleSortChange}
                />
            </div>
        </div>
    );
};

export default PromoCodesTableHeader;
