import React from 'react';
import './styles.css';
import BulletPoint from '../../common/BulletPoint';
import TranslateTemplate from '../../common/TranslateTemplate';

import { getTranslation } from '../../../helpers/getLanguage';
import { getLabel, getSelectedValue } from '../../utils/benefits';

import CustomScrollbar from '../../common/CustomScrollbar';
import Initials from '../../common/Initials';
import { getColorByInitials } from '../../../helpers/getInitials';
import { listOfInitialColors } from '../../../constants/listOfInitialColors';
const isDefaultCategory = (value) => ['Consumer', 'Company', 'Other'].includes(value);
const ExpandedRow = ({ row, getStatusColor, colorClass, benefitInfo, currentLanguage }) => {
    const issues = row.issues.map((issue, index) => {
        const createdBy = issue.createdByUser ? issue.createdByUser : `${issue.userInfo.name} ${issue.userInfo.lastName}`;
        const [initialsBackgroundColor, initialsTextColor] = getColorByInitials(createdBy, listOfInitialColors);

        const issuesMessages = issue?.messages?.map((message, index) => {
            const createdBy = `${message.userInfo.name} ${message.userInfo.lastName}`;
            const [initialsBackgroundColor, initialsTextColor] = getColorByInitials(createdBy, listOfInitialColors);

            return (
                <div key={index} className='flex list-item width-100 ml-30'>
                    <Initials
                        isRounded
                        userFullName={createdBy}
                        initialsBackgroundColor={initialsBackgroundColor}
                        initialsTextColor={initialsTextColor}
                    />
                    <TranslateTemplate as='span' className={'message-expanded-report'}>
                        {message.message}
                    </TranslateTemplate>
                </div>
            );
        });

        return (
            <div key={index}>
                <div key={index} className='flex list-item width-100'>
                    <Initials
                        isRounded
                        userFullName={createdBy}
                        initialsBackgroundColor={initialsBackgroundColor}
                        initialsTextColor={initialsTextColor}
                        currentLanguage={currentLanguage}
                    />

                    <div className="flex-column">
                        <TranslateTemplate as="p" className="issue-expanded-report">
                            {issue.comment}:
                        </TranslateTemplate>
                        <TranslateTemplate as="span" className="issue-expanded-report issue-description">
                            {issue.description}
                        </TranslateTemplate>
                    </div>
                </div>
                {issuesMessages}
            </div>
        );
    });

    const initialDetails = {
        Consumer: [],
        Company: [],
        Other: [],
    };

    const customBenefit = row.benefits.map((benefit) => ({...benefit, target:benefit.target.map((target) => target.name)}))

    const benefitDetails = customBenefit.reduce(
        (p, n) => ({ ...p, [n.target.sort()]: [...(p[n.target] || [])] }),
        initialDetails
    );

    const customDetails = { ...benefitDetails };    
    
    Object.keys(customDetails)
        .filter((key) => isDefaultCategory(key))
        .forEach((key) => delete customDetails[key]);

    const sortedCustomDetails = Object.keys(customDetails)
        .sort()
        .reduce((p, n) => ((p[n] = customDetails[n]), p), {});

        customBenefit.forEach((benefit) => {
        if (benefit.target && benefit.name) {
            benefitDetails[benefit.target].push(`${benefit.userInfo ? benefit.userInfo : row.createdBy}, ${benefit.name}, ${benefit.description}`);
        }
    });

    const getBenefitList = (key) => {
        if (!Object.keys(benefitDetails).includes(key) || !benefitDetails[key].length) {
            return null;
        }

        const keys = key.split(',');
        const label = benefitInfo[key] ? getLabel(benefitInfo[key].name) : getSelectedValue(keys);

        return (
            <div className='flex-column benefits-list-container' key={key}>
                <div className='flex align-flex-start benefits-list-title mb-10'>
                    {isDefaultCategory(key) && <img src={benefitInfo[key].icon} alt='' />}
                    {!isDefaultCategory(key) && <BulletPoint />}
                    <span>{label.replace(/,/g, ', ') || getTranslation('NO_BENEFICIARY_SPECIFIED_LABEL')}</span>
                </div>

                <div className='flex benefits-list flex-column'>
                    {benefitDetails[key].map((benefit, index) => (
                        <div key={index} className='flex list-item width-100'>
                            <Initials
                                isRounded
                                userFullName={benefit.split(',')[0]}
                                initialsBackgroundColor={getColorByInitials(benefit.split(',')[0], listOfInitialColors)[0]}
                                initialsTextColor={getColorByInitials(benefit.split(',')[0], listOfInitialColors)[1]}
                                currentLanguage={currentLanguage}
                             />

                            <div className="flex-column ml-10">
                                <p>{benefit.split(',')[1]}:</p>
                                <span>{benefit.split(',')[2]}</span>
                            </div>
                        </div> 
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div
            className={'reports-row-expanded flex ' + colorClass[row.status]}
            style={{ borderLeft: `7px solid ${getStatusColor()}` }}
        >
            <div className='flex reports-row-expanded-container'>
                <div className='reports-row-expanded-section flex-column'>
                    <div className={'reports-row-expanded-header flex ' + colorClass[row.status]}>
                        <span>{getTranslation('REPORT_DESCRIPTION')}</span>
                    </div>

                    <div className={'reports-row-expanded-content flex ' + colorClass[row.status]}>
                        <CustomScrollbar right={-10} backgroundColor='transparent' dependencies={[row.comments]}>
                            <span className='reports-row-comments'>{row.comments}</span>
                        </CustomScrollbar>
                    </div>
                </div>

                <div className='reports-row-expanded-section flex-column'>
                    <div className={'reports-row-expanded-header flex ' + colorClass[row.status]}>
                        <span>{getTranslation('REPORT_BENEFITS')}</span>
                    </div>

                    <div className={'reports-row-expanded-content ' + colorClass[row.status]}>
                        <CustomScrollbar right={-10} backgroundColor='transparent' dependencies={[row.benefits]}>
                            {getBenefitList('Consumer')}
                            {getBenefitList('Company')}
                            {getBenefitList('Other')}
                            {Object.keys(sortedCustomDetails).map((category) => getBenefitList(category))}
                        </CustomScrollbar>
                    </div>
                </div>

                <div className='reports-row-expanded-section flex-column'>
                    <div className={'reports-row-expanded-header flex ' + colorClass[row.status]}>
                        <span>{getTranslation('REPORT_IMPLEMENTATION_CHALLENGES')}</span>
                    </div>

                    <div className={'reports-row-expanded-content ' + colorClass[row.status]}>
                        <CustomScrollbar right={-10} backgroundColor='transparent' dependencies={[issues]}>
                            {issues}
                        </CustomScrollbar>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpandedRow;
