import React, { useState, useEffect } from 'react';
import './styles.css';

import { activatePromoCodeForUser } from '../../../../api/promoCodes';

import { validateField } from '../../../../common/validation';

import CustomTextarea from '../../../inputs/CustomTextarea';
import CustomInput from '../../../inputs/CustomInput';
import InformationModal from '../../../dialogs/InformationModal';

import { getTranslation } from '../../../../helpers/getLanguage';

const initialFields = {
    emails: '',
    codeActivationEmails: [],
    codeActivationCode: ''
};

const initialErrors = {
    codeActivationEmails: '',
    codeActivationCode: ''
};

const errorCodes = ['30300', '30251', '30253', '30330'];

const ActivateMultipleCodes = () => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(initialErrors);
    const [errorsEmails, setErrorsEmails] = useState([]);
    const [successEmails, setSuccessEmails] = useState([]);
    const [numberActivated, setNumberActivated] = useState(0);
    const [isPending, setIsPending] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [infoEmail, setInfoEmail] = useState(false);
    const endRequests = fields.codeActivationEmails.length === numberActivated + 1;

    useEffect(() => {
        if (endRequests) {
            setNumberActivated(0);
            setInfoEmail(false);
        }
    }, [numberActivated, errorsEmails, endRequests]);

    const handleChange = e => {
        let { name, value } = e.target;
        if (name === 'emails') {
            const splitEmails = value
                .split(`\n`)
                .map(email => email.trim())
                .filter(email => email);
            const codeEmails = Array.from(new Set(splitEmails));
            setNumberActivated(0);
            setFields({ ...fields, [name]: value, codeActivationEmails: codeEmails });
        } else setFields({ ...fields, [name]: value });
    };

    const handleSetErrors = (e, value) => {
        const name = e.target || e;
        setErrors(errors => ({ ...errors, [name]: value }));
    };

    const validateFields = (codeActivationEmails, codeActivationCode) => {
        const errorMessages = {
            codeActivationEmails: '',
            codeActivationCode: ''
        };

        if (!codeActivationEmails.length) {
            errorMessages.codeActivationEmails = 'This field is required';
        }

        for (let key of codeActivationEmails) {
            const match = key.match(/\S+@\S+\.\S+/);
            const message = match ? '' : 'Please enter email "' + key + '" in correct format';
            if (message.length) {
                errorMessages.codeActivationEmails = message;
                break;
            }
        }

        errorMessages.codeActivationCode = validateField({ name: 'codeActivationCode', value: codeActivationCode });
        setErrors({ ...errors, ...errorMessages });

        const isValid = Object.values(errorMessages).filter(message => message).length === 0;
        if (isValid) {
            return codeActivationEmails;
        } else {
            return null;
        }
    };

    async function handleActivationRequests(activationEmails, codeActivationCode) {
        let counter = 0;
        setInfoEmail(true);
        setErrorsEmails([]);
        setSuccessEmails([]);
        let isError = false;
        const showEmails = activationEmails.map(email => {
            return () =>
                new Promise(async resolve => {
                    const params = { email: email, code: codeActivationCode };

                    const onSuccess = () => {
                        setIsPending(endRequests);
                        setSuccessEmails([...successEmails, email]);
                        const filteredEmails = fields.codeActivationEmails.filter(
                            currentEmail => email !== currentEmail
                        );

                        setFields({ ...fields, codeActivationEmails: filteredEmails });
                        resolve();
                    };

                    const onError = error => {
                        setIsPending(endRequests);
                        console.error(error);
                        setErrorsEmails(errorsEmails => [
                            ...errorsEmails,
                            { email, error: error.response.data.message }
                        ]);

                        if (errorCodes.includes(error.response.data.code)) {
                            isError = true;
                            setModalOpen(true);
                            setNumberActivated(0);
                            setInfoEmail(false);
                        } else {
                            isError = false;
                            resolve();
                        }
                    };

                    setNumberActivated(counter++);
                    setIsPending(true);
                    !isError && activatePromoCodeForUser(params, onSuccess, onError);
                });
        });

        await showEmails.reduce((p, cb) => p.then(cb), Promise.resolve());
        setModalOpen(true);
    }

    const handleCodeActivation = () => {
        let { codeActivationEmails, codeActivationCode } = fields;
        const customTextareaValue = codeActivationEmails.join(`\n`);
        setFields({ ...fields, emails: customTextareaValue });
        const codeEmails = validateFields(codeActivationEmails, codeActivationCode);
        if (codeEmails) handleActivationRequests(codeEmails, codeActivationCode);
    };

    const handleSubmitEnterCodeActivation = event => {
        if (!isPending && event.key === 'Enter') {
            handleCodeActivation();
        }
    };

    return (
        <>
            <div className="manage-user-container">
                <div className="flex-column admin-password-container">
                    <span className="manage-user-label mb-20">{getTranslation("ACTIVATE_CODE_MULTIPLE_USERS_TITLE")}</span>
                    {infoEmail && (
                        <span className="manage-user-label mb-20">
                            Progress {numberActivated}/{fields.codeActivationEmails.length} emails
                        </span>
                    )}

                    <CustomTextarea
                        value={fields.emails}
                        onChange={handleChange}
                        name="emails"
                        containerClass="flex-column mb-20"
                        placeholder={getTranslation("ACTIVATE_CODE_MULTIPLE_USERS_USERS_EMAILS")}
                        errorMessage={errors.codeActivationEmails}
                    />

                    <CustomInput
                        value={fields.codeActivationCode}
                        name="codeActivationCode"
                        onChange={handleChange}
                        onKeyDown={handleSubmitEnterCodeActivation}
                        containerClass="flex-column width-100 mb-20"
                        label={getTranslation("ACTIVATE_CODE_MULTIPLE_USERS_PROMO_CODE")}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.codeActivationCode}
                    />
                    <button className="manage-users-button" onClick={handleCodeActivation} disabled={numberActivated}>
                        <span>{getTranslation("ACTIVATE_CODE_MULTIPLE_USERS_BUTTON")}</span>
                    </button>
                </div>
            </div>

            {modalOpen && (
                <InformationModal
                    closeOnClickOutside={false}
                    closeDialog={() => setModalOpen(false)}
                    allEmails={fields.codeActivationEmails}
                    errorsEmails={errorsEmails}
                    successEmails={successEmails}
                    buttonText={getTranslation("CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT")}
                    onConfirm={() => setModalOpen(false)}
                    autoFocus={true}
                />
            )}
        </>
    );
};

export default ActivateMultipleCodes;
