import React, { useState, useEffect } from 'react';
import './styles.css';

import { ReactComponent as CheckmarkIcon } from '../../../../assets/images/checkmark-circle.svg';

import { getTranslation } from '../../../../helpers/getLanguage';

const DependencyTabs = ({ 
    currentTab, 
    labels, 
    dependency,
    changeCurrentTab 
}) => {
    const [lastCompletedTab, setLastCompletedTab] = useState(0);

    const checkmarkEnabled = (index) => {
        const isEnabled = dependency.isIdeaNew || dependency.createsBenefits;
        const { isIdeaFeasible, isIdeaNew, createsBenefits, score, benefits, issues, comment, image } = dependency;
        switch (index) {
            case 0:
                return isIdeaNew !== null || createsBenefits !== null;
            case 1:
                return (isEnabled && comment.length > 0) || image.name?.length > 0;
            case 2:
                const benefitsLength = benefits.filter((benefit) => benefit.name && benefit.target).length;
                return isEnabled && benefitsLength > 0 && score > 0;
            case 3:
                return (
                    isEnabled &&
                    issues.filter((issue) => issue).length > 0 &&
                    issues.map((issue) => issue.comment.length > 0).includes(true) &&
                    isIdeaFeasible === false
                );
            default:
                return false;
        }
    };

    useEffect(() => {
        const updateLastCompletedTab = () => {
            const {
                existingDependency,
                isIdeaNew,
                createsBenefits,
                isIdeaFeasible,
                score,
                benefits,
                issues,
                comment,
                image,
            } = dependency;

            if (existingDependency === null || (!isIdeaNew && !createsBenefits)) {
                setLastCompletedTab(0);
                return;
            }

            if (
                issues.filter((issue) => issue).length > 0 &&
                issues.map((issue) => issue.comment.length > 0).includes(true) &&
                isIdeaFeasible === false
            ) {
                setLastCompletedTab(3);
                return;
            }

            const benefitsLength = benefits.filter((benefit) => benefit.name && benefit.target).length;
            if (benefitsLength > 0 && score > 0) {
                setLastCompletedTab(2);
                return;
            }

            if (comment.length > 0 || image.name) {
                setLastCompletedTab(1);
                return;
            }

            setLastCompletedTab(0);
        };

        updateLastCompletedTab();
    }, [currentTab, dependency]);

    const tabs = labels.map((label, index) => {
        const activeClass = index <= currentTab || index <= lastCompletedTab ? ' active' : '';
        const pointerClass = ' pointer';
        const labelClass = index === currentTab ? ' active' : '';

        return (
            <div key={index} className='flex align-center dependency-tab'>
                <div className='dependency-tabs-line-container'>
                    <div className={'dependency-tabs-line' + activeClass}></div>
                </div>
                <div 
                    className={'dependency-tabs-circle' + activeClass + pointerClass}
                    onClick={() => changeCurrentTab(index)}
                >
                    <CheckmarkIcon
                        className={checkmarkEnabled(index) ? ' completed' : ''}
                        color={'#3e6fd9'}
                        fill='white'
                    />

                    <button className={'dependency-tab-button'}>
                        <span className={'dependency-tab-label' + labelClass}>{getTranslation(label)}</span>
                    </button>
                </div>
            </div>
        );
    });

    return <div className='flex dependency-tabs-container'>{tabs}</div>;
};

export default DependencyTabs;
