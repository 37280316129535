export const getDetailsMatrixForReport = (detailsMatrix, projectData, messages) => {
    const currentDetails = [];
    const { attributes } = projectData;
    let index = 0;

    detailsMatrix.forEach((details) => {
        const attributeRow = projectData.attributes.find((attribute) => attribute.id === details.idAttributeRow);
        const componentRow =
            attributeRow &&
            attributeRow.componentId &&
            projectData.components.find((component) => component.id === attributeRow.componentId);
        const attributeCol = projectData.attributes.find((attribute) => attribute.id === details.idAttributeCol);
        const componentCol =
            attributeCol &&
            attributeCol.componentId &&
            projectData.components.find((component) => component.id === attributeCol.componentId);

        const rowStatus = {
            internal: componentRow && componentRow.internal,
            locked: componentRow && componentRow.locked,
        };

        const colStatus = {
            internal: componentCol && componentCol.internal,
            locked: componentCol && componentCol.locked,
        };

        details.issues = details.issues.map((issue) => {
            issue.messages = messages.filter((message) => issue.id === message.issueId);

            return issue;
        });

        const newDetails = {
            type: 'dependency',
            index: details.status === 0 ? -1 : index++,
            id: details.id,
            rating: details.rating,
            feasibleRating: details.feasibleRating,
            status: details.status,
            name: details.dependencyName,
            benefits: details.potentialBenefitsOfDependency || [],
            issues: details.issues || [],
            comments: details.comments,
            firstComponent: componentRow ? componentRow.name : '',
            secondComponent: componentCol ? componentCol.name : '',
            firstAttribute: attributeRow ? attributeRow.name : '',
            secondAttribute: attributeCol ? attributeCol.name : '',
            rowStatus,
            colStatus,
            firstAttributeId: details.idAttributeRow,
            secondAttributeId: details.idAttributeCol,
            created: details.dateCreatedAt,
            createdBy: details.createdBy,
            userId: details.userId,
            isReversed: false,
        };

        const isFirstAttributeDisabled = attributes.find((attribute) => attribute.id === newDetails.firstAttributeId);
        const isSecondAttributeDisabled = attributes.find((attribute) => attribute.id === newDetails.secondAttributeId);

        if (isFirstAttributeDisabled && isSecondAttributeDisabled) {
            currentDetails.push(newDetails);
        }

        if (details.reversed) {
            if (details.reversed.issues) {
                details.reversed.issues = details.reversed.issues.map((issue) => {
                    issue.messages = messages.filter((message) => issue.id === message.issueId);

                    return issue;
                });
            }
        }

        if (details.reversed) {
            const reversed = details.reversed;
            const reversedDetails = { ...newDetails };
            reversedDetails.index = reversed.status === 0 ? -1 : index++;
            reversedDetails.rating = reversed.rating;
            reversedDetails.status = reversed.status;
            reversedDetails.name = reversed.dependencyName;
            reversedDetails.benefits = reversed.potentialBenefitsOfDependency || [];
            reversedDetails.issues = reversed.issues || [];
            reversedDetails.comments = reversed.comments;
            reversedDetails.firstComponent = newDetails.secondComponent;
            reversedDetails.secondComponent = newDetails.firstComponent;
            reversedDetails.firstAttribute = newDetails.secondAttribute;
            reversedDetails.secondAttribute = newDetails.firstAttribute;
            reversedDetails.rowStatus = colStatus;
            reversedDetails.colStatus = rowStatus;
            reversedDetails.created = reversed.dateCreatedAt;
            reversedDetails.createdBy = reversed.createdBy;
            reversedDetails.userId = reversed.userId;
            reversedDetails.isReversed = true;

            const isFirstAttributeDisabled = attributes.find(
                (attribute) => attribute.id === reversedDetails.firstAttributeId
            );
            const isSecondAttributeDisabled = attributes.find(
                (attribute) => attribute.id === reversedDetails.secondAttributeId
            );

            if (isFirstAttributeDisabled && isSecondAttributeDisabled) {
                currentDetails.push(reversedDetails);
            }
        }
    });

    return [index, currentDetails];
};

export const getSubtractionsForReport = (subtractions, projectData, startIndex, messages) => {
    let index = startIndex;
    const { components } = projectData;
    let currentSubtractions = [];

    subtractions.forEach((details) => {
        const component = components.find((component) => component.id === details.idRemoteComponent);

        details.issues = details.issues.map((issue) => {
            issue.messages = messages.filter((message) => issue.id === message.issueId);

            return issue;
        });

        if (!component) return;

        const newSubtraction = {
            type: 'subtraction',
            index: details.status === 0 ? -1 : index++,
            id: details.id,
            rating: details.rating,
            feasibleRating: details.feasibleRating,
            status: details.status,
            name: details.subtractionName,
            benefits: details.potentialBenefitsOfSubtraction || [],
            issues: details.issues || [],
            comments: details.comments,
            remoteComponent: component.name,
            created: details.dateCreatedAt,
            createdBy: details.createdBy,
            userId: details.userId,
            removeBenefit: details.removeBenefit,
            existingSubtraction: details.existingSubtraction,
            idRemoteComponent: component.id,
            disabled: details.disabled,
        };

        const isRemoteComponentDisabled = components.find(
            (component) => component.id === newSubtraction.idRemoteComponent
        );

        if (isRemoteComponentDisabled) {
            currentSubtractions.push(newSubtraction);
        }
    });

    return [index, currentSubtractions];
};

export const getReplacementsForReport = (replacements, projectData, startIndex, messages) => {
    let index = startIndex;
    const { components } = projectData;
    let currentReplacements = [];

    replacements.forEach((details) => {
        const remoteComponent = components.find((component) => component.id === details.idRemoteComponent);
        const replacingComponent = components.find((component) => component.id === details.idReplacingComponent);

        details.issues = details.issues.map((issue) => {
            issue.messages = messages.filter((message) => issue.id === message.issueId);

            return issue;
        });

        if (!remoteComponent || !replacingComponent) return;

        const newReplacement = {
            type: 'replacement',
            index: details.status === 0 ? -1 : index++,
            id: details.id,
            rating: details.rating,
            feasibleRating: details.feasibleRating,
            status: details.status,
            name: details.replacementName,
            benefits: details.potentialBenefitsOfReplacement || [],
            issues: details.issues || [],
            comments: details.comments,
            remoteComponent: remoteComponent.name,
            replacingComponent: replacingComponent.name,
            created: details.dateCreatedAt,
            createdBy: details.createdBy,
            userId: details.userId,
            removeBenefit: details.removeBenefit,
            existingReplacement: details.existingReplacement,
            idReplacingComponent: replacingComponent.id,
            idRemoteComponent: remoteComponent.id,
            disabled: details.disabled,
        };

        const isRemoteComponentDisabled = components.find(
            (component) => component.id === newReplacement.idRemoteComponent
        );

        const isReplacingComponentDisabled = components.find(
            (component) => component.id === newReplacement.idReplacingComponent
        );

        if (isRemoteComponentDisabled && isReplacingComponentDisabled) {
            currentReplacements.push(newReplacement);
        }
    });

    return [index, currentReplacements];
};

export const getMultiplicationsForReport = (multiplications, projectData, startIndex, messages) => {
    let index = startIndex;
    const { components, attributes } = projectData;
    let currentMultiplications = [];
    multiplications.forEach((details) => {
        const multipliedComponent = components.find((component) => component.id === details.idMultiplicationComponent);
        const linkedAttribute = attributes.find((attribute) => attribute.id === details.linkedAttribute);

        details.issues = details.issues.map((issue) => {
            issue.messages = messages.filter((message) => issue.id === message.issueId);

            return issue;
        });

        if (!multipliedComponent) return;

        const newMultiplication = {
            type: 'multiplication',
            index: details.status === 0 ? -1 : index++,
            id: details.id,
            rating: details.rating,
            feasibleRating: details.feasibleRating,
            status: details.status,
            name: details.multiplicationName,
            benefits: details.potentialBenefitsOfMultiplication || [],
            issues: details.issues || [],
            comments: details.comments,
            multiplicationComponent: multipliedComponent.name,
            idMultiplicationComponent: multipliedComponent.id,
            linkedAttribute: linkedAttribute?.id || null,
            created: details.dateCreatedAt,
            createdBy: details.createdBy,
            userId: details.userId,
            removeBenefit: details.removeBenefit,
            existingMultiplication: details.existingMultiplication,
            disabled: details.disabled,
        };

        const isMultiplicationComponentDisabled = components.find(
            (component) => component.id === newMultiplication.idMultiplicationComponent
        );

        if (isMultiplicationComponentDisabled) {
            currentMultiplications.push(newMultiplication);
        }
    });

    return [index, currentMultiplications];
};

export const getEurekaIdeasForReport = (eurekaIdeas, projectData, startIndex, messages) => {
    let index = startIndex;
    let currentEurekaIdeas = [];

    eurekaIdeas.forEach((details) => {
        details.issues = details.issues.map((issue) => {
            issue.messages = messages.filter((message) => issue.id === message.issueId);

            return issue;
        });

        const newEureka = {
            type: 'eureka',
            index: details.status === 0 ? -1 : index++,
            comments: details.description,
            id: details.id,
            rating: details.rating,
            feasibleRating: details.feasibleRating,
            status: details.status,
            name: details.name,
            benefits: details.potentialBenefitsOfEureka || [],
            issues: details.issues || [],
            description: details.description,
            created: details.dateCreatedAt,
            createdBy: details.createdBy,
            userId: details.userId,
        };

        currentEurekaIdeas.push(newEureka);
    });

    return currentEurekaIdeas;
};
