import React from 'react';
import GenerateModalWindow from '../../GenerateModalWindow';
import CustomInputWithSelect from '../../../inputs/CustomInputWithSelect';
import CustomScoreInput from '../../../inputs/CustomScoreInput';
import CustomTextarea from '../../../inputs/CustomTextarea';
import CustomScrollbar from '../../../common/CustomScrollbar';
import Initials from '../../../common/Initials';
import Tooltip from '../../../common/Tooltip';
import { getTranslation } from '../../../../helpers/getLanguage';
import { getColorByInitials } from '../../../../helpers/getInitials';
import { ratingColors, ratingSelection } from '../../../../constants/rating';
import { listOfInitialColors } from '../../../../constants/listOfInitialColors';
import { ReactComponent as PlusIcon } from '../../../../assets/images/plus-icon-small.svg';
import { ReactComponent as AIGenerateIcon } from '../../../../assets/images/ai-generate-icon.svg';
import CloseIcon from '../../../../assets/images/close-icon.svg';

const Benefits = ({ 
    benefits, 
    addBenefit, 
    removeBenefit, 
    removeLastBenefit,
    handleBenefitChange,
    handleTargetChange, 
    customCategories,
    score, 
    handleChangeScore,
    handleGenerateBenefits,
    suggestedBenefits,
    openGenerateBenefitsModal,
    closeGenerateBenefitsModal,
    suggestedLoading,
    addGenerateBenefits,
    selectGenerateOption,
    selectedGenerateOptions,
    userFullName,
    handleDeleteOption,
    handleRenameOption,
    addCustomCategory
}) => {
    const [initialsBackgroundColor, initialsTextColor ] = getColorByInitials(`${userFullName.name} ${userFullName.lastName}`, listOfInitialColors);

    const generatedUnusedBenefits = suggestedBenefits?.filter(nameBenefit => !benefits.find(benefit => {
        return (
            `${benefit.name}:${benefit.description}` === nameBenefit
        );
    })) || [];

    const benefitElements = benefits.map((benefit, index) => (
        <div key={index} className="multiplication-modal-benefit flex align-center">
            <div className="multiplication-modal-benefit__initials">
                <Tooltip 
                    color={initialsTextColor} 
                    message={benefit.userInfo || `${userFullName.name} ${userFullName.lastName}`} 
                    tooltipMessageClass="width-fit-content"
                >
                    <Initials
                        isRounded
                        userFullName={benefit.userInfo || `${userFullName.name} ${userFullName.lastName}`}
                        initialsBackgroundColor={initialsBackgroundColor}
                        initialsTextColor={initialsTextColor}
                    />
                </Tooltip>
            </div>

            <div className="flex-column width-100 mb-20">
                <CustomInputWithSelect
                    value={benefit.name}
                    placeholder={getTranslation("MULTIPLICATION_MODAL_BENEFITS_PLACEHOLDER_TITLE")}
                    selectValue={benefit.target ? benefit.target : []}
                    selectPlaceholder={getTranslation("CUSTOM_INPUT_SELECT_PLACEHOLDER")}
                    onChange={e => handleBenefitChange(index, e, 'name')}  
                    handleSelectChange={e => handleTargetChange(index, e)}
                    containerClass="flex-1"
                    textareaClass="multiplication-modal-benefit__title textarea"
                    customCategories={customCategories}
                    handleDeleteOption={handleDeleteOption}
                    handleRenameOption={handleRenameOption}
                    addCustomCategory={addCustomCategory}
                />

                <CustomTextarea
                    value={benefit.description ? benefit.description : ""}
                    onChange={e => handleBenefitChange(index, e.target.value, 'description')}
                    height={60}
                    maxHeight={60}
                    name="description"
                    placeholder={getTranslation("MULTIPLICATION_MODAL_BENEFITS_PLACEHOLDER_DESCRIPTION")}
                    containerClass="multiplication-modal-benefit-input"
                    textareaClass="multiplication-modal-benefit-input__empty"
                />

                <div className="multiplication-modal-benefit-line"></div>
            </div>

            <div className="flex-column">
                <button className="multiplication-modal__benefit-button" onClick={index === benefits.length - 1 ? () => removeLastBenefit(index) : () => removeBenefit(index)}>
                    <img src={CloseIcon} alt="" className="multiplication-modal__close-benefit-icon" />
                </button>

                {index === benefits.length - 1 &&
                    <button className="multiplication-modal__benefit-button" onClick={addBenefit}>
                        <PlusIcon className="multiplication-modal__add-benefit-icon" />
                    </button>
                }
            </div>
        </div>
    ));

    return (
        <>
            <div className="flex justify-center align-center">
                <span className="multiplication-modal-label">{getTranslation("DEPENDENCY_MODAL_LABEL")}</span>
                <button 
                    className="multiplication-modal__button-ai multiplication-modal__button-ai_benefits"
                    onClick={handleGenerateBenefits}
                >
                    <AIGenerateIcon style={{ width: "20px", marginRight: "8px" }}/>
                    <span>{getTranslation("GENERATE_BENEFITS_BUTTON")}</span>
                </button>
            </div>
            <div className="benefit-input-container flex-column">
                <CustomScrollbar right={-20} dependencies={[benefits]} style={{height: "258px"}}>
                    {benefitElements}
                </CustomScrollbar>

                <div className="flex-center flex-column">
                    <span className="multiplication-modal-label mb-1">{getTranslation("RATE_IDEA_TEXT")}</span>
                    <CustomScoreInput
                        value={score}
                        maxValue={5}
                        isRateIdea={true}
                        colors={ratingColors}
                        rectangleStyle={ratingSelection}
                        setValue={e => handleChangeScore(e)}
                        startFromZero
                    />
                </div>
            </div>

            {openGenerateBenefitsModal &&
                <GenerateModalWindow
                    headerModalWindow={getTranslation("GENERATE_BENEFITS_MODAL_HEADER")} 
                    closeGenerateModal={closeGenerateBenefitsModal}
                    suggestedOptions={generatedUnusedBenefits}
                    suggestedLoading={suggestedLoading}
                    handleClickButton={addGenerateBenefits}
                    titleDisableButton={getTranslation("GENERATE_BENEFITS_MODAL_SELECT_BUTTON")}
                    titleActionButton={getTranslation("GENERATE_BENEFITS_MODAL_ADD_BUTTON")}
                    selectGenerateOption={selectGenerateOption}
                    selectedGenerateOptions={selectedGenerateOptions}
                    textWhenNoGeneratedOption={getTranslation("GENERATE_BENEFITS_MODAL_TEXT_WHEN_NO_BENEFITSS")}
                />
            }
        </>
    );
};

export default Benefits;
