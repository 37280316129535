import React from 'react';

const TableCell = ({ value, containerClass = '', textClass = 'users-row-text' }) => {
    return (
        <div className={'flex align-center ' + containerClass}>
            <span className={textClass}>{value || ''}</span>
        </div>
    );
};

export default TableCell;
