export const getDate = date => {
    return new Date(date).toGMTString().slice(5, 16);
};

export const getPrice = price => {
    return `${price}$`;
};

export const getDiscount = discount => {
    return `${discount}%`;
};

export const getBinaryAnswer = value => {
    return value ? 'yes' : 'no';
};

export const getLicenses = row => {
    return `${row.usedLicenses}/${row.totalLicenses}`;
};

export const getDuration = row => {
    return `${row.durationType}` + (row.durationCount > 1 ? 's' : '');
};
