import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

const baseUrl = `${baseApiUrl}/params`;

export const getUrl = async (params, onSuccess, onError) => {
    axios
        .get(`${baseUrl}/tutorial-video`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};
