import React from 'react';
import './styles.css';

import PromoCodesTableHeader from './PromoCodesTableHeader';
import PromoCodesTableBody from './PromoCodesTableBody';

const PromoCodesTable = ({ containerHeight, containerRef, promoCodes, sort, handleSortChange, openPromoCodeModal }) => {
    return (
        <div className="promo-codes-table-container">
            <PromoCodesTableHeader sort={sort} handleSortChange={handleSortChange} />
            <PromoCodesTableBody
                containerHeight={containerHeight}
                containerRef={containerRef}
                rows={promoCodes}
                openPromoCodeModal={openPromoCodeModal}
            />
        </div>
    );
};

export default PromoCodesTable;
