import React, { useState, useEffect } from 'react'
import Dialog from '../Dialog'
import './styles.css';
import { connect } from 'react-redux';

import { getInviteUsers } from '../../../api/invites';

import Loader from '../../common/Loader';
import CustomScrollbar from '../../common/CustomScrollbar';

import CloseIcon from '../../../assets/images/close-icon.svg';
import AssigneeIcon from '../../../assets/images/assignee-icon.svg';

import { getParticipants } from '../../utils/participants'

import { getTranslation } from '../../../helpers/getLanguage';

const UsersInProjectModal = ({ setIsOpenModal, projectId, creatorName, user }) => {
    const { name, lastName } = user;
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [projectCreatorEmail, setProjectCreatorEmail] = useState('')
    const [hasError, setHasError] = useState(false)
    const fullName = `${name} ${lastName}`

    useEffect(() => {
        getUsersData()
    }, [])

    const getUsersData = () => {
        const onSuccess = response => {
            const teams = response.teams
            let teamId = ''

            if (response && response.owner && response.owner.email) setProjectCreatorEmail(response.owner.email)

            for (const team of teams) {
                const currentId = team.participants.find(participant => fullName === participant.fullname)
                if (currentId) teamId = team.id
            }

            if (!teamId) {
                setUsers(getParticipants(teams))
            } else {
                const currentTeam = teams.filter(team => team.id === teamId)
                setUsers(getParticipants(currentTeam))
            }

            setIsLoading(false)
        };

        const onError = () => {
            setHasError(true)
            setIsLoading(false)
        };

        const params = { projectId, ownerInfoRequired: true };
        getInviteUsers({ params }, onSuccess, onError);
    };

    return (
        <Dialog closeDialog={() => setIsOpenModal(false)} closeOnClickOutside={false}>
            <div className="users-in-project-info flex-column">
                <div className="users-in-project-info-close flex width-100">
                    <img src={CloseIcon} alt="" className="pointer" onClick={() => setIsOpenModal(false)} />
                </div>

                {isLoading && <Loader />}
                {!isLoading && !hasError &&
                    <CustomScrollbar right={-10} dependencies={[users]}>
                        <div className="flex-column align-center">
                            {users && users.length > 0 ?
                                users.map((user, index) =>
                                    <div className='users-in-project-info-main-content' key={`element-key-${index}`}>
                                        <img className="users-in-project-info-icon" src={AssigneeIcon} alt="" />
                                        {creatorName === user.fullname ?
                                            <div className="users-in-project-info-div-content flex-column align-center">
                                                <span className="users-in-project-info-text" >
                                                    {user.fullname} ({getTranslation("USERS_IN_PROJECT_MODAL_PROJECT_CREATOR")})
                                                </span>
                                                <span className="users-in-project-info-text" >
                                                    {user.email}
                                                </span>
                                            </div>
                                            :
                                            <div className="users-in-project-info-div-content flex-column align-center">
                                                <span className="users-in-project-info-text">
                                                    {user.fullname}
                                                </span>
                                                <span className="users-in-project-info-text" >
                                                    {user.email}
                                                </span>
                                                <span className="users-in-project-info-text" >
                                                    {getTranslation("USERS_IN_PROJECT_MODAL_INVITED_BY")} {creatorName}
                                                </span>
                                                <span className="users-in-project-info-text" >
                                                    {projectCreatorEmail}
                                                </span>
                                            </div>}
                                    </div>
                                ) :
                                <span className="users-in-project-info-zero-users" >
                                    {getTranslation("USERS_IN_PROJECT_MODAL_NO_USERS")}
                                </span>
                            }
                        </div>
                    </CustomScrollbar>}
                {!isLoading && hasError &&
                    <span className="users-in-project-info-error" >
                        {getTranslation("USERS_IN_PROJECT_MODAL_ERROR_LOADING")}
                    </span>}
            </div>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(UsersInProjectModal);
