import React, { useEffect, useLayoutEffect, useState, useCallback, useRef } from 'react';
import './styles.css';

const Scrollbar = ({
    children,
    className,
    width = 7,
    right = 0,
    scrollHandleColor = '#d9dde5',
    backgroundColor = 'white',
    offsetX,
    dependencies = [],
    haveScroll = true,
    height,
    ...restProps
}) => {
    const [scrollBoxHeight, setScrollBoxHeight] = useState(0);
    const [scrollBoxTop, setScrollBoxTop] = useState(0);
    const [scrollContainerHeight, setScrollContainerHeight] = useState(0);
    const [scrollHandlePosition, setScrollHandlePosition] = useState(0);
    const [isDragging, setDragging] = useState(false);
    const scrollHostRef = useRef(null);

    const updateHandleSize = useCallback(() => {
        if (!scrollHostRef) {
            return;
        }
        const scrollHostElement = scrollHostRef.current;
        const { clientHeight, scrollHeight } = scrollHostElement;
        scrollContainerHeight !== scrollHeight && setScrollContainerHeight(scrollHeight);

        if (!scrollHostElement || clientHeight >= scrollHeight) {
            setScrollBoxHeight(0);
            return;
        }

        const scrollThumbPercentage = clientHeight / scrollHeight || 0;
        const scrollThumbHeight = scrollThumbPercentage * clientHeight || 0;
        setScrollBoxHeight(scrollThumbHeight);
    }, [scrollContainerHeight]);

    const handleScroll = useCallback(() => {
        if (!scrollHostRef) {
            return;
        }

        const scrollHostElement = scrollHostRef.current;
        const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;
        scrollContainerHeight !== scrollHeight && updateHandleSize();

        let newTop = (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
        newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
        setScrollBoxTop(newTop);
    }, [updateHandleSize, scrollBoxHeight, scrollContainerHeight]);

    const handleMouseMove = useCallback(
        (e) => {
            if (isDragging) {
                e.preventDefault();
                e.stopPropagation();
                const scrollHostElement = scrollHostRef.current;
                const { scrollHeight, offsetHeight } = scrollHostElement;
                let deltaY = e.clientY - scrollHandlePosition;
                let percentage = deltaY * (scrollHeight / offsetHeight);

                setScrollHandlePosition(e.clientY);
                setScrollBoxTop(Math.min(Math.max(0, scrollBoxTop + deltaY), offsetHeight - scrollBoxHeight));

                scrollHostElement.scrollTop = Math.min(
                    scrollHostElement.scrollTop + percentage,
                    scrollHeight - offsetHeight
                );
            }
        },
        [isDragging, scrollHandlePosition, scrollBoxHeight, scrollBoxTop]
    );

    const handleMouseUp = useCallback(
        (e) => {
            if (isDragging) {
                setDragging(false);
                e.preventDefault();
            }
        },
        [isDragging]
    );

    useLayoutEffect(() => {
        const scrollHostElement = scrollHostRef.current;
        if (scrollHostElement) {
            updateHandleSize();
            scrollHostElement.addEventListener('scroll', handleScroll, true);
            return () => scrollHostElement.removeEventListener('scroll', handleScroll, true);
        }
    }, [handleScroll, updateHandleSize]);

    useLayoutEffect(() => {
        updateHandleSize();
    }, [restProps.preferences, scrollHostRef, updateHandleSize, ...dependencies]);

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mouseleave', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mouseleave', handleMouseUp);
        };
    }, [handleMouseMove, handleMouseUp]);

    const handleScrollbarMouseDown = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setScrollHandlePosition(e.clientY);
        setDragging(true);
    }, []);

    const scrollHandleStyle = { height: scrollBoxHeight, top: scrollBoxTop, backgroundColor: scrollHandleColor, width };

    const scrollHostClass = className ? ` ${className}` : '';
    return (
        <div className='custom-scrollbar-container' style={height && { height }}>
            <div ref={scrollHostRef} className={'custom-scrollbar-scrollable-container' + scrollHostClass}>
                {children}
            </div>

            {haveScroll && (
                <div className='custom-scrollbar' style={{ backgroundColor, width, right }}>
                    <div
                        className='custom-scrollbar-handle'
                        style={scrollHandleStyle}
                        onMouseDown={handleScrollbarMouseDown}
                    ></div>
                </div>
            )}
        </div>
    );
};

export default Scrollbar;
