import {
    GET_SUBSCRIPTION_INFO,
    GET_SUBSCRIPTION_INFO_SUCCESS,
    GET_SUBSCRIPTION_INFO_ERROR,
    CREATE_SUBSCRIPTION,
    CREATE_SUBSCRIPTION_SUCCESS,
    CREATE_SUBSCRIPTION_OPENAI,
    CREATE_SUBSCRIPTION_OPENAI_SUCCESS,
    CREATE_SUBSCRIPTION_ERROR,
    RESET_SUBSCRIPTION,
    UPDATE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_ERROR,
    RETRY_SUBSCRIPTION_PAYMENT,
    RETRY_SUBSCRIPTION_PAYMENT_SUCCESS,
    RETRY_SUBSCRIPTION_PAYMENT_ERROR,
    CREATE_SUBSCRIPTION_OPENAI_ERROR,
} from '../actions/types';

const initialState = {
    isLoading: false,
    errors: null,
    subscriptions: [],
    creating: {
        isLoading: false,
        errors: {},
        success: false,
    },
    updatePaymentMethod: {
        isLoading: false,
        errors: null,
        success: false,
    },
    requestPayment: {
        isLoading: false,
        errors: null,
        success: false,
    },
    openai: {
        isLoading: false,
        errors: null,
        success: false,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SUBSCRIPTION_INFO:
            return {
                ...state,
                isLoading: true,
                errors: null,
            };
        case GET_SUBSCRIPTION_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                subscriptions: action.payload,
                errors: null,
            };
        case GET_SUBSCRIPTION_INFO_ERROR:
            return {
                ...state,
                isLoading: false,
                subscriptions: [],
                errors: action.payload,
            };

        case CREATE_SUBSCRIPTION:
            return {
                ...state,
                creating: { ...initialState.creating, isLoading: true },
            };
        case CREATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                creating: { errors: null, isLoading: false, success: true },
            };
        case CREATE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                creating: { errors: action.payload, isLoading: false, success: false },
            };
        case CREATE_SUBSCRIPTION_OPENAI_ERROR:
            return {
                ...state,
                openai: { errors: action.payload, isLoading: false, success: false },
            };

        case UPDATE_PAYMENT_METHOD:
            return {
                ...state,
                updatePaymentMethod: { ...initialState.updatePaymentMethod, isLoading: true },
            };
        case UPDATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                updatePaymentMethod: { errors: null, success: true, isLoading: false },
            };
        case UPDATE_PAYMENT_METHOD_ERROR:
            return {
                ...state,
                updatePaymentMethod: { errors: action.payload, success: false, isLoading: false },
            };

        case RESET_SUBSCRIPTION:
            return { ...initialState, subscriptions: [...state.subscriptions] };

        case RETRY_SUBSCRIPTION_PAYMENT:
            return {
                ...state,
                requestPayment: { ...initialState.requestPayment, isLoading: true },
            };
        case RETRY_SUBSCRIPTION_PAYMENT_SUCCESS:
            return {
                ...state,
                requestPayment: { errors: null, isLoading: false, success: true },
            };
        case RETRY_SUBSCRIPTION_PAYMENT_ERROR:
            return {
                ...state,
                requestPayment: { errors: action.payload, isLoading: false, success: false },
            };
        case CREATE_SUBSCRIPTION_OPENAI_SUCCESS:
            return {
                ...state,
                openai: { errors: null, isLoading: false, success: true },
            };
        case CREATE_SUBSCRIPTION_OPENAI:
            return {
                ...state,
                openai: { ...initialState.openai, isLoading: true },
            };
        default:
            return state;
    }
}
