import React from 'react';
import { getTranslation } from '../../../helpers/getLanguage';

import CustomCheckbox from '../../inputs/CustomCheckbox';

const SortableCell = ({ name, label, sort, handleSortChange }) => {
    const isActive = name === sort.field;

    return (
        <div className="flex align-center width-100">
            <span
                className={'users-row-text pointer' + (isActive ? ' active' : '')}
                onClick={() => handleSortChange(name)}
            >
                {label}
            </span>
            {isActive && <div className={'sort-triangle-icon' + (sort.asc ? ' asc' : '')}></div>}
        </div>
    );
};

const UsersTableHeader = ({ sort, handleSortChange, handleSelectAllRows, selectedUsersAll, editMode }) => {
    return (
        <div className="users-container-header">
            <div className="users-row-header flex align-center">
                <div className="users-row-color-indicator transparent"></div>
                <div className="users-row-checkbox flex-center">
                    <CustomCheckbox
                        customClass="users-checkbox"
                        checked={selectedUsersAll}
                        handleChange={handleSelectAllRows}
                        disabled={!editMode}
                    />
                </div>
                <div className="users-row-initials flex align-center padding-0"></div>
                <div className="users-row-fullname flex align-center">
                    <SortableCell name="fullname" label={getTranslation("USERS_TABLE_SORTABLE_CELL_FULL_NAME")} sort={sort} handleSortChange={handleSortChange} />
                </div>
                <div className="users-row-username flex align-center">
                    <SortableCell name="email" label={getTranslation("USERS_TABLE_SORTABLE_CELL_USERNAME")} sort={sort} handleSortChange={handleSortChange} />
                </div>
                <div className="users-row-team flex align-center">
                    <SortableCell name="team" label={getTranslation("USERS_TABLE_SORTABLE_CELL_TEAM")} sort={sort} handleSortChange={handleSortChange} />
                </div>
                <div className="users-row-date flex align-center padding-0">
                    <SortableCell name="joined" label={getTranslation("USERS_TABLE_SORTABLE_CELL_DATA_JOINED")} sort={sort} handleSortChange={handleSortChange} />
                </div>
                <div className="menu-button-placeholder"></div>
            </div>
        </div>
    );
};

export default UsersTableHeader;
