import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ children }) => {
    const root = document.getElementById('portal-root');
    const element = document.createElement('div');

    useEffect(() => {
        root.appendChild(element);
        return () => root.removeChild(element);
    }, [element, root]);

    return createPortal(children, element);
};

export default Portal;
