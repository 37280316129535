import React from 'react';
import classnames from 'classnames';

import { getTranslation } from '../../../helpers/getLanguage';

import './styles.css';

const CustomInput = ({
    onKeyDown,
    value,
    name,
    onChange,
    placeholder = '',
    containerClass = '',
    label = '',
    onBlur = null,
    errorMessage = '',
    labelClass = 'custom-input-label-default',
    disabled = false,
    autoFocus = false,
    type = 'text',
    autoComplete = '',
    customInputClass = '',
    emptyClassDisable,
}) => {
    const emptyStateClass = !value.length && !emptyClassDisable ? 'empty' : '';
    const disabledClass = disabled ? 'disabled' : '';
    const validationClass = errorMessage.length > 0 ? 'has-errors' : '';

    const handleChange = (e) => {
        if (!disabled) onChange(e);
    };

    return (
        <div className={containerClass}>
            {label.length > 0 && <span className={labelClass}>{label}</span>}
            <div>
                <input
                    autoFocus={autoFocus}
                    value={value}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onKeyDown={onKeyDown}
                    onChange={handleChange}
                    className={
                        classnames(
                            'custom-input',
                            { empty: emptyStateClass },
                            { disabled: disabledClass },
                            { 'has-errors': validationClass }
                        ) +
                        ' ' +
                        customInputClass
                    }
                    onBlur={onBlur}
                    autoComplete={autoComplete}
                />
            </div>

            {errorMessage.length > 0 && (
                <div className='custom-input-errors flex-center'>
                    <span>{getTranslation(errorMessage)}</span>
                </div>
            )}
        </div>
    );
};

export default CustomInput;
