const localeDate = {
  en: "en-US",
  cn: "zh-CN",
};

export const getDate = (date, language = "en") => {
  if (!date) return "";

  if (language === "en") {
    return new Date(date).toGMTString().split(",")[1].substr(1, 11);
  }

  const correctDate = new Date(date).toLocaleDateString(localeDate[language], {
    year: "numeric",
    day: "numeric",
    month: "short",
  });

  return correctDate;
};
