import React from 'react';
import './styles.css';

import { getTranslation } from '../../../helpers/getLanguage';

const getArray = (maxValue, startFromZero) => {
    const result = [];
    for (let i = 0; i < maxValue + Number(startFromZero); i++) {
        result.push(startFromZero ? i : i + 1);
    }

    return result;
};

const defaultColor = '#3e6fd9';
const defaultRectangleStyle = {
    backgroundColor: '#e7ecfa',
    borderColor: '#3e6fd9',
    height: "60px",
    minHeight: "60px",
};

const transparentRectangle = {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    height: "60px",
    minHeight: "60px",
};

const CustomScoreInput = ({ value, setValue, maxValue = 5, colors = [], rectangleStyle = defaultRectangleStyle, isRateIdea = false, startFromZero = false}) => {
    const buttons = getArray(maxValue, startFromZero).map(el => {
        const isActive = value === el;
        const activeClass = isActive ? ' active' : '';
        
        const arrayOffset = startFromZero ? el : el - 1;
        const circleColor = !isActive ? 'white' : colors[arrayOffset] || defaultColor;
        const circleBorder = colors[arrayOffset] || defaultColor;
        const circleStyle = { backgroundColor: circleColor, borderColor: circleBorder };

        const spanStyle = { color: colors[arrayOffset] || defaultColor };
        
        return (
            <div className="flex-column align-center justify-center" key={el}>
                <div
                    className={'custom-score-selection flex-center pointer' + activeClass}
                    onClick={() => setValue(el)}
                    style={isActive ? rectangleStyle : transparentRectangle}
                >
                    <div className={'custom-score-input-circle pointer flex-center' + activeClass} style={circleStyle}>
                        <span style={spanStyle}>{el}</span>
                    </div>
                </div>
                <div className="flex-center">
                    {((startFromZero && el === 0) || (!startFromZero && (el === 1) || el === maxValue)) && isRateIdea && (
                        <span className="custom-sub-label">{`${(el === 1 || (startFromZero && el === 0)) ? getTranslation("CUSTOM_SUB_LABEL_LOW") : getTranslation("CUSTOM_SUB_LABEL_HIGH")}`}</span>
                    )}
                </div>
            </div>
        );
    });

    return <div className="custom-score-input-container flex align-baseline">{buttons}</div>;
};

export default CustomScoreInput;
