import {
  GET_STATISTICS,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_ERROR,
} from './types';

import { getStatistics as requestStatistics } from '../api/admin';

export const getStatistics = () => dispatch => {
  dispatch({ type: GET_STATISTICS });

  const onSuccess = response => dispatch({ type: GET_STATISTICS_SUCCESS, payload: response });
  const onError = error => dispatch({ type: GET_STATISTICS_ERROR, payload: error });

  const params = {};
  requestStatistics({ params }, onSuccess, onError);
};