import React from 'react';
import { connect } from 'react-redux';
import '../styles.css';
import FlagItem from './FlagItem';

const LanguageItem = ({
    languageDropdownActive,
    setLanguageDropdownActive,
    compact,
    languageVersion,
    changeLanguage,
    currentLanguage,
    language,
}) => {
    const languages = {
        en: 'ENGLISH',
        cn: 'CHINESE',
        es: 'SPANISH',
    };

    const shortTitleLanguage = {
        en: 'Eng',
        cn: '中版',
        es: 'Esp',
    };

    const ariaLabels = {
        en: 'English',
        cn: 'Chinese',
        es: 'Spanish',
    };

    return (
        <div style={{ position: 'relative', top: '-2px', width: '100%' }}>
            <div>
                <div
                    className='sidenav-item flex align-center position-relative pointer'
                    onClick={() => setLanguageDropdownActive(!languageDropdownActive)}
                    style={{ paddingRight: '10px', marginRight: compact ? '4px' : '' }}
                    aria-label={ariaLabels[language]}
                >
                    <div
                        className={`sidenav-item flex align-center position-relative pointer ${compact}`}
                        style={compact ? { width: '', marginLeft: '13px' } : { width: '90%', marginLeft: '50px' }}
                        aria-label={ariaLabels[language]}
                        role='button'
                    >
                        {
                            compact ?
                            <>
                                <span style={{ width: '30px' }}>{shortTitleLanguage[language]}</span>
                                <div className='sidenav-item-tooltip flex-center'>
                                    <span>{languageVersion[languages[language]]}</span>
                                </div>
                            </>
                            : <div className='flex align-center justify-space-between width-100'>
                                <span className='flex'>{languageVersion[languages[language]]}</span>
                            </div>
                        }
                    </div>
                    {!compact && <div className='triangle-right-icon'></div>}
                </div>
            </div>
            {!languageDropdownActive ? null : (
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 1000,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        left: !compact ? '' : '10px',
                    }}
                >
                    {Object.keys(languages).map((languageKey, index) => {
                        return languageKey === language ? null : (
                            <div
                                style={{
                                    backgroundColor: '#171e46',
                                    margin: '1px 0',
                                    width: !compact ? '92%' : '58px',
                                }}
                                onClick={() => {
                                    setLanguageDropdownActive(!languageDropdownActive);
                                    changeLanguage(currentLanguage[languages[languageKey]]);
                                }}
                                key={index}
                            >
                                <FlagItem
                                    compact={compact}
                                    shortTitleLanguage={shortTitleLanguage[languageKey]}
                                    languageVersion={languageVersion[languages[languageKey]]}
                                    changeLanguage={changeLanguage}
                                    currentLanguage={languageVersion[languages[currentLanguage]]}
                                    labelClass={`${languageKey}-flag-label`}
                                    ariaLabels={ariaLabels[languageKey]}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    language: state.auth.userInfo.language,
});

export default connect(mapStateToProps)(LanguageItem);
