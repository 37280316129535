import React, { useState } from 'react';

import { validateField } from '../../../../common/validation';
import { deleteUser } from '../../../../api/users';

import CustomInput from '../../../inputs/CustomInput';
import ConfirmationModal from '../../../dialogs/ConfirmationModal';

import { getTranslation } from '../../../../helpers/getLanguage';

const initialFields = {
    email: ''
};

const initialErrors = {
    email: ''
};

const DeleteUser = () => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(initialErrors);
    const [isPending, setIsPending] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const handleSetErrors = (e, value) => {
        const { name } = e.target || e;
        setErrors(errors => ({ ...errors, [name]: value }));
    };

    const handleDeleteUser = () => {
        const message = validateField({ name: 'email', value: fields.email });
        if (message.length) {
            setErrors({ ...errors, email: message });
            return;
        }

        const params = { email: fields.email };
        const onSuccess = () => {
            setIsPending(false);
            setConfirmationText(getTranslation("DELETE_EXISTING_USER_CONFIRMATION_TEXT_SUCCESSFULLY"))
            setModalOpen(true);
        };

        const onError = error => {
            setIsPending(false);
            console.error(error);
            setConfirmationText(`${getTranslation("DELETE_EXISTING_USER_CONFIRMATION_TEXT_ERROR")} ${error.response.data.message}`);
            setModalOpen(true);
        };

        setIsPending(true);
        deleteUser(params, onSuccess, onError);
    };

    const handleSubmitEnterCodeActivation = event => {
        if (!isPending && event.key === 'Enter') {
            handleDeleteUser();
        }
    };

    return (
        <>
            <div className="manage-user-container mb-30">
                <div className="flex-column admin-password-container">
                    <span className="manage-user-label mb-20">{getTranslation("DELETE_EXISTING_USER_TITLE")}</span>
                    <CustomInput
                        value={fields.email}
                        name="email"
                        onChange={handleChange}
                        onKeyDown={handleSubmitEnterCodeActivation}
                        containerClass="flex-column width-100 mb-20"
                        label={getTranslation("DELETE_EXISTING_USER_USER_EMAIL")}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.email}
                    />

                    <button className="manage-users-button" onClick={handleDeleteUser} disabled={isPending}>
                        <span>{getTranslation("DELETE_EXISTING_USER_BUTTON")}</span>
                    </button>
                </div>
            </div>

            {modalOpen && (
                <ConfirmationModal
                    closeOnClickOutside={false}
                    closeDialog={() => setModalOpen(false)}
                    message={confirmationText}
                    buttonText="OK"
                    messageWidth={400}
                    onConfirm={() => setModalOpen(false)}
                />
            )}
        </>
    );
};

export default DeleteUser;
