import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import CloseIcon from '../../../../assets/images/close-icon.svg';

import { ReactComponent as CloseEditIcon } from '../../../../assets/images/close-description-icon.svg';
import { ReactComponent as PlusSign } from '../../../../assets/images/add-button-blue-plan.svg';

import Dialog from '../../../dialogs/Dialog';
import CustomNumberInput from '../../../inputs/CustomNumberInput';
import Loader from '../../../common/Loader';
import CustomInput from '../../../inputs/CustomInput';
import ConfirmationModal from '../../../dialogs/ConfirmationModal';

import { getAllCreditsInfo, updateOneCreditInfo } from '../../../../api/credits';
import {
    createNewPlanSubscription,
    getAllPlans,
    changeDescriptionPlan,
    removeDescriptionPlan,
    addDescriptionPlan,
} from '../../../../api/plans';
import { getTranslation } from '../../../../helpers/getLanguage';

import manageSubscriptionStyles from '../../../MyAccount/ManageSubscription/ManageSubscription.module.css';
import styles from './Plans.module.css';

const modalNames = {
    ADDING_PLAN: 'addingPlan',
    EDITING_CREDITS: 'editingCredits',
    ERROR: 'error',
};

const initialOpenModal = {
    [modalNames.ADDING_PLAN]: false,
    [modalNames.ERROR]: false,
};

const initialPlans = {
    subscription: [],
    tokens: [],
};

const initialEditingDescription = { descriptionId: null, message: '' };

const initialPlanFields = {
    durationType: 1,
    credits: 0,
    price: 1,
    deletedAt: true,
    id: null,
};

const defaultFieldsPlanClassNames = classNames(
    'flex',
    'flex-column',
    'justify-center',
    'align-flex-start',
    styles['number-field']
);

const blueButtonClassNames = classNames(
    'show-prefabs-button',
    'promo-code-button',
    'dark-blue-btn',
    'flex align-center',
    'justify-center',
    'width-100'
);

const sortPlansByField = (plans, field, valueField) => [
    ...(plans.filter((plan) => plan[field] === valueField) ?? []),
    ...(plans.filter((plan) => plan[field] !== valueField) ?? []),
];

const PlanModal = ({ closeDialog, addOnePlan, editingPlan, updateOnePlan }) => {
    const [fields, setFields] = useState(
        editingPlan
            ? {
                  ...editingPlan,
                  price: editingPlan.type === 'month' ? editingPlan.price : (editingPlan.price / 12)?.toFixed(2),
              }
            : initialPlanFields
    );
    const [isLoading, setIsLoading] = useState(false);

    const isEditMode = Boolean(editingPlan);

    const changeField = useCallback((value, field) => {
        setFields((prev) => ({ ...prev, [field]: value }));
    }, []);

    const fieldsInfo = useMemo(
        () => [
            { label: 'Price', value: fields.price, minValue: 1, fieldId: 'plan-price', fieldName: 'price' },
            { label: 'Tokens', value: fields.credits, minValue: 0, fieldId: 'plan-credits', fieldName: 'credits' },
        ],
        [fields]
    );

    const createPlan = useCallback(() => {
        const planData = {
            durationCount: fields.durationType,
            price: fields.type === 'month' ? fields.price : (fields.price * 12).toFixed(2),
            credits: fields.credits,
            id: fields.id,
            type: fields.type,
        };

        const onSuccess = (plan) => {
            isEditMode ? updateOnePlan(plan) : addOnePlan(plan);

            setIsLoading(false);
            closeDialog();
        };

        const onError = () => {
            setIsLoading(false);
            closeDialog();
        };

        setIsLoading(true);
        createNewPlanSubscription(planData, onSuccess, onError);
    }, [fields]);

    return (
        <Dialog isLoading={isLoading} closeDialog={closeDialog}>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={styles['plan-dialog']}>
                    <div className={classNames('flex', 'justify-between', styles['top-dialog'])}>
                        <div>
                            <span className={styles['header-dialog']}>{`${isEditMode ? 'Edit' : 'Add'} Plan`}</span>
                        </div>
                        <div className={classNames('delete-popup-close', 'flex')}>
                            <img
                                className={classNames(styles['dialog-close'], 'pointer')}
                                src={CloseIcon}
                                alt='close'
                                onClick={closeDialog}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(
                            'flex',
                            'flex-column',
                            'justify-between',
                            'align-start',
                            'width-100',
                            styles['modal-adding-part']
                        )}
                    >
                        <div>
                            <div className={classNames('flex')}>
                                {fieldsInfo.map(({ label, value, minValue, fieldId, fieldName }) => (
                                    <div key={fieldId + fieldName} className={defaultFieldsPlanClassNames}>
                                        <label className={classNames('mb-0')} htmlFor={fieldId}>
                                            {label}
                                        </label>
                                        <CustomNumberInput
                                            id={fieldId}
                                            value={value}
                                            onChange={(value) => changeField(value, fieldName)}
                                            minValue={minValue}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <button
                                onClick={createPlan}
                                className={classNames(blueButtonClassNames, 'force-width-100', 'm-0')}
                            >
                                {isEditMode ? 'UPDATE' : 'CREATE'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Dialog>
    );
};

const SubscriptionPlan = ({
    plan,
    index,
    editPlan,
    saveDescription,
    tokens,
    setSelectedToken,
    className,
    addOneDescription,
    removeOneDescription,
}) => {
    const { id: planId, type, description, price, credits, durationCount } = plan;

    const [openAISelect, setOpenAISelect] = useState(false);
    const [editingDescription, setEditingDescription] = useState(initialEditingDescription);

    const editOneDescription = useCallback((e) => {
        e.persist();
        setEditingDescription((prev) => ({ ...prev, message: e?.target?.value ?? '' }));
    }, []);

    const saveOneDescription = useCallback(() => {
        if (!editingDescription?.descriptionId) return;

        saveDescription(editingDescription);
        setEditingDescription(initialEditingDescription);
    }, [editingDescription]);

    const cancelEditDescription = useCallback(() => {
        setEditingDescription(initialEditingDescription);
    }, [editingDescription]);

    return (
        <div
            key={index + planId + 'plan'}
            className={classNames(
                manageSubscriptionStyles['plan'],
                'flex',
                'flex-column',
                'justify-between',
                styles['plan'],
                className
            )}
        >
            <div className={classNames('flex', 'flex-column')}>
                <span className={manageSubscriptionStyles['plan-header']}>Standard Plan</span>
                <span className={classNames('flex', manageSubscriptionStyles['plan-prev'])}>
                    <b className={manageSubscriptionStyles['plan-amount']}>{`$${
                        type === 'month' ? price.toFixed(2) : (price / 12)?.toFixed(2)
                    }`}</b>
                    <b className={manageSubscriptionStyles['plan-period']}>{`/${
                        durationCount === 1 ? '' : durationCount
                    } ${'month'}`}</b>
                    <b className={styles['plan-monthly']}>{type[0]?.toUpperCase() + type.slice(1) + 'ly'}</b>
                </span>
                <span className={styles['plan-billed']}>{`${credits} start credits`}</span>
                <button
                    onClick={() => editPlan({ ...plan, durationType: plan.durationCount })}
                    className={classNames(
                        'flex',
                        'justify-center',
                        'align-center',
                        manageSubscriptionStyles['plan-buy']
                    )}
                >
                    EDIT PLAN
                </button>

                <ul className={manageSubscriptionStyles['plan-descriptions']}>
                    {description?.map(({ message, id }) =>
                        editingDescription.descriptionId === id ? (
                            <div className={classNames('flex')} key={id}>
                                <CustomInput
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') saveOneDescription();
                                    }}
                                    value={editingDescription.message}
                                    name='editingDescription'
                                    onChange={editOneDescription}
                                    customInputClass={styles['small-description-input']}
                                    autoFocus
                                />
                                <button
                                    onClick={cancelEditDescription}
                                    className={classNames(
                                        styles['close-description'],
                                        'flex',
                                        'align-center',
                                        'justify-center'
                                    )}
                                >
                                    <CloseEditIcon className={styles['icon-close']} />
                                </button>
                                <button
                                    onClick={saveOneDescription}
                                    className={classNames(
                                        styles['save-description'],
                                        'flex',
                                        'align-center',
                                        'justify-center'
                                    )}
                                >
                                    <PlusSign fill='white' className={styles['plus-icon']} />
                                </button>
                            </div>
                        ) : (
                            <li
                                onClick={() => setEditingDescription({ descriptionId: id, message: message ?? '' })}
                                className={classNames(
                                    manageSubscriptionStyles['active-plan-item'],
                                    styles['active-plan-item']
                                )}
                                key={id}
                            >
                                {getTranslation(message)}
                            </li>
                        )
                    )}
                </ul>
            </div>
            <div>
                <div className={classNames('flex', 'mb-10px')}>
                    <button
                        className={classNames(
                            'flex',
                            'justify-center',
                            'align-center',
                            manageSubscriptionStyles['plan-buy'],
                            styles['add-description-field']
                        )}
                        onClick={() => addOneDescription(planId)}
                    >
                        Add Field
                    </button>
                    <button
                        onClick={() => removeOneDescription(description[description.length - 1].id)}
                        className={classNames(
                            'add-attribute-button',
                            'flex',
                            'align-center',
                            'justify-space-around',
                            'generate-component',
                            'mb-0',
                            'width-70',
                            styles['remove-description-field']
                        )}
                    >
                        Remove Field
                    </button>
                </div>
                <div className={manageSubscriptionStyles['plan-credits-buy']}>
                    <span className={manageSubscriptionStyles['plan-credits-description']}>
                        {getTranslation('ADD_AI_CREDITS')}
                    </span>

                    {openAISelect && (
                        <div id='select-credits-block' className={manageSubscriptionStyles['select-credits-block']}>
                            {tokens.map(({ amount, price, id }) => {
                                const isYear = plan?.type === 'year';
                                return (
                                    <div
                                        key={id + planId}
                                        className={classNames(
                                            'flex',
                                            'justify-between',
                                            manageSubscriptionStyles['select-credits']
                                        )}
                                        onClick={() => {
                                            if (id) setSelectedToken({ id, price, amount });
                                            setOpenAISelect(false);
                                        }}
                                    >
                                        <span>{`${amount} ${getTranslation('AI_TAB_TITLE')}`}</span>
                                        <span>{price ? `$${price}` : ''}</span>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <button
                        className={classNames(
                            openAISelect
                                ? manageSubscriptionStyles['select-amount-active']
                                : manageSubscriptionStyles['credits-buy'],
                            'force-width-100',
                            manageSubscriptionStyles['select-amount'],
                            'flex',
                            'align-center',
                            'justify-between'
                        )}
                        id='select-amount-active'
                        onClick={() => setOpenAISelect((prev) => !prev)}
                    >
                        {getTranslation('SELECT_AMOUNT')}
                        <div className={manageSubscriptionStyles['arrow-icon']}>
                            <div className='custom-dropdown-triangle-icon'></div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

const CreditsModal = ({ closeDialog, isLoading, selectedToken, tokenInfo, updateToken }) => {
    const [fields, setFields] = useState(selectedToken);

    const updateOneToken = useCallback(() => {
        updateToken(fields);
        setFields(selectedToken);
        closeDialog();
    }, [fields]);

    const fieldsInfo = useMemo(() => [
        {
            label: 'Tokens',
            value: fields.amount,
            minValue: 1,
            fieldId: tokenInfo?.id + 'edit-credits',
            fieldName: 'amount',
        },
        {
            label: 'Amount',
            value: fields.price,
            minValue: 1,
            fieldId: tokenInfo?.id + 'edit-credits',
            fieldName: 'price',
        },
    ]);

    const changeField = useCallback((value, field) => {
        setFields((prev) => ({ ...prev, [field]: value }));
    }, []);

    return (
        <Dialog closeDialog={closeDialog}>
            {isLoading ? (
                <Loader />
            ) : (
                <div className={styles['plan-tokens-dialog']}>
                    <div className={classNames('flex', 'justify-between', styles['top-dialog'])}>
                        <div>
                            <span className={styles['header-dialog']}>Edit token</span>
                        </div>
                        <div className={classNames('delete-popup-close', 'flex')}>
                            <img
                                className={classNames(styles['dialog-close'], 'pointer')}
                                src={CloseIcon}
                                alt='close'
                                onClick={closeDialog}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(
                            'flex',
                            'flex-column',
                            'justify-between',
                            'align-start',
                            'width-100',
                            styles['modal-adding-tokens-part']
                        )}
                    >
                        <div>
                            <div className={classNames('flex')}>
                                {fieldsInfo.map(({ label, value, minValue, fieldId, fieldName }) => (
                                    <div key={fieldId + fieldName} className={defaultFieldsPlanClassNames}>
                                        <label className={classNames('mb-0')} htmlFor={fieldId}>
                                            {label}
                                        </label>
                                        <CustomNumberInput
                                            id={fieldId}
                                            value={value}
                                            onChange={(value) => changeField(value, fieldName)}
                                            minValue={minValue}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <button
                                onClick={updateOneToken}
                                className={classNames(blueButtonClassNames, 'force-width-100', 'm-0')}
                            >
                                {'UPDATE'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Dialog>
    );
};

const Plans = () => {
    const [modalOpen, setModalOpen] = useState(initialOpenModal);
    const [plans, setPlans] = useState(initialPlans);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(null);
    const [selectedToken, setSelectedToken] = useState(null);

    useEffect(() => {
        getPlans();
        getTokens();
    }, []);

    const getTokens = useCallback(() => {
        const onSuccess = (tokens) => {
            setPlans((prev) => ({ ...prev, tokens }));
            setIsLoading(false);
        };

        const onError = (err) => {
            setError(err?.message ?? '');
            setIsLoading(false);
        };

        setIsLoading(true);
        getAllCreditsInfo(onSuccess, onError);
    }, []);

    const updateOneToken = useCallback(
        (token) =>
            setPlans((prev) => ({ ...prev, tokens: prev.tokens.map((item) => (item.id === token.id ? token : item)) })),
        []
    );

    const updateToken = useCallback(({ id, amount, price }) => {
        const onSuccess = (updatedToken) => {
            if (updatedToken) updateOneToken(updatedToken);
            setIsLoading(false);
        };

        const onError = (err) => {
            if (err?.message) setError(err?.message ?? '');
            setIsLoading(false);
        };

        setIsLoading(true);
        updateOneCreditInfo({ id, amount, price }, onSuccess, onError);
    }, []);

    const getPlans = useCallback(() => {
        const onSuccess = (allPlans) => {
            const sortingPlans = sortPlansByField(allPlans, 'type', 'year');

            setPlans((prev) => ({ ...prev, subscription: sortingPlans }));
            setIsLoading(false);
        };

        const onError = (error) => {
            setError(error?.message || '');
            showModalOpen(modalNames.ERROR, true);
            setIsLoading(false);
        };

        setIsLoading(true);
        getAllPlans(onSuccess, onError);
    });

    const showModalOpen = useCallback(
        (modalName, modalState) => setModalOpen((prev) => ({ ...prev, [modalName]: modalState })),
        []
    );

    const addOnePlan = useCallback(
        (newPlan) => setPlans((prev) => ({ ...prev, subscription: [...prev.subscription, newPlan] })),
        []
    );

    const updateOnePlan = useCallback(
        (updatedPlan) =>
            setPlans((prev) => ({
                ...prev,
                subscription: prev.subscription.map((prevPlan) =>
                    prevPlan.id === updatedPlan.id
                        ? { ...updatedPlan, description: [...prevPlan.description] }
                        : prevPlan
                ),
            })),
        []
    );

    const setNewDescription = useCallback(
        ({ planId, id, message }) =>
            setPlans((prev) => ({
                ...prev,
                subscription: prev.subscription.map((plan) =>
                    plan.id === planId
                        ? {
                              ...plan,
                              description: plan.description.map((item) =>
                                  item.id === id ? { ...item, message } : item
                              ),
                          }
                        : plan
                ),
            })),
        []
    );

    const removeDescription = useCallback(
        ({ planId, id }) =>
            setPlans((prev) => ({
                ...prev,
                subscription: prev.subscription.map((plan) =>
                    plan.id === planId
                        ? {
                              ...plan,
                              description: plan.description.filter((item) => item.id !== id),
                          }
                        : plan
                ),
            })),
        []
    );

    const addNewOneDescription = useCallback(
        (newDescriptionData) =>
            setPlans((prev) => ({
                ...prev,
                subscription: prev.subscription.map((plan) =>
                    plan.id === newDescriptionData.planId
                        ? {
                              ...plan,
                              description: [...plan.description, newDescriptionData],
                          }
                        : plan
                ),
            })),
        []
    );

    const saveDescription = useCallback(({ descriptionId, message }) => {
        if (!descriptionId) return;

        const onSuccess = ({ id, message, planId }) => {
            setNewDescription({ id, message, planId });
            setIsLoading(false);
        };

        const onError = (err) => {
            setError(err?.message ?? 'Unknown error');
            setIsLoading(false);
        };

        setIsLoading(true);
        changeDescriptionPlan({ descriptionId, message }, onSuccess, onError);
    }, []);

    const addOneDescription = useCallback((planId) => {
        const onSuccess = (newDescriptionData) => {
            addNewOneDescription(newDescriptionData);
            setIsLoading(false);
        };

        const onError = (err) => {
            setError(err?.message ?? 'Unknown error');
            setIsLoading(false);
        };

        setIsLoading(true);
        addDescriptionPlan({ planId, message: '' }, onSuccess, onError);
    });

    const removeOneDescription = useCallback((descriptionId) => {
        const onSuccess = (removedData) => {
            removeDescription(removedData);
            setIsLoading(false);
        };

        const onError = (err) => {
            setError(err?.message ?? 'Unknown error');
            setIsLoading(false);
        };

        setIsLoading(true);
        removeDescriptionPlan(descriptionId, onSuccess, onError);
    });

    return (
        <>
            <div className={classNames('flex', 'flex-column')}>
                <div>
                    <button
                        onClick={() => showModalOpen(modalNames.ADDING_PLAN, true)}
                        className={classNames(
                            'show-prefabs-button',
                            'promo-code-button',
                            'dark-blue-btn',
                            'flex align-center',
                            'justify-center',
                            'width-100',
                            'display-hide-hard'
                        )}
                    >
                        Add plan
                    </button>
                </div>
                <div>
                    <div className={classNames('flex')}>
                        {plans.subscription?.map((plan, index) => (
                            <SubscriptionPlan
                                className={index % 2 && 'ml-60px'}
                                key={plan.id}
                                plan={plan}
                                tokens={plans.tokens}
                                index={index}
                                addOneDescription={addOneDescription}
                                removeOneDescription={removeOneDescription}
                                setSelectedToken={(tokenData) => {
                                    setSelectedToken({ ...tokenData, isYearlyModal: plan?.type === 'year' });
                                    showModalOpen(modalNames.EDITING_CREDITS, true);
                                }}
                                saveDescription={saveDescription}
                                editPlan={(plan) => {
                                    if (!plan) return;
                                    showModalOpen(modalNames.ADDING_PLAN, true);
                                    setSelectedSubscriptionPlan(plan);
                                }}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {modalOpen.error && (
                <ConfirmationModal
                    closeDialog={() => showModalOpen(modalNames.ERROR, false)}
                    onConfirm={() => showModalOpen(modalNames.ERROR, false)}
                    buttonText={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    message={error}
                    hideCloseIcon
                    autoFocus
                />
            )}

            {modalOpen.addingPlan && (
                <PlanModal
                    closeDialog={() => {
                        showModalOpen(modalNames.ADDING_PLAN, false);
                        setSelectedSubscriptionPlan(null);
                    }}
                    editingPlan={selectedSubscriptionPlan}
                    addOnePlan={addOnePlan}
                    updateOnePlan={updateOnePlan}
                />
            )}

            {modalOpen.editingCredits && (
                <CreditsModal
                    closeDialog={() => {
                        showModalOpen(modalNames.EDITING_CREDITS, false);
                        setSelectedToken(null);
                    }}
                    selectedToken={selectedToken}
                    updateToken={updateToken}
                />
            )}
        </>
    );
};

export default Plans;
