import React from 'react';
import { getTranslation } from '../../../../../helpers/getLanguage';

const CommentSolve = ({ onCommentSolve, setIsShowThread, issueId, issueIndex }) => {
    return (
        <div className="replacement-modal__comment-solve-container" onClick={() => onCommentSolve(setIsShowThread, issueId, issueIndex)}>
            <span>{getTranslation('COMMENT_SOLVE_BUTTON')}</span>
        </div>
    );
};

export default CommentSolve;
