import React, { useState } from 'react';

import { getTranslation } from '../../../helpers/getLanguage';

import Eye from '../../../assets/images/eye-icon.svg';
import EyeStriked from '../../../assets/images/eye-icon-striked.svg';

import './styles.css';

const CustomPasswordInput = ({
    value,
    name,
    onChange,
    onBlur,
    onKeyDown,
    placeholder = '',
    containerClass = '',
    errorMessage = ''
}) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const activeClass = errorMessage.length > 0 ? ' has-errors' : '';

    return (
        <div className={'width-100 position-relative ' + containerClass}>
            <input
                value={value}
                name={name}
                type={passwordVisible ? 'text' : 'password'}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                className={'custom-password-input' + activeClass}
            />
            <button className="visibility-button pointer" onClick={() => setPasswordVisible(!passwordVisible)}>
                <img src={passwordVisible ? EyeStriked : Eye} alt="" />
            </button>
            {errorMessage.length > 0 && (
                <div className="custom-password-input-errors flex-center">
                    <span>{getTranslation(errorMessage)}</span>
                </div>
            )}
        </div>
    );
};

export default CustomPasswordInput;
