import React from 'react';

const Importance = ({ value, compact }) => {
    if (compact) {
        return (
            <div className="importance-block-compact flex-center">
                <span>{value}</span>
            </div>
        );
    } else {
        const importanceBlocks = new Array(value)
            .fill()
            .map((val, index) => <div key={index} className="importance-block"></div>);
        return <div className="flex">{importanceBlocks}</div>;
    }
};

export default Importance;
