import React from 'react';
import './styles.css';

import { getTranslation } from '../../../helpers/getLanguage';

const getArray = (maxValue) => {
    const result = [];
    for (let i = 0; i < maxValue; i++) {
        result.push(i + 1);
    }

    return result;
};

const defaultColor = '#3e6fd9';

const transparentRectangle = {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
};

const CustomScoreInputComponent = ({ value, setValue, maxValue = 5, colors = [], isRateIdea = false }) => {
    const buttons = getArray(maxValue).map((el) => {
        const isActive = value === el;
        const activeClass = isActive ? ' active' : '';

        const circleColor = !isActive ? '#9eb6ec' : colors[el - 1] || defaultColor;
        const circleStyle = { backgroundColor: circleColor };

        const spanStyle = { color: colors[el - 1] || 'white' };

        return (
            <div className='flex-column align-center justify-center' key={el}>
                <div
                    className={'custom-score-component-selection flex-center pointer' + activeClass}
                    onClick={() => setValue(el)}
                    style={transparentRectangle}
                >
                    <div className={'custom-score-component-input-circle pointer flex-center' + activeClass} style={circleStyle}>
                        <span style={spanStyle}>{el}</span>
                    </div>
                </div>
                <div className='flex-center'>
                    {(el === 1 || el === 5) && isRateIdea && (
                        <span className='custom-component-sub-label'>{`(${
                            el === 1 ? getTranslation('CUSTOM_SUB_LABEL_LOW') : getTranslation('CUSTOM_SUB_LABEL_HIGH')
                        } ${getTranslation('CUSTOM_SUB_LABEL_IMPORTANCE')})`}</span>
                    )}
                </div>
            </div>
        );
    });

    return (
        <div className='custom-score-component-input-container flex align-center flex-column'>
            <div className='flex'> {buttons}</div>
            <div
                className='width-100 flex align-center justify-center'
                style={{ position: 'absolute', height: '100%' }}
            >
                <div className='grey-line' />
            </div>
        </div>
    );
};

export default CustomScoreInputComponent;
