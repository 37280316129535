import React, { useEffect, useState, useCallback } from 'react';
import { connect } from "react-redux";
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment';
import 'moment/locale/zh-cn';

import { getStatistics } from '../../../../actions/adminActions';
import { getStatisticsReport } from '../../../../api/admin';
import { getTranslation } from '../../../../helpers/getLanguage';

import Loader from '../../../common/Loader';
import ConfirmationModal from '../../../dialogs/ConfirmationModal';

import './styles.css';

const defaultStatistics = {
  projectsCount: 0,
  ideasCount: 0,
  usersCount: 0
};

const localeDate = {
  en: 'en',
  cn: 'zh-cn'
};

const Statistics = ({ adminInfo, getStatistics, language }) => {
  const { payload, isLoading } = adminInfo;

  const [statistics, setStatistics] = useState(defaultStatistics);
  const [isPending, setIsPending] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');

  const updateStatistics = useCallback(() => {
    getStatistics();
  }, [getStatistics]);

  useEffect(() => {
    updateStatistics();
  }, [updateStatistics]);

  useEffect(() => {
    setStatistics(payload)
  }, [setStatistics, payload]);

  const getStatisticsDate = () => {
    const todayDate = new Date();
    todayDate.setMonth(todayDate.getMonth() - 1)

    moment.locale(localeDate[language])

    return moment(todayDate).format('MMM YYYY')
  };

  const checkValue = (key) => {
    return statistics.monthStatistics && statistics.monthStatistics[key] ? statistics.monthStatistics[key] : 0
  }


  const exportStatistics = () => {
    const onSuccess = data => {
      const ws = XLSX.utils.json_to_sheet(data);
      const csv = XLSX.utils.sheet_to_csv(ws, { FS: ',' });
      const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
      saveAs(blob, 'omnivati_statistics.csv');
    };

    const onError = error => {
      setIsPending(false);
      console.error(error);
      setConfirmationText(`${getTranslation("STATISTICS_TAB_CONFIRMATION_TEXT_ERROR")} ${error.response.data.message}`);
      setModalOpen(true);
    };

    setIsPending(true);
    getStatisticsReport({}, onSuccess, onError);
  };

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="flex align-baseline ml-600">
          <div className="flex-column align-baseline mr-20">
            <p>{getTranslation("STATISTICS_TAB_TOTAL")}</p>
            {[
              `${getTranslation("STATISTICS_TAB_PROJECTS_COUNT")} ${statistics.projectsCount}`,
              `${getTranslation("STATISTICS_TAB_USERS_COUNT")} ${statistics.usersCount}`,
              `${getTranslation("STATISTICS_TAB_ACTIVE_USERS_COUNT")} ${statistics.activeUsersCount}`,
              `${getTranslation("STATISTICS_TAB_IDEAS_COUNT")} ${statistics.allIdeasCount}`
            ].map((text, index) => (
              <p className="statistics-text" key={`statistic-${index}`}>{text}</p>
            ))}
          </div>
          <div className="flex-column align-baseline">
            <p>{`${getTranslation("STATISTICS_TAB_STATISTICS_FROM")} ${getStatisticsDate()}`}</p>
            {[
              `${getTranslation("STATISTICS_TAB_PROJECTS_COUNT")} ${checkValue('projectsCount')}`,
              `${getTranslation("STATISTICS_TAB_USERS_COUNT")} ${checkValue('usersCount')}`,
              `${getTranslation("STATISTICS_TAB_IDEAS_COUNT")} ${checkValue('ideasCount')}`
            ].map((text, index) => (
              <p className="statistics-text" key={`statistic-${index}`}>{text}</p>
            ))}
          </div>
          <div>
            <button
              className="promo-code-button dark-blue-btn flex align-center justify-space-between"
              onClick={exportStatistics}
              disabled={isPending}
            >
              <span>{getTranslation("STATISTICS_TAB_STATISTICS_FROM")}</span>
            </button>
          </div>
          {modalOpen && (
            <ConfirmationModal
              closeOnClickOutside={false}
              closeDialog={() => setModalOpen(false)}
              message={confirmationText}
              buttonText="OK"
              messageWidth={400}
              onConfirm={() => setModalOpen(false)}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  language: state.auth.userInfo.language,
  adminInfo: state.adminInfo,
  isLoading: state.adminInfo.isLoading,
  errors: state.adminInfo.errors
});

export default connect(mapStateToProps, { getStatistics })(Statistics);
