import React, { useState } from 'react';
import Dialog from '../Dialog';
import CustomTextarea from '../../inputs/CustomTextarea';
import { getTranslation } from '../../../helpers/getLanguage';
import CloseIcon from '../../../assets/images/close-icon.svg';
import './styles.css';

const AddNewPrefabModal = ({ closeAddPrefabModal, saveNewPrefab }) => {
    const [newPrefab, setNewPrefab] = useState('');

    return (
        <Dialog >
            <div className="add-new-prefab-modal flex-column">
                <div className="add-new-prefab-modal-header flex">
                    <h2>{getTranslation('ADMIN_PANEL_PREFABS_MODAL_ADD_PROMPT_HEADER')}</h2>
                    <img src={CloseIcon} alt="close modal" className="pointer" onClick={closeAddPrefabModal} />
                </div>

                <div className="add-new-prefab-modal-body flex-column justify-flex-start height-100">
                    <CustomTextarea 
                        value={newPrefab}
                        onChange={(e) => setNewPrefab(e.target.value)}
                        containerClass="add-new-prefab-modal-body__container height-100"
                        textareaClass="add-new-prefab-modal-body__textarea"
                    />
                </div>

                <div className="add-new-prefab-modal-footer flex justify-flex-end">
                    <div className="flex justify-space-between">
                        <button 
                            className="add-new-prefab-modal-footer-buttons__button add-new-prefab-modal-footer-buttons__button_cancel"
                            onClick={closeAddPrefabModal}
                        >
                            {getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_CANCEL")}
                        </button>
                        <button
                            className="add-new-prefab-modal-footer-buttons__button add-new-prefab-modal-footer-buttons__button_save"
                            onClick={() => saveNewPrefab(newPrefab)}
                        >
                            {getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_SAVE")}
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default AddNewPrefabModal;