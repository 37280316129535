import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.css';

import { loginUser, resetLoginState, setLanguage } from '../../actions/authActions';
import { setRecoveryEmail } from '../../actions/passwordRecoveryActions';
import { REACT_APP_COLUMBINA_SAML_URL } from '../../common/config';

import { validateField } from '../../common/validation';

import CustomLoginInput from '../inputs/CustomLoginInput';
import CustomPasswordInput from '../inputs/CustomPasswordInput';
import LoginLayout from '../common/LoginLayout';
import BottomLinks from './BottomLinks';
import ConfirmationModal from '../dialogs/ConfirmationModal';
import { useAuth0 } from '@auth0/auth0-react';

import { getTranslation } from '../../helpers/getLanguage';

import { languageVersion } from '../../constants/language';

const initialCredentials = {
    email: '',
    password: '',
};

const initialErrors = {
    email: '',
    password: '',
};

const CONFIRMATION_MESSAGE = 'LOGIN_PAGE_CONFIRMATION_MESSAGE';

const Login = ({
    errors: loginError,
    auth,
    history,
    location,
    loginUser,
    setRecoveryEmail,
    resetLoginState,
    setLanguage,
}) => {
    const [credentials, setCredentials] = useState(initialCredentials);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [errors, setErrors] = useState(initialErrors);
    const [modalOpen, setModalOpen] = useState(false);
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (loginError) {
            if (['10052'].includes(loginError.code)) {
                setModalOpen(true);
            } else {
                setErrors((errors) => ({ ...errors, password: loginError.message }));
            }
        }
    }, [loginError]);

    useEffect(() => {
        if (auth.isAuthenticated && !auth.status.premium) {
            const redirect = decodeURIComponent(redirectUrl.split('?redirect=')[1] || '');
            history.push(redirect || '/');
        }

        if (auth.isAuthenticated && auth.status.premium) {
            history.push('/');
        }
    }, [auth, history, redirectUrl]);

    const handleSubmit = () => {
        let hasErrors = false;

        for (let key in credentials) {
            const message = validateField({ name: key, value: credentials[key] }, handleSetErrors);
            message.length && (hasErrors = true);
        }

        !hasErrors && loginUser(credentials);
    };

    useEffect(() => {
        setRedirectUrl(location.search);
    }, [location.search]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleSetErrors = (e, value) => {
        const { name } = e.target || e;
        setErrors((errors) => ({ ...errors, [name]: value }));
    };

    const handleRecoveryClick = () => {
        setRecoveryEmail(credentials.email);
        history.push('/recover-password');
    };

    const handleSignupClick = () => {
        resetLoginState();
        history.push('/signup');
    };

    const handleSubmitEnter = (event) => {
        if (!auth.loading && event.key === 'Enter') {
            handleSubmit();
        }
    };

    const changeLanguage = (language) => {
        setLanguage(language);
    };

    return (
        <LoginLayout>
            <div className='login-form width-100 flex-column align-center'>
                <span className='login-title mb-20'>{getTranslation('LOGIN_PAGE_TITLE')}</span>
                <div className='login-text mb-20'>
                    <span>{getTranslation('LOGIN_PAGE_NO_ACCOUNT')} </span>
                    <span className='underlined pointer' onClick={handleSignupClick}>
                        {getTranslation('LOGIN_PAGE_SIGN_UP')}
                    </span>
                </div>

                <CustomLoginInput
                    containerClass='mb-20'
                    type='text'
                    name='email'
                    placeholder={getTranslation('LOGIN_PAGE_INPUT_LOGIN_USERNAME')}
                    onBlur={(e) => validateField(e, handleSetErrors)}
                    errorMessage={errors.email}
                    value={credentials.email}
                    onChange={handleChange}
                    onKeyDown={handleSubmitEnter}
                />

                <CustomPasswordInput
                    containerClass='mb-25'
                    name='password'
                    placeholder={getTranslation('LOGIN_PAGE_INPUT_PASSWORD')}
                    onBlur={(e) => validateField(e, handleSetErrors)}
                    errorMessage={errors.password}
                    value={credentials.password}
                    onChange={handleChange}
                    onKeyDown={handleSubmitEnter}
                />

                <button className='login-button mb-20' onClick={handleSubmit} disabled={auth.loading}>
                    <span>{getTranslation('LOGIN_PAGE_BUTTON_LOGIN')}</span>
                </button>

                <div className='other-application-login'>
                    <span className='other-application-login__title-text'>
                        {getTranslation('LOG_IN_TEXT_USE_OTHER_SERVICES')}
                    </span>

                    <button
                        className='login-button'
                        onClick={() => (window.location.href = REACT_APP_COLUMBINA_SAML_URL)}
                    >
                        <span>{getTranslation('COLUMBINA_EDU')}</span>
                    </button>
                </div>

                <BottomLinks handleRecoveryClick={handleRecoveryClick} />

                <div className='language-login'>
                    <div className='language-title' onClick={() => changeLanguage('en')}>
                        {languageVersion.ENGLISH}
                    </div>
                    <div className='language-title' onClick={() => changeLanguage('cn')}>
                        {languageVersion.CHINESE}
                    </div>
                    <div className='language-title' onClick={() => changeLanguage('es')}>
                        {languageVersion.SPANISH}
                    </div>
                </div>
            </div>

            {modalOpen && (
                <ConfirmationModal
                    closeDialog={() => setModalOpen(false)}
                    message={CONFIRMATION_MESSAGE}
                    buttonText={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    onConfirm={() => setModalOpen(false)}
                />
            )}
        </LoginLayout>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.auth.errors,
});

const mapDispatchToState = {
    loginUser,
    setRecoveryEmail,
    resetLoginState,
    setLanguage,
};

export default connect(mapStateToProps, mapDispatchToState)(Login);
