import React, { useState } from 'react';

import { activatePromoCodeForUser } from '../../../../api/promoCodes';

import { validateField } from '../../../../common/validation';

import CustomInput from '../../../inputs/CustomInput';
import ConfirmationModal from '../../../dialogs/ConfirmationModal';
import { getTranslation, getTranslatedErrorMessage } from '../../../../helpers/getLanguage';

const initialFields = {
    codeActivationEmail: '',
    codeActivationCode: ''
};

const initialErrors = {
    codeActivationEmail: '',
    codeActivationCode: ''
};

const ActivateCodeForUser = (language) => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(initialErrors);
    const [isPending, setIsPending] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const handleSetErrors = (e, value) => {
        const { name } = e.target || e;
        setErrors(errors => ({ ...errors, [name]: value }));
    };

    const handleCodeActivation = () => {
        const errorMessages = {};
        for (let key in fields) {
            const message = validateField({ name: key, value: fields[key] });
            if (message.length) {
                errorMessages[key] = message;
            }
        }

        setErrors({ ...errors, ...errorMessages });
        if (Object.keys(errorMessages).length) return;

        const params = { email: fields.codeActivationEmail, code: fields.codeActivationCode };
        const onSuccess = response => {
            setIsPending(false);
            const confirmationMessage = response.requiresPayment
                ? getTranslation("ACTIVATE_CODE_FOR_ONE_USER_CONFIRMATION_TEXT_SUCCESSFULLY_ERROR")
                : `${getTranslation("ACTIVATE_CODE_FOR_ONE_USER_CONFIRMATION_TEXT_SUCCESSFULLY")} ${fields.codeActivationEmail}`;
            setConfirmationText(confirmationMessage);
            setModalOpen(true);
        };

        const onError = error => {
            setIsPending(false);
            console.error(error);

            const errorMessage = getTranslatedErrorMessage(error.response.data.message, error.response.data.code, language);
            const confirmationErrorText = `${getTranslation("ACTIVATE_CODE_FOR_ONE_USER_CONFIRMATION_TEXT_ERROR")} ${errorMessage}`;

            setConfirmationText(confirmationErrorText);
            setModalOpen(true);
        };

        setIsPending(true);
        activatePromoCodeForUser(params, onSuccess, onError);
    };

    const handleSubmitEnterCodeActivation = event => {
        if (!isPending && event.key === 'Enter') {
            handleCodeActivation();
        }
    };

    return (
        <>
            <div className="manage-user-container mb-30">
                <div className="flex-column admin-password-container">
                    <span className="manage-user-label mb-20">{getTranslation("ACTIVATE_CODE_FOR_ONE_USER_TITLE")}</span>
                    <CustomInput
                        value={fields.codeActivationEmail}
                        name="codeActivationEmail"
                        onChange={handleChange}
                        onKeyDown={handleSubmitEnterCodeActivation}
                        containerClass="flex-column width-100 mb-10"
                        label={getTranslation("ACTIVATE_CODE_FOR_ONE_USER_USER_EMAIL")}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.codeActivationEmail}
                    />

                    <CustomInput
                        value={fields.codeActivationCode}
                        name="codeActivationCode"
                        onChange={handleChange}
                        onKeyDown={handleSubmitEnterCodeActivation}
                        containerClass="flex-column width-100 mb-20"
                        label={getTranslation("ACTIVATE_CODE_FOR_ONE_USER_PROMO_CODE")}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.codeActivationCode}
                    />
                    <button className="manage-users-button" onClick={handleCodeActivation} disabled={isPending}>
                        <span>{getTranslation("ACTIVATE_CODE_FOR_ONE_USER_BUTTON")}</span>
                    </button>
                </div>
            </div>

            {modalOpen && (
                <ConfirmationModal
                    closeOnClickOutside={false}
                    closeDialog={() => setModalOpen(false)}
                    message={confirmationText}
                    buttonText={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    messageWidth={400}
                    onConfirm={() => setModalOpen(false)}
                />
            )}
        </>
    );
};

export default ActivateCodeForUser;
