import React from 'react';

import ReplacementTabs from './SubtractionTabs';

import { ReactComponent as ReplacementIcon } from '../../../../assets/images/nav-replacement.svg';
import CloseIcon from '../../../../assets/images/close-icon.svg';

import { getTranslation } from '../../../../helpers/getLanguage';

const ReplacementModalHeader = ({
    closeDialog,
    replacement,
    currentTab,
    isCompletedIdea,
    productType,
    getReplacementHeader,
    changeCurrentTab
}) => {

    const getCurrentProductType = () => {
        switch (productType) {
            case ('process'):
                return getTranslation('PRODUCT_TYPE_PROCESS');
            case ('service'):
                return getTranslation('PRODUCT_TYPE_SERVICE');
            default: 
                return getTranslation('PRODUCT_TYPE_PRODUCT');
        };
    };

    const tabsLabels = [
        <span style={{ color: 'inherit' }}>
            {`${getTranslation('REPLACEMENT_TAB_DESCRIBE_NEW_PRODUCT')} ${getCurrentProductType()}`}
        </span>,
        <span style={{ color: 'inherit' }}>
            {getTranslation('SUBTRACTION_MODAL_TABS_LIST_BENEFITS')}
        </span>,
        <span style={{ color: 'inherit' }}>
            {getTranslation('IDEA_MODAL_TABS_LIST_IMPLEMENTATION_CHALLENGES')}
        </span>,
    ];

    return (
        <div className='replacement-dialog-header flex-column'>
            <div className='subtraction-dialog-title-container flex mb-20'>
                <div className='flex align-center'>
                    <ReplacementIcon currentcolor={'#3e6fd9'} width={26} height={26} />
                    <div>
                        <span className='replacement-dialog-title'>{getTranslation('MODAL_TITLE_REPLACEMENT')}</span>
                    </div>
                </div>

                <div className='flex align-center subtraction-dialog-controls'>
                    <img src={CloseIcon} alt='' className='pointer' onClick={closeDialog} />
                </div>
            </div>

            <div className='flex-center'>{getReplacementHeader()}</div>

            <ReplacementTabs
                currentTab={currentTab}
                replacement={replacement}
                labels={tabsLabels}
                isCompletedIdea={isCompletedIdea}
                changeCurrentTab={changeCurrentTab}
            />
        </div>
    );
};

export default ReplacementModalHeader;
