import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

const baseUrl = `${baseApiUrl}/stripe`;

export const getSubscriptionInfo = async (params, onSuccess, onError) => {
    axios
        .get(`${baseUrl}/subscription`, params)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const cancelStripeSubscription = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/cancel-subscription`, params)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const updateAutoRenewal = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/update-renewal`, params)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const updateInterval = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/update-interval`, params)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const updatePaymentMethod = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/update-payment-method`, params)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const getUserSubscriptionStatus = async (params, onSuccess, onError) => {
    axios
        .get(`${baseUrl}/subscriptions-status`, params)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const createPortalSession = async (onSuccess, onError) => {
    axios
        .post(`${baseUrl}/create-portal-session`)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};
