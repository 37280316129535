import React, { useState, useEffect } from 'react';

import CellDropdown from './CellDropdown';

const DEFAULT_CELL_COLOR = '#f7f8fa';
const EMPTY_CELL_COLOR = '#242c5c';
const PLACEHOLDER_CELL_COLOR = '#cdd0d2';

const Cell = ({
    row,
    column,
    color,
    rowIndex,
    columnIndex,
    cellText,
    binaryState,
    binaryMode,
    cellDetails,
    selectedCell,
    setSelectedCell,
    handleOpenDetails,
    getComponentHeight,
    projectId,
    history,
    activeCell,
    setActiveCell,
}) => {
    if (column.name === 'attributes') {
        return (
            <AttributeCell
                row={row}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                selectedCell={selectedCell}
                projectId={projectId}
                history={history}
            />
        );
    }

    if (column.name === 'components') {
        return (
            <ComponentCell
                row={row}
                rowIndex={rowIndex}
                selectedCell={selectedCell}
                getComponentHeight={getComponentHeight}
                projectId={projectId}
                history={history}
            />
        );
    }

    if (!['attributes', 'components'].includes(column.name)) {
        return (
            <ColorCell
                row={row}
                column={column}
                color={color}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                cellText={cellText}
                binaryState={binaryState}
                binaryMode={binaryMode}
                cellDetails={cellDetails}
                selectedCell={selectedCell}
                setSelectedCell={setSelectedCell}
                handleOpenDetails={handleOpenDetails}
                activeCell={activeCell}
                setActiveCell={setActiveCell}
            />
        );
    }
};

const ColorCell = ({
    row,
    column,
    color,
    rowIndex,
    columnIndex,
    cellText,
    binaryState = { reversed: {} },
    binaryMode,
    cellDetails,
    selectedCell,
    setSelectedCell,
    handleOpenDetails,
    activeCell,
    setActiveCell,
}) => {
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (activeCell.row !== '' && activeCell.column !== '') {
            setActiveCell({ ...activeCell, isLocked: true });
        }
    }, []);

    if (color === DEFAULT_CELL_COLOR) {
        return (
            <td className='table-cell-container'>
                <div className='table-cell'></div>
            </td>
        );
    }

    const cellDisplayText = binaryState.value || '';
    const textStyle = {};
    binaryState.color && (textStyle.color = binaryState.color);

    const cellSecondText = binaryState.reversed.value || '';
    const secondTextStyle = {};
    binaryState.reversed.color && (secondTextStyle.color = binaryState.reversed.color);

    const isDifferent = cellDisplayText !== cellSecondText || textStyle.color !== secondTextStyle.color;

    const cellCountText = cellText || '';

    const isSelected = (cell) => {
        return cell.row === rowIndex && cell.column === columnIndex - 3 && cell.isLocked === true;
    };

    const formRows = () => {
        const sortDetails = [...cellDetails];
        const currentDetails = [];

        let index = 0;
        sortDetails.forEach((details) => {
            const newDetails = {
                index: details.status === 0 ? -1 : index++,
                id: details.id,
                rating: details.rating,
                feasibleRating: details.feasibleRating,
                status: details.status,
                name: details.dependencyName,
                isReversed: false,
            };

            currentDetails.push(newDetails);

            if (details.reversed) {
                const reversed = details.reversed;
                const reversedDetails = { ...newDetails };

                reversedDetails.index = reversed.status === 0 ? -1 : index++;
                reversedDetails.rating = reversed.rating;
                reversedDetails.feasibleRating = reversed.feasibleRating;
                reversedDetails.status = reversed.status;
                reversedDetails.name = reversed.dependencyName;
                reversedDetails.isReversed = true;

                currentDetails.push(reversedDetails);
            }
        });
        const green = currentDetails.filter((details) => details.status === 3).sort((a, b) => b.rating - a.rating);
        const yellow = currentDetails.filter((details) => details.status === 1);
        const red = currentDetails.filter((details) => details.status === 2);

        return [...green, ...yellow, ...red];
    };

    const handleClick = () => {
        setActiveCell({ row: rowIndex, column: columnIndex - 3, isLocked: false });

        setHover(false);
        if (isSelected(selectedCell)) {
            setSelectedCell({ row: rowIndex, column: columnIndex - 3, isLocked: false });
        } else {
            cellDetails.length
                ? setSelectedCell({ row: rowIndex, column: columnIndex - 3, isLocked: true })
                : handleOpenDetails(row, column, null);
        }
    };

    return (
        <td className='table-cell-container'>
            <div
                className={
                    'table-cell pointer' + (hover || isSelected(selectedCell) || isSelected(activeCell) ? ' hover' : '')
                }
                style={{ backgroundColor: color }}
                onMouseEnter={() => {
                    if (!selectedCell.isLocked) {
                        setHover(true);
                        setSelectedCell({ row: rowIndex, column: columnIndex - 3, isLocked: false });
                    }
                }}
                onMouseLeave={() => {
                    if (!selectedCell.isLocked) {
                        setHover(false);
                        setSelectedCell({ row: null, column: null, isLocked: false });
                    }
                }}
                onClick={() => handleClick()}
            >
                {binaryMode && cellDisplayText.length > 0 && (
                    <div className='flex-center height-100 cell-mark'>
                        <span style={textStyle}>{cellDisplayText}</span>
                        {cellSecondText.length > 0 && isDifferent && (
                            <span className='cell-binary-text'>
                                (<span style={secondTextStyle}>{cellSecondText}</span>)
                            </span>
                        )}
                    </div>
                )}
                {(hover || isSelected(selectedCell)) && !binaryMode && cellCountText.length > 0 && (
                    <div className='flex-center height-100 cell-mark'>
                        <span style={textStyle}>{cellCountText}</span>
                    </div>
                )}
            </div>
            {isSelected(selectedCell) && (
                <CellDropdown
                    row={row}
                    column={column}
                    rows={formRows()}
                    setSelectedCell={setSelectedCell}
                    handleOpenDetails={handleOpenDetails}
                />
            )}
        </td>
    );
};

const ComponentCell = ({ row, rowIndex, selectedCell, getComponentHeight, history, projectId }) => {
    const isDisplayed = row.rowSpan !== 0;

    if (!isDisplayed) {
        return null;
    } else {
        const cellText = row.components;
        const cellHeight = getComponentHeight();
        const activeClass =
            selectedCell.row !== null && selectedCell.row >= rowIndex && selectedCell.row < rowIndex + cellHeight
                ? 'highlighted'
                : '';

        const redirectToComponentPage = () => {
            history.push(`/project/${projectId}/components?component=${row.componentId}`);
        };

        return (
            <td
                height={`${row.rowSpan * 34}px`}
                rowSpan={cellHeight}
                className={'component-cell-container ' + activeClass}
                onClick={() => redirectToComponentPage()}
            >
                <div className='component-cell flex align-center'>
                    <span className='matrix-component-title'>{cellText}</span>
                </div>
            </td>
        );
    }
};

const AttributeCell = ({ row, rowIndex, selectedCell, projectId, history }) => {
    const redirectToComponentPage = (row, projectId, history) => {
        let rowId = '';
        const rowKeys = Object.keys(row);
        for (const key of rowKeys) {
            if (row[key] === '') {
                rowId = key;
                break;
            }
        }
        return history.push(`/project/${projectId}/components?component=${row.componentId}&attribute=${rowId}`);
    };

    return (
        <td
            style={{ borderLeft: 'none' }}
            className={'attribute-cell-container ' + (selectedCell.row === rowIndex ? 'highlighted' : '')}
            onClick={() => redirectToComponentPage(row, projectId, history)}
        >
            <div className='attribute-cell flex align-center'>
                <span className='matrix-attribute-title'>{row.attributes}</span>
            </div>
        </td>
    );
};

export const EmptyCell = () => {
    return (
        <td>
            <div className='table-cell' style={{ backgroundColor: EMPTY_CELL_COLOR }}></div>
        </td>
    );
};

export const PlaceholderCell = () => {
    return (
        <td>
            <div className='table-cell' style={{ backgroundColor: PLACEHOLDER_CELL_COLOR }}></div>
        </td>
    );
};

export default Cell;
