const redirectHosts = ['app.omnivati.com', 'sit-dev.herokuapp.com', 'ad-development.herokuapp.com'];

export const redirectToHttps = () => {
    const protocol = window.location.protocol.replace(':', '');
    if (protocol === 'http') {
        const host = window.location.host;

        if (redirectHosts.includes(host)) {
            const href = window.location.href.replace('http:', 'https:');
            window.location.href = href;
        }
    }
};
