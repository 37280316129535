import React from 'react';
import './styles.css';

import UsersTableHeader from './UsersTableHeader';
import UsersRows from './UsersRows';

const UsersTable = ({
    rows,
    sort,
    handleSortChange,
    getDate,
    handleSelectUser,
    handleSelectAllRows,
    selectedUsers,
    selectedUsersAll,
    editMode,
    handleChangeTeam,
    handleUserDelete,
    canChangeTeam,
    containerHeight,
    usersTableRef
}) => {
    return (
        <>
            <UsersTableHeader
                sort={sort}
                handleSortChange={handleSortChange}
                handleSelectAllRows={handleSelectAllRows}
                selectedUsersAll={selectedUsersAll}
                editMode={editMode}
            />
            <UsersRows
                rows={rows}
                getDate={getDate}
                handleSelectUser={handleSelectUser}
                selectedUsers={selectedUsers}
                editMode={editMode}
                handleChangeTeam={handleChangeTeam}
                handleUserDelete={handleUserDelete}
                canChangeTeam={canChangeTeam}
                containerHeight={containerHeight}
                usersTableRef={usersTableRef}
            />
        </>
    );
};

export default UsersTable;
