import React, { useEffect, useRef } from 'react';
import Draggable from 'react-draggable';

const Dialog = ({
    children,
    closeDialog,
    draggable = false,
    boundToParent = false,
    closeOnClickOutside = true,
    closeOnEscape = true,
    isLoading,
}) => {
    const ref = useRef(null);

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.addEventListener('mousedown', handleClickOutside, true);
        document.addEventListener('keydown', handleKeyDown, true);

        return () => {
            document.body.style.overflow = 'overlay';
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    });

    const handleClickOutside = (e) => {
        if (closeOnClickOutside && ref.current && !ref.current.contains(e.target)) {
            closeDialog && closeDialog();
        }
    };

    const handleKeyDown = (e) => {
        if (closeOnEscape && e.key === 'Escape') {
            closeDialog && closeDialog();
        }
    };

    return (
        <>
            {draggable ? (
                <div className='dialog-overlay draggable flex-column flex-center'>
                    <Draggable handle='.draggable-handle' bounds={boundToParent ? 'parent' : ''}>
                        <div className='dialog-content draggable' ref={ref}>
                            {children}
                        </div>
                    </Draggable>
                </div>
            ) : (
                <div className='dialog-overlay flex-column'>
                    <div style={isLoading ? { background: 'none' } : {}} className='dialog-content' ref={ref}>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
};

export default Dialog;
