import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getFullProjectInfo } from '../../actions/projectActions';
import { setPreference } from '../../actions/preferencesActions';
import EurekaEditModal from '../dialogs/EurekaEditModal';

import MessageShowNotFound from '../common/MessageShowNotFound';
import ProjectTopBar from '../common/ProjectTopBar';
import Loader from '../common/Loader';

import EurekaHeader from './EurekaHeader';
import IdeasCard from './IdeasCard';
import './styles.css';
import { deleteIdea } from '../../api/eureka';

const initialState = {
    search: '',
};

const modalsInitialState = {
    delete: false,
};

const sortLabel = 'EUREKA_PAGE_SORT_IDEAS_LABEL';

const EurekaMatrix = ({
    match,
    errors,
    product,
    eurekaIdeas,
    auth,
    history,
    isLoading,
    isSorted,
    setPreference,
    getProjectInfo,
}) => {
    const projectId = match.params.projectId;
    const showNotFoundError = errors && errors.response && errors.response.status === 404;
    const {
        user: { name, lastName, id: userId },
    } = auth;
    const [filteredIdeas, setFilteredIdeas] = useState([]);
    const [inputValues, setInputValues] = useState(initialState);
    const [modalOpen, setModalOpen] = useState(modalsInitialState);
    const [editMode, setEditMode] = useState(false);
    const [activeIdea, setActiveIdea] = useState('');

    useEffect(() => {
        getProjectInfo(projectId);
    }, [getProjectInfo, projectId]);

    useEffect(() => {
        const filterIdeas = () => {
            const searchString = inputValues.search.toLowerCase();

            const filteredComponents = eurekaIdeas?.filter((component) =>
                component.name.toLowerCase().includes(searchString)
            );

            setFilteredIdeas([...filteredComponents]);
        };

        if (eurekaIdeas?.length) {
            if (!inputValues.search) {
                setFilteredIdeas([...eurekaIdeas]);
            } else {
                filterIdeas();
            }
        } else {
            setFilteredIdeas([]);
        }
    }, [inputValues.search, eurekaIdeas]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    };

    const handleModalOpen = (type, activeIdeaId, edit) => {
        setActiveIdea(eurekaIdeas.find((idea) => idea.id === activeIdeaId));

        edit && setEditMode(true);

        setModalOpen({ ...modalOpen, [type]: true });
    };

    const handleCloseModal = () => {
        getProjectInfo(projectId);
        setEditMode(false);
        setModalOpen({
            component: false,
            delete: false,
            edit: false,
            details: false,
        });
    };

    const deleteEureka = (ideaId) => {
        if (!ideaId) return;

        const params = {
            id: ideaId,
            productId: projectId,
            teamId: product.teamId || '',
        };

        const onSuccess = () => {
            getProjectInfo(projectId);
        };

        const onError = (error) => {
            console.error(error);
        };

        deleteIdea(params, onSuccess, onError);
    };

    return (
        <div>
            <ProjectTopBar match={match} history={history} currentProjectName={product.name} />

            <div className='components-dashboard-wrapper'>
                {isLoading && <Loader />}

                {!isLoading && !showNotFoundError && (
                    <>
                        <EurekaHeader
                            isSorted={isSorted}
                            sortLabel={sortLabel}
                            inputValues={inputValues}
                            handleChange={handleChange}
                            setSorted={(e) => setPreference({ componentsSortedAlphabetical: e })}
                        />
                        <IdeasCard
                            projectType={product.type}
                            isSorted={isSorted}
                            deleteEureka={deleteEureka}
                            filteredIdeas={filteredIdeas}
                            inputValues={inputValues}
                            handleModalOpen={handleModalOpen}
                        />
                    </>
                )}

                {modalOpen['eureka'] && (
                    <EurekaEditModal
                        closeDialog={() => handleCloseModal('eureka')}
                        userFullName={{ name, lastName }}
                        userId={userId}
                        edit={editMode}
                        projectName={product?.name || ''}
                        projectId={projectId}
                        eurekaIdea={activeIdea || {}}
                        teamId={product.teamId}
                        categories={product.customCategories}
                        productType={product.type}
                        productName={product.typeName}
                    />
                )}

                {showNotFoundError && <MessageShowNotFound history={history} />}
            </div>
        </div>
    );
};

EurekaMatrix.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    prop: state,
    auth: state.auth,
    errors: state.project.errors,
    product: state.project.product,
    eurekaIdeas: state.project.eurekaIdeas,
    isLoading: state.project.isLoading,
    isSorted: state.preferences.componentsSortedAlphabetical,
});

export default connect(mapStateToProps, { getProjectInfo: getFullProjectInfo, setPreference })(EurekaMatrix);
