import React from 'react';
import './styles.css';

import logo from '../Omnivati-Logo-Tagline.jpg';

const TermsOfService = () => {
    return (
        <div className="terms-of-service-container">
            <div className="logo">
                <img src={logo} alt="" />
            </div>
            <p className="center underlined">
                <b>Omnivati Software Platform - Terms of Services</b>
            </p>
            <p className="center">
                <b>[Updated on November 17 2020]</b>
            </p>
            <p>
                Welcome to Omnivati’s Software Platform. We provide a digital toolkit suitable for educators, students
                and businesses, which enables the rapid and systematic generation and management of creative ideas ("
                <b>Platform</b>"). The Platform enables users to intuitively apply multiple structured creativity
                methods and rapidly generate new valuable strategies, products, services, processes and tools ("
                <b>Services</b>").
            </p>
            <p>
                These Terms of Services (the “<b>Terms</b>”) together with Omnivati’s Privacy Policy available at{' '}
                <a
                    className="underlined"
                    href="https://sit-dev.herokuapp.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    https://sit-dev.herokuapp.com/signup
                </a>{' '}
                (collectively referred to as the "<b>Agreements</b>") as updated from time to time, constitute the
                entire agreement between <b>Omnivati Ltd.</b> ("<b>Omnivati</b>" "<b>We</b>" "<b>Our</b>") and its users
                (" <b>you</b>" "<b>your</b>" or "<b>users</b>") for the Services (as defined below) provided by the
                Platform.
            </p>
            <p>
                Upon the earlier of, you checking "I agree", where applicable or upon you using the Services via the App
                or System, you shall be deemed to have confirmed and agreed that you have read the Agreements,
                understood them, and that such confirmation and agreement constitutes as a valid and legally binding
                contract between yourself and Omnivati. If you do not agree to any of the terms of the Agreements,
                Omnivati may be unwilling to grant you access to the Services. If you are unwilling to accept all of the
                terms of the Agreements, you may not use the Services. If the Services are considered to be illegal
                under the laws of the country in which you are situated, or you are otherwise barred from receiving the
                services under such laws, please avoid or discontinue your use of the Services
            </p>
            <p>
                Omnivati reserves the right, at its sole discretion, to revise or modify the Agreements at any time, and
                you agree to be bound by such revisions or modifications. These changes will apply at that instant to
                all current and subsequent uses of the Services. You agree that you shall have the responsibility to
                review the Agreements periodically and hereby waive any right you may have to receive specific notice of
                such changes or modifications. Without derogating from the above, Omnivati may notify you of a change or
                amendment to the Agreements by sending you an e-mail, all in accordance to Omnivati’s sole discretion.
                Your continued use of the Services shall be deemed as an act of acceptance of such changes or
                modifications. If you do not agree to the Agreement as updated from time to time, your sole remedy shall
                be to discontinue the use of the Services.
            </p>
            <p className="underlined">
                <b>1. The Services</b>
            </p>
            <ul>
                <p>
                    1.1 Please read the Agreements carefully as such Agreement represents the entire understanding
                    between Omnivati and its users regarding the Services, as may be updated from time to time.
                </p>
                <p>
                    1.2. Subject to your compliance with the Agreements, as part of the Services and to the extent
                    needed in order to receive the Services, Omnivati grants you a personal, revocable, non-exclusive
                    and non-transferable limited license to use our Services on your computer, mobile devices, or other
                    electronic devices ("<b>Device</b>") during the term for which you have subscribed and for as long
                    you are paying the fees as set below.
                </p>
                <p>
                    1.3. Omnivati may make modifications, additions and upgrades to the Services and any software
                    provided through the Platform. The terms of the Agreements will apply to any updates that Omnivati
                    may make available to you. For the removal of doubt, it is clarified that Omnivati is under no
                    obligation to develop or release any updates or upgrades.
                </p>
                <p>
                    1.4. As a user, you shall be identified inter-alia through the information you provide us as further
                    elaborated in Omnivati’s Privacy Policy. You shall be responsible for compliance with the provisions
                    of the Agreements to the extent that such provisions apply to you and for all the activities that
                    occur when you use our Services. Without derogating from the provisions of section ‎3 below such
                    responsibility shall include any use of the Services by any individual or entity to which you have
                    granted access to our Services, or any individual or entity using the Services on your behalf.
                </p>
            </ul>
            <p className="underlined">
                <b>2. Support and Maintenance</b>
            </p>
            <ul>
                <p>
                    2.1. Omnivati makes no representation or warranty that the Services will be accessible at all times.
                    You acknowledge and agree that the Platform Services may be from to time: (i) automatically updated,
                    upgraded or modified; and/or (ii) inaccessible or inoperable at any time and for any reason,
                    including without limitation due to connectivity issues, unscheduled maintenance or repairs, or
                    causes that are beyond Omnivati’s reasonable control or not reasonably foreseeable by Omnivati,
                    including without limitation, interruption or failure of telecommunication or digital transmission
                    links, hostile network attacks, network congestion or other failures.
                </p>
                <p>
                    2.2. If the Services become inaccessible to users or not fully functional, other than due to
                    scheduled maintenance, Omnivati shall use commercially reasonable efforts to respond and endeavor to
                    remedy such unavailability or failure of functionality as soon as reasonably possible.
                </p>
                <p>
                    2.3. You hereby confirm that you are: (i) aware that the Services operation depend and rely on third
                    party products, services and infrastructure; and (ii) that you shall be responsible to ensure that
                    your Device(s), operating systems, computer networks and network connections, telecommunications
                    facilities meets all the necessary technical specifications to enable it to access and use the
                    Services. Any failure or slowdown of the foregoing may directly or indirectly affect the manner and
                    reliability in which the Services are provided and/or the function.
                </p>
            </ul>
            <p className="underlined">
                <b>3. Rules of Services Usage</b>
            </p>
            <ul>
                <p>
                    3.1. You undertake that your use of the Services shall be lawful and not to defraud, or attempt to
                    defraud, Omnivati or other users and that you shall not act in bad faith in while using the
                    Services. You hereby agree that in case that Omnivati determines that you have acted in bad faith
                    and/or in violation of the Agreements, or if Omnivati determines your actions fall outside of
                    reasonable standards, Omnivati may, at its sole discretion, prohibit you from using the Services. In
                    furtherance of the foregoing, and as an example and not as a limitation, you hereby agree that by
                    using the Services you shall{' '}
                    <b>
                        <u>not</u>
                    </b>{' '}
                    act as follows:
                </p>
                <ul>
                    <p>
                        3.1.1. Promote, host, display or implement the Services or any information received thereof, on
                        websites that: (i) contain indecent, obscene or pornographic material, hate speech, defamatory,
                        libelous, obscene, gambling-related, discriminatory, deceptive, abusive spyware, adware, or
                        other malicious programs or code, unsolicited mass distribution of email, or any illegal subject
                        matter or activities or otherwise offensive content; (ii) incorporate any materials that
                        infringe or assists others to infringe on any copyright, trademark or other intellectual
                        property rights; (iii) are an incentive-based website, such as a lottery or sweepstakes website
                        which rewards users for clicking on links; (iv) engage in activities that violate Omnivati 's
                        Privacy Policy; or (v) infringe any right of any third party or violate any applicable law or
                        regulation;
                    </p>
                    <p>
                        3.1.2. Libel, ridicule, defame, mock, stalk, intimidate, threaten, harass, or abuse anyone,
                        hatefully, racially, ethnically or in any other manner;
                    </p>
                    <p>
                        3.1.3. Copy, sell, transfer, assign, sublicense, disassemble, decompile, private label, reverse
                        engineer or (except if expressly authorized by Omnivati or permitted under applicable law),
                        modify or alter any part of Omnivati’s software or grant any other third party the right to do
                        any of the above;
                    </p>
                    <p>
                        3.1.4. Upload or transmit (or attempt to upload or transmit) files that contain viruses, trojan
                        horses, worms, time bombs, cancelbots, corrupted files or data, or any other similar software or
                        programs that may damage the operation of the Services or the Devices of other users of the
                        Services;
                    </p>
                    <p>
                        3.1.5. Advertise, solicit or transmit any commercial advertisements, including chain letters,
                        junk e-mail or repetitive messages (spam) to anyone;
                    </p>
                    <p>
                        3.1.6. Violate the contractual, personal, intellectual property or other rights of any party
                        including by using, uploading, transmitting, distributing, or otherwise making available any
                        information or material made available through the Services in any manner that infringes any
                        copyright, trademark, design, patent, trade secret, or other rights of any party (including
                        rights of privacy or publicity);
                    </p>
                    <p>
                        3.1.7. Create false personas, multiple identities, use bots or other automated software programs
                        to defraud or which otherwise violate this Agreement and/or the terms of service of any
                        third-party applications;
                    </p>
                    <p>
                        3.1.8. Attempt to obtain private information from other users including personally identifiable
                        information (whether in text, image or video form), identification documents, or financial
                        information;
                    </p>
                    <p>
                        3.1.9. Upload or transmit (or attempt to upload or to transmit), without Omnivati's express
                        consent, any material that acts as a passive or active information collection or transmission
                        mechanism, including, without limitation, clear graphics interchange formats ("gifs"), 1x1
                        pixels, web bugs, cookies or other similar devices (sometimes referred to as "spyware", "passive
                        collection mechanisms" or "pcms");
                    </p>
                    <p>3.1.10. Improperly use the support services or make false reports to Omnivati;</p>
                    <p>
                        3.1.11. Encourage any third party to: (i) edit or modify any tag, or remove, obscure or minimize
                        any tag in any way; or (ii) engage in any action or practice that reflects poorly on Omnivati or
                        otherwise disparages or devalues Omnivati's reputation or goodwill;
                    </p>
                    <p>
                        3.1.12. Engage in any fraudulent activity with respect to payment methods or advertiser tracking
                        mechanisms;
                    </p>
                    <p>
                        3.1.13. violate any applicable laws or regulations, or encourage or promote any illegal activity
                        including, but not limited to, copyright infringement, trademark infringement, defamation,
                        invasion of privacy, identity theft, hacking, cracking or distribution of counterfeit software,
                        or cheats or hacks to the Services;
                    </p>
                    <p>
                        3.1.14. Attempt to interfere with, hack into or decipher any transmissions to or from the
                        servers of the Services;
                    </p>
                    <p>
                        3.1.15. Interfere with the ability of others to enjoy using the Services, including disruption,
                        overburden or aid the disruption or overburdening of the Services’ servers, or take actions that
                        interfere with or materially increase the cost to provide the Services for the enjoyment of all
                        its users;
                    </p>
                    <p>
                        3.1.16. Attempt to discover any source code or underlying ideas or algorithms of the Services;
                        and/or
                    </p>
                    <p>
                        3.1.17. Provide, lease, rent, lend, license, assign, delegate, or otherwise transfer or use or
                        allow others to transfer or use the Services or any output generated by the Services for the
                        benefit of any third party.
                    </p>
                </ul>
            </ul>
            <p className="underlined">
                <b>4. SUBMISSIONS.</b>
            </p>
            <ul>
                <p>
                    4.1. For the purpose of this Agreement "Submission(s)" shall mean: any feedbacks, identifying
                    potential errors, enhancements, improvements, unsolicited ideas, solicited ideas, works, materials,
                    proposals, suggestions, artwork, content or the like, including for advertising campaigns,
                    promotions, products, services, technologies, product enhancements, processes, marketing strategies,
                    product names, content or creative materials which relates directly or indirectly to the Services
                    and the Platform. You agree that: (1) your Submissions and their contents along with related
                    intellectual property rights will automatically become the property of Omnivati, without any
                    compensation to you; (2) Omnivati may use or redistribute the Submissions and their contents for any
                    purpose and in any way on an unrestricted basis; (3) there is no obligation for Omnivati to review
                    the Submissions; and (4) there is no obligation to keep any Submissions confidential.
                </p>
            </ul>
            <p className="underlined">
                <b>5. User Content</b>
            </p>
            <ul>
                <p>
                    5.1. You agree that any content uploaded by you through the Services shall be uploaded via the use
                    of technology and tools provided by Omnivati. You agree that you are uploading such content
                    willingly and you represent that you own such content or have received the necessary authorizations
                    from third parties to the extent that such authorizations are necessary, and that such
                    authorizations are documented and shall be provided to Omnivati upon request, and according to which
                    you have all rights to upload said content and that uploading of the content by you complies with
                    all applicable laws.
                </p>
                <p>
                    5.2. We do not claim ownership of any videos, data, text, graphics, photographs, or any other
                    content, and their selection and arrangement, provided or uploaded to the Services by any user (the
                    "<b>User Content</b>").
                </p>
                <p>
                    5.3. We may or may not regulate User Content and provide no representations or guarantees regarding
                    the accuracy, quality, or integrity of any User Content posted via the Service. By using the
                    Services you acknowledge and accept that you may be exposed to material you find offensive or
                    objectionable. You agree that we will not, under any circumstances, be responsible or liable for any
                    User Content, including, but not limited to, errors in any User Content or any loss or damage
                    incurred by use of the User Content or for any failure to or delay in removing User Content.
                </p>
                <p>
                    5.4. We reserve the right (but shall at no time be obligated) to, in our sole discretion, remove,
                    block, edit, move, disable or permanently delete User Content from the Platform with or without
                    notice for violating the terms of this Agreement. You hereby agree that, to the maximum extent
                    permitted by applicable law, we shall at no time be responsible or held liable for the removal,
                    modification or blocking of material or User Content and shall at no time be obligated to effect
                    such removal other than under applicable law.
                </p>
            </ul>
            <p className="underlined">
                <b>6. Intellectual Property Rights</b>
            </p>
            <ul>
                <p>
                    <u>Ownership of Intellectual Property.</u> You hereby acknowledge Omnivati’s exclusive right, title
                    and interest in and to the Services, the Platform and to any and all Intellectual Property Rights
                    (as defined below) evidenced thereby, embodied therein, and/or attached, connected or related
                    thereto and any derivatives thereof including but not limited to all materials, any computer
                    software (in object code and source code form), data or information employed or received by Omnivati
                    pursuant to the Agreements, include the modifications, improvements, updates, upgrades, enhancement,
                    added features, inventions, ideas, insights, concepts, methods, designs, know-how, and processes
                    relating to the same, whether created, developed, discovered or conceived in connection with the
                    Agreements or otherwise including any other Intellectual Property Rights therein, and regardless of
                    whether you or anyone on its behalf may have contributed to the conception of any of the foregoing,
                    or paid Omnivati for such, are and shall at all times vest exclusively with Omnivati, and may not be
                    used by yourself or anyone on your behalf.
                </p>
                <p>
                    For purposes of this Agreement, "<b>Intellectual Property Rights</b>" means all intangible legal
                    rights, titles and interests evidenced by or embodied in or attached/connected/related to the
                    Services and the Platform, including without limitation: all inventions, patents, patent
                    applications, trademarks, service marks, trade dress, logos, designs, trade names, and corporate
                    names, domain names, any work of authorship, copyrights, trade secrets, Confidential Information,
                    and all other proprietary rights in whatever form or medium, in each case on a worldwide basis;
                    together with all revisions, extensions, reexaminations, translations, adaptations, derivations, and
                    combinations thereof and including all goodwill associated therewith.
                </p>
            </ul>

            <p className="underlined">
                <b>7. Fees; Payment</b>
            </p>
            <ul>
                <p>
                    7.1. The Services or any part thereof may be subject to payment of applicable fees, as shall be
                    detailed by Omnivati from time to time. The access to the Services and use of its features will be
                    contingent upon timely payment of applicable fees. Omnivati reserves the right to terminate your use
                    of the Services in case of any failure on your part to timely pay any fees due by you to us.
                    Omnivati may, at its sole discretion, add, remove or change the features and Services it offers or
                    the fees (including the amount or the type of fees) Omnivati charges at any time. You agree that you
                    are solely responsible for checking via the Services to confirm whether there are any new fees and
                    their effective date(s). Omnivati is not responsible for any charges or expenses you incur as a
                    result from being billed by Omnivati in accordance with these Terms.
                </p>
                <p>
                    7.2. When applicable, payment will be safely processed through a third party payment processor (a "
                    <b>Payment Processor</b>") and then shall be paid to us. In the event of credit card fraud, most
                    banks and credit card companies bear the risk and cover all the charges resulting from such fraud or
                    misuse, which may sometimes be subject to a deductible. In addition, you agree: (i) that the
                    services of Payment Processors may be used to process transactions; (ii) to review and be bound by
                    the Payment Processor's terms of use and privacy policy; (iii) to only provide valid and current
                    information for yourself; and (iv) that all payments made are final and non-refundable. You
                    acknowledge that you may need to hold, or register, an active account with the Payment Processor. We
                    are not responsible or liable for any activities or conduct of a Payment Processor, and you agree
                    that you shall have no claim, suit or demand of any kind, and by agreeing to these Terms, hereby
                    irrevocably and completely waive any such claim, suit or demand of any kind, to the extent such may
                    exist or hereafter arise, towards us and any of our managers, directors, officers, shareholders,
                    partners, affiliates, employees, consultants, service providers and/or any other person or entity
                    acting on our behalf, in connection with or related to the conduct or any act or omission of a
                    Payment Processor
                </p>
            </ul>
            <p className="underlined">
                <b>8. Data Management</b>
            </p>
            <ul>
                <p>
                    8.1. You are aware and agree: (i) to the terms of the Privacy Policy, which set forth and describe
                    how Omnivati collects and processes your information when you subscribe and use the Services; and
                    (ii)(a) that Omnivati has no obligation to review any information that Omnivati collects for its
                    accuracy or completeness, or for their potential violation of any third party rights and/or
                    violation of any privacy rights, and/or to check or monitor any such contents for any of the
                    foregoing before or during any provision of Services thereof, and (b) that you bear the entire
                    responsibility and liability in connection with such information and/or their transference, use and
                    analysis (including without limitation, to obtain any applicable third-party consent to the extent
                    required under applicable laws).
                </p>
                <p>
                    8.2. Without derogating from any of Omnivati’s rights and remedies under the Agreements and/or under
                    applicable law, Omnivati will be entitled, at its sole discretion, to immediately discontinue the
                    Services or any part thereof in the event of any alleged infringement, misappropriation or violation
                    of any rights of any third parties in connection with such information.
                </p>
                <p>
                    8.3. <u>Security Measures.</u> Omnivati implements commercially reasonable physical, technical,
                    administrative and organizational safeguards to protect data against unauthorized processing and
                    against accidental loss or destruction in accordance with accepted industry security practices.
                    Omnivati shall ensure that persons authorized to process the personal data have committed themselves
                    to confidentiality or are under an appropriate statutory obligation of confidentiality.
                </p>
            </ul>
            <p className="underlined">
                <b>9. Term and Termination</b>
            </p>
            <ul>
                <p>
                    The term of the Agreements shall commence as of the date on which you provide us with your consent
                    to be bound by the Agreements and will continue to apply to you until terminated by either you or
                    Omnivati. You acknowledge and agree that the perpetual license granted by you in relation to the
                    Submissions, is irrevocable and will therefore continue after expiry or termination of any of the
                    Agreements, for any reason. Omnivati or you may terminate the Agreement, at any time, for any or no
                    reason. If Omnivati or you terminate the Agreement, you agree that we shall have no liability or
                    responsibility to you, to the fullest extent permitted under applicable law. Please note that
                    termination of the Agreement shall not affect any provisions of the Agreement which are expressly or
                    by implication intended to come into force or continue in force on or after such termination.
                </p>
            </ul>
            <p className="underlined">
                <b>10. Services - Disclaimer of Warranty; Warranty; and Limitations of Liability</b>
            </p>
            <ul>
                <p>
                    10.1. Omnivati does not warrant nor make any representation regarding the results, performance
                    correctness, accuracy, completeness, reliability or otherwise of the Services and/or any information
                    provided by Omnivati. You are aware that the use of the Services depends and relies on third-party
                    service providers and third-party products, services and infrastructure engaged by Omnivati or
                    yourself. A number of factors may impact the quality of your communications and use of the Services
                    and may result in the failure of the Services communications, including, without limitation, the
                    productivity, completeness, reliability of the: local network, firewall, internet service provider,
                    the public internet and power supply. Any failure or slowdown or unavailability of the foregoing may
                    directly or indirectly affect the manner in which the Services function. You are aware that any
                    failure or slowdown or unavailability of third-party products, services, and infrastructure are
                    beyond Omnivati’s control and Omnivati takes no responsibility for any disruption, interruption or
                    delay caused by any failure of, or inadequacy in any of these items or any other items over which we
                    have no control, including, but not limited to, failures or delays resulting from labor disputes,
                    strikes, lockouts, war, terrorism, riot, or acts of God.
                </p>
                <p>
                    10.2. Except for the limited warranties set forth herein, the Services and/or components provided
                    therein are provided "AS IS" and "AS AVAILABLE" basis. You shall be solely responsible for any and
                    all acts or omissions taken or made in reliance on the Services. To the fullest extent permitted by
                    law, Omnivati its officers, directors, employees, and agents disclaim any and all other warranties,
                    whether express or implied, including, without limitation, any implied warranties of
                    merchantability, title, fitness for a particular purpose or use, satisfactory quality,
                    non-infringement, accuracy or completeness of the content of the Services or contents of any sites
                    linked to the Services, that any data stored with Omnivati will be secure or otherwise not lost or
                    damaged, or that the Services will be uninterrupted or error free. No oral or written information or
                    advice given by Omnivati, its employees, distributors, dealers or agents shall increase the scope of
                    the above warranties or create any new warranties.
                </p>
                <p>
                    10.3. Omnivati makes no warranties or representations about the accuracy or completeness of the
                    Services and assumes no liability or responsibility for any:
                </p>
                <ul>
                    <p>10.3.1. Errors, mistakes, inaccuracies, non-suitability or non-conformity of any content.</p>
                    <p>
                        10.3.2. Direct, indirect, special, incidental, punitive or consequential damages including
                        without derogating personal injury, property damages and/or monetary damages, of any nature
                        whatsoever, arising out of the use of or the inability to use the Services;
                    </p>
                    <p>
                        10.3.3. Any unauthorized access to or use of Omnivati’s servers and/or any and all personal
                        information and/or financial information stored therein.
                    </p>
                    <p>10.3.4. any interruption or cessation of transmission to or from the Platform;</p>
                    <p>
                        10.3.5. any bugs, viruses, Trojan horses, or the like which may be transmitted to or through the
                        Services by any third party;
                    </p>
                    <p>
                        10.3.6. the quality of any information, or other material obtained by you through the Services;
                    </p>
                    <p>
                        10.3.7. Any errors or omissions in any content or for any loss or damage of any kind incurred as
                        a result of the use of any content posted, e-mailed, transmitted, or otherwise made available
                        via the Services.
                    </p>
                    <p>
                        10.3.8. any content which is published, displayed and/or suggested through the Services, its
                        integrity, accuracy and/or reliability; or
                    </p>
                    <p>
                        10.3.9. Damages and losses due to the operation, failure or slowdown or unavailability of
                        third-party products, services and infrastructure.
                    </p>
                </ul>
                <p>
                    10.4. Without detracting from the above, Omnivati or anyone on its behalf shall not be liable toward
                    yourself or any third parties for indirect, direct, special, incidental, consequential or punitive
                    damages, including, without limitation, any damage or injury to business earnings, lost profits or
                    goodwill, business interruption, loss of business information, and/or personal injury, suffered by
                    any person arising from and/or related with and/or connected to the use of or the inability to use
                    the Services and any materials and/or components provided therein, regardless of the cause and
                    whether arising in contract (including fundamental breach), tort (including negligence) or
                    otherwise,
                </p>
                <p>
                    10.5. Notwithstanding anything to the contrary herein, under no circumstances will Omnivati or
                    anyone on its behalf’s aggregate liability towards yourself from all causes of action of any kind,
                    including without limitation contract, tort, negligence, strict liability, breach of warranty, or
                    otherwise, arising out of or related to this Agreement, exceed the fees actually paid to Omnivati
                    under the Agreements.
                </p>
            </ul>
            <p className="underlined">
                <b>11. Indemnification</b>
            </p>
            <ul>
                <p>
                    11.1. To the fullest extent permitted by applicable law, you shall defend, indemnify and hold
                    Omnivati, its affiliates, and their respective officers, employees and agents, harmless from any and
                    all claims, suits, demands, or proceedings brought against Omnivati by a third party insofar and to
                    the extent that such claims are due to or arise out of:
                </p>
                <ul>
                    <p>11.1.1. your misuse or modification of the Services;</p>
                    <p>
                        11.1.2. any representation or warranty made by yourself concerning the quality, performance or
                        other characteristics of the Services, inconsistent with the Agreements;
                    </p>
                    <p>
                        11.1.3. infringement made by yourself, anyone on your behalf or under its permission, of
                        intellectual property of any third party;
                    </p>
                    <p>
                        11.1.4. a breach of your undertakings, representations, and warranties contained herein and/or
                        breach of the terms of the Agreements or any applicable laws;
                    </p>
                </ul>
                <p>
                    11.2. Omnivati reserves the right to assume the exclusive defense and control of any matter for
                    which you are required to indemnify Omnivati, and you agree to cooperate with Omnivati in defense of
                    such claims. You further agree not to settle any such claim without Omnivati’s prior written
                    consent.
                </p>
            </ul>
            <p className="underlined">
                <b>12. Notices</b>
            </p>
            <ul>
                <p>
                    Notices to you may be made via the App and/or email or published on the Agreements. Omnivati may
                    also provide notices of changes to the Agreements or other matters by displaying notices or links to
                    notices to you generally on the Services. You agree that all agreements, notices, disclosures and
                    any other communications that Omnivati provide as aforementioned satisfy any applicable legal
                    requirements that such communications be in writing.
                </p>
            </ul>
            <p className="underlined">
                <b>13. General</b>
            </p>
            <ul>
                <p>
                    By using or accessing the Services, you agree that the laws of the State of Israel, without regard
                    to principles of conflict of laws and regardless of your location, will govern these Terms and any
                    dispute of any sort that might arise between you and Omnivati. Any claim or dispute between you and
                    Omnivati that arises in whole or in part from your use of the Services shall be decided exclusively
                    by a court of competent jurisdiction located in Tel-Aviv, Israel, to the exclusion of any other
                    courts, and you hereby consent to, and waive all defenses of lack of personal jurisdiction and forum
                    non-convenient with respect to venue and jurisdiction in the courts of Tel-Aviv, Israel. Omnivati
                    reserves the right to amend these Terms at any time and without notice, and it is your
                    responsibility to review these Terms regularly for any changes. Nothing contained herein shall be
                    deemed to create a joint venture, partnership, or agency relationship between Omnivati (and anyone
                    on its behalf) and yourself. Please note that a failure of Omnivati to enforce any right granted
                    herein or to take action against you in the event of any breach of these Terms shall not deemed a
                    waiver on our behalf as to subsequent enforcement of rights or subsequent actions in the event of
                    future breaches. YOU AND OMNIVATI AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE
                    SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE
                    OF ACTION IS PERMANENTLY BARRED.
                </p>
            </ul>
        </div>
    );
};

export default TermsOfService;
