import React from 'react';
import classNames from 'classnames';

import CustomTextarea from '../../../../inputs/CustomTextarea';
import Initials from '../../../../common/Initials';

import { ReactComponent as PlusIcon } from '../../../../../assets/images/plus-icon-small.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/close-icon.svg';
import { getTranslation } from '../../../../../helpers/getLanguage';
import { getColorByInitials } from '../../../../../helpers/getInitials';
import { listOfInitialColors } from '../../../../../constants/listOfInitialColors';
import Tooltip from '../../../../common/Tooltip';

const Message = ({
    message,
    index,
    handleMessageChange,
    addMessage,
    messages,
    issueId,
    issueIndex,
    userId,
    userFullName,
    removeMessage,
    removeLastMessage
}) => {
    const { message: issueMessage, userInfo } = message;
    const createdBy = userInfo
        ? `${userInfo.name} ${userInfo.lastName}`
        : `${userFullName.name} ${userFullName.lastName}`;
    const [initialsBackgroundColor, initialsTextColor] = getColorByInitials(createdBy, listOfInitialColors);

    const isSavedMessage = message._id;
    const isLastMessage = index === messages.length - 1;
    const disabled = message.userId !== userId;

    const handleChange = (e) => {
        if (message.userId === userId) {
            handleMessageChange(e.target.value, message.index, userId, issueIndex);
        }
    };

    const handleDeleteMessage = () => {
        if (message.userId === userId) {
            removeMessage(message.index, isSavedMessage);
        };
    };

    const handleDeleteLastMessage = () => {
        if (message.userId === userId) {
            removeLastMessage(message.index, issueIndex, isSavedMessage);
        };
    };

    return (
        <div className='issue-container width-100 flex flex-column align-flex-start'>
            <div className='width-100 flex align-center'>
                <div className='initials-container'>
                    <Tooltip color={initialsTextColor} message={createdBy} tooltipMessageClass='width-fit-content'>
                        <Initials
                            isRounded
                            userFullName={createdBy}
                            initialsBackgroundColor={initialsBackgroundColor}
                            initialsTextColor={initialsTextColor}
                        />
                    </Tooltip>
                </div>
                <CustomTextarea
                    value={issueMessage}
                    onChange={handleChange}
                    placeholder={getTranslation('CUSTOM_TEXT_AREA_MESSAGE_PLACEHOLDER')}
                    autoResize
                    containerClass='issues-input-container width-90'
                    isIssue
                    disabled={disabled}
                />

                <div className="flex">
                    <button className="eureka-modal__issue-button" onClick={isLastMessage ? handleDeleteLastMessage : handleDeleteMessage}>
                        <CloseIcon className="eureka-modal__close-solve-icon" />
                    </button>

                    {isLastMessage && (
                        <button className="eureka-modal__issue-button" onClick={() => addMessage(issueId, issueIndex)}>
                            <PlusIcon className="eureka-modal__add-solve-icon" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Message;
