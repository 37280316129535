import React, { useState, useRef } from 'react';
import GenerateNamesModal from '../../GenerateNamesModal';
import ImageModal from '../../ImageModal';
import GenerateImageModal from '../../GenerateImageModal';
import ErrorMessageModal from '../../ErrorMessageModal';
import { ImagesCarousel } from '../../../inputs/ImagesCarousel';
import CustomTextarea from '../../../inputs/CustomTextarea';
import ImageUploader from '../../../inputs/ImageUploader';
import CustomInput from '../../../inputs/CustomInput';
import { getTranslation } from '../../../../helpers/getLanguage';
import { ReactComponent as AIGenerateIcon } from '../../../../assets/images/ai-generate-icon.svg';

const DescribeNewProduct = ({
    selectCurrentMultiplicationImageFromLibrary,
    removeImageFromLibrary,
    multiplication,
    potentialGeneratedImageUrl,
    handleAcceptPotentialImage,
    handleChange,
    uploadInfo,
    newGeneratedImage,
    handleCancelClick,
    handleFileSelect,
    isLoadingImage,
    productType,
    header,
    handleGenerateNames,
    handleGenerateImage,
    openGenerateNameModal,
    closeGenerateNamesModal,
    suggestedNamesForIdeas,
    suggestedLoading,
    addIdeasName,
    isOpenGenerateImageModal,
    closeDialogGenerateImage,
    generatedImage,
}) => {
    const [isOpenImage, setIsOpenImage] = useState(false);

    const [currentModalImage, setCurrentModalImage] = useState(null);
    const [error, setError] = useState({
        openErrorModal: false,
        message: '',
        headerError: '',
    });

    const closeErrorWindow = () => {
        setError({
            openErrorModal: false,
            message: '',
            headerError: '',
        });
    };

    const openImageModal = (newImage) => {
        setCurrentModalImage(newImage);
        setIsOpenImage(true);
    };

    const fileSelectRef = useRef(null);

    const getMultiplicationDescription = (header) => {
        return (
            <p className='multiplication-description'>
                {`${getTranslation('MULTIPLICATION_TAB_DESCRIBE_NEW_PRODUCT_LABEL')} ${productType} ${getTranslation(
                    'MULTIPLICATION_DESCRIPTION_IN_WHICH'
                )}`}
                <u>{header.component}</u>
                {productType === 'process'
                    ? getTranslation('MULTIPLICATION_DESCRIPTION_STEP_IS_MULTIPLIED')
                    : getTranslation('MULTIPLICATION_DESCRIPTION_IS_MULTIPLIED')}

                {header.activeAttribute && (
                    <>
                        {` ${getTranslation('MULTIPLICATION_DESCRIPTION_AND_ITS_ATTRIBUTES')}`}
                        <u>{header.activeAttribute}</u>
                        {getTranslation('MULTIPLICATION_DESCRIPTION_HAS_DIFFERENT_VALUE')}
                    </>
                )}
            </p>
        );
    };

    const validateFile = (file) => {
        if (!file || file.size > 2097152) {
            setError({
                openErrorModal: true,
                message: 'Uploaded file is too big, max image size is 2 MB',
                headerError: getTranslation('ERROR_MESSAGE_MODAL_HEADER'),
            });
            return false;
        }
        return true;
    };

    const handleFileUpload = (event) => {
        const file = event.target ? event.target.files[0] : event[0];
        if (!validateFile(file)) return;
        const reader = new FileReader();
        reader.onload = (e) => {
            const buffer = e.target.result;
            const imageUrl = `data:image/png;base64,${Buffer.from(buffer).toString('base64')}`;
            handleFileSelect(file, imageUrl);
        };
        reader.readAsArrayBuffer(file);
    };

    const isImagesLibraryFull = multiplication.imagesLibrary.length > 9;

    const setMaxImageLibraryError = () => {
        setError({
            openErrorModal: true,
            message: (
                <p className='flex-column mt-25px'>
                    <span style={{ margin: '0' }}>{getTranslation('IMAGE_LIBRARY_MAX_SIZE_ERROR_IMAGES_COUNT')}</span>
                    <span>{getTranslation('IMAGE_LIBRARY_MAX_SIZE_ERROR_DELETE')}</span>
                </p>
            ),
            headerError: getTranslation('IMAGE_LIMIT_REACHED'),
        });
    };

    return (
        <>
            <div>{getMultiplicationDescription(header)}</div>

            <div className='flex multiplication-modal-container'>
                <CustomTextarea
                    value={multiplication.comment}
                    onChange={handleChange}
                    height={287}
                    maxHeight={287}
                    name='comment'
                    label={getTranslation('MULTIPLICATION_TAB_DESCRIBE_DESCRIPTION')}
                    containerClass='flex-column subtraction-modal-input'
                    textareaClass='bgc-white multiplication-modal-container__describe'
                />

                <div className='flex-column' style={{ marginTop: '-35px' }}>
                    <button
                        className='multiplication-modal__button-ai multiplication-modal__button-ai_name-idea'
                        onClick={handleGenerateNames}
                    >
                        <AIGenerateIcon style={{ marginRight: '10px', width: '18px' }} />
                        <span>{getTranslation('GENERATE_IDEAS_NAMES_BUTTON')}</span>
                    </button>
                    <CustomInput
                        labelClass='multiplication-modal-container__label'
                        value={multiplication.ideaName}
                        onChange={handleChange}
                        name='ideaName'
                        label={getTranslation('RATE_IDEA_CUSTOM_INPUT')}
                        containerClass='flex-column subtraction-modal-input'
                        customInputClass='bgc-white multiplication-modal-container__name'
                    />

                    <div style={{ marginTop: '-25px' }}>
                        {multiplication.imagesLibrary.length > 0 && (
                            <>
                                <input
                                    type='file'
                                    ref={fileSelectRef}
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(e)}
                                />
                                <button
                                    className={`multiplication-modal__upload-image-button multiplication-modal__button-ai
                                        ${isImagesLibraryFull && 'multiplication-modal__upload-image-button_disabled'}`}
                                    onClick={() =>
                                        isImagesLibraryFull ? setMaxImageLibraryError() : fileSelectRef.current.click()
                                    }
                                >
                                    {getTranslation("UPLOAD_IMAGES")}
                                </button>
                            </>
                        )}
                        <button
                            className={`multiplication-modal__button-ai 
                                multiplication-modal__button-ai_image 
                                ${isImagesLibraryFull && 'multiplication-modal__button-ai_image-disabled'}`}
                            onClick={() => (isImagesLibraryFull ? setMaxImageLibraryError() : handleGenerateImage())}
                            style={{ left: multiplication.imagesLibrary.length ? '10px' : '95px' }}
                        >
                            <p>
                                <AIGenerateIcon style={{ marginRight: '10px', width: '18px' }} />
                                <span>{getTranslation('GENERATE_IMAGES_BUTTON')}</span>
                            </p>
                            <p>{getTranslation('GENERATE_IMAGES_BUTTON_AI_CREDITS')}</p>
                        </button>
                        {multiplication.imagesLibrary.length ? (
                            <ImagesCarousel
                                openImageModal={openImageModal}
                                imagesLibrary={multiplication.imagesLibrary}
                                currentImageId={multiplication.image.id}
                                selectCurrentDependencyImageFromLibrary={selectCurrentMultiplicationImageFromLibrary}
                                startIndex={multiplication.imagesLibrary.findIndex(
                                    (image) => image.id === multiplication.image.id
                                )}
                                removeImageFromLibrary={removeImageFromLibrary}
                            />
                        ) : (
                            <ImageUploader
                                label={getTranslation('DESCRIBE_NEW_DEPENDENCY_ADD_SKETCH')}
                                uploadInfo={uploadInfo}
                                handleCancelClick={handleCancelClick}
                                handleFileSelect={handleFileSelect}
                                displayPreview
                                isLoadingImage={isLoadingImage}
                                height={200}
                                maxHeight={200}
                                currentProjectImageId={multiplication.image.id}
                                openImage={() => setIsOpenImage(true)}
                                generatedImage={generatedImage}
                            />
                        )}
                    </div>
                </div>
            </div>

            {openGenerateNameModal && (
                <GenerateNamesModal
                    closeGenerateNamesModal={closeGenerateNamesModal}
                    suggestedNamesForIdeas={suggestedNamesForIdeas}
                    suggestedLoading={suggestedLoading}
                    addIdeasName={addIdeasName}
                />
            )}

            {error.openErrorModal && (
                <ErrorMessageModal
                    message={error.message}
                    closeModalWindow={closeErrorWindow}
                    textButton={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    handleButton={closeErrorWindow}
                    header={error.headerError}
                />
            )}

            {isOpenImage && (
                <ImageModal
                    closeModal={() => setIsOpenImage(false)}
                    imageData={currentModalImage}
                    altImage={getTranslation('IMAGE_MODAL_ALT_SKETCH_IDEA')}
                />
            )}

            {isOpenGenerateImageModal && (
                <GenerateImageModal
                    handleAcceptPotentialImage={handleAcceptPotentialImage}
                    closeModalWindow={closeDialogGenerateImage}
                    header={getTranslation('OPEN_AI_LOADER_HEADER_IMAGE')}
                    message={getTranslation('OPEN_AI_LOADER_IMAGE')}
                    imageData={potentialGeneratedImageUrl}
                    suggestedLoading={suggestedLoading}
                />
            )}
        </>
    );
};

export default DescribeNewProduct;
