import React from 'react';
import { getTranslation } from '../../../helpers/getLanguage';
import './styles.css';

const IssueItem = ({ title, text, date, ongoing, onStatusClick = undefined, handleDelete = undefined, isPending }) => {
    const statusClassnames = 'flex-center issue-status ';

    const deleteHandler = () => {
        handleDelete();
    };

    return (
        <div className='flex align-item-start flex-row issue-box'>
            <div className='issue-side'>
                <div className='issue-line-container'>
                    <div className='issue-line'></div>
                </div>
                <span
                    onClick={onStatusClick}
                    className={
                        statusClassnames +
                        (ongoing ? 'issue-ongoing ' : 'issue-fixed ') +
                        (onStatusClick ? 'withPointer ' : '') +
                        (isPending && 'disabled ')
                    }
                >
                    {ongoing ? '!' : '✓'}
                </span>
                {handleDelete && (
                    <button className='flex-center admin-delete-btn' onClick={deleteHandler} disabled={isPending}>
                        {getTranslation('GENERAL_BUTTON_TEXT_DELETE')}
                    </button>
                )}
            </div>
            <div className='issue-info-container'>
                <div className='flex align-items-start flex-column'>
                    <span className='issue-title'>{title}</span>
                    <span className='issue-date'>{new Date(date).toLocaleString('RU')}</span>
                </div>
                <p className='issue-text-body'>{text}</p>
            </div>
        </div>
    );
};

export default IssueItem;
