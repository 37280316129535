import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';

import { getTranslation } from '../../../helpers/getLanguage';

import { createComponents } from '../../../api/components';

import CustomDropdown from '../../inputs/CustomDropdown';
import Dialog from '../Dialog';
import RadioInput from '../../inputs/CustomRadioInput';
import DragAndDrop from '../../inputs/DragAndDrop';

import ProjectIcon from '../../../assets/images/project-icon.svg';
import CloseIcon from '../../../assets/images/close-icon.svg';

import './styles.css';

const initialUpload = {
    progress: 100,
    cancel: null,
    name: '',
    date: '',
    size: 0,
    uploaded: false,
};

const attributesLabelsInitialState = [
    { value: 'name', label: 'IMPORT_NAME' },
    { value: 'description', label: 'IMPORT_DESCRIPTION' },
    { value: 'comments', label: 'IMPORT_COMMENTS' },
    { value: 'importance', label: 'IMPORT_IMPORTANCE' },
];

const componentsLabelsInitialState = [
    { value: 'name', label: 'IMPORT_NAME' },
    { value: 'internal', label: 'IMPORT_INTERNAL_EXTERNAL' },
    { value: 'description', label: 'IMPORT_DESCRIPTION' },
    { value: 'importance', label: 'IMPORT_IMPORTANCE' },
];

const componentFieldsInitialState = {
    name: 'name',
    internal: 'internal',
    description: null,
    importance: null,
};

const attributeFieldsInitialState = {
    name: 'name',
    description: null,
    comments: null,
    importance: null,
};

const numbers = ['first', 'second', 'third', 'firth', 'fifth', 'sixth', 'seventh', 'eighth'];

const setComponentDropdownValuesInitialState = {
    first: 'name',
    second: 'internal',
    third: 'description',
    firth: 'importance',
};

const attributeDropdownValuesInitialState = {
    first: 'name',
    third: 'description',
    second: 'comments',
    firth: 'importance',
};

const lowercaseKeys = (obj) =>
    Object.keys(obj).reduce((acc, key) => {
        acc[key.toLowerCase()] = obj[key];

        return acc;
    }, {});

function getHeaderRow(sheet) {
    if (!sheet) return [];

    var headers = [];
    var range = XLSX.utils.decode_range(sheet['!ref']);
    var C,
        R = range.s.r;

    for (C = range.s.c; C <= range.e.c; ++C) {
        var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

        var hdr = 'UNKNOWN ' + C;
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

        headers.push(hdr);
    }
    return headers;
}

const validateImportance = (importance) => {
    const isNumber = typeof importance === 'number';
    if (!isNumber) return 1;

    const intImportance = Math.floor(importance);
    const includingRange = intImportance > 0 ? (intImportance < 5 ? intImportance : 5) : 1;

    return includingRange;
};

const validateInternal = (internal) => {
    const isString = typeof internal === 'string';
    if (!isString) return true;

    const internalLowerCaseTrim = internal.toLowerCase().trim();
    const isInternal = internalLowerCaseTrim === internal || internalLowerCaseTrim.includes('int');
    if (isInternal) return true;

    const isExternal = internalLowerCaseTrim === 'external' || internalLowerCaseTrim.includes('ext');
    if (isExternal) return false;

    return true;
};

const filterAttributesByComponent = (attributes, componentId) => {
    if (!attributes.length) return [];

    const re = new RegExp('refer');

    const filteredAttributes = attributes.filter((attribute) => {
        const lowercaseAttribute = lowercaseKeys(attribute);
        const keys = Object.keys(lowercaseAttribute);
        const key = keys.find((key) => key.match(re));

        return lowercaseAttribute[key] === componentId;
    });

    return filteredAttributes;
};

const ImportModal = ({ closeDialog, edit = false, currentProject, getProjects }) => {
    const [currentTab, setCurrentTab] = useState(0);
    const [uploadInfo, setUploadInfo] = useState(initialUpload);
    const [data, setData] = useState({ components: [], attributes: [] });
    const [isPending, setIsPending] = useState(false);
    const [isImportAll, setImportAll] = useState(null);
    const [componentFields, setComponentFields] = useState(componentFieldsInitialState);
    const [attributeFields, setAttributeFields] = useState(attributeFieldsInitialState);
    const [componentDropdownValues, setComponentDropdownValues] = useState(setComponentDropdownValuesInitialState);
    const [attributeDropdownValues, setAttributeDropdownValues] = useState(attributeDropdownValuesInitialState);
    const [componentsLabels, setComponentsLabels] = useState(componentsLabelsInitialState);
    const [attributesLabels, setAttributesLabels] = useState(attributesLabelsInitialState);

    const sendData = () => {
        const componentsToSave = data.components.map((component) => {
            const attributesToSave = data.attributes.length
                ? filterAttributesByComponent(data.attributes, lowercaseKeys(component)['id']).map((attribute) => {
                      return {
                          name: attribute[attributeDropdownValues['first']],
                          description: attributeFields.description ? attribute[attributeDropdownValues['second']] : '',
                          comments: attributeFields.comments ? attribute[attributeDropdownValues['third']] : '',
                          importance: attributeFields.importance
                              ? validateImportance(attribute[attributeDropdownValues['firth']])
                              : 1,
                      };
                  })
                : [];

            return {
                name: component[componentDropdownValues['first']],
                internal: validateInternal(component[componentDropdownValues['second']]),
                description: componentFields.description ? component[componentDropdownValues['third']] : '',
                importance: componentFields.importance
                    ? validateImportance(component[componentDropdownValues['firth']])
                    : 1,
                productId: currentProject.id,
                attributes: attributesToSave,
            };
        });

        const params = {
            productId: currentProject.id,
            teamId: currentProject.teamId,
            components: componentsToSave,
        };

        const onSuccess = () => {
            getProjects();
            setIsPending(false);
            closeDialog();
        };

        const onError = () => {
            setIsPending(false);
        };

        setIsPending(true);
        createComponents(params, onSuccess, onError);
    };

    useEffect(() => {
        if (isImportAll) {
            setComponentFields({
                name: 'name',
                internal: 'internal',
                description: 'description',
                importance: 'importance',
            });
            setAttributeFields({
                name: 'name',
                comments: 'comments',
                description: 'description',
                importance: 'importance',
            });
        } else {
            setComponentFields(componentFieldsInitialState);
            setAttributeFields(attributeFieldsInitialState);
        }
    }, [isImportAll]);

    const handleDataUpload = (e) => {
        const file = e;
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, {
                type: 'binary',
            });

            const componentHeaders = getHeaderRow(workbook.Sheets[workbook.SheetNames?.[0]]).filter(
                (header) => header.toLowerCase() !== 'id' && !header.toLowerCase().includes('reference')
            );
            const attributeHeaders = getHeaderRow(workbook.Sheets[workbook.SheetNames?.[1]]).filter(
                (header) => header.toLowerCase() !== 'id' && !header.toLowerCase().includes('reference')
            );
            const componentsValues = {};
            const componentsLabels = [];
            const attributesValues = {};
            const attributesLabels = [];

            const components = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
            const attributes = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[1]]);

            setData({ components, attributes });

            numbers.slice(0, componentHeaders.length).forEach((number, index) => {
                componentsLabels.push({ value: componentHeaders[index], label: componentHeaders[index] });
                componentsValues[number] = componentHeaders[index];
            });

            numbers.slice(0, attributeHeaders.length).forEach((number, index) => {
                attributesLabels.push({ value: attributeHeaders[index], label: attributeHeaders[index] });
                attributesValues[number] = attributeHeaders[index];
            });

            componentsLabels.length && setComponentsLabels(componentsLabels);
            attributesLabels.length && setAttributesLabels(attributesLabels);

            componentsLabels.length && setComponentDropdownValues(componentsValues);
            attributesLabels.length && setAttributeDropdownValues(attributesValues);

            setCurrentTab(1);
        };
        reader.onerror = () => {
            return;
        };

        reader.readAsBinaryString(file);
    };

    const handleCancelClick = () => {};

    const handleCloseDialog = () => {
        if (!edit && uploadInfo.name) {
            if (!uploadInfo.uploaded) {
                uploadInfo.cancel && uploadInfo.cancel('Canceled by user');
            }
            setUploadInfo({ ...initialUpload });
        }
        closeDialog && closeDialog();
    };

    const handleChangeComponentDropdown = (e, index) => {
        for (let key in componentDropdownValues) {
            if (componentDropdownValues[key] === e) {
                setComponentDropdownValues({
                    ...componentDropdownValues,
                    [key]: componentDropdownValues[index],
                    [index]: e,
                });
            }
        }
    };

    const handleChangeAttributeDropdown = (e, index) => {
        for (let key in attributeDropdownValues) {
            if (attributeDropdownValues[key] === e) {
                setAttributeDropdownValues({
                    ...attributeDropdownValues,
                    [key]: attributeDropdownValues[index],
                    [index]: e,
                });
            }
        }
    };

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
            <div className='import-dialog-wrapper' style={{ minHeight: currentTab === 1 ? '890px' : '' }}>
                <div className='project-dialog-header flex-column'>
                    <div className='flex justify-space-between width-100 project-dialog-header-top'>
                        <div className='flex align-center'>
                            <img className='project-dialog-header-icon' src={ProjectIcon} alt='' />
                            <span className='project-dialog-title'>Import data from CSV</span>
                        </div>
                        <img src={CloseIcon} alt='' className='pointer' onClick={() => handleCloseDialog()} />
                    </div>
                    <div className='flex height-100'>
                        <div
                            className={'project-dialog-tab pointer' + (currentTab === 0 ? ' active' : '')}
                            onClick={() => setCurrentTab(0)}
                        >
                            <span>Import</span>
                            {currentTab === 0 && <div className='project-tab-line left' />}
                        </div>
                        <div
                            className={
                                'project-dialog-tab' +
                                (currentTab === 1 ? ' active' : '') +
                                (data.components[0] ? ' pointer' : '')
                            }
                            onClick={() => data.components[0] && setCurrentTab(1)}
                        >
                            <span>Confirm</span>

                            {currentTab === 1 && <div className='project-tab-line right' />}
                        </div>
                    </div>
                </div>

                <div className='project-dialog-body flex-column'>
                    {currentTab === 0 && (
                        <>
                            <DragAndDrop
                                uploadInfo={uploadInfo}
                                supportDescription={'IMPORT_IMPORT_FILE_SUPPORT'}
                                handleCancelClick={handleCancelClick}
                                label={'Project XLSX file'}
                                handleFileSelect={handleDataUpload}
                                isLoadingImage={false}
                            />
                        </>
                    )}
                    {currentTab === 1 && (
                        <>
                            <span className='import-csv-header' style={{ marginTop: '10px' }}>
                                {getTranslation('IMPORT_FIELD_CONFIGURATION')}
                            </span>
                            <div className='import-csv-component-container'>
                                <span className='import-csv-label'>{getTranslation('IMPORT_COMPONENT')}</span>
                                <div style={{ width: '200px' }}>
                                    <RadioInput
                                        options={[{ label: 'IMPORT_IMPORT_ALL', value: 'importAll' }]}
                                        value={isImportAll}
                                        setValue={(e) => setImportAll(e)}
                                    />
                                </div>
                            </div>
                            <div className='import-csv-name-container' style={{ margin: '5px 0' }}>
                                <div style={{ width: '200px', marginRight: '10px' }}>
                                    <RadioInput
                                        options={[{ label: 'IMPORT_NAME', value: 'name', disabled: true }]}
                                        value={componentFields['name']}
                                        setValue={(e) => setComponentFields({ ...componentFields, name: e })}
                                        canUnset={false}
                                        disabled
                                    />
                                </div>
                                <CustomDropdown
                                    value={componentDropdownValues['first']}
                                    handleChange={(e) => handleChangeComponentDropdown(e, 'first')}
                                    options={componentsLabels}
                                />
                            </div>
                            <div className='import-csv-name-container' style={{ margin: '5px 0' }}>
                                <div style={{ width: '200px', marginRight: '10px' }}>
                                    <RadioInput
                                        options={[
                                            {
                                                label: 'IMPORT_INTERNAL_EXTERNAL',
                                                value: 'internal',
                                                disabled: true,
                                            },
                                        ]}
                                        value={componentFields['internal']}
                                        setValue={(e) => setComponentFields({ ...componentFields, internal: e })}
                                        canUnset={false}
                                        disabled
                                    />
                                </div>
                                <CustomDropdown
                                    value={componentDropdownValues['second']}
                                    handleChange={(e) => handleChangeComponentDropdown(e, 'second')}
                                    options={componentsLabels}
                                />
                            </div>
                            <div className='import-csv-name-container' style={{ margin: '5px 0' }}>
                                <div style={{ width: '200px', marginRight: '10px' }}>
                                    <RadioInput
                                        options={[{ label: 'IMPORT_DESCRIPTION', value: 'description' }]}
                                        value={componentFields['description']}
                                        setValue={(e) => setComponentFields({ ...componentFields, description: e })}
                                    />
                                </div>
                                <CustomDropdown
                                    value={componentDropdownValues['third']}
                                    handleChange={(e) => handleChangeComponentDropdown(e, 'third')}
                                    options={componentsLabels}
                                />
                            </div>
                            <div className='import-csv-name-container' style={{ margin: '5px 0' }}>
                                <div style={{ width: '200px', marginRight: '10px' }}>
                                    <RadioInput
                                        options={[{ label: 'IMPORT_IMPORTANCE', value: 'importance' }]}
                                        value={componentFields['importance']}
                                        setValue={(e) => setComponentFields({ ...componentFields, importance: e })}
                                    />
                                </div>
                                <CustomDropdown
                                    value={componentDropdownValues['firth']}
                                    handleChange={(e) => handleChangeComponentDropdown(e, 'firth')}
                                    options={componentsLabels}
                                />
                            </div>
                            <span className='import-csv-label' style={{ margin: '10px 0' }}>
                                {getTranslation('IMPORT_ATTRIBUTE')}
                            </span>
                            <div className='import-csv-name-container' style={{ margin: '5px 0' }}>
                                <div style={{ width: '200px', marginRight: '10px' }}>
                                    <RadioInput
                                        options={[{ label: 'IMPORT_NAME', value: 'name', disabled: true }]}
                                        value={attributeFields['name']}
                                        setValue={(e) => setAttributeFields({ ...attributeFields, name: e })}
                                        canUnset={false}
                                        disabled
                                    />
                                </div>
                                <CustomDropdown
                                    value={attributeDropdownValues['first']}
                                    handleChange={(e) => handleChangeAttributeDropdown(e, 'first')}
                                    options={attributesLabels}
                                />
                            </div>
                            <div className='import-csv-name-container' style={{ margin: '5px 0' }}>
                                <div style={{ width: '200px', marginRight: '10px' }}>
                                    <RadioInput
                                        options={[{ label: 'IMPORT_DESCRIPTION', value: 'description' }]}
                                        value={attributeFields['description']}
                                        setValue={(e) => setAttributeFields({ ...attributeFields, description: e })}
                                    />
                                </div>
                                <CustomDropdown
                                    value={attributeDropdownValues['second']}
                                    handleChange={(e) => handleChangeAttributeDropdown(e, 'second')}
                                    options={attributesLabels}
                                />
                            </div>
                            <div className='import-csv-name-container' style={{ margin: '5px 0' }}>
                                <div style={{ width: '200px', marginRight: '10px' }}>
                                    <RadioInput
                                        options={[{ label: 'IMPORT_COMMENTS', value: 'comments' }]}
                                        value={attributeFields['comments']}
                                        setValue={(e) => setAttributeFields({ ...attributeFields, comments: e })}
                                    />
                                </div>
                                <CustomDropdown
                                    value={attributeDropdownValues['third']}
                                    handleChange={(e) => handleChangeAttributeDropdown(e, 'third')}
                                    options={attributesLabels}
                                />
                            </div>
                            <div className='import-csv-name-container' style={{ margin: '5px 0' }}>
                                <div style={{ width: '200px', marginRight: '10px' }}>
                                    <RadioInput
                                        options={[{ label: 'IMPORT_IMPORTANCE', value: 'importance' }]}
                                        value={attributeFields['importance']}
                                        setValue={(e) => setAttributeFields({ ...attributeFields, importance: e })}
                                    />
                                </div>
                                <CustomDropdown
                                    value={attributeDropdownValues['firth']}
                                    handleChange={(e) => handleChangeAttributeDropdown(e, 'firth')}
                                    options={attributesLabels}
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className='project-dialog-buttons'>
                    <button className='project-dialog-button dark-blue-btn' disabled={isPending} onClick={sendData}>
                        {getTranslation('EDIT_PROJECT_DIALOG_BUTTON')}
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default ImportModal;
