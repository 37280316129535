import {
  GET_STATISTICS,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_ERROR,
} from '../actions/types';

const initialState = {
  isLoading: true,
  errors: null,
  payload: {
    projectsCount: 0,
    ideasCount: 0,
    usersCount: 0,
    monthStatistics: {
      projectsCount: 0,
      ideasCount: 0,
      usersCount: 0,
    }
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_STATISTICS:
      return { ...state, isLoading: true };
    case GET_STATISTICS_SUCCESS:
      return {
        ...state,
        errors: null,
        isLoading: false,
        payload: action.payload
      };
    case GET_STATISTICS_ERROR:
      console.error(action.payload);
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
        payload: {
          projectsCount: 0,
          ideasCount: 0,
          usersCount: 0,
          monthStatistics: {
            projectsCount: 0,
            ideasCount: 0,
            usersCount: 0,
          }
        }
      };

    default:
      return state;
  }
}