import React, { useState } from 'react';

import { getUrlFromConfig } from '../../../helpers/getUrlFromConfig';
import { circulateProject, shareProject } from '../../../api/invites';
import { copyTextToClipboard } from '../../../common/copyTextToClipboard';
import { getTranslation } from '../../../helpers/getLanguage';

import Tooltip from '../../common/Tooltip';
import ConfirmationModal from '../../dialogs/ConfirmationModal';

import './styles.css';

const buttonText = {
    circulate: {
        pending: 'PAGE_DASHBOARD_BUTTON_TEXT_PENDING',
        success: 'PAGE_DASHBOARD_BUTTON_TEXT_SUCCESS',
        error: 'PAGE_DASHBOARD_BUTTON_TEXT_ERROR',
        default: 'PAGE_DASHBOARD_BUTTON_TEXT_DEFAULT_CIRCULATE'
    },
    share: {
        pending: 'PAGE_DASHBOARD_BUTTON_TEXT_PENDING',
        success: 'PAGE_DASHBOARD_BUTTON_TEXT_SUCCESS',
        error: 'PAGE_DASHBOARD_BUTTON_TEXT_ERROR',
        default: 'PAGE_DASHBOARD_BUTTON_TEXT_DEFAULT_SHARE'
    }
};

const buttonStatus = {
    success: 'success',
    pending: 'pending',
    error: 'error'
};

const copyMode = {
    circulate: 'circulate',
    share: 'share'
};

const tooltipMessages = {
    circulate: "PAGE_DASHBOARD_BUTTON_SEND_COPY_TOOLTIP_MESSAGE",
    share: "PAGE_DASHBOARD_BUTTON_INVITE_TOOLTIP_MESSAGE",
};

const errorMessages = {
    circulate: "PAGE_DASHBOARD_BUTTON_SEND_COPY_ERROR_MESSAGE",
    share: "PAGE_DASHBOARD_BUTTON_INVITE_ERROR_MESSAGE",
};

const CopyLinkButton = ({
    projectId = '',
    mode = copyMode.circulate,
    onSuccess: successCallback,
    onError: errorCallback,
    onRequestStart,
    containerClass = '',
    disabled = false,
    itemDisabled = false,
    tooltipPosition = 'top'
}) => {
    const [copyStatus, setCopyStatus] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [requestSuccess, setRequestSuccess] = useState(false);
    const [link, setLink] = useState('');
    const [copyButton, setCopyButton] = useState('OK');

    const handleCopyLink = () => {
        if (disabled || copyStatus) return;

        if (itemDisabled) {
            setCopyStatus(buttonStatus.error);
            setModalMessage(errorMessages[mode]);
            setRequestSuccess(false);
            setModalOpen(true);
            return;
        }

        const params = { projectId };

        const onSuccess = response => {
            const inviteUrl = getUrlFromConfig(`invite/${response.id}`);
            setLink(inviteUrl);
            setCopyStatus(buttonStatus.success);
            setModalMessage((<span>{getTranslation("PAGE_DASHBOARD_PROJECT_LINK")} <p><span id="link" className="copy-link">{inviteUrl}</span></p></span>));
            setCopyButton(getTranslation("PAGE_DASHBOARD_COPY_BUTTON_MESSAGE_COPY"));
            setRequestSuccess(true);
            setModalOpen(true);
        };

        const onError = error => {
            console.error(error);
            setCopyStatus(buttonStatus.error);
            setModalMessage((error.response && error.response.data.message) || "Couldn't copy the link");
            setRequestSuccess(false);
            setModalOpen(true);
        };

        setCopyStatus(buttonStatus.pending);
        onRequestStart && onRequestStart();
        mode === copyMode.circulate && circulateProject(params, onSuccess, onError);
        mode === copyMode.share && shareProject(params, onSuccess, onError);
    };

    const handleCloseModal = () => {
        setCopyStatus('');
        setModalOpen(false);
        setLink('');
        setCopyButton(getTranslation("PAGE_DASHBOARD_COPY_BUTTON_MESSAGE_OK"));

        requestSuccess && successCallback && successCallback();
        !requestSuccess && errorCallback && errorCallback();
    };

    const handleCopy = () => {
        copyTextToClipboard(link);
        const copiedLink = document.getElementById('link');
        copiedLink.classList.add('copied-link');
    };

    const activeClass = itemDisabled ? ' not-allowed' : '';

    return (
        <div className={`flex align-center ${containerClass}`}>
            <button
                className={'copy-link-button flex justify-space-between align-center' + activeClass}
                onClick={handleCopyLink}
            >
                <span> {getTranslation(buttonText[mode][copyStatus || 'default'])} </span>

                <Tooltip
                    position={tooltipPosition}
                    message={getTranslation(tooltipMessages[mode])}
                    containerClass="copy-link-tooltip"
                />
            </button>

            {modalOpen && (
                <ConfirmationModal
                    closeDialog={handleCloseModal}
                    message={getTranslation(modalMessage)}
                    buttonText={copyButton === "OK" ? "CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT" : copyButton}
                    messageWidth={440}
                    messageHeight={80}
                    onConfirm={copyButton === 'OK' ? handleCloseModal : handleCopy}
                />
            )}
        </div>
    );
};

export default CopyLinkButton;
