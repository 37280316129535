import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

const baseUrl = `${baseApiUrl}/shared-ideas`;

export const getSharedIdeas = async (params, onSuccess, onError) => {
    axios
        .get(`${baseUrl}`, params)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const shareIdeas = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}`, params)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};
