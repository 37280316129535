import React, { useState } from 'react';
import CustomTextarea from '../../inputs/CustomTextarea';
import { getTranslation } from '../../../helpers/getLanguage';
import './styles.css';
import { formatDate } from '../../../helpers/format-date';

const Prefab = ({ 
    prefab, 
    saveEditedPrefab, 
    reselectPrefab, 
    selectPrefabForDelete, 
    selectedPrefabs 
}) => {
    const [isEditPrefab, setIsEditPrefab] = useState(false);
    const [editedPrefab, setEditedPrefab] = useState({
        id: prefab.id,
        textPrompt: prefab.textPrompt,
    });

    const handleClickSavePrefab = () => {
        setIsEditPrefab(false);
        saveEditedPrefab(editedPrefab);
    };

    return (
        <div className={`prefab ${selectedPrefabs.has(prefab.id) ? "prefab_deleted" : "" }`}>
            {!isEditPrefab && 
                <input 
                    type="radio" 
                    checked={prefab.isSelected}
                    id={prefab.id}
                    onChange={(e) => reselectPrefab(e)}
                />
            }

            <div className="prefab-box">
                {isEditPrefab ? (                 
                    <CustomTextarea 
                        value={editedPrefab.textPrompt}
                        onChange={(e) => setEditedPrefab({ ...editedPrefab,  textPrompt: e.target.value })}
                        containerClass="prefab-box__textarea"
                    />
                ) : (
                    <p className="prefab-box-text">{prefab.textPrompt}</p>
                )}

                <div className="prefab-box-info flex">
                    <div className="flex-column align-flex-start">
                        <p>{getTranslation("ADMIN_PANEL_PREFABS_TEXT_CREATED")} {prefab.created}</p>

                        {prefab.edited && 
                            <p>{getTranslation("ADMIN_PANEL_PREFABS_TEXT_EDITED")} {prefab.edited}</p>
                        }
                    </div>

                    <div className="flex-column align-flex-start">
                        <p>{getTranslation("ADMIN_PANEL_PREFABS_TEXT_DATE")} {formatDate(prefab.creationDate, "DD.MM.YYYY")}</p>

                        {prefab.editDate && 
                            <p>{getTranslation("ADMIN_PANEL_PREFABS_TEXT_DATE")} {formatDate(prefab.editDate, "DD.MM.YYYY")}</p>
                        }
                    </div>
                </div> 
            </div>

            <div className="flex-column">
                <button 
                    className="prefab-button mb-10"
                    onClick={isEditPrefab ? handleClickSavePrefab : () => setIsEditPrefab(true)}
                >
                    {isEditPrefab ? getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_SAVE") : getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_EDIT")}
                </button>
                {!isEditPrefab &&
                    <button 
                        className={`prefab-button ${selectedPrefabs.has(prefab.id) ? "prefab-button_deleted" : ""} ${prefab.isSelected ? "disabled" : ""}`}
                        onClick={() => selectPrefabForDelete(prefab.id)}
                        disabled={prefab.isSelected}
                    >
                        {getTranslation("ADMIN_PANEL_PREFABS_TEXT_BUTTON_DELETE")}
                    </button>
                }
            </div>
        </div>
    )
}

export default Prefab;