import React from 'react';
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons';

import { getTranslation } from '../../../helpers/getLanguage';

import './styles.css';

const ReplacementModalFooter = ({
    resetReplacement,
    prevEnabled,
    nextEnabled,
    isCompleted,
    handleRightClick,
    handleLeftClick,
    disabledNextButton,
    checkRating,
    currentTab
}) => {
    const getClassNameForSaveButton = () => {
        if (currentTab === 1) {
            return " dark-blue-btn";
        };
        
        if (currentTab === 2 && isCompleted()) {
            return " dark-blue-btn";
        };

        return " outlined";
    };

    return (
        <div className='replacement-dialog-footer flex justify-space-between align-center '>
            <button className='replacement-dialog-button secondary' onClick={() => resetReplacement()}>
                <span>{getTranslation('SUBTRACTION_MODAL_BUTTON_CLEAR')}</span>
            </button>

            <div className='subtraction-dialog-buttons'>
                <button
                    className={'replacement-dialog-button secondary control' + (prevEnabled() ? '' : ' hidden')}
                    onClick={handleLeftClick}
                >
                    <ArrowLeft className='dialog-buttons-arrow-left' />
                    <span>{getTranslation('SUBTRACTION_MODAL_BUTTON_PREVIOUS')}</span>
                </button>
                <button
                    className={'replacement-dialog-button dark-blue-btn control' + (nextEnabled() ? '' : ' hidden') + (disabledNextButton ? ' disabled' : '')}
                    onClick={handleRightClick}
                >
                    <span>{getTranslation('SUBTRACTION_MODAL_BUTTON_NEXT')}</span>
                    <ArrowRight className='dialog-buttons-arrow-right' />
                </button>
            </div>

            <button
                className={'replacement-dialog-button ' + getClassNameForSaveButton()}
                onClick={checkRating}
            >
                <span>{getTranslation('SUBTRACTION_MODAL_BUTTON_SAVE')}</span>
            </button>
        </div>
    );
};

export default ReplacementModalFooter;
