import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { parseLocationSearch } from '../../common/parseLocationSearch';
import { changePassword, resetRecoveryState } from '../../actions/passwordRecoveryActions';

import { validateField } from '../../common/validation';

import CustomLoginInput from '../inputs/CustomLoginInput';
import LoginLayout from '../common/LoginLayout';

import { getTranslation } from '../../helpers/getLanguage';

const initialCredentials = {
    password: '',
    confirmPassword: ''
};

const ResetPassword = ({ history, location, auth, recovery, changePassword, resetRecoveryState }) => {
    const [credentials, setCredentials] = useState(initialCredentials);
    const [recoveryToken, setRecoveryToken] = useState('');
    const [errors, setErrors] = useState(initialCredentials);
    const { isAuthenticated } = auth;

    useEffect(() => {
        resetRecoveryState();

        if (!location.search) {
            history.push('/');
        } else {
            const params = parseLocationSearch(location.search);
            const { token } = params;
            if (!token) {
                history.push('/');
            } else {
                setRecoveryToken(token);
            }
        }
    }, [resetRecoveryState, location.search, history]);

    useEffect(() => {
        resetRecoveryState();
        if (isAuthenticated) {
            history.push('/');
        }
    }, [resetRecoveryState, isAuthenticated, history]);

    useEffect(() => {
        if (recovery.errors) {
            setErrors({ confirmPassword: recovery.errors.message });
        }
    }, [recovery.errors]);

    const handleSubmit = () => {
        let hasErrors = false;

        for (let key in credentials) {
            const message = validateField({ name: key, value: credentials[key] }, handleSetErrors);
            message.length && (hasErrors = true);
        }

        if (credentials.confirmPassword && credentials.password !== credentials.confirmPassword) {
            setErrors(errors => ({ ...errors, confirmPassword: 'Passwords do not match' }));
            hasErrors = true;
        }

        !hasErrors && changePassword(recoveryToken, credentials.password);
    };

    const handleChange = e => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleSetErrors = (e, value) => {
        const { name } = e.target || e;
        setErrors(errors => ({ ...errors, [name]: value }));
    };

    const handleLoginClick = () => {
        history.push('/login');
    };

    const handleSubmitEnter = event => {
        if (!recovery.isLoading && event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <LoginLayout>
            <div className="login-form width-100 flex-column align-center">
                <span className="login-title mb-20">{getTranslation("LOGIN_PAGE_RECOVER_PASSWORD")}</span>

                {!recovery.success && (
                    <>
                        <CustomLoginInput
                            containerClass="mb-20"
                            type="password"
                            name="password"
                            placeholder={getTranslation("LOGIN_PAGE_INPUT_PASSWORD")}
                            onBlur={e => validateField(e, handleSetErrors)}
                            errorMessage={errors.password}
                            value={credentials.password}
                            onChange={handleChange}
                            onKeyDown={handleSubmitEnter}
                        />

                        <CustomLoginInput
                            containerClass="mb-25"
                            type="password"
                            name="confirmPassword"
                            placeholder={getTranslation("LOGIN_PAGE_CONFIRM_PASSWORD")}
                            onBlur={e => validateField(e, handleSetErrors)}
                            errorMessage={errors.confirmPassword}
                            value={credentials.confirmPassword}
                            onChange={handleChange}
                            onKeyDown={handleSubmitEnter}
                        />

                        <button className="login-button mb-20" onClick={handleSubmit} disabled={recovery.isLoading}>
                            <span>{getTranslation("LOGIN_PAGE_CHANGE_PASSWORD")}</span>
                        </button>
                    </>
                )}

                {recovery.success && (
                    <>
                        <div className="login-text mb-20">
                            <span>{getTranslation("LOGIN_PAGE_PASSWORD_BEEN_CHANGED")} </span>
                        </div>

                        <button className="login-button mb-20" onClick={handleLoginClick}>
                            <span>{getTranslation("LOGIN_PAGE_EMAIL_VERIFICATION_BACK_TO_LOGIN")}</span>
                        </button>
                    </>
                )}

            </div>
        </LoginLayout>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    recovery: state.recovery
});

export default connect(mapStateToProps, { changePassword, resetRecoveryState })(ResetPassword);
