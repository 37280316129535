import React from 'react';
import './styles.css';
import CheckmarkIcon from '../../../assets/images/checkmark.svg';

const CustomCheckbox = ({ checked, handleChange, customClass = '', disabled = false, hasErrors = false, checkboxIcon, customActivClass = '' }) => {
    const activeClass = (checked && customActivClass ? customActivClass : checked ? ' checked' : '') + (disabled ? ' disabled' : ' pointer');
    const errorClass = hasErrors ? ' red' : '';

    const handleClick = e => {
        e.stopPropagation();
        !disabled && handleChange(!checked);
    };

    return (
        <div className={'custom-checkbox flex-center ' + customClass + activeClass + errorClass} onClick={handleClick}>
            {checked && <img src={checkboxIcon ? checkboxIcon : CheckmarkIcon} alt="" />}
        </div>
    );
};

export default CustomCheckbox;
