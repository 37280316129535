import {
    GET_SUBSCRIPTION_INFO,
    GET_SUBSCRIPTION_INFO_SUCCESS,
    GET_SUBSCRIPTION_INFO_ERROR,
    CREATE_SUBSCRIPTION,
    CREATE_SUBSCRIPTION_ERROR,
    RESET_SUBSCRIPTION,
    UPDATE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_ERROR,
    RETRY_SUBSCRIPTION_PAYMENT,
    RETRY_SUBSCRIPTION_PAYMENT_ERROR,
    CREATE_SUBSCRIPTION_OPENAI,
    CREATE_SUBSCRIPTION_OPENAI_ERROR,
} from '../../actions/types';

import {
    getSubscriptionInfo as requestSubscriptionInfo,
    updatePaymentMethod as requestUpdatePaymentMethod,
} from '../../api/stripe';
import {
    retryInvoiceWithNewPaymentMethod,
    createSubscription,
    retrySubscriptionPayment,
    createOpenAISubscription,
} from './stripeActions';

export const getSubscriptionInfo = () => (dispatch) => {
    dispatch({ type: GET_SUBSCRIPTION_INFO });

    const onSuccess = (response) => dispatch({ type: GET_SUBSCRIPTION_INFO_SUCCESS, payload: response });
    const onError = (error) => dispatch({ type: GET_SUBSCRIPTION_INFO_ERROR, payload: error });

    requestSubscriptionInfo({}, onSuccess, onError);
};

export const handleCreatingSubscription =
    ({
        isPaymentRetry,
        invoiceId,
        cardElement,
        interval,
        quantity,
        stripe,
        fields,
        promoCode,
        socketId,
        creditsId,
        planId,
    }) =>
    (dispatch) => {
        dispatch({ type: CREATE_SUBSCRIPTION });

        stripe.createPaymentMethod({ type: 'card', card: cardElement }).then((response) => {
            if (response.error) {
                dispatch({ type: CREATE_SUBSCRIPTION_ERROR, payload: response });
            } else {
                const { id } = response.paymentMethod;
                if (isPaymentRetry) {
                    retryInvoiceWithNewPaymentMethod(id, invoiceId, dispatch, stripe);
                } else {
                    createSubscription(
                        id,
                        interval,
                        quantity,
                        dispatch,
                        stripe,
                        fields,
                        promoCode,
                        socketId,
                        creditsId,
                        planId
                    );
                }
            }
        });
    };

export const handleCreateOpenAISubscription =
    ({ cardElement, interval, quantity, stripe, number, promoCode, socketId, creditsId }) =>
    (dispatch) => {
        dispatch({ type: CREATE_SUBSCRIPTION_OPENAI });

        stripe.createPaymentMethod({ type: 'card', card: cardElement }).then((response) => {
            if (response.error) {
                dispatch({ type: CREATE_SUBSCRIPTION_OPENAI_ERROR, payload: response });
            } else {
                const { id } = response.paymentMethod;
                createOpenAISubscription(id, interval, quantity, dispatch, stripe, creditsId, promoCode, socketId);
            }
        });
    };

export const resetSubscription = () => (dispatch) => {
    dispatch({ type: RESET_SUBSCRIPTION });
};

export const updatePaymentMethod =
    ({ subscriptionId, cardElement, stripe }) =>
    (dispatch) => {
        dispatch({ type: UPDATE_PAYMENT_METHOD });

        stripe.createPaymentMethod({ type: 'card', card: cardElement }).then((response) => {
            if (response.error) {
                dispatch({ type: UPDATE_PAYMENT_METHOD_ERROR, payload: response });
            } else {
                const params = { subscriptionId, paymentMethodId: response.paymentMethod.id };
                const onSuccess = () => dispatch({ type: UPDATE_PAYMENT_METHOD_SUCCESS });
                const onError = (error) => dispatch({ type: UPDATE_PAYMENT_METHOD_ERROR, payload: error });
                requestUpdatePaymentMethod(params, onSuccess, onError);
            }
        });
    };

export const handleRetrySubscriptionPayment =
    ({ cardElement, subscriptionId, stripe }) =>
    (dispatch) => {
        dispatch({ type: RETRY_SUBSCRIPTION_PAYMENT });

        stripe.createPaymentMethod({ type: 'card', card: cardElement }).then((response) => {
            if (response.error) {
                dispatch({ type: RETRY_SUBSCRIPTION_PAYMENT_ERROR, payload: response });
            } else {
                const { id } = response.paymentMethod;
                retrySubscriptionPayment(subscriptionId, id, dispatch, stripe);
            }
        });
    };
