import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

export const getCurrentIssues = async (data, onSuccess, onError) => {
    axios
        .get(`${baseApiUrl}/currentIssues`, data)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const readCurrentIssues = async (data, onSuccess, onError) => {
    axios
        .post(`${baseApiUrl}/users/read-issues`, data)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};
