import React, { useRef, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import './styles.css';
import useContainerHeight from '../../common/useContainerHeight';

import { getProjectInfo } from '../../actions/projectActions';
import { isProjectEditable, canProjectBeCirculated, canProjectBeShared } from '../../common/project';

import { getInviteUsers, circulateProject, shareProject, removeInviteParticipant } from '../../api/invites';
import { filterUsers, getDate, sortUsers } from './filters';

import MessageShowNotFound from '../common/MessageShowNotFound';
import ProjectTopBar from '../common/ProjectTopBar';
import UsersHeader from './UsersHeader';
import UsersTable from './UsersTable';
import ProjectEditModal from '../dialogs/ProjectEditModal';
import InvitationModal from '../dialogs/InvitationModal';
import ConfirmationModal from '../dialogs/ConfirmationModal';

import Loader from '../common/Loader';
import { getTranslation } from '../../helpers/getLanguage';

const initialFiltersState = {
    search: '',
};

const initialModalOpen = {
    project: false,
    invite: false,
    delete: false,
};

const popupMessages = {
    user: 'CONFIRM_MODAL_DELETE_USER',
    users: 'CONFIRM_MODAL_DELETE_USERS',
};

const Users = ({ auth, product, isLoading: isProjectLoading, history, match, errors, getProjectInfo }) => {
    const editMode = isProjectEditable(product, auth.user.id);
    const canCirculate = canProjectBeCirculated(product);
    const canShare = canProjectBeShared(product);
    const projectId = match.params.projectId;
    const [filters, setFilters] = useState(initialFiltersState);
    const [sort, setSort] = useState({ field: 'team', asc: false });
    const [initialUsers, setInitialUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [modalOpen, setModalOpen] = useState(initialModalOpen);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUsersAll, setSelectedUsersAll] = useState(false);
    const [inviteInfo, setInviteInfo] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [canChangeTeam, setCanChangeTeam] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);

    const usersTableRef = useRef(null);
    const [containerHeight, updateHeight] = useContainerHeight(usersTableRef, 20);

    useEffect(() => {
        let componentExists = true;

        const onSuccess = (response) => {
            const teams = response.teams;
            const users = [];
            let teamIndex = 0;
            let userIndex = 0;

            teams.forEach((team) => {
                const isTeam = !team.isIndividual;

                const participants = team.participants.map((participant) => ({
                    ...participant,
                    index: userIndex++,
                    team: isTeam ? `Team ${teamIndex + 1}` : '',
                    teamId: team.id,
                    maxPeople: team.maxPeople,
                }));
                isTeam && teamIndex++;
                users.push(...participants);
            });

            if (componentExists) {
                response.id && setInviteInfo(response);
                setCanChangeTeam(response.id !== '');
                setInitialUsers(users);
                handleSelectAllRows(false);
                updateHeight();
                setUsersLoading(false);
            }
        };

        const onError = (error) => {
            console.error(error);
            componentExists && setInitialUsers([]);
            updateHeight();
            componentExists && setUsersLoading(false);
        };

        const params = { projectId };

        componentExists && setUsersLoading(true);
        getInviteUsers({ params }, onSuccess, onError);

        !isLoading && getProjectInfo(projectId);

        return () => (componentExists = false);
    }, [projectId, getProjectInfo]);

    useEffect(() => {
        const filteredUsers = filterUsers(initialUsers, filters);
        const sortedUsers = sortUsers(filteredUsers, sort);
        setUsers(sortedUsers);
    }, [initialUsers, filters, sort]);

    const getUsersData = () => {
        const onSuccess = (response) => {
            const teams = response.teams;
            const users = [];
            let teamIndex = 0;
            let userIndex = 0;

            teams.forEach((team) => {
                const isTeam = !team.isIndividual;

                const participants = team.participants.map((participant) => ({
                    ...participant,
                    index: userIndex++,
                    team: isTeam ? `Team ${teamIndex + 1}` : '',
                    teamId: team.id,
                    maxPeople: team.maxPeople,
                }));
                isTeam && teamIndex++;
                users.push(...participants);
            });

            response.id && setInviteInfo(response);
            setCanChangeTeam(response.id !== '');
            setInitialUsers(users);
            handleSelectAllRows(false);
            updateHeight();
            setUsersLoading(false);
        };

        const onError = (error) => {
            console.error(error);
            setInitialUsers([]);
            updateHeight();
            setUsersLoading(false);
        };

        const params = { projectId };
        setUsersLoading(true);
        getInviteUsers({ params }, onSuccess, onError);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
        handleSelectAllRows(false);
    };

    const handleSortChange = (field) => {
        field === sort.field ? setSort({ field: field, asc: !sort.asc }) : setSort({ field, asc: true });
    };

    const handleEditTeamSettings = () => {
        setModalOpen({ ...modalOpen, project: true });
    };

    const handleCloseModal = useCallback(() => {
        setModalOpen({ ...initialModalOpen });
    }, []);

    const handleSelectUser = (index) => {
        const selection = selectedUsers.slice();
        selection[index] = !selection[index];
        !selection[index] && setSelectedUsersAll(false);
        setSelectedUsers(selection);
    };

    const selectAllUsers = (rowsCount, value) => {
        setSelectedUsers(new Array(rowsCount).fill(value));
    };

    const handleSelectAllRows = (value) => {
        selectAllUsers(initialUsers.length, value);
        setSelectedUsersAll(value);
    };

    const getSelectedUsers = () => {
        const selectedRows = initialUsers.filter((row) => selectedUsers[row.index]);
        const unfilteredRows = [];

        selectedRows.forEach((currentRow) => {
            const foundRow = users.find((row) => row.index === currentRow.index);
            foundRow && unfilteredRows.push(foundRow);
        });

        return unfilteredRows;
    };

    const handleChangeTeam = (user) => {
        handleSetUserInfo(user);
        setModalOpen({ ...modalOpen, invite: true });
    };

    const handleSetUserInfo = (user) => {
        const markedUsers = getSelectedUsers().map((markedUser) => ({
            userId: markedUser.id,
            currentTeamId: markedUser.teamId,
        }));

        const foundMarkedUser = markedUsers.find(
            (foundUser) => foundUser.userId === user.id && foundUser.currentTeamId === user.teamId
        );

        if (markedUsers.length && foundMarkedUser) {
            setUserInfo(markedUsers);
        } else {
            setUserInfo([{ userId: user.id, currentTeamId: user.teamId }]);
        }
    };

    const updateInvite = () => {
        const params = { projectId };

        if (canCirculate) {
            circulateProject(params, getUsersData);
        } else if (canShare) {
            shareProject(params, getUsersData);
        }
    };

    const updateProjectInfo = () => {
        getProjectInfo(projectId);
        updateInvite();
    };

    const handleUserDelete = (user) => {
        handleSetUserInfo(user);
        setModalOpen({ ...modalOpen, delete: true });
    };

    const deleteUser = () => {
        const params = { id: inviteInfo.id, users: userInfo };

        const onSuccess = () => {
            getUsersData();
            setUserInfo({});
            handleCloseModal();
        };

        removeInviteParticipant(params, onSuccess);
    };

    const handleTeamChanges = (data) => {
        if (editMode) {
            getUsersData();
        } else {
            const result = data && data[0];
            if (result && result.success) {
                history.push(`/project/${result.projectId}/users`);
            }
        }
    };

    const handleTeamChangeError = (error) => {
        if ((error.response.data.message || '').includes('has expired')) {
            getUsersData();
        }
        console.error((error.response && error.response.data.message) || error);
    };

    const deletePopupText = userInfo.length > 1 ? popupMessages.users : popupMessages.user;

    const isLoading = isProjectLoading || usersLoading;

    const showNotFoundError = errors && errors.response && errors.response.status === 404;

    return (
        <div>
            <ProjectTopBar match={match} history={history} projectId={projectId} currentProjectName={product.name} />

            <div className='users-page-wrapper'>
                {isLoading && <Loader />}

                {!showNotFoundError && !isLoading && (
                    <>
                        <UsersHeader
                            filters={filters}
                            handleChange={handleChange}
                            handleEditTeamSettings={handleEditTeamSettings}
                            projectId={projectId}
                            editMode={editMode}
                            initialUsers={initialUsers}
                            canCirculate={canCirculate}
                            canShare={canShare}
                            getUsersData={getUsersData}
                            getProjectInfo={() => getProjectInfo(projectId)}
                            isLoading={isLoading}
                        />
                        {users.length > 0 && (
                            <UsersTable
                                sort={sort}
                                handleSortChange={handleSortChange}
                                rows={users}
                                getDate={getDate}
                                handleSelectUser={handleSelectUser}
                                handleSelectAllRows={handleSelectAllRows}
                                selectedUsers={selectedUsers}
                                selectedUsersAll={selectedUsersAll}
                                editMode={editMode}
                                handleChangeTeam={handleChangeTeam}
                                handleUserDelete={handleUserDelete}
                                canChangeTeam={canChangeTeam}
                                containerHeight={containerHeight}
                                usersTableRef={usersTableRef}
                            />
                        )}
                        {users.length === 0 && (
                            <div className='flex-column users-placeholder'>
                                <span>{getTranslation('NO_USERS_IN_PROJECT_MESSAGE')}</span>
                            </div>
                        )}
                    </>
                )}

                {modalOpen.project && (
                    <ProjectEditModal
                        edit={true}
                        isProcessType={product.type === 'process'}
                        closeDialog={handleCloseModal}
                        currentProject={product}
                        onSuccess={updateProjectInfo}
                        openTab={1}
                    />
                )}
                {modalOpen.invite && (
                    <InvitationModal
                        closeDialog={handleCloseModal}
                        inviteInfo={inviteInfo}
                        onSuccess={handleTeamChanges}
                        onError={handleTeamChangeError}
                        edit={true}
                        userInfo={userInfo}
                    />
                )}
                {modalOpen.delete && (
                    <ConfirmationModal
                        closeDialog={handleCloseModal}
                        message={getTranslation(deletePopupText)}
                        onConfirm={deleteUser}
                        buttonText={getTranslation('CONFIRM_MODAL_DELETE_USER_BUTTON')}
                    />
                )}

                {showNotFoundError && <MessageShowNotFound history={history} />}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    product: state.project.product,
    isLoading: state.project.isLoading,
    errors: state.project.errors,
});

export default connect(mapStateToProps, { getProjectInfo })(Users);
