import {
    CREATE_PROJECT_FOLDER,
    GET_PROJECTS,
    GET_PROJECTS_ERROR,
    GET_PROJECTS_SUCCESS,
    GET_PROJECT_FOLDERS,
    GET_PROJECT_INFO,
    GET_PROJECT_INFO_ERROR,
    GET_PROJECT_INFO_SUCCESS,
    MOVE_PROJECT_OUT_OF_FOLDER,
    MOVE_PROJECT_TO_FOLDER,
    REMOVE_PROJECT_FOLDER,
    RENAME_PROJECT_FOLDER,
    RESET_PROJECTS_STORE,
    RESET_PROJECT_INFO,
    RESET_OPENAI_GENERATE_WORDS,
    RESET_OPENAI_GENERATE_COMPONENTS,
    DEFAULT_OPENAI_GENERATE,
    SET_NUMBER_OPENAI_REQUESTS,
    RESET_CURRENT_COMPONENTS,
    SET_STEPS_OPENAI,
    SET_PROJECT_CACHED_AI,
    SET_COMPONENT_CACHED_AI,
} from '../actions/types';

const initialCellsCount = { total: 0, explored: 0, unexplored: 0, yellow: 0, red: 0, green: 0, totalIdeas: 0 };

const initialState = {
    isLoading: true,
    errors: null,
    folders: [],
    product: {},
    attributes: [],
    components: [],
    detailsMatrix: [],
    subtractions: [],
    multiplications: [],
    contradictions: [],
    contradictionsSolvings: [],
    replacements: [],
    eurekaIdeas: [],
    cellsCount: initialCellsCount,
    currentProjectName: '',
    openAI: {
        numberRequests: null,
        steps: '',
        words: '',
        components: {
            internal: [],
            external: [],
        },
    },
    projects: [],
    fullProduct: {
        isLoading: true,
        errors: null,
        product: {},
        attributes: [],
        components: [],
        detailsMatrix: [],
        subtractions: [],
        multiplications: [],
        replacements: [],
        eurekaIdeas: [],
        contradictions: [],
        contradictionsSolvings:[]
    },
    projectsLoading: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PROJECT_INFO:
            return { ...state, isLoading: true };
        case GET_PROJECT_INFO_SUCCESS:
            return {
                ...state,
                currentProjectName: action.payload.product.name,
                errors: null,
                isLoading: false,
                product: action.payload.product,
                attributes: action.payload.attributes,
                components: action.payload.components,
                detailsMatrix: action.payload.detailsMatrix,
                subtractions: action.payload.subtractions,
                multiplications: action.payload.multiplications,
                replacements: action.payload.replacements,
                eurekaIdeas: action.payload.eurekaIdeas,
                contradictions: action.payload.contradictions,
                contradictionsSolvings: action.payload.contradictionsSolvings,
                cellsCount: getCellsCount(action.payload),
            };
        case GET_PROJECT_INFO_ERROR:
            console.error(action.payload);
            return {
                ...state,
                errors: action.payload,
                isLoading: false,
                product: {},
                attributes: [],
                components: [],
                detailsMatrix: [],
                subtractions: [],
                multiplications: [],
                replacements: [],
                eurekaIdeas: [],
                contradictions: [],
                contradictionsSolvings: [],
                cellsCount: { ...initialCellsCount },
            };
        case RESET_PROJECT_INFO:
            return {
                ...state,
                errors: null,
                isLoading: false,
                product: {},
                attributes: [],
                components: [],
                detailsMatrix: [],
                subtractions: [],
                multiplications: [],
                replacements: [],
                eurekaIdeas: [],
                contradictions: [],
                contradictionsSolvings: [],
                cellsCount: { ...initialCellsCount },
            };
        case GET_PROJECTS:
            return { ...state, projectsLoading: true };
        case GET_PROJECTS_SUCCESS:
            const projects = action.payload.map((project) => ({ ...project }));
            return {
                ...state,
                projects,
                projectsLoading: false,
            };
        case GET_PROJECTS_ERROR:
            console.error(action.payload);
            return { ...state, errors: action.payload, projectsLoading: false };
        case RESET_PROJECTS_STORE:
            return { ...initialState };
        case GET_PROJECT_FOLDERS:
            return { ...state, folders: action.payload };
        case MOVE_PROJECT_TO_FOLDER:
            return {
                ...state,
                folders: state.folders.map((f) =>
                    f.id === action.payload.folderId
                        ? { ...f, projects: [...(f?.projects ?? []), action.payload.projectId] }
                        : f
                ),
            };
        case MOVE_PROJECT_OUT_OF_FOLDER:
            return {
                ...state,
                folders: state.folders.map((f) =>
                    f.id === action.payload.folderId
                        ? { ...f, projects: f?.projects?.filter((p) => p !== action.payload?.projectId) }
                        : f
                ),
            };
        case RENAME_PROJECT_FOLDER:
            return {
                ...state,
                folders: state.folders.map((f) =>
                    f.id === action.payload.folderId ? { ...f, name: action.payload?.newName } : f
                ),
            };
        case REMOVE_PROJECT_FOLDER:
            return {
                ...state,
                folders: state.folders.filter((f) => f.id !== action.payload.folderId),
            };
        case CREATE_PROJECT_FOLDER:
            return {
                ...state,
                folders: [...state.folders, action.payload],
            };
        case RESET_OPENAI_GENERATE_WORDS:
            return {
                ...state,
                openAI: { ...state.openAI, words: action.payload },
            };
        case RESET_CURRENT_COMPONENTS:
            return {
                ...state,
                components: action.payload,
            };
        case RESET_OPENAI_GENERATE_COMPONENTS:
            return {
                ...state,
                openAI: {
                    ...state.openAI,
                    components: action.payload,
                },
            };
        case DEFAULT_OPENAI_GENERATE:
            return {
                ...state,
                openAI: {
                    ...state.openAI,
                    words: '',
                    components: {
                        internal: [],
                        external: [],
                    },
                },
            };
        case SET_NUMBER_OPENAI_REQUESTS:
            return {
                ...state,
                openAI: {
                    ...state.openAI,
                    numberRequests: action.payload,
                },
            };
        case SET_STEPS_OPENAI:
            return {
                ...state,
                openAI: {
                    ...state.openAI,
                    steps: action.payload,
                },
            };
        case SET_PROJECT_CACHED_AI:
            return {
                ...state,
                product: {
                    ...state.product,
                    hasAIResponseCached: action.payload,
                },
            };
        case SET_COMPONENT_CACHED_AI:
            return {
                ...state,
                components: [
                    ...state.components.map((component) => {
                        if (action.payload.componentId === component.id) {
                            component.hasAIResponseCached = true;
                        }

                        return component;
                    }),
                ],
            };
        default:
            return state;
    }
}

export const getCellsCount = (payload) => {
    const enabledAttributes = payload.attributes.filter((attribute) => !attribute.disabled);
    const enabledComponents = payload.components.filter((component) => !component.disabled);
    const internalComponents = enabledComponents.filter((component) => component.internal && !component.locked);
    const externalComponents = enabledComponents.filter((component) => !component.internal && !component.locked);
    const internalAttributes = enabledAttributes.filter((attribute) => attribute.internal).length;
    const externalAttributes = enabledAttributes.filter((attribute) => !attribute.internal).length;

    const componentsCount = internalComponents.length + externalComponents.length;
    const internalAttributesCount = ((internalAttributes - 1) * internalAttributes) / 2;
    const externalAttributesCount = internalAttributes * externalAttributes;
    const total = internalAttributesCount + externalAttributesCount + componentsCount * 3;

    const distinct = (arr, total) => {
        const map = new Map();
        let green = 0,
            yellow = 0,
            red = 0,
            explored = 0;

        arr.forEach((entry) => {
            if (
                !entry.idRemoteComponent &&
                !entry.idMultiplicationComponent &&
                !map.has(entry.idAttributeCol + '|' + entry.idAttributeRow)
            ) {
                map.set(entry.idAttributeCol + '|' + entry.idAttributeRow, entry);
            }
            if (entry.idRemoteComponent && !entry.idReplacingComponent && !map.has(entry.idRemoteComponent)) {
                map.set(entry.idRemoteComponent, entry);
            }
            if (
                entry.idRemoteComponent &&
                entry.idReplacingComponent &&
                !map.has(entry.idRemoteComponent + '|' + entry.idReplacingComponent)
            ) {
                map.set(entry.idRemoteComponent + '|' + entry.idReplacingComponent, entry);
            }
            if (entry.idMultiplicationComponent && !map.has(entry.idMultiplicationComponent)) {
                map.set(entry.idMultiplicationComponent, entry);
            }
        });

        Array.from(map.values()).forEach((idea) => {
            if (idea.status === 3 || (idea.reversed && idea.reversed.status === 3)) {
                green += 1;
            } else if (idea.status === 1 || (idea.reversed && idea.reversed.status === 1)) {
                yellow += 1;
            } else if (idea.status === 2 || (idea.reversed && idea.reversed.status === 2)) {
                red += 1;
            }
            explored++;
        });

        return { green, yellow, red, explored, total, unexplored: total - explored };
    };

    const getIdeasByStatus = (detailsMatrix, subtractions, replacements, multiplications, contradictionsSolvings) => {
        const ideas = [];
        const statuses = [3, 1, 2];

        statuses.forEach((status) => {
            detailsMatrix
                .filter((idea) => idea.status === status || (idea.reversed && idea.reversed.status === status))
                .forEach((idea) => {
                    idea.status === status && ideas.push(idea);
                    if (idea.reversed && idea.reversed.status === status) {
                        ideas.push({ ...idea, isReversed: true });
                    }
                });
            subtractions
                .filter((idea) => idea.status === status)
                .forEach((idea) => {
                    idea.status === status && ideas.push(idea);
                });
            replacements
                .filter((idea) => idea.status === status)
                .forEach((idea) => {
                    idea.status === status && ideas.push(idea);
                });
            multiplications
                .filter((idea) => idea.status === status)
                .forEach((idea) => {
                    idea.status === status && ideas.push(idea);
                });
            contradictionsSolvings
                .filter((idea) => idea.status === status)
                .forEach((idea) => {
                    idea.status === status && ideas.push(idea);
                });
        });

        return ideas;
    };

    const enabledDetailsMatrix = payload.detailsMatrix.filter((details) => !details.disabled);
    const enabledSubtractions = payload.subtractions.filter((subtraction) => !subtraction.disabled);
    const enabledMultiplications = payload.multiplications.filter((multiplication) => !multiplication.disabled);
    const enabledReplacements = payload.replacements.filter((replacement) => !replacement.disabled);
    const enabledcontradictionsSolvings = payload.contradictionsSolvings.filter(
        (contradictionsIdea) => !contradictionsIdea.disabled
    );
    const sortedDetails = getIdeasByStatus(
        enabledDetailsMatrix,
        enabledSubtractions,
        enabledReplacements,
        enabledMultiplications,
        enabledcontradictionsSolvings
    );
    const distinctSortedDetails = distinct(sortedDetails, total);

    return distinctSortedDetails;
};
