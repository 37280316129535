import React from 'react';
import CustomInput from '../CustomInput/index';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus-icon-small.svg';
import './styles.css';

const CustomInputCategory = ({ onKeyDown, value, name, onChange, placeholder = '', onSubmit, autoFocus = false }) => {
    return (
        <div className="custom-input-category-container position-relative">
            <CustomInput
                value={value}
                name={name}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                autoFocus={autoFocus}
            />
            {onSubmit && <button className="category-button position-absolute" onClick={onSubmit}>
                <PlusIcon className="add-benefit-icon" />
            </button>}
        </div>
    );
};

export default CustomInputCategory;
