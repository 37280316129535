export function getInitials(userInfo) {
    if (typeof userInfo !== 'string' || userInfo.trim() === '') {
        return '- -';
    }

    const words = userInfo.replace(/\s+/g, ' ').split(' ');

    if (words.length <= 1) {
        return words[0].slice(0, 2).toUpperCase();
    } else {
        return (words[0][0] + words[1][0]).toUpperCase();
    }
}

export function getColorByInitials(userInfo, listOfInitialColors) {
    const initials = getInitials(userInfo);
    const lettersCodeFirst = initials.charCodeAt(0);
    const lettersCodeSecond = initials.charCodeAt(1) || 0;
    const index = (lettersCodeFirst + lettersCodeSecond) % 10;

    return listOfInitialColors[index];
}
