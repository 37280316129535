import React, { useEffect, useState } from 'react';
import Dialog from '../Dialog';
import CircularProgress from '../../common/CircularProgress';
import CloseIcon from '../../../assets/images/close-icon.svg';
import { ReactComponent as ImageAltIcon } from '../../../assets/images/image-alt.svg';
import { ReactComponent as AIGenerateIcon } from '../../../assets/images/ai-generate-icon.svg';
import './styles.css';

const GenerateImageModal = ({
    header,
    message,
    closeModalWindow,
    handleAcceptPotentialImage,
    imageData,
    suggestedLoading,
    isTestingMode = false,
}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 1700);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Dialog>
            <div className='flex-column'>
                <div className='flex generate-image-modal-header'>
                    <div className='flex justify-space-between'>
                        <AIGenerateIcon style={{ color: '#CC4B4F', height: 30, width: 30 }} />
                        <h2>{header}</h2>
                    </div>
                    {suggestedLoading ? (
                        <img src={CloseIcon} alt='' className='pointer' onClick={closeModalWindow} />
                    ) : (
                        <div className='generate-image-modal__buttons-container'>
                            {isTestingMode ? (
                                <img src={CloseIcon} alt='' className='pointer' onClick={closeModalWindow} />
                            ) : (
                                <>
                                    <button
                                        className={'generate-image-modal__button generate-image-modal__button_accept'}
                                        onClick={handleAcceptPotentialImage}
                                    >
                                        Accept
                                    </button>
                                    <button
                                        className={'generate-image-modal__button generate-image-modal__button_decline'}
                                        src={CloseIcon}
                                        alt=''
                                        onClick={closeModalWindow}
                                    >
                                        Decline
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>

                {suggestedLoading ? (
                    <div className='flex-center generate-image-modal__progress'>
                        <CircularProgress
                            r='20'
                            cx='50'
                            cy='50'
                            strokeWidth='5'
                            progress={progress}
                            classNameBody='generate-image-modal__body'
                        >
                            <ImageAltIcon style={{ color: '#9880ff', height: 17, width: 17 }} />
                        </CircularProgress>
                        <p className='generate-image-modal__text'>{message}</p>
                    </div>
                ) : (
                    <img src={imageData} className='generate-image-modal__img' alt='generated open ai' />
                )}
            </div>
        </Dialog>
    );
};

export default GenerateImageModal;
