import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '../Dialog';
import './styles.css';
import { connect } from 'react-redux';

import { shareIdeas } from '../../../api/sharedIdeas';

import CloseIcon from '../../../assets/images/close-icon.svg';
import Lightbulb from '../../../assets/images/lightbulb-icon.svg';

import CustomRadioButtons from '../../inputs/CustomRadioButtons';
import InvitationDropdown from '../../inputs/InvitationInput/InvitationDropdown/InvitationDropdown';
import UserOptionComponent, { FirstUserOption } from './UserOptionComponent';
import TeamOptionComponent, { FirstTeamOption } from './TeamOptionComponent';

import { getTranslation } from '../../../helpers/getLanguage';

const radioButtonsOptions = [
    { label: 'RADIO_BUTTONS_OPTIONS_SHARE_WITH_INDIVIDUAL', value: 'individual', backgroundColor: '#f7f8fa' },
    { label: 'RADIO_BUTTONS_OPTIONS_SHARE_WITH_TEAM', value: 'team', backgroundColor: '#f7f8fa' }
];

const initialDropdownOpen = {
    team: false,
    individual: false
};

const ShareIdeasModal = ({ closeDialog, inviteInfo, ideas, projectId, userId }) => {
    const [shareTarget, setShareTarget] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(initialDropdownOpen);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [users, setUsers] = useState([]);
    const [isPending, setIsPending] = useState(false);

    useEffect(() => {
        if (inviteInfo && Object.keys(inviteInfo).length) {
            const userOptions = [];
            const teamOptions = [];
            let teamIndex = 1;

            inviteInfo.teams.forEach(team => {
                let inCurrentTeam = false

                const participants = team.participants.map(participant => {
                    if (participant.id === userId) {
                        inCurrentTeam = true
                        teamIndex++
                    }

                    return {
                        value: participant.id,
                        label: participant.fullname,
                        disabled: false
                    }
                });

                userOptions.push(...participants);

                if (!inCurrentTeam) {
                    const teamInfo = {
                        value: team.id,
                        label: !team.isIndividual ? `Team ${teamIndex++}` : 'Individual',
                        labels: team.participants.map(participant => participant.fullname),
                        disabled: false
                    }
                    teamOptions.push(teamInfo)
                }
            });

            const owner = inviteInfo.owner;
            if (owner) {
                const isPresent = userOptions.find(option => option.value === owner.id);
                !isPresent && userOptions.push({ value: owner.id, label: owner.fullname });
            }

            setTeams(teamOptions);
            setUsers(userOptions.filter(option => option.value !== userId));
        }
    }, [inviteInfo]);

    const handleShareIdeas = () => {
        const params = {
            projectId,
            teams: selectedTeams,
            users: selectedUsers,
            ideas
        };

        const onSuccess = () => {
            setIsPending(false);
            closeDialog();
        };

        const onError = error => {
            console.error(error);
            setIsPending(false);
            closeDialog();
        };

        setIsPending(true);
        shareIdeas(params, onSuccess, onError);
    };

    const handleSetSelectedUsers = userId => {
        const isSelected = selectedUsers.includes(userId);
        isSelected
            ? setSelectedUsers(selectedUsers.filter(id => id !== userId))
            : setSelectedUsers([...selectedUsers, userId]);
    };

    const handleSetSelectedTeams = teamId => {
        const isSelected = selectedTeams.includes(teamId);
        isSelected
            ? setSelectedTeams(selectedTeams.filter(id => id !== teamId))
            : setSelectedTeams([...selectedTeams, teamId]);
    };

    const handleSetShareTarget = target => {
        const isSelected = shareTarget.includes(target);
        isSelected
            ? setShareTarget(shareTarget.filter(currentTarget => currentTarget !== target))
            : setShareTarget([...shareTarget, target]);
    };

    const shareButtonEnabled =
        (shareTarget.includes('individual') && selectedUsers.length > 0) ||
        (shareTarget.includes('team') && selectedTeams.length > 0);

    const handleSetDropdownOpen = useCallback((isOpen, name) => {
        setDropdownOpen(dropdownOpen => ({ ...dropdownOpen, [name]: isOpen }));
    }, []);

    return (
        <Dialog closeDialog={closeDialog} closeOnClickOutside={false}>
            <div className="share-ideas-wrapper">
                <div className="share-ideas-header flex">
                    <div className="flex align-center">
                        <span className="invitation-dialog-title">{inviteInfo.name}</span>
                    </div>
                    <img src={CloseIcon} draggable="false" alt="" className="pointer" onClick={() => closeDialog()} />
                </div>

                <div className="share-ideas-body flex-column align-center">
                    <div className="share-ideas-label mb-30 flex-center">
                        <img src={Lightbulb} alt="" />
                        <span>{`${getTranslation("SHARE_IDEAS_DIALOG_SHARING")} ${ideas.length} ${getTranslation("SHARE_IDEAS_DIALOG_IDEAS")}`}</span>
                    </div>

                    <div className="mb-30">
                        <CustomRadioButtons
                            options={radioButtonsOptions}
                            value={shareTarget}
                            setValue={handleSetShareTarget}
                            optionTextClass="share-ideas-radio-text"
                            optionHeight={120}
                        />
                    </div>

                    {shareTarget.includes('individual') && (
                        <div className="mb-20">
                            <InvitationDropdown
                                options={users}
                                name="individual"
                                isOpen={dropdownOpen.individual}
                                setIsOpen={handleSetDropdownOpen}
                                value={selectedUsers}
                                setValue={handleSetSelectedUsers}
                                placeholder={getTranslation("INVITATION_DROPDOWN_PLACEHOLDER_INDIVIDUAL")}
                                OptionComponent={UserOptionComponent}
                                FirstOptionComponent={FirstUserOption}
                                showSearch
                                searchPlaceholder={getTranslation("INVITATION_DROPDOWN_PLACEHOLDER_SEARCH_INDIVIDUAL")}
                            />
                        </div>
                    )}

                    {shareTarget.includes('team') && (
                        <InvitationDropdown
                            options={teams}
                            name="team"
                            isOpen={dropdownOpen.team}
                            setIsOpen={handleSetDropdownOpen}
                            value={selectedTeams}
                            setValue={handleSetSelectedTeams}
                            placeholder={getTranslation("INVITATION_DROPDOWN_PLACEHOLDER_TEAM")}
                            OptionComponent={TeamOptionComponent}
                            FirstOptionComponent={FirstTeamOption}
                            showSearch
                            searchPlaceholder={getTranslation("INVITATION_DROPDOWN_PLACEHOLDER_SEARCH_TEAM")}
                        />
                    )}

                    {shareButtonEnabled && (
                        <button
                            className="share-ideas-button dark-blue-btn flex-center"
                            onClick={() => handleShareIdeas()}
                            disabled={isPending}
                        >
                            <span>{getTranslation("SHARE_IDEAS_DIALOG_BUTTON")}</span>
                        </button>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    userId: state.auth.user.id,
});

export default connect(mapStateToProps)(ShareIdeasModal)
