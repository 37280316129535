import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import './styles.css';

import { getProjectInfoForReports } from '../../actions/projectActions';

import { getInviteUsers } from '../../api/invites';
import { getSharedIdeas as requestSharedIdeas } from '../../api/sharedIdeas';
import { generateReport } from '../../api/report';
import { saveBufferInNewTab } from '../../common/downloads';

import MessageShowNotFound from '../common/MessageShowNotFound';
import ReportHeader from './ReportHeader';
import ProjectTopBar from '../common/ProjectTopBar';
import ReportsTable from './ReportsTable';
import ReportsTabsHeader from './ReportsTabs/ReportsTabsHeaders';
import DependencyEditModal from '../dialogs/DependencyEditModal';
import SubtractionEditModal from '../dialogs/SubtractionEditModal';
import ReplacementEditModal from '../dialogs/ReplacementEditModal';
import ShareIdeasModal from '../dialogs/ShareIdeasModal';
import ConfirmationModal from '../dialogs/ConfirmationModal';
import { getMessagesByIssuesIds } from '../../api/detailsMatrix';

import Loader from '../common/Loader';

import { getTranslation } from '../../helpers/getLanguage';
import {
    getDetailsMatrixForReport,
    getSubtractionsForReport,
    getReplacementsForReport,
    getMultiplicationsForReport,
    getEurekaIdeasForReport,
} from '../utils/sortDetailsForReports';
import EurekaEditModal from '../dialogs/EurekaEditModal';
import MultiplicationEditModal from '../dialogs/MultiplicationEditModal';

const initialFiltersState = {
    filterBy: '',
    ideas: '',
    search: '',
};

const projectInitialState = {
    product: {},
    attributes: [],
    components: [],
    detailsMatrix: [],
    subtractions: [],
    multiplications: [],
    replacements: [],
    eurekaIdeas: [],
};

const lockedNames = {
    true: 'Internal Attributes',
    false: 'External Attributes',
};

const initialModalOpen = {
    dependency: false,
    subtraction: false,
    replacement: false,
    multiplication: false,
    eureka: false,
    share: false,
    confirmation: false,
};

const sharedRowsParser = (sharedRows) => {
    return sharedRows.map((details, index) => {
        if (details.idRemoteComponent && details.idReplacingComponent) {
            return {
                type: 'replacement',
                index,
                id: details.id,
                rating: details.rating,
                status: details.status,
                name: details.replacementName,
                benefits: details.potentialBenefitsOfReplacement || [],
                issues: details.issues || [],
                comments: details.comments,
                idRemoteComponent: details.idRemoteComponent,
                idReplacingComponent: details.idReplacingComponent,
                remoteComponent: details.remoteComponent,
                replacingComponent: details.replacingComponent,
                created: details.dateCreatedAt,
                createdBy: details.createdBy,
                disabled: details.disabled,
            };
        } else if (details.idRemoteComponent) {
            return {
                type: 'subtraction',
                index,
                id: details.id,
                rating: details.rating,
                status: details.status,
                name: details.subtractionName,
                benefits: details.potentialBenefitsOfSubtraction || [],
                issues: details.issues || [],
                comments: details.comments,
                idRemoteComponent: details.idRemoteComponent,
                remoteComponent: details.remoteComponent,
                created: details.dateCreatedAt,
                createdBy: details.createdBy,
                disabled: details.disabled,
            };
        } else if (details.idMultiplicationComponent) {
            return {
                type: 'multiplication',
                index,
                id: details.id,
                rating: details.rating,
                status: details.status,
                name: details.multiplicationName,
                benefits: details.potentialBenefitsOfMultiplication || [],
                issues: details.issues || [],
                comments: details.comments,
                idMultiplicationComponent: details.idMultiplicationComponent,
                multiplicationComponent: details.multiplicationComponent,
                multiplicationCount: details.multiplicationCount,
                linkedAttribute: details.linkedAttribute,
                created: details.dateCreatedAt,
                createdBy: details.createdBy,
                disabled: details.disabled,
            };
        } else if (details.description) {
            return {
                type: 'eureka',
                index,
                id: details.id,
                rating: details.rating,
                status: details.status,
                name: details.name,
                benefits: details.potentialBenefitsOfSubtraction || [],
                issues: details.issues || [],
                description: details.description,
                created: details.dateCreatedAt,
                createdBy: details.createdBy,
                disabled: details.disabled,
            };
        }

        return {
            type: 'dependency',
            index,
            id: details.id,
            rating: details.rating,
            status: details.status,
            name: details.dependencyName,
            benefits: details.potentialBenefitsOfDependency || [],
            issues: details.issues || [],
            comments: details.comments,
            firstComponent: details.componentRow,
            secondComponent: details.componentCol,
            firstAttribute: details.attributeRow,
            secondAttribute: details.attributeCol,
            rowStatus: details.rowStatus,
            colStatus: details.colStatus,
            created: details.dateCreatedAt,
            createdBy: details.createdBy,
            disabled: details.disabled,
            isReversed: details.isReversed,
        };
    });
};

const Report = ({
    isLoading,
    detailsMatrix,
    subtractions,
    multiplications,
    replacements,
    eurekaIdeas,
    components,
    attributes,
    product,
    errors,
    auth,
    match,
    location,
    history,
    getProjectInfoForReports
}) => {
    const {
        user,
        user: { name, lastName, id: userId },
        userInfo: { language: currentLanguage },
    } = auth;
    const userFullName = `${user.name} ${user.lastName}`;
    const projectId = match.params.projectId;
    const isPrivate = !(product.isShared || (product.teamId || '').length > 0);
    const [filters, setFilters] = useState(initialFiltersState);
    const [sort, setSort] = useState({ field: 'rating', asc: false });
    const [modalOpen, setModalOpen] = useState(initialModalOpen);
    const [selectedIdeas, setSelectedIdeas] = useState([]);
    const [selectedIdeasAll, setSelectedIdeasAll] = useState(false);
    const [initialRows, setInitialRows] = useState([]);
    const [reportRow, setReportRow] = useState([]);
    const [projectData, setProjectData] = useState(projectInitialState);
    const [activeTab, setActiveTab] = useState(0);
    const [expandedIdeas, setExpandedIdeas] = useState([]);
    const [inviteInfo, setInviteInfo] = useState({});
    const [currentIdea, setCurrentIdea] = useState({ id: '', isReversed: false });
    const [ideasToShare, setIdeasToShare] = useState([]);
    const [initialSharedRows, setInitialSharedRows] = useState([]);
    const [sharedRows, setSharedRows] = useState([]);
    const [initialMyIdeasRows, setInitialMyIdeasRows] = useState([]);
    const [myIdeasRows, setMyIdeasRows] = useState([]);
    const ideasCount = initialRows.length;
    const ideasCountOther = initialSharedRows.length;
    const ideasCountMyIdeas = initialMyIdeasRows.length;
    const [dependency, setDependency] = useState({});
    const [subtraction, setSubtraction] = useState({});
    const [multiplication, setMultiplication] = useState({});
    const [replacement, setReplacement] = useState({});
    const [eureka, setEureka] = useState({});
    const [isPending, setIsPending] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        let componentExists = true;
        let issuesIds = [];

        detailsMatrix.forEach((details) => {
            if (details.reversed) {
                if (details.reversed.issues) details.reversed.issues.forEach((issue) => issuesIds.push(issue.id));
            }

            if (details.issues) details.issues.forEach((issue) => issuesIds.push(issue.id));
        });

        subtractions.forEach((subtraction) =>
            subtraction.issues ? subtraction.issues.forEach((issue) => issuesIds.push(issue.id)) : []
        );

        replacements.forEach((replacement) =>
            replacement.issues ? replacement.issues.forEach((issue) => issuesIds.push(issue.id)) : []
        );

        multiplications.forEach((multiplication) =>
            multiplication.issues ? multiplication.issues.forEach((issue) => issuesIds.push(issue.id)) : []
        );

        eurekaIdeas.forEach((replacement) =>
            replacement.issues ? replacement.issues.forEach((issue) => issuesIds.push(issue.id)) : []
        );

        const params = { issues: [...issuesIds] };

        const onSuccess = (response) => {
            if (componentExists) setMessages(response);
        };

        const onError = (error) => {
            console.error(error);
        };

        getMessagesByIssuesIds(params, onSuccess, onError);

        return () => (componentExists = false);
    }, [detailsMatrix, subtractions, replacements, multiplications, eurekaIdeas]);

    const getUsersData = useCallback(() => {
        const onSuccess = (response) => setInviteInfo(response);
        const onError = (error) => {
            console.error(error);
            setInviteInfo({});
        };

        const params = { projectId, isSharing: true };
        getInviteUsers({ params }, onSuccess, onError);
    }, [projectId]);

    const getSharedIdeas = useCallback(() => {
        const onSuccess = (response) => {
            const sortedDetails = getSortedReportRows(sharedRowsParser(response));
            setInitialSharedRows(sortedDetails);
            setSharedRows(sortedDetails);
        };

        const onError = (error) => {
            setInitialSharedRows([]);
            setSharedRows([]);
            console.error(error);
        };

        const params = { projectId };
        requestSharedIdeas({ params }, onSuccess, onError);
    }, [projectId]);

    const handleExpandAll = useCallback((rowsCount, value = false) => {
        setExpandedIdeas(new Array(rowsCount).fill(value));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    useEffect(() => {
        getProjectInfoForReports(projectId);
        setActiveTab(0);
    }, [projectId, getProjectInfoForReports]);

    useEffect(() => {
        let componentExists = true;
        if (!isPrivate) {
            const onSuccessSharedRows = (response) => {
                const newSharedRows = sharedRowsParser(response);
                const sortedDetails = getSortedReportRows(newSharedRows);

                componentExists && setInitialSharedRows(sortedDetails);
                componentExists && setSharedRows(sortedDetails);
            };

            const onErrorSharedRows = (error) => {
                setInitialSharedRows([]);
                setSharedRows([]);
                console.error(error);
            };

            const paramsSharedRows = { projectId };

            requestSharedIdeas({ params: paramsSharedRows }, onSuccessSharedRows, onErrorSharedRows);

            const onSuccessInviteUsers = (response) => componentExists && setInviteInfo(response);
            const onErrorInviteUsers = (error) => {
                console.error(error);
                componentExists && setInviteInfo({});
            };

            const paramsInviteUsers = { projectId, isSharing: true };
            getInviteUsers({ params: paramsInviteUsers }, onSuccessInviteUsers, onErrorInviteUsers);
        }

        return () => (componentExists = false);
    }, [isPrivate, getUsersData, getSharedIdeas]);

    useEffect(() => {
        setProjectData((projectData) => ({
            ...projectData,
            product: product,
            attributes: attributes,
            components: components,
            detailsMatrix: detailsMatrix,
            subtractions: subtractions,
            multiplications: multiplications,
            replacements: replacements,
            eurekaIdeas: eurekaIdeas,
        }));
    }, [subtractions, detailsMatrix, replacements, multiplications, components, attributes, product, eurekaIdeas]);

    useEffect(() => {
        const paramsString = location.search.split('?').filter((param) => param);
        const params = paramsString[0] && paramsString[0].split('&');

        if (params) {
            for (let param of params) {
                const [name, value] = param.split('=');
                name === 'id' && setCurrentIdea((state) => ({ ...state, id: value }));
                name === 'isReversed' &&
                    value === 'true' &&
                    setCurrentIdea((state) => ({ ...state, isReversed: true }));
            }
        } else {
            setCurrentIdea({ id: '', isReversed: false });
        }
    }, [location.search]);

    const closeModals = () => {
        getProjectInfoForReports(projectId);
        setMessages([]);
        setModalOpen({ ...initialModalOpen });
    };

    useEffect(() => {
        if (
            ((projectData.detailsMatrix.length ||
                projectData.subtractions.length ||
                projectData.replacements.length ||
                projectData.multiplications.length) &&
                (projectData.components.length || projectData.attributes.length)) ||
            projectData.eurekaIdeas.length
        ) {
            const [index, currentDetails] = getDetailsMatrixForReport(projectData.detailsMatrix, projectData, messages);
            const [indexForReplacement, currentSubtractions] = getSubtractionsForReport(
                projectData.subtractions,
                projectData,
                index,
                messages
            );
            const [indexForMultiplicationIdeas, currentReplacements] = getReplacementsForReport(
                projectData.replacements,
                projectData,
                indexForReplacement,
                messages
            );
            const [indexForEurekaIdeas, currentMultiplications] = getMultiplicationsForReport(
                projectData.multiplications,
                projectData,
                indexForMultiplicationIdeas,
                messages
            );

            const currentEurekaIdeas = getEurekaIdeasForReport(
                projectData.eurekaIdeas,
                projectData,
                indexForEurekaIdeas,
                messages
            );

            const allIdeas = [
                ...currentDetails,
                ...currentSubtractions,
                ...currentReplacements,
                ...currentMultiplications,
                ...currentEurekaIdeas,
            ];

            const sortedCurrentDetails = getSortedReportRows(allIdeas);
            setInitialRows(sortedCurrentDetails);

            sortedCurrentDetails.forEach((report, index) => {
                report.index = index;
            });

            setReportRow(sortedCurrentDetails);

            const sortedRows = _.cloneDeep(sortedCurrentDetails);
            const filteredRows = sortedRows.filter((el) => el.createdBy === userFullName);
            filteredRows.forEach((row, index) => (row.index = index));
            setInitialMyIdeasRows(filteredRows);
            setMyIdeasRows(filteredRows);

            selectAllRows(currentDetails.length, false);
            handleExpandAll(currentDetails.length, false);
            setCurrentlyExpandedIdea(currentDetails);
        } else {
            setInitialRows([]);
            setReportRow([]);
            setInitialMyIdeasRows([]);
            setMyIdeasRows([]);
            setExpandedIdeas([]);
        }
    }, [projectData, handleExpandAll, messages]);

    useEffect(() => {
        filterReports(activeTab);
    }, [filters, sort, activeTab]);

    const getCurrentRows = (currentTab = activeTab) => {
        if (currentTab === 0) return initialRows;
        else if (currentTab === 1) return initialMyIdeasRows;
        else return initialSharedRows;
    };

    const selectAllRows = (rowsCount, value) => {
        setSelectedIdeas(new Array(rowsCount).fill(value));
    };

    const handleSelectAllRows = (value) => {
        selectAllRows(getCurrentRows().length, value);
        setSelectedIdeasAll(value);
    };

    const getSortedReportRows = (details) => {
        const sortingDetails = [...details];

        const green = sortingDetails.filter((row) => row.status === 3).sort((a, b) => b.rating - a.rating);
        const yellow = sortingDetails.filter((row) => row.status === 1);
        const red = sortingDetails.filter((row) => row.status === 2);

        return [...green, ...yellow, ...red];
    };

    const filterReports = (currentTab) => {
        const currentInitialRows = getCurrentRows(currentTab);
        let filteredReports = currentInitialRows.slice();

        const filterByName = (element) => {
            const { firstComponent, firstAttribute, secondComponent, secondAttribute, name } = element;
            const stringToSearch = `${firstComponent}/${firstAttribute}/${secondComponent}/${secondAttribute}/${name}`;
            return stringToSearch.toLowerCase().includes(filters.search.toLowerCase());
        };

        filters.filterBy &&
            (filteredReports = filteredReports.filter(
                (report) => report.benefits.filter((benefit) => benefit.target.includes(filters.filterBy)).length
            ));
        filters.ideas &&
            (filteredReports = filteredReports.filter((report) => report.status === Number(filters.ideas)));
        filters.search.trim() && (filteredReports = filteredReports.filter((report) => filterByName(report)));

        const sortedReport = sortReports(filteredReports);

        sortedReport.forEach((report, index) => {
            report.index = index;
        });

        if (currentTab === 0) setReportRow(sortedReport);
        else if (currentTab === 1) setMyIdeasRows(sortedReport);
        else setSharedRows(sortedReport);

        selectAllRows(currentInitialRows.length, false);
        handleExpandAll(currentInitialRows.length, false);
        setSelectedIdeasAll(false);
    };

    const sortReports = (reports) => {
        let sortedReport = [...reports];

        switch (sort.field) {
            case 'rating':
                const green = sortedReport.filter((row) => row.status === 3).sort((a, b) => b.rating - a.rating);
                const yellow = sortedReport.filter((row) => row.status === 1);
                const red = sortedReport.filter((row) => row.status === 2);

                sortedReport = [...green, ...yellow, ...red];
                sort.asc && sortedReport.reverse();
                return sortedReport;
            case 'component':
                sortedReport.sort((a, b) =>
                    a?.remoteComponent?.toLowerCase() < b?.remoteComponent?.toLowerCase() ? 1 : -1
                );
                sort.asc && sortedReport.reverse();
                sortedReport = [
                    ...sortedReport.filter((report) => report?.remoteComponent),
                    ...sortedReport.filter((report) => !report?.remoteComponent),
                ];
                return sortedReport;
            case 'firstAttribute':
                sortedReport.sort((a, b) =>
                    (a.type === 'dependency' && b.type === 'dependency'
                        ? a.firstAttribute + a.firstComponent
                        : ''
                    ).toLowerCase() <
                    (a.type === 'dependency' && b.type === 'dependency'
                        ? b.firstAttribute + b.firstComponent
                        : ''
                    ).toLowerCase()
                        ? 1
                        : -1
                );
                sort.asc && sortedReport.reverse();
                sortedReport = [
                    ...sortedReport.filter((report) => report.firstAttribute),
                    ...sortedReport.filter((report) => !report.firstAttribute),
                ];
                return sortedReport;
            case 'secondAttribute':
                sortedReport.sort((a, b) =>
                    (a.type === 'dependency' && b.type === 'dependency'
                        ? a.secondAttribute + a.secondComponent
                        : ''
                    ).toLowerCase() <
                    (a.type === 'dependency' && b.type === 'dependency'
                        ? b.secondAttribute + b.secondComponent
                        : ''
                    ).toLowerCase()
                        ? 1
                        : -1
                );
                sort.asc && sortedReport.reverse();
                sortedReport = [
                    ...sortedReport.filter((report) => report.secondAttribute),
                    ...sortedReport.filter((report) => !report.secondAttribute),
                ];
                return sortedReport;
            case 'name':
                sortedReport.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1));
                sort.asc && sortedReport.reverse();
                sortedReport = [
                    ...sortedReport.filter((report) => report.name),
                    ...sortedReport.filter((report) => !report.name),
                ];
                return sortedReport;
            case 'created':
                sortedReport.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
                sort.asc && sortedReport.reverse();
                return sortedReport;
            case 'method':
                sortedReport.sort((a, b) => (a.idRemoteComponent && !b.idRemoteComponent ? -1 : 1));
                sort.asc && sortedReport.reverse();
                return sortedReport;
            case 'benefits':
                sortedReport.sort((a, b) =>
                    a.benefits.filter((benefit) => benefit.target.length > 0).length >
                    b.benefits.filter((benefit) => benefit.target.length > 0).length
                        ? -1
                        : 1
                );
                sort.asc && sortedReport.reverse();
                sortedReport = [
                    ...sortedReport.filter(
                        (report) => report.benefits.filter((benefit) => benefit.target.length > 0).length
                    ),
                    ...sortedReport.filter(
                        (report) => !report.benefits.filter((benefit) => benefit.target.length > 0).length
                    ),
                ];
                return sortedReport;
            default:
                console.warn("Couldn't sort field ", sort.field);
                break;
        }
    };

    const handleSelectIdea = (index) => {
        const selection = selectedIdeas.slice();
        selection[index] = !selection[index];
        !selection[index] && setSelectedIdeasAll(false);
        setSelectedIdeas(selection);
    };

    const handleExpandIdea = (index, value = null) => {
        const selection = expandedIdeas.slice();
        selection[index] = value;
        setExpandedIdeas(() => selection);
    };

    const handleSortChange = (field) => {
        field === sort.field ? setSort({ field, asc: !sort.asc }) : setSort({ field, asc: true });
    };

    const handleOpenDetails = (details) => {
        let selectedRow, selComponentRow, selectedCol, selComponentCol, selDetailsMatrix;
        let edit = false;

        const rowId = details.firstAttributeId;
        const foundAttribute = projectData.attributes.find((attribute) => attribute.id === rowId);
        const columnId = details.secondAttributeId;
        if (rowId) {
            selectedRow = { id: rowId, name: details.firstAttribute, internal: foundAttribute.internal };
            const selectedDetails = projectData.detailsMatrix.find(
                (detail) =>
                    (detail.idAttributeCol === columnId && detail.idAttributeRow === rowId) ||
                    (detail.idAttributeCol === rowId && detail.idAttributeRow === columnId)
            );
            if (selectedDetails) {
                selDetailsMatrix = selectedDetails;
                edit = true;
            }
        }

        selectedCol = { id: columnId, name: details.secondAttribute };
        const rowName = details.rowStatus.locked ? lockedNames[details.rowStatus.internal] : '';
        const colName = details.colStatus.locked ? lockedNames[details.colStatus.internal] : '';

        selComponentRow = rowName || details.firstComponent;
        selComponentCol = colName || details.secondComponent;

        const isReversed = details.isReversed;

        const newDependency = {
            selectedRow: isReversed ? selectedCol : selectedRow,
            selComponentRow: isReversed ? selComponentCol : selComponentRow,
            selectedCol: isReversed ? selectedRow : selectedCol,
            selComponentCol: isReversed ? selComponentRow : selComponentCol,
            selDetailsMatrix,
            isReversed: details.isReversed,
            edit,
        };

        setSelectedIdeasAll(false);
        setDependency(newDependency);
        setModalOpen({ ...modalOpen, dependency: true });
    };

    const handleOpenSubtractionsDetails = (subtraction) => {
        let selectedSubtraction;
        let edit = false;

        const { idRemoteComponent } = subtraction;
        const selRemoteComponent = projectData.components.find((component) => component.id === idRemoteComponent);

        if (selRemoteComponent) {
            const selSubtraction = projectData.subtractions.find(
                (details) => details.idRemoteComponent === idRemoteComponent && details.id === subtraction.id
            );

            if (selSubtraction) {
                selectedSubtraction = selSubtraction;
                edit = true;
            }
        }

        const newSubtraction = {
            edit,
            selRemoteComponent,
            selectedSubtraction,
        };

        setSelectedIdeasAll(false);
        setSubtraction(newSubtraction);
        setModalOpen({ ...modalOpen, subtraction: true });
    };

    const handleOpenMultiplicationsDetails = (multiplication) => {
        let selectedMultiplication;
        let edit = false;

        const { idMultiplicationComponent, linkedAttribute } = multiplication;
        const selMultiplicationComponent = projectData.components.find(
            (component) => component.id === idMultiplicationComponent
        );
        const selMultiplicationAttribute = projectData.attributes.find((attribute) => attribute.id === linkedAttribute);

        if (selMultiplicationComponent) {
            const selSubtraction = projectData.multiplications.find(
                (details) =>
                    details.idMultiplicationComponent === idMultiplicationComponent && details.id === multiplication.id
            );

            if (selSubtraction) {
                selectedMultiplication = selSubtraction;
                edit = true;
            }
        }

        const newMultiplication = {
            edit,
            selMultiplicationComponent,
            selMultiplicationAttribute,
            selectedMultiplication,
        };

        setSelectedIdeasAll(false);
        setMultiplication(newMultiplication);
        setModalOpen({ ...modalOpen, multiplication: true });
    };

    const handleOpenReplacementsDetails = (replacement) => {
        let selectedReplacement;
        let edit = false;

        const { idRemoteComponent, idReplacingComponent } = replacement;
        const selRemoteComponent = projectData.components.find((component) => component.id === idRemoteComponent);
        const selReplacingComponent = projectData.components.find((component) => component.id === idReplacingComponent);

        if (selRemoteComponent && selReplacingComponent) {
            const selReplacement = projectData.replacements.find(
                (details) =>
                    details.idRemoteComponent === idRemoteComponent &&
                    details.id === replacement.id &&
                    details.idReplacingComponent === idReplacingComponent
            );

            if (selReplacement) {
                selectedReplacement = selReplacement;
                edit = true;
            }
        }

        const newReplacement = {
            edit,
            selRemoteComponent,
            selReplacingComponent,
            selectedReplacement,
        };

        setSelectedIdeasAll(false);
        setReplacement(newReplacement);
        setModalOpen({ ...modalOpen, replacement: true });
    };

    const handleOpenEurekaDetails = (eureka) => {
        let selectedEureka;
        let edit = false;

        const selSubtraction = projectData.eurekaIdeas.find((details) => details.id === eureka.id);

        if (selSubtraction) {
            selectedEureka = selSubtraction;
            edit = true;
        }

        const newEureka = {
            edit,
            selectedEureka: selectedEureka,
        };

        setSelectedIdeasAll(false);
        setEureka(newEureka);
        setModalOpen({ ...modalOpen, eureka: true });
    };

    const handleRedirectToMatrix = () => {
        history.push(`/project/${projectId}/matrix`);
    };

    const handleRedirectToSubtraction = () => {
        history.push(`/project/${projectId}/subtraction`);
    };

    const handleRedirectToMultiplication = () => {
        history.push(`/project/${projectId}/multiplication`);
    };

    const handleRedirectToReplacement = () => {
        history.push(`/project/${projectId}/replacement`);
    };

    const handleRedirectToEureka = () => {
        history.push(`/project/${projectId}/eureka`);
    };

    const getSelectedIdeas = (rows, selection) => {
        const selectedRowsAll = rows.filter((row) => selection[row.index]);
        const selectedRowsFiltered = [];
        const currentRows = getCurrentRows();

        selectedRowsAll.forEach((currentRow) => {
            const foundRow = currentRows.find((row) => row.index === currentRow.index);

            if (foundRow?.type === 'replacement') {
                selectedRowsFiltered.push({
                    ...replacements.find((replacementIdea) => replacementIdea.id === foundRow.id),
                    ...foundRow,
                });
            }
            if (foundRow?.type === 'subtraction') {
                selectedRowsFiltered.push({
                    ...subtractions.find((subtractionIdea) => subtractionIdea.id === foundRow.id),
                    ...foundRow,
                });
            }
            if (foundRow?.type === 'multiplication') {
                selectedRowsFiltered.push({
                    ...multiplications.find((multiplicationIdea) => multiplicationIdea.id === foundRow.id),
                    ...foundRow,
                });
            }
            if (foundRow?.type === 'dependency') {
                selectedRowsFiltered.push({
                    ...detailsMatrix.find((detailsMatrixIdea) => detailsMatrixIdea.id === foundRow.id),
                    ...foundRow,
                });
            }
            if (foundRow?.type === 'eureka') {
                selectedRowsFiltered.push({
                    ...eurekaIdeas.find((eureka) => eureka.id === foundRow.id),
                    ...foundRow,
                });
            }
        });

        return selectedRowsFiltered;
    };

    const handlePrint = () => {
        if (!isPending) {
            const currentRows = getCurrentRows();
            const ideasToPrint = getSelectedIdeas(currentRows, selectedIdeas);
            if (!ideasToPrint.length) {
                setConfirmationMessage(getTranslation('CONFIRMATION_MESSAGE_TO_PRINT'));
                setModalOpen({ ...modalOpen, confirmation: true });

                return;
            }

            const ideasIds = ideasToPrint.map((idea) => {
                const remoteComponent = components.find((component) => component.id === idea?.idRemoteComponent);
                const replacingComponent = components.find((component) => component.id === idea?.idReplacingComponent);
                const multiplicationComponent = components.find(
                    (component) => component.id === idea?.idMultiplicationComponent
                );
                const attributeCol = attributes.find((attribute) => attribute.id === idea?.idAttributeCol);
                const attributeRow = attributes.find((attribute) => attribute.id === idea?.idAttributeRow);
                const firstComponent = components.find((component) => component.id === attributeCol?.componentId);
                const secondComponent = components.find((component) => component.id === attributeRow?.componentId);

                const issuesWithMessages = idea?.issues.map((issue) => {
                    return {
                        id: issue.id,
                        userInfo: issue.createdByUser ? issue.createdByUser : `${issue.userInfo.name} ${issue.userInfo.lastName}`,
                        comment: issue.comment,
                        description: issue.description,
                        ideaId: issue.ideaId,
                        messages: issue?.messages?.map((messages) => {
                            return {
                                id: messages.id,
                                userInfo: messages.userInfo,
                                message: messages.message,
                                issueId: messages.issueId,
                            };
                        }),
                    };
                });

                return {
                    index: idea.index,
                    id: idea.id,
                    isReversed: idea.isReversed,
                    remoteComponent: {
                        id: remoteComponent?.id,
                        name: remoteComponent?.name,
                        locked: remoteComponent?.locked,
                    },
                    replacingComponent: {
                        id: replacingComponent?.id,
                        name: replacingComponent?.name,
                        locked: replacingComponent?.locked,
                    },
                    multiplicationComponent: {
                        id: multiplicationComponent?.id,
                        name: multiplicationComponent?.name,
                        locked: multiplicationComponent?.locked,
                    },
                    firstAttribute: { id: attributeCol?.id, name: attributeCol?.name },
                    secondAttribute: { id: attributeRow?.id, name: attributeRow?.name },
                    firstComponent: {
                        id: firstComponent?.id,
                        name: firstComponent?.name,
                        locked: firstComponent?.locked,
                    },
                    secondComponent: {
                        id: secondComponent?.id,
                        name: secondComponent?.name,
                        locked: secondComponent?.locked,
                    },
                    issuesWithMessages,
                };
            });

            const params = {
                ideas: ideasIds,
                projectId: projectId,
                teamId: product.teamId,
                language: auth.userInfo.language,
                isSharedIdeas: activeTab === 2,
            };

            const onSuccess = (data) => {
                setIsPending(false);
                saveBufferInNewTab(data, 'pdf');
            };

            const onError = (error) => {
                console.error(error);
                setIsPending(false);
            };

            setIsPending(true);
            generateReport(params, onSuccess, onError);
        }
    };

    const handleSetActiveTab = (index) => {
        if (activeTab !== index) {
            const currentRows = getCurrentRows(index);
            handleSelectAllRows(false);
            handleExpandAll(currentRows.length, false);

            setActiveTab(index);
        }
    };

    const setCurrentlyExpandedIdea = (rows) => {
        if (currentIdea.id && rows.length) {
            const foundRow = rows.find((row) => row.id === currentIdea.id && row.isReversed === currentIdea.isReversed);
            if (foundRow) {
                foundRow.userId !== user.id && setActiveTab(1);
                handleExpandIdea(foundRow.index, true);
            }
        }
    };

    const handleSharing = () => {
        const dataRows = activeTab === 0 ? initialRows : initialMyIdeasRows;
        const ideasToShare = getSelectedIdeas(dataRows, selectedIdeas);
        if (ideasToShare.length) {
            const sharedIdeas = ideasToShare.map((idea) => {
                if (idea.idRemoteComponent && !idea.idReplacingComponent) {
                    return {
                        id: idea.id,
                        ideaMethod: 'S',
                    };
                } else if (idea.idRemoteComponent && idea.idReplacingComponent) {
                    return {
                        id: idea.id,
                        ideaMethod: 'R',
                    };
                } else if (!idea.idRemoteComponent && !idea.idReplacingComponent && idea.idAttributeCol) {
                    return {
                        id: idea.id,
                        isReversed: idea.isReversed,
                        ideaMethod: 'AD',
                    };
                } else if (idea.idMultiplicationComponent) {
                    return {
                        id: idea.id,
                        ideaMethod: 'M',
                    };
                } else {
                    return {
                        id: idea.id,
                        ideaMethod: 'EU',
                    };
                }
            });
            setIdeasToShare(sharedIdeas);
            setModalOpen({ ...modalOpen, share: true });
        } else {
            setConfirmationMessage(getTranslation('CONFIRMATION_MESSAGE_TO_SHARE'));
            setModalOpen({ ...modalOpen, confirmation: true });
        }
    };

    const closeShareModal = () => {
        setModalOpen({ ...modalOpen, share: false });
    };

    const canPrintReport = activeTab < 2 ? reportRow.length > 0 : sharedRows.length > 0;
    const canShareIdeas = activeTab < 2 && !isPrivate && reportRow.length > 0;

    const showNotFoundError = errors && errors.response && errors.response.status === 404;

    const getRows = () => {
        if (activeTab === 0) return reportRow;
        else if (activeTab === 1) return myIdeasRows;
        else return sharedRows;
    };

    return (
        <div>
            <ProjectTopBar match={match} history={history} currentProjectName={projectData.product.name} />

            <div className='report-page-wrapper'>
                {isLoading && <Loader />}

                {!isLoading && !showNotFoundError && (
                    <>
                        <ReportHeader
                            filters={filters}
                            handleChange={handleChange}
                            setFilters={setFilters}
                            handlePrint={handlePrint}
                            handleSharing={handleSharing}
                            showPrintButton={canPrintReport}
                            showShareButton={canShareIdeas}
                        />
                        <ReportsTabsHeader
                            showOthersIdeas={!isPrivate}
                            activeTab={activeTab}
                            setActiveTab={handleSetActiveTab}
                            userIdeas={ideasCount}
                            otherIdeas={ideasCountOther}
                            myIdeas={ideasCountMyIdeas}
                        />
                        <ReportsTable
                            currentLanguage={currentLanguage}
                            rows={getRows()}
                            attributes={attributes}
                            components={components}
                            canEdit={activeTab < 2}
                            handleSelectIdea={handleSelectIdea}
                            handleExpandIdea={handleExpandIdea}
                            selected={selectedIdeas}
                            expanded={expandedIdeas}
                            sort={sort}
                            handleSortChange={handleSortChange}
                            handleOpenDetails={handleOpenDetails}
                            handleOpenSubtractionsDetails={handleOpenSubtractionsDetails}
                            handleOpenReplacementsDetails={handleOpenReplacementsDetails}
                            handleOpenMultiplicationsDetails={handleOpenMultiplicationsDetails}
                            handleRedirectToMatrix={handleRedirectToMatrix}
                            handleRedirectToSubtraction={handleRedirectToSubtraction}
                            handleRedirectToReplacement={handleRedirectToReplacement}
                            handleRedirectToMultiplication={handleRedirectToMultiplication}
                            handleRedirectToEureka={handleRedirectToEureka}
                            handleOpenEurekaDetails={handleOpenEurekaDetails}
                            handleSelectAllRows={handleSelectAllRows}
                            selectedIdeasAll={selectedIdeasAll}
                            currentIdea={currentIdea}
                        />
                    </>
                )}

                {modalOpen.share && (
                    <ShareIdeasModal
                        closeDialog={closeShareModal}
                        inviteInfo={inviteInfo}
                        ideas={ideasToShare}
                        projectId={projectId}
                    />
                )}

                {modalOpen.confirmation && (
                    <ConfirmationModal
                        closeDialog={() => setModalOpen({ ...initialModalOpen })}
                        buttonText={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                        message={confirmationMessage}
                        onConfirm={() => setModalOpen({ ...initialModalOpen })}
                    />
                )}
            </div>

            {modalOpen.dependency && (
                <DependencyEditModal
                    closeDialog={closeModals}
                    userFullName={{ name, lastName }}
                    userId={userId}
                    edit={dependency.edit}
                    rowAttribute={dependency.selectedRow}
                    selectedComponentRow={dependency.selComponentRow}
                    colAttribute={dependency.selectedCol}
                    selectedComponentCol={dependency.selComponentCol}
                    productId={projectId}
                    user={dependency.user}
                    detailsMatrix={dependency.selDetailsMatrix}
                    teamId={product.teamId}
                    isReversed={dependency.isReversed}
                    categories={product.customCategories}
                    productType={product.type}
                    productName={product.typeName}
                    projectName={product.name}
                />
            )}

            {modalOpen.subtraction && (
                <SubtractionEditModal
                    closeDialog={closeModals}
                    userFullName={{ name, lastName }}
                    userId={userId}
                    project={projectData}
                    edit={subtraction.edit}
                    mainComponent={subtraction.selMainComponent}
                    remoteComponent={subtraction.selRemoteComponent}
                    productId={projectId}
                    subtractionsMatrix={subtraction.selectedSubtraction}
                    teamId={product.teamId}
                    categories={product.customCategories}
                    productType={product.type}
                    productName={product.typeName}
                />
            )}

            {modalOpen.multiplication && (
                <MultiplicationEditModal
                    closeDialog={closeModals}
                    userFullName={{ name, lastName }}
                    userId={userId}
                    project={projectData}
                    edit={multiplication.edit}
                    multipliedComponent={multiplication.selMultiplicationComponent}
                    activeAttribute={multiplication.selMultiplicationAttribute}
                    multiplicationCount={multiplication.selectedMultiplication.multiplicationCount}
                    productId={projectId}
                    multiplicationsMatrix={multiplication.selectedMultiplication}
                    teamId={product.teamId}
                    categories={product.customCategories}
                    productType={product.type}
                    productName={product.typeName}
                />
            )}

            {modalOpen.replacement && (
                <ReplacementEditModal
                    closeDialog={closeModals}
                    userFullName={{ name, lastName }}
                    userId={userId}
                    edit={replacement.edit}
                    remoteComponent={replacement.selRemoteComponent}
                    replacingComponent={replacement.selReplacingComponent}
                    project={projectData}
                    productId={projectId}
                    replacementMatrix={replacement.selectedReplacement}
                    teamId={product.teamId}
                    categories={product.customCategories}
                    productType={product.type}
                    productName={product.typeName}
                />
            )}

            {modalOpen['eureka'] && (
                <EurekaEditModal
                    closeDialog={closeModals}
                    userFullName={{ name, lastName }}
                    userId={userId}
                    edit={eureka.edit}
                    projectName={product?.name || ''}
                    projectId={projectId}
                    eurekaIdea={eureka.selectedEureka}
                    teamId={product.teamId}
                    categories={product.customCategories}
                />
            )}

            {modalOpen.share && (
                <ShareIdeasModal
                    closeDialog={closeShareModal}
                    inviteInfo={inviteInfo}
                    ideas={ideasToShare}
                    projectId={projectId}
                />
            )}

            {modalOpen.confirmation && (
                <ConfirmationModal
                    closeDialog={() => setModalOpen({ ...initialModalOpen })}
                    buttonText={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    message={confirmationMessage}
                    onConfirm={() => setModalOpen({ ...initialModalOpen })}
                />
            )}

            {showNotFoundError && <MessageShowNotFound history={history} />}
        </div>
    );
};

Report.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    product: state.project.product,
    attributes: state.project.attributes,
    components: state.project.components,
    detailsMatrix: state.project.detailsMatrix,
    subtractions: state.project.subtractions,
    multiplications: state.project.multiplications,
    replacements: state.project.replacements,
    eurekaIdeas: state.project.eurekaIdeas,
    isLoading: state.project.isLoading,
    errors: state.project.errors,
});

export default connect(mapStateToProps, { getProjectInfoForReports })(Report);
