import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
    const { pathname, search } = rest.location;
    const redirectTo = pathname.length > 1 ? `?redirect=${encodeURIComponent(pathname + search)}` : '';
    return (
        <Route
            {...rest}
            render={props =>
                auth.isAuthenticated ? <Component {...props} /> : <Redirect to={'/login' + redirectTo} />
            }
        />
    );
};

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
