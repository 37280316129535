import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

const baseUrl = `${baseApiUrl}/credits`;

export const getAllCreditsInfo = async (onSuccess, onError) => {
    axios
        .get(`${baseUrl}`)
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};

export const updateOneCreditInfo = async ({ id, price, amount }, onSuccess, onError) => {
    axios
        .patch(`${baseUrl}/${id}`, { price, amount })
        .then((response) => handleSuccess(response, onSuccess))
        .catch((error) => handleError(error, onError));
};
