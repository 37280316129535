import React from 'react';

import Tooltip from '../../../common/Tooltip';

import { getTranslation } from '../../../../helpers/getLanguage';

const BlockDiagram = ({ values, labels = [], colors = [], tooltips = ['', ''], projectType }) => {
    return (
        <div className='block-diagram-container flex'>
            {values.length > 2 && (
                <div
                    className='project-diagram-block-big flex-center flex-column'
                    style={{ backgroundColor: colors[2] || '#5ac57a' }}
                >
                    <span>{values[2]}</span>
                    <span>{getTranslation(labels[2])}</span>
                </div>
            )}

            <div className='flex-column'>
                <div className='flex align-center diagram-block-top'>
                    <div
                        className='project-diagram-block flex flex-center'
                        style={{ backgroundColor: colors[0] || '#3e6fd9' }}
                    >
                        <span>{values[0]}</span>
                    </div>

                    {!tooltips[0].length && (
                        <span className='project-diagram-block-label'>{getTranslation(labels[0])}</span>
                    )}
                    {tooltips[0].length > 0 && (
                        <Tooltip
                            message={getTranslation(tooltips[0])}
                            containerClass='project-diagram-block-label'
                            innerText={getTranslation(labels[0])}
                        />
                    )}
                </div>

                {!projectType && (
                    <div className='flex align-center'>
                        <div
                            className='project-diagram-block flex flex-center'
                            style={{ backgroundColor: colors[1] || '#cdd1e5' }}
                        >
                            <span>{values[1]}</span>
                        </div>

                        {!tooltips[1].length && (
                            <span className='project-diagram-block-label'>{getTranslation(labels[1])}</span>
                        )}
                        {tooltips[1].length > 0 && (
                            <Tooltip
                                message={getTranslation(tooltips[1])}
                                containerClass='project-diagram-block-label'
                                innerText={getTranslation(labels[1])}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default BlockDiagram;
