import React from 'react';

const InvitationCard = ({ invitation, handleInvitationClick, disabled }) => {
    const handleClick = value => {
        if (!disabled) handleInvitationClick(invitation, value);
    };

    const baseButtonClass = 'invitation-button flex-center ';
    const disabledClass = disabled ? ' not-allowed' : '';

    return (
        <div className="project flex-column">
            <div className="project-title-container flex">
                <span className="project-title">{invitation.name}</span>
            </div>

            <div className="flex justify-space-between width-100 invitation-button-container mb-20">
                <button className={baseButtonClass + 'dark-blue-btn' + disabledClass} onClick={() => handleClick(true)}>
                    <span>JOIN</span>
                </button>
                <button className={baseButtonClass + 'gray' + disabledClass} onClick={() => handleClick(false)}>
                    <span>DECLINE</span>
                </button>
            </div>
        </div>
    );
};

export default InvitationCard;
