import React, { useState } from 'react';
import Dialog from '../Dialog';
import CustomScrollbar from '../../common/CustomScrollbar';
import DotFlashing from '../../common/DotFlashing';
import { getTranslation } from '../../../helpers/getLanguage';
import CloseIcon from '../../../assets/images/close-icon.svg';
import { ReactComponent as AIGenerateIcon } from '../../../assets/images/ai-generate-icon.svg';
import './styles.css';

const GenerateNamesModal = ({ closeGenerateNamesModal, suggestedNamesForIdeas, suggestedLoading, addIdeasName }) => {
    const [selectedName, setSelectedName] = useState({
        ideasIndex: null,
        ideasName: ""
    });

    return (
        <Dialog>
            <div className="flex-column generate-names">
                <div className="generate-names-header flex">
                    <div className="flex justify-between align-center">
                        <AIGenerateIcon style={{ color: "#CC4B4F", width: 28, marginRight: "10px" }} />
                        <h2>{getTranslation("GENERATE_IDEAS_NAMES_MODAL_HEADER")}</h2>
                    </div>
                    <img src={CloseIcon} alt="close modal" className="pointer" onClick={closeGenerateNamesModal} />
                </div>

                <CustomScrollbar 
                    backgroundColor="#f5f5f5"
                    scrollHandleColor="#C6CBED"
                    isShowArrows
                    width={5}
                    className={`${
                        suggestedLoading && "justify-content-center"
                    }`}
                    style={{ overflow: "hidden" }}
                >
                    {suggestedLoading ? (
                        <div className="flex-center height-100">
                            <DotFlashing 
                                textLoader={getTranslation("OPEN_AI_LOADER_TEXT")} 
                            />
                        </div>
                    ) : (
                        <div className="flex-column align-flex-start generate-names-list">
                            {suggestedNamesForIdeas.map((name, index) => (
                                <div 
                                    key={index} 
                                    className={`generate-names-list__name ${selectedName.ideasIndex === index ? "selected" : ""}`}
                                    onClick={() => setSelectedName({ ideasIndex: index, ideasName: name })}
                                >
                                    <p>{name}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </CustomScrollbar>
                <button
                    className="generate-names__button"
                    disabled={(selectedName.ideasIndex || selectedName.ideasIndex === 0) ? false : true}
                    onClick={() => addIdeasName(selectedName.ideasName)}
                >
                    {(selectedName.ideasIndex || selectedName.ideasIndex === 0)
                        ? `+ ${getTranslation("GENERATE_IDEAS_NAMES_MODAL_ADD_BUTTON")}` 
                        : `+ ${getTranslation("GENERATE_IDEAS_NAMES_MODAL_SELECT_BUTTON")}`
                    }
                </button>
            </div>
        </Dialog>
    )
}

export default GenerateNamesModal;