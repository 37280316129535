import React from 'react';
import './styles.css'

const ProjectProgressBar = ({ project }) => {
    const width = {
        green: (project.green / project.total) * 100,
        yellow: (project.yellow / project.total) * 100,
        red: (project.red / project.total) * 100
    };

    return (
        <div className="project-progress-bar flex">
            <div className="project-progress-colors flex width-animated">
                <div className="project-progress green" style={{ width: `${width.green}%` }}></div>
                <div className="project-progress yellow" style={{ width: `${width.yellow}%` }}></div>
                <div className="project-progress red" style={{ width: `${width.red}%` }}></div>
            </div>
        </div>
    );
};

export default ProjectProgressBar;
