import React from 'react';
import './styles.css';

import Tooltip from '../../common/Tooltip';

import { getTranslation } from '../../../helpers/getLanguage';

const CustomSwitch = ({
    value,
    setValue,
    labels = ['', ''],
    labelClass = 'custom-switch-label-default',
    tooltips = ['', ''],
    invert = false,
    disabled = false
}) => {
    const currentValue = invert ? !value : value;
    const additionalClass = currentValue ? ' active' : '';
    const disabledClass = disabled ? ' disabled not-allowed' : '';
    const pointerClass = !disabled ? ' pointer' : '';

    const handleSetValue = value => {
        if (!disabled) setValue(value);
    };

    return (
        <div className={'flex align-center custom-switch' + disabledClass}>
            <span
                className={labelClass + pointerClass + (!currentValue ? ' active' : '')}
                onClick={() => handleSetValue(invert ? true : false)}
            >
                {getTranslation(labels[0])}
            </span>
            {tooltips[0].length > 0 && <Tooltip message={getTranslation(tooltips[0])} containerClass="custom-switch-tooltip" />}

            <div
                className={'flex-center custom-switch-container' + pointerClass}
                onClick={() => handleSetValue(!value)}
            >
                <div className={'custom-switch-rectangle'}>
                    <div className={'custom-switch-circle' + additionalClass}></div>
                </div>
            </div>

            <span
                className={labelClass + pointerClass + additionalClass}
                onClick={() => handleSetValue(invert ? false : true)}
            >
                {getTranslation(labels[1])}
            </span>
            {tooltips[0].length > 0 && <Tooltip message={getTranslation(tooltips[1])} containerClass="custom-switch-tooltip" />}
        </div>
    );
};

export default CustomSwitch;
