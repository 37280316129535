import {
  RESET_RECOVERY_STATE,
  SET_RECOVERY_EMAIL,
  REQUEST_PASSWORD_RECOVERY,
  REQUEST_PASSWORD_RECOVERY_SUCCESS,
  REQUEST_PASSWORD_RECOVERY_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR
} from "../actions/types";

const initialState = {
  recoveryEmail: "",
  isLoading: false,
  success: false,
  errors: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET_RECOVERY_STATE:
      return {
        ...initialState
      };
    case SET_RECOVERY_EMAIL:
      return {
        ...state,
        recoveryEmail: action.payload
      };
    case REQUEST_PASSWORD_RECOVERY:
      return {
        ...state,
        isLoading: true,
        success: false,
        errors: null
      };
    case REQUEST_PASSWORD_RECOVERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        errors: null
      };
    case REQUEST_PASSWORD_RECOVERY_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        errors: action.payload
      };

    case CHANGE_PASSWORD:
      return {
        ...state,
        isLoading: true,
        success: false,
        errors: null
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        errors: null
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
        errors: action.payload
      };
    default:
      return state;
  }
}
