import React from "react";
import classnames from "classnames";
import PropTypes from 'prop-types'
import "./style.css";

import { checkLocale } from "../../utils/regularExpression";

const TranslateTemplate = ({ className, children, as = 'div' }) => {
    const C = as;

    const isChine = checkLocale(children);

    return (
        <C className={classnames(className, { cn: isChine })}>
            {children}
        </C>);
};

TranslateTemplate.propTypes = {
    as: PropTypes.elementType
}

export default TranslateTemplate;
