import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../../../../assets/images/plus-icon-small.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/close-icon.svg';
import { listOfInitialColors } from '../../../../../constants/listOfInitialColors';
import { getColorByInitials } from '../../../../../helpers/getInitials';
import { getTranslation } from '../../../../../helpers/getLanguage';
import CustomTextarea from '../../../../inputs/CustomTextarea';
import Initials from '../../../../common/Initials';
import Tooltip from '../../../../common/Tooltip';
import CommentSolve from './CommentSolve';
import Thread from './Thread';

const Issue = ({ 
    issue, 
    issues, 
    index, 
    addItem, 
    removeItem, 
    removeLastIssue,
    handleChange, 
    userFullName, 
    userId, 
    ...props 
}) => {
    const { comment, userInfo, description, createdByUser } = issue;

    const [isShowThread, setIsShowThread] = useState(false);

    const createdBy = createdByUser 
        ? createdByUser 
        : `${userFullName.name} ${userFullName.lastName}`;
    const [initialsBackgroundColor, initialsTextColor] = getColorByInitials(`${userFullName.name} ${userFullName.lastName}`, listOfInitialColors);
    const isCanChange = issue.userId === userId;

    useEffect(() => {
        setIsShowThread(false);
    }, [issue.id]);

    useEffect(() => {
        if (props.messages.length && props.messages[props?.messages?.length - 1]?.message !== '') {
            setIsShowThread(true);
        }
    }, []);

    const handleChangeIssue = ({ target: { value, name } }) => {
        if (isCanChange) {
            handleChange(index, value, name);
        }
    };

    const handleDelete = () => {
        if (isCanChange) {
            removeItem(index, issue.id);
            setIsShowThread(false);
        }
    };

    const handleDeleteLastIssue = () => {
        removeLastIssue(index, issue.id);
        setIsShowThread(false);
    };

    return (
        <div className="issue-container width-100 flex flex-column align-flex-start">
            <div className="width-100 flex align-center">
                <div className="replacement-modal-challenges__initials">
                    <Tooltip 
                        color={initialsTextColor} 
                        message={createdBy} 
                        tooltipMessageClass="width-fit-content"
                    >
                        <Initials
                            isRounded
                            userFullName={createdBy}
                            initialsBackgroundColor={initialsBackgroundColor}
                            initialsTextColor={initialsTextColor}
                        />
                    </Tooltip>
                </div>

                <div className="flex-column width-100 mb-20">
                    <CustomTextarea
                        value={comment}
                        name="comment"
                        height={60}
                        maxHeight={60}
                        onChange={handleChangeIssue}
                        placeholder={getTranslation("IDEA_MODAL_PLACEHOLDER_IMPLEMENTATION_CHALLENGES")}
                        autoResize
                        containerClass="replacement-modal-challenges__title textarea"
                        textareaClass="replacement-modal-challenges-input__empty replacement-modal-challenges-input__empty_title"
                        isIssue
                    />
                    <CustomTextarea
                        value={description ? description : ''}
                        onChange={handleChangeIssue}
                        height={60}
                        maxHeight={60}
                        name="description"
                        placeholder={getTranslation("SUBTRACTION_MODAL_BENEFITS_PLACEHOLDER_DESCRIPTION")}
                        containerClass="replacement-modal-challenges-input"
                        textareaClass="replacement-modal-challenges-input__empty"
                    />
                </div>

                <div className="flex-column">
                    <button className="replacement-modal__issue-button" onClick={index === issues.length - 1 ? handleDeleteLastIssue : handleDelete}>
                        <CloseIcon className="replacement-modal__close-issue-icon" />
                    </button>
                    
                    {index === issues.length - 1 &&
                        <button className="replacement-modal__issue-button" onClick={addItem}>
                            <PlusIcon className="replacement-modal__add-issue-icon" />
                        </button>
                    }
                </div>
            </div>
            {comment.length > 0 && (
                <CommentSolve
                    setIsShowThread={setIsShowThread}
                    onCommentSolve={props.onCommentSolve}
                    issueId={issue.id}
                    issueIndex={index}
                />
            )}
            {isShowThread && (
                <Thread
                    key={index}
                    userFullName={userFullName}
                    userId={userId}
                    issueId={issue.id}
                    issueIndex={index}
                    isShowThread={isShowThread}
                    messages={props.messages}
                    handleMessageChange={props.handleMessageChange}
                    addMessage={props.addMessage}
                    removeMessage={props.removeMessage}
                    removeLastMessage={props.removeLastMessage}
                />
            )}
        </div>
    );
};

export default Issue;
