import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/images/close-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/subtraction-edit-icon.svg';
import { ReactComponent as ApplyIcon } from '../../../assets/images/check-mark-grey.svg';
import ConsumerBenefit from '../../../assets/images/consumer-benefit.svg';
import CompanyBenefit from '../../../assets/images/company-benefit.svg';
import OtherBenefit from '../../../assets/images/other-benefit.svg';
import CustomInputCategory from '../CustomInputCategory';
import CustomScrollbar from '../../common/CustomScrollbar';
import BulletPoint from '../../common/BulletPoint';
import CustomTextArea from '../CustomTextarea';
import CustomCheckbox from '../CustomCheckbox';
import ConfirmationModal from '../../dialogs/ConfirmationModal';

import { getTranslation } from '../../../helpers/getLanguage';

import { getLabel, getSelectedValue } from "../../utils/benefits"

import './styles.css';

const defaultOptions = [
    { name: 'Consumer', icon: ConsumerBenefit, id: 0 },
    { name: 'Company', icon: CompanyBenefit, id: 1 },
    { name: 'Other', icon: OtherBenefit, id: 2 }
];

const CustomInputWithSelect = ({
    value,
    placeholder = '',
    selectValue,
    onChange,
    handleSelectChange,
    selectPlaceholder = '',
    containerClass = '',
    textareaClass = '',
    customCategories,
    handleDeleteOption,
    handleRenameOption,
    addCustomCategory
}) => {
    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [newLabel, setNewLabel] = useState('');
    const [oldLabel, setOldLabel] = useState(null);
    const [deletingName, setDeletingName] = useState(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    });

    useLayoutEffect(() => {
        if (isOpen && ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setPosition(rect.y + rect.height - 10);
        } else {
            setPosition(null);
        }
    }, [isOpen, ref]);

    const handleClickOutside = e => {
        if (ref.current && !ref.current.contains(e.target) && (ref.current.className !== 'deletingModal')) {
            setIsOpen(false);
        }
    };
    
    const onAddCategory = () => {
        if (!inputValue.trim().length) return; 

        addCustomCategory(inputValue);
        setInputValue('');
    };

    const deleteCategory = (name) => {
        handleDeleteOption(name);
        setDeletingName(null);
    }

    const handleSetChangedLabel = (name) => {
        if (oldLabel === null && name.trim().length) {
            setNewLabel(name);
            setOldLabel(name);

            return;
        }
        
        handleRenameOption(oldLabel, newLabel)
        setOldLabel(null);
    }

    const handleOnKeyEnter = e => {
        if (e.key === 'Enter') {
            if (oldLabel !== null) {
                handleSetChangedLabel();
                return;
            }

            onAddCategory();
        }
    }

    let selectedOption;
    if (selectValue.length === 1) {
        selectedOption = defaultOptions.concat(customCategories).find(option => option.name === selectValue[0].name);
    } else if (selectValue.length > 1) {
        selectedOption = { name: '', icon: null };
    }

    const firstOption = (
        <div
            className={
                'custom-input-container-option justify-content-start first flex align-center width-100 pointer ' + (isOpen ? ' open' : '')
            }
        >
            {selectedOption && selectedOption.icon && <img src={selectedOption.icon} alt="" />}
            {selectedOption && !selectedOption.icon && <BulletPoint />}
            <span>{selectedOption ? getSelectedValue(selectValue.map(value => value.name)) : selectPlaceholder}</span>
        </div>
    );

    const selectOptions = customCategories.map(option => (
        <div key={option.id} className='flex justify-content-between align-center width-100 pointer'>
            <div className='custom-input-container-option flex align-center'>
                <CustomCheckbox
                    checked={selectValue.find(elem => elem.name === option.name)}
                    handleChange={() => handleSelectChange(option)}
                    customClass="custom-category-checkbox"
                />
                <BulletPoint />
                {option.name === oldLabel ?
                    <CustomInputCategory
                        value={newLabel}
                        onChange={e => setNewLabel(e.target.value)}
                        name="benefitTarget"
                        onKeyDown={handleOnKeyEnter}
                        autoFocus
                    /> : 
                    <span className="custom-input-option-text text-start">{option.name}</span>}
            </div>
            <div className='custom-button-panel'>
                <button
                    className='custom-edit-delete-button'
                    onClick={() => handleSetChangedLabel(option.name)}
                >
                    {option.name === oldLabel ? <ApplyIcon /> : <EditIcon />}
                </button>
                <button onClick={() => setDeletingName(option.name)}>
                    <DeleteIcon />
                </button>
            </div>
        </div>
    ));

    const renderDefaultOptions = defaultOptions.map(option => (
        <div key={option.id} className='custom-input-container-option flex align-center'>
            <CustomCheckbox
                checked={selectValue.find(elem => elem.name === option.name)}
                handleChange={() => handleSelectChange(option)}
                customClass="custom-category-checkbox"
            />
            <img src={option.icon} alt="" />
            <span className="custom-input-option-text text-start">{getLabel(option.name)}</span>
        </div>
    ))

    return (
        <div className={'flex ' + containerClass} ref={ref}>
            {deletingName && 
            <ConfirmationModal
                className="deletingModal"
                closeDialog={() => setDeletingName(null)}
                onConfirm={() => deleteCategory(deletingName)}
                buttonText={getTranslation("CONFIRM_MODAL_DELETE_BUTTON_TEXT")}
                message={getTranslation("CONFIRM_MODAL_DELETE_BENEFIT")}
                messageWidth={400}
                messageHeight={70}
            />}
            <CustomTextArea
                value={value}
                placeholder={placeholder}
                onChange={e => onChange(e.target.value)}
                autoResize
                containerClass="benefits-textarea-container"
                textareaClass={textareaClass}
            />

            <div className="custom-input-select-container">
                <div
                    className="flex align-center pointer custom-input-first-option"
                    onMouseDown={() => setIsOpen(isOpen => !isOpen)}
                >
                    {firstOption}
                    <div className="custom-input-triangle-icon"></div>
                </div>

                {isOpen && typeof position === 'number' && (
                    <div
                        className="custom-input-select-options flex-column align-center pointer"
                        style={{ top: position }}
                    >
                        <CustomScrollbar dependencies={[customCategories]}>
                            <CustomInputCategory
                                value={inputValue}
                                name="benefitTarget"
                                onChange={e => setInputValue(e.target.value)}
                                onKeyDown={handleOnKeyEnter}
                                onSubmit={onAddCategory}
                                placeholder={getTranslation("SELECT_INPUT_OPTIONS_NEW_CATEGORY")}
                            />
                            {renderDefaultOptions}
                            {selectOptions}
                        </CustomScrollbar>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomInputWithSelect;