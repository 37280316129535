import React from 'react';
import ComponentCard from './ComponentCard';

import { sortableElement, SortableHandle } from 'react-sortable-hoc';

const ComponentCards = ({
    inputValues,
    projectData,
    handleModalOpen,
    handleDelete,
    handleDuplicate,
    handleDisable,
    compact,
    isSorted,
    history,
    queryParams,
    setAttributeId,
    setComponentId,
    dragMode,
    suggestedMode,
    setSuggestedMode,
    handleSuggestingAttributes,
    isHorizontalScroll,
    isProcessType,
    numberOpenAIRequests,
}) => {
    const DragHandle = SortableHandle(({ children }) => (
        <div className='flex width-100 sortable-hoc-drag-handle'>{children}</div>
    ));

    const SortableCard = sortableElement((props) => {
        return (
            <ComponentCard
                isProcessType={isProcessType}
                {...props}
                isHorizontalScroll={isHorizontalScroll}
                HandleComponent={DragHandle}
            />
        );
    });

    const componentCards = projectData.components.map((component, index) => {
        const attributes = projectData.attributes.filter((attribute) => attribute.componentId === component.id);
        const activeStatusClass = queryParams.component === component.id ? ' active-card-element' : '';

        return (
            <SortableCard
                key={component.id}
                index={index}
                inputValues={inputValues}
                compact={compact}
                isSorted={isSorted}
                component={component}
                attributes={attributes}
                handleModalOpen={handleModalOpen}
                handleDelete={handleDelete}
                handleDuplicate={handleDuplicate}
                handleDisable={handleDisable}
                history={history}
                activeStatusClass={activeStatusClass}
                queryParams={queryParams}
                setAttributeId={setAttributeId}
                setComponentId={setComponentId}
                dragMode={dragMode}
                {...{ suggestedMode, setSuggestedMode, handleSuggestingAttributes, numberOpenAIRequests }}
            />
        );
    });

    if (!projectData) {
        return null;
    }

    return <>{componentCards}</>;
};

export default ComponentCards;
