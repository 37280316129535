import React, { useState, useEffect } from 'react';

import { getTranslation } from '../../../helpers/getLanguage';

import Dialog from '../Dialog';
import CustomInput from '../../inputs/CustomInput';

import CloseIcon from '../../../assets/images/close-icon.svg';

import './styles.css';
import CustomToggle from '../../inputs/CustomToggle';
import ConfirmationModal from '../ConfirmationModal';
import WarningModal from '../WarningModal';

import {
    changeUserPasswordByEmail,
    changeUserRoles,
    changeUserFeatures,
    deleteUser,
    verifyEmail,
} from '../../../api/users';
import { validateField } from '../../../common/validation';
import { activatePromoCodeForUser } from '../../../api/promoCodes';
import Tooltip from '../../common/Tooltip';

const userRoles = { admin: false, support_admin: false };

const userFeatures = { import: false };

const initialErrors = {
    password: '',
    codeActivationCode: '',
};

const initialFields = {
    password: '',
    codeActivationCode: '',
};

const translateRoles = {
    user: 'CHANGE_USER_ROLES_ROLE_USER',
    admin: 'CHANGE_USER_ROLES_ROLE_ADMIN',
    support_admin: 'CHANGE_USER_ROLES_ROLE_SUPPORT_ADMIN',
};

const translateFeatures = {
    import: 'USERS_MODAL_IMPORT',
};

const UserModal = ({ closeDialog, userInfo = null, featuresList }) => {
    const [roles, setRoles] = useState(userRoles);
    const [features, setFeatures] = useState(userFeatures);
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(initialErrors);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [warningText, setWarningText] = useState('');
    const [activeAction, setActiveAction] = useState('');

    useEffect(() => {
        setRoles({ admin: userInfo.roles.includes('admin'), support_admin: userInfo.roles.includes('support_admin') });
        setFeatures(() => {
            const features = {};

            featuresList.forEach((feature) => {
                const featureName = feature.name?.toLowerCase();

                features[featureName] = userInfo.features?.includes(featureName);
            });

            return features;
        });
    }, [userInfo, featuresList]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const handleCloseDialog = () => {
        closeDialog && closeDialog();
    };

    const handleToggleRoles = (key, value) => {
        setRoles((fields) => ({ ...fields, [key]: value }));
    };

    const handleToggleFeatures = (key, value) => {
        setFeatures((fields) => ({ ...fields, [key]: value }));
    };

    const handleChangePassword = () => {
        const errorMessages = {};
        const message = validateField({ name: 'password', value: fields['password'] });
        if (message.length) {
            errorMessages['password'] = message;
        }

        setErrors({ ...errors, ...errorMessages });
        if (Object.keys(errorMessages).length) return;

        const params = { email: userInfo.email, password: fields['password'] };

        const onSuccess = () => {
            setWarningModal(false);
            setWarningText('');

            setConfirmationText(getTranslation('USERS_MODAL_CHANGE_PASSWORD_CONFIRMATION'));
            setConfirmationModal(true);
        };

        const onError = (error) => {
            setWarningModal(false);
            setWarningText('');

            console.error(error);
            setConfirmationText(
                `${getTranslation('DELETE_EXISTING_USER_CONFIRMATION_TEXT_ERROR')} ${error.response.data.message}`
            );
            setConfirmationModal(true);
        };

        changeUserPasswordByEmail(params, onSuccess, onError);
    };

    const handleActivateCode = () => {
        const errorMessages = {};
        const message = validateField({ name: 'codeActivationCode', value: fields['codeActivationCode'] });
        if (message.length) {
            errorMessages['codeActivationCode'] = message;
        }

        setErrors({ ...errors, ...errorMessages });
        if (Object.keys(errorMessages).length) return;

        const params = { email: userInfo.email, code: fields.codeActivationCode };
        const onSuccess = () => {
            setWarningModal(false);
            setWarningText('');

            setConfirmationText(getTranslation('USERS_MODAL_ACTIVATE_PROMO_CODE_CONFIRMATION'));
            setConfirmationModal(true);
        };

        const onError = (error) => {
            setWarningModal(false);
            setWarningText('');

            console.error(error);
            setConfirmationText(
                `${getTranslation('DELETE_EXISTING_USER_CONFIRMATION_TEXT_ERROR')} ${error.response.data.message}`
            );
            setConfirmationModal(true);
        };

        activatePromoCodeForUser(params, onSuccess, onError);
    };

    const handleChangeRoles = () => {
        const userRoles = Object.keys(roles)
            .map((field) => (roles[field] ? field : ''))
            .filter((field) => field);

        const params = { email: userInfo.email, roles: userRoles };
        const onSuccess = () => {
            setWarningModal(false);
            setWarningText('');

            setConfirmationText(getTranslation('USERS_MODAL_SET_ROLES_CONFIRMATION'));
            setConfirmationModal(true);
        };

        const onError = (error) => {
            setWarningModal(false);
            setWarningText('');

            console.error(error);
            setConfirmationText(
                `${getTranslation('DELETE_EXISTING_USER_CONFIRMATION_TEXT_ERROR')} ${error.response.data.message}`
            );
            setConfirmationModal(true);
        };

        changeUserRoles(params, onSuccess, onError);
    };

    const handleChangeFeatures = () => {
        const userFeatures = Object.keys(features)
            .map((field) => (features[field] ? field : ''))
            .filter((field) => field);

        const params = { email: userInfo.email, features: userFeatures };
        const onSuccess = () => {
            setWarningModal(false);
            setWarningText('');

            setConfirmationText(getTranslation('USERS_MODAL_SET_FEATURES_CONFIRMATION'));
            setConfirmationModal(true);
        };

        const onError = (error) => {
            setWarningModal(false);
            setWarningText('');

            console.error(error);
            setConfirmationText(
                `${getTranslation('DELETE_EXISTING_USER_CONFIRMATION_TEXT_ERROR')} ${error.response.data.message}`
            );
            setConfirmationModal(true);
        };

        changeUserFeatures(params, onSuccess, onError);
    };

    const handleVerifyEmail = () => {
        const params = { email: userInfo.email };

        const onSuccess = () => {
            setWarningModal(false);
            setWarningText('');

            setConfirmationText(getTranslation('USERS_MODAL_VERIFY_EMAIL_CONFIRMATION'));
            setConfirmationModal(true);
        };

        const onError = (error) => {
            setWarningModal(false);
            setWarningText('');

            console.error(error);
            setConfirmationText(
                `${getTranslation('DELETE_EXISTING_USER_CONFIRMATION_TEXT_ERROR')} ${error.response.data.message}`
            );
            setConfirmationModal(true);
        };

        verifyEmail(params, onSuccess, onError);
    };

    const handleDeleteUser = () => {
        const params = { email: userInfo.email };
        const onSuccess = () => {
            setWarningModal(false);
            setWarningText('');

            setConfirmationText(getTranslation('USERS_MODAL_DELETE_CONFIRMATION'));
            setConfirmationModal(true);
        };

        const onError = (error) => {
            setWarningModal(false);
            setWarningText('');

            console.error(error);
            setConfirmationText(
                `${getTranslation('DELETE_EXISTING_USER_CONFIRMATION_TEXT_ERROR')} ${error.response.data.message}`
            );
            setConfirmationModal(true);
        };

        deleteUser(params, onSuccess, onError);
    };

    const handleSetErrors = (e, value) => {
        const { name } = e.target || e;
        setErrors((errors) => ({ ...errors, [name]: value }));
    };

    const onConfirmWarning = () => {
        activeAction === 'password' && handleChangePassword();
        activeAction === 'promoCode' && handleActivateCode();
        activeAction === 'roles' && handleChangeRoles();
        activeAction === 'email' && handleVerifyEmail();
        activeAction === 'delete' && handleDeleteUser();
        activeAction === 'features' && handleChangeFeatures();
    };

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false}>
            <div className='user-dialog-wrapper'>
                <div className='user-dialog-header flex'>
                    <div className='flex align-center'>
                        <span className='user-dialog-title'>{'User settings'}</span>
                    </div>
                    <img src={CloseIcon} alt='' className='pointer' onClick={() => handleCloseDialog()} />
                </div>
                <div className='user-dialog-body flex-column'>
                    <div className='modal-user-info-container'>
                        <p>{`${getTranslation('USERS_MODAL_FULL_USERNAME')} ${userInfo.name} ${userInfo.lastName}`}</p>
                        <p>{`${getTranslation('USERS_MODAL_EMAIL')} ${userInfo.email}`}</p>
                    </div>
                    <div className='modal-user-change-pass-container'>
                        <CustomInput
                            value={fields.password}
                            name='password'
                            type='password'
                            onChange={handleChange}
                            containerClass='modal-user-change-password'
                            label={getTranslation('CHANGE_USER_PASSWORD_NEW_PASSWORD')}
                            onBlur={(e) => validateField(e, handleSetErrors)}
                            errorMessage={errors.password}
                            autoComplete='new-password'
                        />

                        <button
                            className='modal-users-button'
                            onClick={() => {
                                if (!errors['password']) {
                                    setActiveAction('password');
                                    setWarningText('USERS_MODAL_CHANGE_PASSWORD_WARNING');
                                    setWarningModal(true);
                                }
                            }}
                            disabled={false}
                        >
                            <span>{getTranslation('CHANGE_USER_PASSWORD_BUTTON')}</span>
                        </button>
                    </div>
                    <div className='modal-user-change-pass-container'>
                        <CustomInput
                            value={fields.codeActivationCode}
                            name='codeActivationCode'
                            onChange={handleChange}
                            containerClass='modal-user-change-password'
                            label={getTranslation('ACTIVATE_CODE_FOR_ONE_USER_PROMO_CODE')}
                            onBlur={(e) => validateField(e, handleSetErrors)}
                            errorMessage={errors.codeActivationCode}
                        />
                        <button
                            className='modal-users-button'
                            onClick={() => {
                                if (!errors['codeActivationCode']) {
                                    setActiveAction('promoCode');
                                    setWarningText('USERS_MODAL_ACTIVATE_PROMO_CODE_WARNING');
                                    setWarningModal(true);
                                }
                            }}
                            disabled={false}
                        >
                            <span>{getTranslation('ACTIVATE_CODE_FOR_ONE_USER_BUTTON')}</span>
                        </button>
                    </div>
                    <div className='user-modal-change-roles'>
                        <div>
                            {Object.keys(roles).map((key, index) => {
                                const currentLabel = getTranslation(translateRoles[key]);

                                return (
                                    <div className='flex mb-20' key={index}>
                                        <CustomToggle
                                            gray
                                            value={roles[key]}
                                            setValue={(e) => handleToggleRoles(key, e)}
                                            label={currentLabel}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <button
                            className='modal-users-button'
                            onClick={() => {
                                setActiveAction('roles');
                                setWarningText('USERS_MODAL_SET_ROLES_WARNING');
                                setWarningModal(true);
                            }}
                            disabled={false}
                        >
                            <span>{getTranslation('CHANGE_USER_ROLES_BUTTON')}</span>
                        </button>
                    </div>
                    <div className='user-modal-change-features mb-20'>
                        <div>
                            {Object.keys(features).map((key, index) => {
                                const currentLabel = getTranslation(translateFeatures[key]);

                                return (
                                    <div className='flex mb-20' key={index}>
                                        <Tooltip
                                            position='top'
                                            message={featuresList[index].description}
                                            containerClass={'flex'}
                                        >
                                            <CustomToggle
                                                gray
                                                value={features[key]}
                                                setValue={(e) => handleToggleFeatures(key, e)}
                                                label={currentLabel}
                                                labelClass={'feature-hover-style'}
                                            />
                                        </Tooltip>
                                    </div>
                                );
                            })}
                        </div>
                        <button
                            className='modal-users-button'
                            onClick={() => {
                                setActiveAction('features');
                                setWarningText('USERS_MODAL_SET_FEATURES_WARNING');
                                setWarningModal(true);
                            }}
                            disabled={false}
                        >
                            <span>{getTranslation('CHANGE_USER_FEATURES_BUTTON')}</span>
                        </button>
                    </div>
                    <button
                        className='manage-users-button mb-20'
                        onClick={() => {
                            setActiveAction('email');
                            setWarningText('USERS_MODAL_VERIFY_EMAIL_WARNING');
                            setWarningModal(true);
                        }}
                        disabled={false}
                    >
                        <span>{getTranslation('VERIFY_USER_EMAIL_BUTTON')}</span>
                    </button>
                    <button
                        className='manage-users-button'
                        onClick={() => {
                            setActiveAction('delete');
                            setWarningText('USERS_MODAL_DELETE_WARNING');
                            setWarningModal(true);
                        }}
                        disabled={false}
                    >
                        <span>{getTranslation('DELETE_EXISTING_USER_BUTTON')}</span>
                    </button>
                </div>
            </div>
            {confirmationModal && (
                <ConfirmationModal
                    closeOnClickOutside={false}
                    closeDialog={() => setConfirmationModal(false)}
                    message={confirmationText}
                    buttonText='OK'
                    messageWidth={400}
                    onConfirm={() => setConfirmationModal(false)}
                />
            )}
            {warningModal && (
                <WarningModal
                    closeOnClickOutside={false}
                    closeDialog={() => setWarningModal(false)}
                    message={warningText}
                    leftButtonText='NO'
                    rightButtonText='YES'
                    messageWidth={400}
                    onConfirm={onConfirmWarning}
                    onCancel={() => setWarningModal(false)}
                />
            )}
        </Dialog>
    );
};

export default UserModal;
