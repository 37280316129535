import React, { useState } from 'react';

import LightbulbIcon from '../../assets/images/ligntbulb-icon-large.svg';

import useWindowWidth from '../../common/useWindowWidth';
import Tooltip from '../common/Tooltip';
import UsersInProjectModal from '../dialogs/UsersInProjectModal';
import ProjectsDashboardTopRow from './ProjectsDashboardTopRow';
import ViewTutorialButtonWithModal from '../common/ViewTutorialButtonWithModal';

import { getUrlFromConfig } from '../../helpers/getUrlFromConfig';
import { getTranslation } from '../../helpers/getLanguage';
import { getDate } from '../utils/getDate';

import './styles.css';

const ProjectsDashboardHeader = ({
    project,
    CalendarIcon,
    AssigneeIcon,
    canEdit,
    language,
    modalOpen,
    setModalOpen,
    getProjectInfo,
    userId,
}) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    const windowWidth = useWindowWidth(10);
    const imageId = (project.image && project.image.id) || '';
    const imageUrl = imageId && getUrlFromConfig(`images/${imageId}`);
    const activeClass = !canEdit ? ' no-edit' : '';
    const peopleCount = typeof project.participants === 'number' ? project.participants : '';

    const handleClick = () => {
        if (project && project.sharingType && project.teamId) setIsOpenModal(true);
    };

    return (
        <>
            <div className='project-dashboard-header flex justify-space-between'>
                <div className={'project-header-container flex-column' + activeClass}>
                    <div className='dashboard-information flex mb-10'>
                        <ViewTutorialButtonWithModal name='Dashboard' />
                    </div>
                    <div className='flex project-description-container'>
                        <div className='project-details'>
                            <div className='project-details-info-container flex-column'>
                                <span className='projects-dashboard-label'>
                                    {getTranslation('PAGE_DASHBOARD_LABEL_DATE_CREATED')}
                                </span>
                                <div className='flex align-center'>
                                    <img className='project-details-icon' src={CalendarIcon} alt='' />
                                    <span className='project-details-info'>{getDate(project.created, language)}</span>
                                </div>
                                <hr />
                            </div>
                            <div className='project-details-info-container flex-column'>
                                <span className='projects-dashboard-label'>
                                    {getTranslation('PAGE_DASHBOARD_LABEL_LAST_VISITED')}
                                </span>
                                <div className='flex align-center'>
                                    <img className='project-details-icon' src={CalendarIcon} alt='' />
                                    <span className='project-details-info'>
                                        {getDate(project.lastVisited, language)}
                                    </span>
                                </div>
                                <hr />
                            </div>
                            <div className='project-details-info-container flex-column'>
                                <span className='projects-dashboard-label'>
                                    {getTranslation('PAGE_DASHBOARD_LABEL_CREATED_BY')}
                                </span>
                                <div className='flex align-center'>
                                    <img className='project-details-icon' src={AssigneeIcon} alt='' />
                                    <span className='project-details-info'>{project.createdBy || ''}</span>
                                </div>
                            </div>
                            <div className='project-details-info-container flex-column'>
                                <span className='projects-dashboard-label people-in-project' onClick={handleClick}>
                                    {getTranslation('PAGE_DASHBOARD_LABEL_USERS_IN_PROJECT')}
                                </span>
                                <div className='flex align-center'>
                                    <img className='project-details-icon' src={AssigneeIcon} alt='' />
                                    {peopleCount > 0 ? (
                                        <span className='project-details-info'>{peopleCount}</span>
                                    ) : (
                                        <Tooltip
                                            message={getTranslation('NO_ADDITIONAL_USERS_MESSAGE')}
                                            position='right'
                                            containerClass='project-details-info people-in-project-tooltip'
                                            innerText={getTranslation('NO_ADDITIONAL_USERS_INNER_TEXT')}
                                        />
                                    )}
                                </div>
                            </div>

                            {windowWidth < 1340 && (
                                <div
                                    className={`idea-container small flex-center ${
                                        !imageId && 'idea-container-with-default-image'
                                    }`}
                                >
                                    {!imageId && (
                                        <img
                                            className='idea-placeholder-icon-scaled placeholder'
                                            src={LightbulbIcon}
                                            alt='project'
                                        />
                                    )}
                                    {imageId && <img className='idea-image' src={imageUrl} alt='' />}
                                </div>
                            )}
                        </div>

                        {((project.description && project.description.length) || 0) > 0 && (
                            <div className='project-description flex-column'>
                                <span className='projects-dashboard-label'>
                                    {getTranslation('PAGE_DASHBOARD_LABEL_DESCRIPTION')}
                                </span>
                                <span className={'project-description-text' + activeClass}>{project.description}</span>
                            </div>
                        )}
                    </div>
                    <div className='project-edit-buttons justify-flex-start'>
                        <ProjectsDashboardTopRow
                            canEdit={canEdit}
                            project={project}
                            modalOpen={modalOpen.project}
                            setModalOpen={setModalOpen}
                            getProjectInfo={getProjectInfo}
                            userId={userId}
                        />
                    </div>
                </div>

                {windowWidth >= 1340 && (
                    <div className='idea-container flex-center'>
                        {!imageId && (
                            <img
                                className='idea-placeholder-icon-scaled placeholder'
                                src={LightbulbIcon}
                                alt='project'
                            />
                        )}
                        {imageId && <img className='idea-image' src={imageUrl} alt='' />}
                    </div>
                )}
            </div>
            {isOpenModal && (
                <UsersInProjectModal
                    setIsOpenModal={setIsOpenModal}
                    projectId={project.id}
                    creatorName={project.createdBy}
                />
            )}
        </>
    );
};

export default ProjectsDashboardHeader;
