export const parseLocationSearch = locationSearch => {
    const result = {};
    const paramsString = locationSearch.split('?').filter(param => param);
    const params = paramsString[0] && paramsString[0].split('&');

    if (params) {
        for (let param of params) {
            const [name, rawValue] = param.split('=');
            if (name && rawValue) {
                result[name] = rawValue;
            }
        }
    }

    return result;
};
