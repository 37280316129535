import React from 'react';
import EurekaIdeaCard from './EurekaIdeaCard';
import CustomScrollbar from '../../common/CustomScrollbar';
import { sortByName } from '../../common/utils/sort';
import { ReactComponent as PlusIconSmall } from '../../../assets/images/plus-icon-small.svg';
import { getTranslation } from '../../../helpers/getLanguage';

const IdeasCard = ({ isSorted, inputValues, handleModalOpen, filteredIdeas, deleteEureka }) => {
    const sortedIdeas = isSorted ? filteredIdeas.slice().sort(sortByName) : filteredIdeas;

    const componentElements = sortedIdeas?.map((sortedIdea, index) => (
        <EurekaIdeaCard
            key={index}
            index={index}
            deleteEureka={deleteEureka}
            idea={sortedIdea}
            handleModalOpen={handleModalOpen}
        />
    ));

    return (
        <div className='eureka-component-card compact'>
            <CustomScrollbar
                width={5}
                right={-10}
                backgroundColor='transparent'
                dependencies={[inputValues, filteredIdeas]}
            >
                <div className='eureka-ideas-container'>
                    {[
                        ...(componentElements || []),
                        <button
                            className='add-component-button flex align-center justify-space-between'
                            style={{ width: '550px' }}
                            onClick={() => handleModalOpen('eureka')}
                            key={9999}
                        >
                            <span>{getTranslation('EUREKA_PAGE_ADD_NEW_IDEA')}</span>
                            <PlusIconSmall />
                        </button>,
                    ]}
                </div>
            </CustomScrollbar>
        </div>
    );
};

export default IdeasCard;
