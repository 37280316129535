import classNames from 'classnames';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Dialog from '../Dialog';

import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkmark.svg';

import { getAllCreditsInfo } from '../../../api/credits';

import styles from './BuyMoreAICreditsModal.module.css';
import { getTranslation } from '../../../helpers/getLanguage';

const defaultButtonClasses = [
    'details-button',
    'dark-blue-btn',
    'flex',
    'align-center',
    'justify-center',
    styles['default-button'],
];

const SelectTariff = ({ selectedTariff, setSelectedTariff, goNextPage, tokensInfo }) => {
    const aiCreditsText = getTranslation('AI_CREDITS_SPAN');

    return (
        <div>
            <div className={classNames('flex', 'flex-column', 'justify-center', 'align-center')}>
                <span className={classNames(styles['main-span'], styles['default-color'])}>
                    {getTranslation('SELECT_OPTION_BELOW_SPAN')}
                </span>
                <span className={classNames(styles['description-span'], styles['default-color'])}>
                    {getTranslation('CREDITS_ARE_NON_EXPIRING_SPAN')}
                </span>
            </div>
            <div className={classNames('flex', 'justify-center')}>
                {tokensInfo.map(({ amount: credits, price: amount, id: key }, index) => (
                    <div
                        onClick={() => setSelectedTariff(key)}
                        key={key + index}
                        className={classNames(
                            'flex',
                            'flex-column',
                            'justify-center',
                            'align-center',
                            styles['tariff-block'],
                            index && styles['tariff-left-margin']
                        )}
                    >
                        {selectedTariff === key ? (
                            <CheckmarkIcon className={styles['icon-check_mark']} />
                        ) : (
                            <div className={styles.check_mark}></div>
                        )}
                        {[credits, aiCreditsText, `$${amount}`].map((row, index) => (
                            <span className={styles['tariff-info']} key={index + credits + amount + key}>
                                {row}
                            </span>
                        ))}
                    </div>
                ))}
            </div>
            <div className={classNames('flex', 'flex-column', 'align-center', 'justify-center')}>
                <button disabled={!selectedTariff} onClick={goNextPage} className={classNames(...defaultButtonClasses)}>
                    {getTranslation(selectedTariff ? 'CONTINUE_BUTTON' : 'SELECT_AN_AMOUNT_BUTTON')}
                </button>
            </div>
        </div>
    );
};

const BuyTariff = ({ selectedTariff, setPaymentInfo, subscriptionInfo, tokensInfo }) => {
    const tariff = useMemo(
        () => tokensInfo.find((token) => token?.id === selectedTariff),
        [selectedTariff, tokensInfo]
    );

    const openPaymentModal = useCallback(() => {
        setPaymentInfo((prev) => ({
            ...prev,
            total: tariff?.price,
            openAIRequests: tariff?.amount,
            credits: null,
            key: `OpenAI-${tariff?.id}`,
        }));
    }, [tariff]);

    return (
        <div className={classNames('flex', 'flex-column', 'align-center')}>
            <span className={classNames(styles['main-span'], styles['default-color'], styles['confirm-span'])}>
                {getTranslation('CONFIRM_YOUR_PURCHASE_SPAN')}
            </span>
            <div className={classNames(styles['total-table'], styles['total-table-subscription'])}>
                {subscriptionInfo && (
                    <div
                        className={classNames(
                            'flex',
                            'justify-between',
                            styles['credits-block'],
                            subscriptionInfo && styles['credits-block-subscription']
                        )}
                    >
                        <div className='flex flex-column align-flex-start'>
                            <span>{`Omnivati ${
                                subscriptionInfo?.interval[0] + subscriptionInfo?.interval?.slice(1) + 'ly'
                            } Plan`}</span>
                            <span>{`($${
                                subscriptionInfo.total / 12 ** Number(subscriptionInfo?.interval === 'year')
                            } / mo billed ${subscriptionInfo?.interval + 'ly'})`}</span>
                        </div>
                        <span>{`$${subscriptionInfo.total}`}</span>
                    </div>
                )}
                {tariff && (
                    <div
                        className={classNames(
                            'flex',
                            'justify-between',
                            styles['credits-block'],
                            subscriptionInfo && styles['credits-block-bottom']
                        )}
                    >
                        <div className='flex flex-column align-flex-start'>
                            <span>{`${tariff.amount} ${getTranslation('AI_CREDITS_SPAN')}`}</span>
                            {subscriptionInfo && <span>{`(${getTranslation('ONE_TINE_PAYMENT')})`}</span>}
                        </div>
                        <span>{`$${tariff.price}`}</span>
                    </div>
                )}
                <div className={classNames('flex', 'justify-between', styles['total-block'])}>
                    <span>{'Total'}</span>
                    <span>{`$${(tariff?.price || 0) + (subscriptionInfo?.total || 0)}`}</span>
                </div>
            </div>
            <div className={classNames('flex', 'flex-column', 'align-center', 'justify-center')}>
                <button
                    onClick={openPaymentModal}
                    className={classNames(...defaultButtonClasses, styles['purchase-button'])}
                >
                    {getTranslation('PURCHASE')}
                </button>
            </div>
        </div>
    );
};

const BuyMoreAICreditsModal = ({
    closeDialog,
    closeOnClickOutside,
    closeOnEscape,
    setPaymentInfo,
    pageInfo,
    selectedTariffInfo,
    subscriptionInfo,
}) => {
    const [selectedTariff, setSelectedTariff] = useState(selectedTariffInfo || null);
    const [page, setPage] = useState(pageInfo || 0);
    const [tokensInfo, setTokensInfo] = useState([]);

    useEffect(() => {
        getTokens();
    }, []);

    const getTokens = useCallback(() => {
        const onSuccess = (tokens) => {
            setTokensInfo(tokens);
        };

        const onError = (err) => {
            alert(err?.message ?? '');
        };

        getAllCreditsInfo(onSuccess, onError);
    }, []);

    return (
        <Dialog closeDialog={closeDialog} closeOnClickOutside={closeOnClickOutside} closeOnEscape={closeOnEscape}>
            <div className={page ? styles['next-dialog'] : styles.dialog}>
                <div
                    className={classNames(
                        styles.header,
                        'generate-modal-title',
                        'justify-between',
                        'align-center',
                        'flex'
                    )}
                >
                    <div className={classNames(styles['left-header'], 'align-center', 'flex')}>
                        <span className={styles['title-header']}>
                            {page
                                ? getTranslation('BUY_SUBSCRIPTION_PLAN')
                                : getTranslation('BUY_MORE_AI_CREDITS_TITLE')}
                        </span>
                    </div>
                    <div className={styles['close-icon']}>
                        <CloseIcon onClick={closeDialog} />
                    </div>
                </div>
                {page ? (
                    <BuyTariff
                        selectedTariff={selectedTariff}
                        setPaymentInfo={(e) => {
                            setPaymentInfo(e);
                            closeDialog();
                        }}
                        subscriptionInfo={subscriptionInfo}
                        tokensInfo={tokensInfo}
                    />
                ) : (
                    <SelectTariff
                        tokensInfo={tokensInfo}
                        goNextPage={() => setPage(1)}
                        setSelectedTariff={setSelectedTariff}
                        selectedTariff={selectedTariff}
                    />
                )}
            </div>
        </Dialog>
    );
};

export default BuyMoreAICreditsModal;
