import React, { useState } from 'react';

import { changeUserPasswordByEmail } from '../../../../api/users';

import { validateField } from '../../../../common/validation';

import CustomInput from '../../../inputs/CustomInput';
import ConfirmationModal from '../../../dialogs/ConfirmationModal';

import { getTranslation } from '../../../../helpers/getLanguage';

const initialFields = {
    email: '',
    password: ''
};

const initialErrors = {
    email: '',
    password: ''
};

const ChangeUserPassword = () => {
    const [fields, setFields] = useState(initialFields);
    const [errors, setErrors] = useState(initialErrors);
    const [isPending, setIsPending] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const handleSetErrors = (e, value) => {
        const { name } = e.target || e;
        setErrors(errors => ({ ...errors, [name]: value }));
    };

    const handleChangePassword = () => {
        const errorMessages = {};
        for (let key in fields) {
            const message = validateField({ name: key, value: fields[key] });
            if (message.length) {
                errorMessages[key] = message;
            }
        }

        setErrors({ ...errors, ...errorMessages });
        if (Object.keys(errorMessages).length) return;

        const params = { ...fields };

        const onSuccess = () => {
            setIsPending(false);
            setConfirmationText(`${getTranslation("CHANGE_USER_PASSWORD_CONFIRMATION_TEXT_SUCCESSFULLY")} ${fields.email}`);
            setModalOpen(true);
        };

        const onError = error => {
            setIsPending(false);
            console.error(error);
            setConfirmationText(`${getTranslation("CHANGE_USER_PASSWORD_CONFIRMATION_TEXT_ERROR")} ${error.response.data.message}`);
            setModalOpen(true);
        };

        setIsPending(true);
        changeUserPasswordByEmail(params, onSuccess, onError);
    };

    const handleSubmitEnterPassword = event => {
        if (!isPending && event.key === 'Enter') {
            handleChangePassword();
        }
    };

    return (
        <>
            <div className="manage-user-container mb-30">
                <div className="flex-column admin-password-container">
                    <span className="manage-user-label mb-20">{getTranslation("CHANGE_USER_PASSWORD_TITLE")}</span>
                    <CustomInput
                        value={fields.email}
                        name="email"
                        onChange={handleChange}
                        onKeyDown={handleSubmitEnterPassword}
                        containerClass="flex-column width-100 mb-10"
                        label={getTranslation("CHANGE_USER_PASSWORD_USER_EMAIL")}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.email}
                    />

                    <CustomInput
                        value={fields.password}
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onKeyDown={handleSubmitEnterPassword}
                        containerClass="flex-column width-100 mb-20"
                        label={getTranslation("CHANGE_USER_PASSWORD_NEW_PASSWORD")}
                        onBlur={e => validateField(e, handleSetErrors)}
                        errorMessage={errors.password}
                        autoComplete="new-password"
                    />

                    <button className="manage-users-button" onClick={handleChangePassword} disabled={isPending}>
                        <span>{getTranslation("CHANGE_USER_PASSWORD_BUTTON")}</span>
                    </button>
                </div>
            </div>

            {modalOpen && (
                <ConfirmationModal
                    closeOnClickOutside={false}
                    closeDialog={() => setModalOpen(false)}
                    message={confirmationText}
                    buttonText="OK"
                    messageWidth={400}
                    onConfirm={() => setModalOpen(false)}
                />
            )}
        </>
    );
};

export default ChangeUserPassword;
