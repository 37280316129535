import { useState, useEffect } from 'react';

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const useWindowWidth = (delay = 100) => {
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
        let timeoutId = null;
        const resize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), delay);
        };

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [delay]);

    return width;
};

export default useWindowWidth;
