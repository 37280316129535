import React, { useState, useEffect } from 'react';
import Dialog from '../Dialog';
import './styles.css';

import {
    createComponent as requestCreateComponent,
    updateComponent as requestUpdateComponent,
} from '../../../api/components';

import { ReactComponent as ComponentIcon } from '../../../assets/images/component-icon-big.svg';
import { ReactComponent as StepIcon } from '../../../assets/images/steps.svg';
import CloseIcon from '../../../assets/images/close-icon.svg';
import CustomInput from '../../inputs/CustomInput';
import CustomScoreComponentInput from '../../inputs/CustomScoreComponentInput';
import CustomSwitch from '../../inputs/CustomSwitch';
import CustomTextarea from '../../inputs/CustomTextarea';

import Tooltip from '../../common/Tooltip';

import { getTranslation } from '../../../helpers/getLanguage';

const initialState = {
    name: '',
    importance: 1,
    internal: true,
    description: '',
    locked: false,
};

const ComponentAddModal = ({
    closeDialog,
    edit = false,
    suggestedMode,
    currentComponent,
    productId,
    projectType,
    onSuccess,
    teamId,
    setModalOpen,
    pendingComponentCreation,
    handleDeleteComponent,
    labelsPerson,
    labelsLocations,
    isCustomerState,
}) => {
    const [component, setComponent] = useState({
        ...initialState,
        internal: currentComponent?.internal ?? true,
        isCustomer: isCustomerState,
    });

    const isProcessType = projectType === 'process';

    const handleChange = (e) => {
        const { name, value } = e.target;
        setComponent({ ...component, [name]: value });
    };

    useEffect(() => {
        edit &&
            setComponent({
                name: currentComponent.name,
                internal: currentComponent.internal,
                importance: currentComponent.importance,
                description: currentComponent.description || '',
                locked: currentComponent.locked,
                isCustomer: currentComponent.isCustomer,
            });
    }, [currentComponent, edit]);

    useEffect(() => {
        suggestedMode?.mode &&
            setComponent({
                ...initialState,
                name: suggestedMode.name,
            });
    }, [currentComponent, suggestedMode]);

    const createComponent = () => {
        if (component.name.trim()) {
            const params = {
                productId: productId,
                name: component.name.trim(),
                importance: component.importance,
                internal: isProcessType ? true : component.internal,
                description: component.description,
                isCustomer: component.isCustomer,
                disabled: false,
                teamId: teamId || '',
            };

            requestCreateComponent(params, onSuccess);
            !pendingComponentCreation && resetComponent();
        }
    };

    const resetComponent = () => {
        setComponent({ ...initialState });
        !pendingComponentCreation && closeDialog();
    };

    const updateComponent = () => {
        if (component.name.trim()) {
            const params = {
                id: currentComponent.id,
                name: component.name,
                internal: component.internal,
                isCustomer: component.isCustomer,
                importance: component.importance,
                description: component.description,
                disabled: component.disabled,
                teamId: teamId || '',
            };

            requestUpdateComponent(params, onSuccess);
            resetComponent();
        }
    };

    const handleSubmitEnter = (event) => {
        if (event.key === 'Enter') {
            edit ? updateComponent() : createComponent();
        }
    };

    const handleClickDeleteButton = () => {
        !pendingComponentCreation && handleDeleteComponent(currentComponent?.id);

        pendingComponentCreation && setModalOpen((prev) => ({ ...prev, component: false, replacement: true }));
    };

    return (
        <Dialog closeDialog={closeDialog} closeOnClickOutside={false}>
            <div className='component-dialog-wrapper' style={{ width: 622, height: 550 }}>
                <div className='component-dialog-header flex'>
                    <div className='flex align-flex-end'>
                        {isProcessType ? (
                            <StepIcon style={{ color: '#838fdb', height: '32', width: '32' }} />
                        ) : (
                            <ComponentIcon style={{ color: '#838fdb' }} />
                        )}

                        <Tooltip
                            message={getTranslation(
                                isProcessType ? 'TOOLTIP_MESSAGE_STEP' : 'TOOLTIP_MESSAGE_COMPONENT'
                            )}
                            containerClass='component-dialog-title'
                            innerText={getTranslation(
                                isProcessType ? 'EDIT_STEP_DIALOG_TITLE' : 'EDIT_COMPONENT_DIALOG_TITLE'
                            )}
                            innerTextClass=''
                        />
                    </div>
                    <img src={CloseIcon} alt='' className='pointer' onClick={() => closeDialog()} />
                </div>

                <div className='component-dialog-body flex-column'>
                    <CustomInput
                        autoFocus={true}
                        value={component.name}
                        onChange={handleChange}
                        onKeyDown={handleSubmitEnter}
                        name='name'
                        placeholder={getTranslation('CUSTOM_INPUT_LABEL_NAME')}
                        containerClass='flex-column mb-30'
                        emptyClassDisable
                    />

                    <div className='component-dialog-input-container flex align-center mb-30'>
                        <span className='component-dialog-importance-label component-importance flex align-center'>
                            {getTranslation('CUSTOM_INPUT_LABEL_IMPORTANCE')}
                        </span>
                        <CustomScoreComponentInput
                            value={component.importance}
                            maxValue={5}
                            setValue={(e) => setComponent({ ...component, importance: e })}
                        />
                    </div>

                    <div className='component-dialog-input-container flex align-center mb-30'>
                        <span className='component-dialog-label'>
                            {getTranslation(
                                isProcessType
                                    ? 'CUSTOM_INPUT_LABEL_VANTAGE_POINT'
                                    : 'CUSTOM_INPUT_LABEL_INTERNAL_EXTERNAL'
                            )}
                        </span>
                        <CustomSwitch
                            value={isProcessType ? isCustomerState : component.internal}
                            setValue={(e) =>
                                setComponent((prev) => {
                                    return !isProcessType && { ...prev, internal: e };
                                })
                            }
                            labels={isProcessType ? labelsPerson : labelsLocations}
                            invert
                            disabled={isProcessType ? true : component.locked}
                        />
                    </div>

                    <CustomTextarea
                        value={component.description}
                        onChange={handleChange}
                        placeholder={getTranslation('CUSTOM_INPUT_LABEL_COMMENTS')}
                        name='description'
                        containerClass='flex-column mb-20'
                        emptyClassDisable
                        textareaClass='attribute-dialog-importance-label'
                    />
                </div>

                <div className='component-dialog-buttons'>
                    <button
                        className='component-dialog-button secondary'
                        onClick={handleClickDeleteButton}
                        disabled={!pendingComponentCreation && !Boolean(currentComponent)}
                    >
                        {pendingComponentCreation
                            ? getTranslation('REPLACEMENT_CANCEL')
                            : getTranslation('EDIT_COMPONENT_DIALOG_BUTTON_DELETE')}
                    </button>
                    <button
                        className='component-dialog-button dark-blue-btn'
                        onClick={() => {
                            edit ? updateComponent() : createComponent();
                        }}
                    >
                        {getTranslation('EDIT_COMPONENT_DIALOG_BUTTON_SAVE')}
                    </button>
                </div>
            </div>
        </Dialog>
    );
};

export default ComponentAddModal;
