import React from 'react';
import Dialog from '../Dialog';
import './styles.css';

import { ReactComponent as ReplacementIcon } from '../../../assets/images/nav-replacement.svg';
import CloseIcon from '../../../assets/images/close-icon.svg';
import { getTranslation } from '../../../helpers/getLanguage';
import CustomScrollbar from '../../common/CustomScrollbar';
import Tooltip from '../../common/Tooltip';

const ComponentReplaceModal = ({
    components,
    closeDialog,
    activeComponent,
    currentComponentId,
    handleChooseComponent,
    projectType,
    setPendingComponentCreation,
    handleOpenIdeaModalAndCloseReplacement,
    handleOpenAddComponentAndCloseReplacement,
}) => {
    const activeComponentId = activeComponent.id;
    const componentToReplaceName = components.find((component) => component.id === currentComponentId)?.name;
    const internalComponents = components
        .filter((component) => component.internal && component.id !== currentComponentId)
        .map((component, index) => (
            <span
                className={
                    'flex pt-0 components-list-element' +
                    (activeComponentId === component.id ? ' active-component' : '') +
                    ' mt-2'
                }
                key={component.id}
                onClick={() => handleChooseComponent(component)}
            >
                <Tooltip
                    containerClass='replace-component-items_tooltip ellipsed-text'
                    position='top'
                    innerTextClass=''
                    message={component.name}
                    innerText={component.name}
                    tooltipMessageClass='tooltip-message white'
                />
            </span>
        ));
    const externalComponents = components
        .filter((component) => !component.internal && component.id !== currentComponentId)
        .map((component, index) => (
            <span
                className={
                    'flex pt-0 components-list-element' +
                    (activeComponentId === component.id ? ' active-component' : '') +
                    ' mt-2'
                }
                key={component.id}
                onClick={() => handleChooseComponent(component)}
            >
                <Tooltip
                    containerClass='replace-component-items_tooltip ellipsed-text'
                    position='top'
                    innerTextClass=''
                    message={component.name}
                    innerText={component.name}
                    tooltipMessageClass='tooltip-message white'
                />
            </span>
        ));

    const handleClickNext = () => {
        if (activeComponentId) {
            handleOpenIdeaModalAndCloseReplacement();
        } else {
            return;
        }
    };

    const handleAddNewComponent = () => {
        setPendingComponentCreation(true);
        handleOpenAddComponentAndCloseReplacement();
    };

    const isDisabledReplaceButton = !activeComponentId;

    return (
        <Dialog closeDialog={closeDialog} closeOnClickOutside={false}>
            <div className='component-dialog-wrapper' style={{ height: 730, width: 1000 }}>
                <div className='component-dialog-header flex flex-column' style={{ height: 140 }}>
                    <div className='component-header-replacement-top'>
                        <div
                            style={{ fontWeight: 500 }}
                            className='flex align-center component-header-replacement-label'
                        >
                            <ReplacementIcon style={{ width: 30, color: '#44ade2', marginRight: 10 }} />
                            <span className='custom-replace-label'>{getTranslation('MODAL_TITLE_REPLACEMENT')}</span>
                        </div>
                        <img src={CloseIcon} alt='' className='pointer' onClick={closeDialog} />
                    </div>
                    <span className='component-to-replace-in-title'>
                        {getTranslation('REPLACEMENT_THE')}
                        <u className='flex'>
                            <Tooltip
                                containerClass='component-to-replace-in-title_tooltip ellipsed-text'
                                position='top'
                                innerTextClass=''
                                message={componentToReplaceName}
                                innerText={componentToReplaceName}
                                tooltipMessageClass='tooltip-message white'
                            />
                        </u>
                        {projectType === 'process' 
                            ? getTranslation('REPLACEMENT_WITH_AN_STEP') 
                            : getTranslation('REPLACEMENT_WITH_AN')
                        } 
                    </span>
                </div>

                <div className='component-replace-dialog-body flex-column'>
                    <div className='flex justify-flex-end'>
                        <button
                            disabled={false}
                            onClick={handleAddNewComponent}
                            className='create-new-replacement-component'
                        >
                            +
                            {getTranslation(
                                projectType === 'process'
                                    ? 'REPLACEMENT_CLICK_TO_CREATE_STEP'
                                    : 'REPLACEMENT_CLICK_TO_CREATE_COMPONENT'
                            )}
                        </button>
                    </div>
                    {(externalComponents[0] || internalComponents[0]) && (
                        <div className='replace-components-container'>
                            <span style={{ marginBottom: 10, fontSize: 22 }} className='custom-replace-label'>
                                {projectType === 'process' 
                                    ? getTranslation('REPLACE_COMPONENTS_LABEL_STEP') 
                                    : getTranslation('REPLACE_COMPONENTS_LABEL')
                                }
                            </span>
                            <div className='flex' style={{ height: 350 }}>
                                {[internalComponents, externalComponents].map((components, isInternal) => (
                                    <div
                                        key={isInternal}
                                        className='replace-component-items'
                                        style={isInternal ? { marginLeft: 5 } : { paddingRight: 2 }}
                                    >
                                        <div className='flex replace-component-items_component'>
                                            <div
                                                className={`component-type flex-center ${isInternal ? 'external' : ''}`}
                                            >
                                                <Tooltip
                                                    message={getTranslation(
                                                        isInternal
                                                            ? 'TOOLTIP_MESSAGE_EXTERNAL_ATTRIBUTE'
                                                            : 'TOOLTIP_MESSAGE_INTERNAL_ATTRIBUTE'
                                                    )}
                                                    innerText={getTranslation(
                                                        isInternal
                                                            ? 'PAGE_COMPONENT_COMPONENT_TYPE_EXTERNAL'
                                                            : 'PAGE_COMPONENT_COMPONENT_TYPE_INTERNAL'
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <CustomScrollbar
                                            heightScroll={320}
                                            style={{ marginTop: 10, height: '95%' }}
                                            className={`replace-scrollbar-host justify-content-center`}
                                        >
                                            <div className='flex flex-wrap'>
                                                {components?.reverse()}
                                                {components?.length % 2 === 1 && <div style={{ width: 256 }} />}
                                            </div>
                                        </CustomScrollbar>
                                    </div>
                                ))}
                            </div>
                            <div className='flex justify-center'>
                                <button
                                    disabled={isDisabledReplaceButton}
                                    onClick={handleClickNext}
                                    style={isDisabledReplaceButton ? { background: '#a1b8ed' } : {}}
                                    className='select-replacement-component'
                                >
                                    {isDisabledReplaceButton
                                        ? projectType === 'process' 
                                            ? getTranslation('REPLACE_STEP_BUTTON') 
                                            : getTranslation('REPLACE_COMPONENT_BUTTON')
                                        : projectType === 'process' 
                                            ? `+ ${getTranslation('REPLACEMENT_SELECT_STEP')}` 
                                            : `+ ${getTranslation('REPLACEMENT_SELECT_COMPONENT')}`
                                    }
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
};

export default ComponentReplaceModal;
