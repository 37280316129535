import React from 'react';
import { useDrop } from 'react-dnd';
import MenuIcon from '../../../../../assets/images/menu-icon.svg';
import ProjectCardModal from '../../../../dialogs/CardModal';
import './styles.css';

export default function ProjectFolder({
    folderId,
    folderName,
    className,
    projectCount,
    setCurrentFolder,
    moveProjectToFolder,
    showFolderRenameModal,
    showFolderRemoveModal,
    showFolderArchiveModal,
    showFolderUnarchiveModal,
    archived,
}) {
    const [{ isHovered }, dropRef] = useDrop({
        accept: 'projectCard',
        drop: (item) => {
            moveProjectToFolder(item.id, folderId);
        },
        collect: (monitor) => ({ isHovered: monitor.isOver({ shallow: true }) }),
    });

    const menuItems = [
        {
            value: 'MENU_ITEM_RENAME_FOLDER',
            action: () => showFolderRenameModal(folderId, folderName),
            hidden: false,
        },
        {
            value: archived ? 'MENU_ITEM_UNARCHIVE_FOLDER' : 'MENU_ITEM_ARCHIVE_FOLDER',
            action: () => showFolderArchiveModal(folderId, folderName, archived),
            hidden: false,
        },
        {
            value: 'MENU_ITEM_DELETE_FOLDER',
            action: () => showFolderRemoveModal(folderId, folderName),
            hidden: false,
        },
    ];

    return (
        <div
            ref={dropRef}
            className={`flex project folder ${isHovered && 'isDragged'} ` + className}
            onClick={() => setCurrentFolder(folderId)}
        >
            <div className='folder-header'>
                <div style={{ position: 'relative' }}>
                    <div className={`folder-square`}></div>
                    <div className={`folder-rectangle folder-rectangle-rotated`}></div>
                </div>
                <ProjectCardModal items={menuItems} offsetX={-160} disabled={false}>
                    <button className={'menu-button folder-context-button'}>
                        <img src={MenuIcon} alt=''></img>
                    </button>
                </ProjectCardModal>
            </div>
            <div className='folder-title'>
                <div className='project-count-container'>
                    <span className='project-count-text'>{projectCount}</span>
                </div>
                <span>{folderName}</span>
            </div>
        </div>
    );
}
