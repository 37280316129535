import {
    GET_CURRENT_ISSUES,
    UPDATE_CURRENT_ISSUES,
    UNSHIFT_CURRENT_ISSUES,
    REMOVE_FROM_CURRENT_ISSUES,
} from '../actions/types';

const initialState = {
    currentIssues: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CURRENT_ISSUES:
            return {
                ...state,
                currentIssues: action.payload,
            };
        case UNSHIFT_CURRENT_ISSUES:
            return {
                ...state,
                currentIssues: [action.payload, ...state.currentIssues],
            };

        case UPDATE_CURRENT_ISSUES:
            return {
                ...state,
                currentIssues: state.currentIssues?.map((issue) =>
                    issue.id === action.payload.id ? action.payload : issue
                ),
            };
        case REMOVE_FROM_CURRENT_ISSUES:
            return {
                ...state,
                currentIssues: [...state.currentIssues?.filter((cI) => cI?.id !== action.payload)],
            };
        default:
            return state;
    }
}
