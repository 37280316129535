import enLocal from "../locale/en.json";
import store from "../store";
import { errorMessages } from "../constants/errorMessages";

export const getLanguage = (language) => {
  const location = require(`../locale/${language}.json`);

  return location || enLocal;
};

export const getTranslation = (string) => {
  const language = store.getState().auth.userInfo.language || "en";
  const location = getLanguage(language);
  return location[string] || string;
};

export const getTranslatedErrorMessage = (message, code, language) => {
  if (language === "en") {
    return message;
  }

  const errorCode = parseInt(code);
  const errorInfo = errorMessages.find((error) => error.code === errorCode);

  const errorMessage = errorInfo ? getTranslation(errorInfo.message) : null;

  return errorMessage;
};
