import React, { useState, useEffect } from 'react';
import Dialog from '../Dialog';
import './styles.css';

import {
    createAttribute as requestCreateAttribute,
    updateAttribute as requestUpdateAttribute,
    deleteAttribute as requestDeleteAttribute,
} from '../../../api/attributes';

import ConfirmationModal from '../ConfirmationModal';
import CustomInput from '../../inputs/CustomInput';
import CustomTextarea from '../../inputs/CustomTextarea';
import CustomScoreComponentInput from '../../inputs/CustomScoreComponentInput';

import { ReactComponent as AttributeIcon } from '../../../assets/images/attribute-icon-big.svg';
import CloseIcon from '../../../assets/images/close-icon.svg';

import Tooltip from '../../common/Tooltip';

import { getTranslation } from '../../../helpers/getLanguage';

const initialState = {
    name: '',
    description: '',
    importance: 1,
    comments: '',
    componentId: '',
    internal: true,
};

const popupMessages = {
    attribute: 'CONFIRM_MODAL_DELETE_ATTRIBUTE',
};

const AttributeAddModal = ({
    closeDialog,
    componentName,
    edit,
    selectedAttribute,
    productId,
    componentId,
    disabled,
    internal,
    onSuccess,
    teamId,
    titleText,
    suggestedMode,
    language,
}) => {
    const [attribute, setAttribute] = useState(initialState);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const currentAttribute = selectedAttribute.element;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAttribute({ ...attribute, [name]: value });
    };

    useEffect(() => {
        edit &&
            setAttribute({
                name: currentAttribute.name,
                description: currentAttribute.description || '',
                importance: currentAttribute.importance || 1,
                comments: currentAttribute.comments || '',
                componentId: currentAttribute.componentId,
            });
    }, [currentAttribute, edit]);

    useEffect(() => {
        if (suggestedMode?.mode && suggestedMode?.attribute?.name) {
            setAttribute({ ...initialState, name: suggestedMode?.attribute?.name });
        }
    }, [currentAttribute, suggestedMode]);

    const createAttribute = () => {
        if (attribute.name.trim()) {
            const params = {
                productId: productId,
                componentId: componentId,
                name: attribute.name.trim(),
                importance: attribute.importance,
                internal: internal,
                description: attribute.description,
                comments: attribute.comments,
                disabled: Boolean(disabled),
                teamId: teamId || '',
            };

            requestCreateAttribute(params, onSuccess);
            resetAttribute();
        }
    };

    const resetAttribute = () => {
        setAttribute({ ...initialState });
        closeDialog();
    };

    const deleteAttribute = () => {
        const params = { id: currentAttribute.id, teamId: teamId || '' };
        requestDeleteAttribute(params, onSuccess);
        resetAttribute();
        closeDialog();
    };

    const updateAttribute = () => {
        if (attribute.name) {
            const params = {
                id: currentAttribute.id,
                name: attribute.name,
                internal: currentAttribute.internal,
                importance: attribute.importance,
                componentId: attribute.componentId,
                version: Number(currentAttribute.version) + 1,
                description: attribute.description,
                comments: attribute.comments,
                disabled: currentAttribute.disabled,
                teamId: teamId || '',
            };

            requestUpdateAttribute(params, onSuccess);
            resetAttribute();
        }
    };

    const handleSubmitEnter = (event) => {
        if (event.key === 'Enter') {
            edit ? updateAttribute() : createAttribute();
        }
    };

    return (
        <Dialog closeDialog={closeDialog} draggable closeOnClickOutside={false}>
            <div className='attribute-dialog-wrapper' style={{ height: 600 }}>
                <div className='attribute-dialog-header flex draggable-handle'>
                    <div className='flex align-center'>
                        <AttributeIcon style={{ color: '#838fdb' }} />
                        {titleText?.length && <div className='attribute-dialog-title-text'>{titleText}</div>}
                        {!titleText?.length && (
                            <Tooltip
                                message={getTranslation('TOOLTIP_MESSAGE_ATTRIBUTE')}
                                innerTextClass=''
                                containerClass='attribute-dialog-title'
                            >
                                <div className='flex'>
                                    <div className='add-attribute-component-name'>
                                        <span>
                                            {`${componentName}${
                                                language === 'en'
                                                    ? componentName[componentName.length - 1]?.toLowerCase() === 's'
                                                        ? "'"
                                                        : "'s"
                                                    : ''
                                            }`}
                                        </span>
                                    </div>
                                    {getTranslation('EDIT_ATTRIBUTE_DIALOG_TITLE')}
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <img src={CloseIcon} alt='' className='pointer' onClick={() => closeDialog()} />
                </div>

                <div className='attribute-dialog-body flex-column'>
                    <CustomInput
                        value={attribute.name}
                        onChange={handleChange}
                        onKeyDown={handleSubmitEnter}
                        placeholder={getTranslation('CUSTOM_INPUT_LABEL_NAME')}
                        name='name'
                        emptyClassDisable
                        containerClass='flex-column mb-20'
                    />

                    <CustomTextarea
                        value={attribute.description}
                        onChange={handleChange}
                        placeholder={getTranslation('CUSTOM_TEXT_AREA_LABEL_DESCRIPTION')}
                        name='description'
                        containerClass='flex-column mb-30'
                        emptyClassDisable
                    />

                    <div className='attribute-dialog-input-container flex align-center mb-30'>
                        <span className='attribute-dialog-importance-label attribute-importance flex align-center'>
                            {getTranslation('CUSTOM_INPUT_LABEL_IMPORTANCE')}
                        </span>
                        <CustomScoreComponentInput
                            value={attribute.importance}
                            maxValue={5}
                            setValue={(e) => setAttribute({ ...attribute, importance: e })}
                        />
                    </div>

                    <CustomTextarea
                        value={attribute.comments}
                        onChange={handleChange}
                        placeholder={getTranslation('CUSTOM_INPUT_LABEL_COMMENTS')}
                        name='comments'
                        containerClass='flex-column'
                        emptyClassDisable
                    />
                </div>

                <div className='attribute-dialog-buttons'>
                    <button
                        className='attribute-dialog-button secondary'
                        onClick={() => setDeleteModalOpen(true)}
                        disabled={!Boolean(currentAttribute)}
                    >
                        {getTranslation('EDIT_ATTRIBUTE_DIALOG_BUTTON_DELETE')}
                    </button>
                    <button
                        className='attribute-dialog-button dark-blue-btn'
                        onClick={() => {
                            edit ? updateAttribute() : createAttribute();
                        }}
                    >
                        {getTranslation('EDIT_ATTRIBUTE_DIALOG_BUTTON_SAVE')}
                    </button>
                </div>
            </div>
            {deleteModalOpen && (
                <ConfirmationModal
                    closeDialog={() => setDeleteModalOpen(false)}
                    message={getTranslation(popupMessages.attribute)}
                    autoFocus={true}
                    buttonText={getTranslation('EDIT_ATTRIBUTE_DIALOG_BUTTON_DELETE')}
                    onConfirm={() => deleteAttribute()}
                />
            )}
        </Dialog>
    );
};

export default AttributeAddModal;
