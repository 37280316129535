import React from 'react';

import { ReactComponent as TeamIcon } from '../../../assets/images/nav-users.svg';
import { ReactComponent as IndividualIcon } from '../../../assets/images/nav-user.svg';
import { getTranslation } from '../../../helpers/getLanguage';

const TeamLabel = ({ team = '', count }) => {
    const isIndividual = !team.length;
    const activeClass = isIndividual ? ' individual' : '';

    return (
        <div className={'team-type-indicator flex align-center' + activeClass}>
            {isIndividual ? <IndividualIcon /> : <TeamIcon />}
            {count >= 0 && <span className="team-type-count">{count}</span>}
            <span className="team-type-label">{team || getTranslation("TEAM_TYPE_LABEL_INDIVIDUAL")}</span>
        </div>
    );
};

export default TeamLabel;
