import React from 'react';
import BlockDiagram from './diagrams/BlockDiagram';
import DonutDiagram from './diagrams/DonutDiagram';

import { ReactComponent as AttributeIcon } from '../../../assets/images/attributes-icon-longer.svg';
import AttributeIconLarge from '../../../assets/images/attributes-icon-large.svg';

import Tooltip from '../../common/Tooltip';
import { getTranslation } from '../../../helpers/getLanguage';

const blockLabels = ['BLOCK_LABEL_INTERNAL', 'BLOCK_LABEL_EXTERNAL'];
const tooltips = ['TOOLTIP_MESSAGE_INTERNAL_ATTRIBUTE', 'TOOLTIP_MESSAGE_EXTERNAL_ATTRIBUTE'];

const AttributesCard = ({ attributes, PlusIconSmall, navigateToComponents, projectType }) => {
    const internal = attributes.filter((attribute) => attribute.internal).length;
    const external = attributes.filter((attribute) => !attribute.internal).length;
    const diagramValues = [internal, external];

    return (
        <div className='project-dashboard-card flex'>
            {!attributes.length && (
                <>
                    <div className='project-card-content flex-column'>
                        <div
                            className='project-dashboard-card-title-container flex align-center pointer'
                            onClick={() => navigateToComponents()}
                        >
                            <AttributeIcon style={{ color: '#8a93c3' }} />
                            <Tooltip
                                message={getTranslation('TOOLTIP_MESSAGE_ATTRIBUTE')}
                                containerClass='project-dashboard-card-title'
                                innerText={getTranslation('TOOLTIP_INNER_TEXT_ATTRIBUTES')}
                            />
                        </div>

                        <div className='dashboard-card-button-container'>
                            <span className='dashboard-card-description mb-20'>
                                {getTranslation(
                                    projectType === 'process' ? 'DASHBOARD_CARD_DESCRIPTION_STEPS' : 'DASHBOARD_CARD_DESCRIPTION_ATTRIBUTES'
                                )}
                            </span>
                            <button className='dashboard-card-button flex mt-20' onClick={() => navigateToComponents()}>
                                <span>
                                    {getTranslation(
                                        projectType === 'process' ? 'ADD_EDIT_STEPS_BUTTON' : 'ADD_EDIT_COMPONENTS_BUTTON'
                                    )}
                                </span>
                                <PlusIconSmall />
                            </button>
                        </div>
                    </div>

                    <div className='flex-center card-image-container'>
                        <img className='attributes-icon' src={AttributeIconLarge} alt='' />
                    </div>
                </>
            )}

            {attributes.length > 0 && (
                <div className='project-card-content flex-column'>
                    <div className='flex justify-space-between'>
                        <div
                            className='project-dashboard-card-title-container flex align-center pointer'
                            onClick={() => navigateToComponents()}
                        >
                            <AttributeIcon style={{ color: '#8a93c3' }} />
                            <Tooltip
                                message={getTranslation('TOOLTIP_MESSAGE_ATTRIBUTE')}
                                containerClass='project-dashboard-card-title'
                                innerText={getTranslation('TOOLTIP_INNER_TEXT_ATTRIBUTES')}
                            />
                        </div>

                        <button className='dashboard-card-button medium flex' onClick={() => navigateToComponents()}>
                            <span>
                                {getTranslation(projectType === 'process' ? 'ADD_EDIT_STEPS_BUTTON' : 'ADD_EDIT_COMPONENTS_BUTTON')}
                            </span>
                            <PlusIconSmall />
                        </button>
                    </div>

                    <div className='card-content-container height-100'>
                        <div className='donut-diagram flex align-center justify-space-around'>
                            <DonutDiagram values={diagramValues} />
                            <div className='card-right-diagram-container'>
                                <BlockDiagram
                                    projectType={projectType}
                                    values={diagramValues}
                                    labels={blockLabels}
                                    tooltips={tooltips}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AttributesCard;
