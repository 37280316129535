import React from 'react';
import SupportModal from '../common/SupportModal';

import { getTranslation } from '../../helpers/getLanguage';

const BottomLinks = ({ handleRecoveryClick, supportText = 'LOGIN_PAGE_BOTTOM_LINKS_SUPPORT_TEXT' }) => {
    return (
        <div className="login-links flex justify-space-between">
            <SupportModal>
                <span className="pointer underlined login-link">{getTranslation(supportText)}</span>
            </SupportModal>

            <span className="login-link"> • </span>
            <span className="pointer underlined login-link" onClick={handleRecoveryClick}>
                {getTranslation("LOGIN_PAGE_BOTTOM_LINKS_FORGOT_PASSWORD")}
            </span>
        </div>
    );
};

export default BottomLinks;
