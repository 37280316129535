import React, { useEffect, useState } from 'react';
import GenerateModalWindow from '../../../GenerateModalWindow';
import CustomScrollbar from '../../../../common/CustomScrollbar';
import Issue from './Issue';
import CustomScoreInput from '../../../../inputs/CustomScoreInput';
import { getTranslation } from '../../../../../helpers/getLanguage';
import { ratingColors, ratingSelection } from '../../../../../constants/rating';
import { ReactComponent as AIGenerateIcon } from '../../../../../assets/images/ai-generate-icon.svg';

const Issues = ({ 
    issues, 
    userId, 
    messages, 
    addIssue, 
    addIssueMessage, 
    feasibleScore, 
    handleChangeFeasibleScore,
    handleGenerateChallenges, 
    openGenerateChallengesModal,
    closeGenerateChallengesModal,
    suggestedChallenges,
    suggestedLoading,
    addGenerateChallenges,
    selectGenerateOption,
    selectedGenerateOptions,
    ...props 
}) => {

    const generatedUnusedChallenges = suggestedChallenges?.filter(nameChallenge => !issues.find(issue => {
        return (
            `${issue.comment}:${issue.description}` === nameChallenge
        );
    })) || [];

    const [updScrollbar, setUpdScrollbar] = useState(false);

    const onCommentSolve = (setIsShowThread, issueId, issueIndex) => {
        setUpdScrollbar(!updScrollbar);
        if (
            messages.filter((message) =>
                message.issueId ? message.issueId === issueId : message.issueIndex === issueIndex
            ).length === 0
        ) {
            addIssueMessage(issueId, issueIndex);
        }
        setIsShowThread((prev) => !prev);
    };

    useEffect(() => {
        if (issues.length === 0) {
            addIssue();
        }
    }, []);

    const issueElements = issues.map((issue, index) => {
        const indexedMessages = messages.map((message, index) => ({ ...message, index }));
        const filteredMessages = indexedMessages.filter((message) =>
            message.issueId ? message.issueId === issue.id : index === message.issueIndex
        );

        if (!issue.userId) {
            issue.userId = userId;
        }

        return (
            <Issue
                key={index}
                issue={issue}
                userId={userId}
                issues={issues}
                messages={filteredMessages}
                userFullName={props.userFullName}
                index={index}
                reversed={props.reversed}
                addItem={addIssue}
                removeItem={props.removeIssue}
                removeLastIssue={props.removeLastIssue}
                removeMessage={props.removeMessage}
                removeLastMessage={props.removeLastMessage}
                handleChange={props.handleChange}
                addMessage={addIssueMessage}
                handleMessageChange={props.handleIssueMessageChange}
                onCommentSolve={onCommentSolve}
            />
        );
    });

    return (
        <>
            <div className="flex justify-center ">
                <label className='dependency-modal__challenges-header'>{getTranslation('IDEA_MODAL_LABEL_IMPLEMENTATION_CHALLENGES')}</label>
                <button 
                    className="dependency-modal__button-ai dependency-modal__button-ai_challenges"
                    onClick={handleGenerateChallenges}
                >
                    <AIGenerateIcon style={{ width: "20px", marginRight: "8px" }}/>
                    <span>{getTranslation("GENERATE_CHALLENGES_BUTTON")}</span>
                </button>
            </div>
            <div className='issue-input-container flex-column'>
                <CustomScrollbar right={-20} dependencies={[issues, messages, updScrollbar]} style={{ height: "215px" }}>
                    {issueElements}
                </CustomScrollbar>

                <div className="flex-center flex-column">
                    <span className='dependency-modal-label mb-1'>{getTranslation('RATE_FEASIBILITY_TEXT')}</span>
                    <CustomScoreInput
                        value={feasibleScore}
                        maxValue={5}
                        isRateIdea={true}
                        colors={ratingColors}
                        rectangleStyle={ratingSelection}
                        setValue={(e) => handleChangeFeasibleScore(e)}
                        startFromZero
                    />
                </div>
            </div>
            {openGenerateChallengesModal &&
                <GenerateModalWindow
                    headerModalWindow={getTranslation("GENERATE_CHALLENGES_MODAL_HEADER")} 
                    closeGenerateModal={closeGenerateChallengesModal}
                    suggestedOptions={generatedUnusedChallenges}
                    suggestedLoading={suggestedLoading}
                    handleClickButton={addGenerateChallenges}
                    titleDisableButton={getTranslation("GENERATE_CHALLENGES_MODAL_SELECT_BUTTON")}
                    titleActionButton={getTranslation("GENERATE_CHALLENGES_MODAL_ADD_BUTTON")}
                    selectGenerateOption={selectGenerateOption}
                    selectedGenerateOptions={selectedGenerateOptions}
                    textWhenNoGeneratedOption={getTranslation('GENERATE_CHALLENGES_MODAL_TEXT_WHEN_NO_CHALLENGES')}
                />
            }
        </>
    );
};

export default Issues;
