import axios from 'axios';
import { handleSuccess, handleError } from './handlers';
import { baseApiUrl } from '../common/constants';

const baseUrl = `${baseApiUrl}/attributes`;

export const createAttribute = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/create`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const createAttributes = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/create/many`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const updateAttribute = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/update`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const deleteAttribute = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/delete`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const disableAttribute = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/disable`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const duplicateAttribute = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/duplicate`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};

export const copyAttribute = async (params, onSuccess, onError) => {
    axios
        .post(`${baseUrl}/copy`, params)
        .then(response => handleSuccess(response, onSuccess))
        .catch(error => handleError(error, onError));
};
