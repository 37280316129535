import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCurrentIssuesRedux } from '../../actions/currentIssuesActions';
import IssueItem from './issue-item';
import './styles.css';

const Issues = ({ getCurrentIssuesRedux, currentIssues }) => {
    useEffect(() => {
        if (!currentIssues?.length) {
            getCurrentIssuesRedux();
        }
    }, []);

    return (
        <div className='issues-main flex justify-content-center flex-column'>
            <div className='flex justify-content-center'>
                <div className='header-image-container flex' style={{ width: '95%' }}>
                    <div className='blue-rectangle flex'>
                        <div className='welcome-container flex-column'>
                            <span className='welcome-title'>Issues page</span>
                            <span className='welcome-text'>
                                This page contains any ongoing issues we
                                <br /> know about and are currently working to fix!
                            </span>
                        </div>
                    </div>
                    <div className='image-container'>
                        <img src='/static/media/ideation-illustration.46ee3498.svg' alt='' />
                    </div>
                </div>
            </div>
            <span className='issue-display-title projects-title'>Current list of ongoing / fixed issues:</span>
            <div className='issue-display-container'>
                {currentIssues?.length ? (
                    currentIssues.map((i) => (
                        <IssueItem key={i.id} title={i.title} text={i.text} date={i.date} ongoing={i.ongoing} />
                    ))
                ) : (
                    <h2>No issues</h2>
                )}
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    currentIssues: state.currentIssues.currentIssues,
});

const mapDispatchToProps = {
    getCurrentIssuesRedux,
};

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
