import React, { useState } from 'react';

import CustomDropdown from '../inputs/CustomDropdown';
import CustomSearchInput from '../inputs/CustomSearchInput';

import ViewTutorialButtonWithModal from '../common/ViewTutorialButtonWithModal';

import { ReactComponent as PlusSign } from '../../assets/images/add-button-blue-round.svg';
import { ReactComponent as FolderIcon } from '../../assets/images/folder-icon.svg';
import { ReactComponent as ArrowUpDown } from '../../assets/images/arrow-up-down.svg';

import { getTranslation } from '../../helpers/getLanguage';
import SingleInputDialog from '../dialogs/SingleInputDialog';
import Tooltip from '../common/Tooltip';

const sortOptions = [
    { value: 'lastVisited', label: 'SORT_OPTIONS_LAST_MODIFIED' },
    { value: 'created', label: 'SORT_OPTIONS_DATE_CREATED' },
    { value: 'name', label: 'SORT_OPTIONS_TITLE_ALPHABETICAL' },
    { value: 'ideasCount', label: 'SORT_OPTIONS_NUMBER_OF_IDEAS' },
    { value: 'completion', label: 'SORT_OPTIONS_COMPLETION_LABEL' },
];

const filterOptions = [
    { value: '', label: 'FILTER_OPTIONS_COMPLETION_LABEL' },
    { value: 'private', label: 'FILTER_OPTIONS_LAST_MODIFIED' },
    { value: 'shared', label: 'FILTER_OPTIONS_Date created' },
    { value: 'team', label: 'FILTER_OPTIONS_TITLE_ALPHABETICAL' },
];

const DashboardInputs = ({
    projectsCount = 0,
    projectModalOpen,
    setModalOpen,
    filters,
    setFilters,
    userStatus,
    createFolder,
    history,
    folders,
}) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };
    const [modalVisible, setModalVisible] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [folderNameError, setFolderNameError] = useState('');

    const handleCreateFolderOnChange = (e) => {
        setNewFolderName(e.target.value);

        if (folders.find((f) => f.name === e.target.value.trim())) {
            return setFolderNameError('Duplicate folder name');
        }

        folderNameError && setFolderNameError('');
    };

    const handleCreateFolder = () => {
        const onSuccess = () => {
            setModalVisible(false);
        };
        createFolder(newFolderName, onSuccess);
    };

    const shouldDisplayFolder = !history?.location?.pathname.includes('folders');

    return (
        <div className='flex-wrap justify-space-between mb-20'>
            <div className='flex-column'>
                <ViewTutorialButtonWithModal name='All projects' className='mb-10' />

                <div className='flex-wrap justify-space-between align-center mb-10'>
                    <span className='projects-title flex'>{getTranslation('PAGE_ALL_PROJECTS_MY_PROJECTS_TITLE')}</span>
                    <span className='dashboard-project-count'>{projectsCount}</span>
                    {userStatus.premium && (
                        <Tooltip 
                            message={getTranslation("DASHBOARD_ADD_NEW_PROJECT_BUTTON_TOOLTIP")} 
                            tooltipMessageClass='my-projects-tooltip' 
                            position='top'
                        >
                            <PlusSign
                                className='pointer add-project-button-round'
                                onClick={() => {
                                    setModalOpen({ delete: false, project: !projectModalOpen });
                                }}
                            />
                        </Tooltip>
                    )}
                    {userStatus.premium && shouldDisplayFolder && (
                        <Tooltip
                            message={getTranslation("DASHBOARD_ADD_NEW_FOLDER_BUTTON_TOOLTIP")}
                            tooltipMessageClass='my-projects-tooltip'
                            containerClass='create-folder-icon'
                            position='top'
                        >
                            <FolderIcon
                                className='pointer folder-icon-class'
                                onClick={() => {
                                    setNewFolderName('');
                                    setModalVisible(true);
                                }}
                                fill='white'
                            />
                        </Tooltip>
                    )}
                </div>
            </div>

            <div className='flex align-center justify-space-between'>
                <div className='project-input-container first flex align-center'>
                    <span className='input-label'>{getTranslation('SORT_BY')}</span>
                    <button
                        className='reverse-sort-button'
                        onClick={() => {
                            setFilters({ ...filters, reverseSort: !filters.reverseSort });
                        }}
                    >
                        <ArrowUpDown />
                    </button>
                    <CustomDropdown
                        value={filters.sortBy}
                        handleChange={(e) => setFilters({ ...filters, sortBy: e })}
                        options={sortOptions}
                    />
                </div>

                <div className='project-input-container flex align-center'>
                    <CustomDropdown
                        value={filters.filterBy}
                        handleChange={(e) => setFilters({ ...filters, filterBy: e })}
                        options={filterOptions}
                    />
                </div>

                <CustomSearchInput
                    name='search'
                    placeholder={getTranslation('SEARCH_PROJECTS')}
                    value={filters.search}
                    onChange={handleChange}
                    containerClass='search-input-container project-search max-width-250px'
                />
                {modalVisible && (
                    <SingleInputDialog
                        value={newFolderName}
                        onChange={handleCreateFolderOnChange}
                        onClick={handleCreateFolder}
                        handleCloseModal={() => setModalVisible(false)}
                        titleText={getTranslation('FOLDER_DIALOG_CREATE_TITLE')}
                        label={getTranslation('FOLDER_DIALOG_CREATE_LABEL')}
                        buttonText={getTranslation('GENERAL_BUTTON_TEXT_CREATE')}
                        errorMessage={folderNameError}
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardInputs;
