import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { parseLocationSearch } from '../../common/parseLocationSearch';
import { verifyEmail } from '../../api/auth';

import LoginLayout from '../common/LoginLayout';

import { getTranslation } from '../../helpers/getLanguage';

import './style.css';

const ConfirmEmail = ({ history, auth, location }) => {
    const [isVerified, setIsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (auth.isAuthenticated) {
            history.push('/');
        }
    }, [auth, history]);

    useEffect(() => {
        setIsLoading(true);

        const search = parseLocationSearch(location.search);
        const { token } = search;
        if (token) {
            const params = { token };

            const onSuccess = () => {
                setIsLoading(false);
                setIsVerified(true);
            };

            const onError = error => {
                console.error(error);
                setIsLoading(false);
            };

            verifyEmail(params, onSuccess, onError);
        } else {
            setIsLoading(false);
        }
    }, [location.search]);

    const handleLoginClick = () => {
        history.push('/profile?tab=1&modal=subscription');
    };

    return (
        <LoginLayout>
            <div className="success-confirm-email width-100 flex-column align-center">
                {!isLoading && isVerified && (
                    <>
                        <span className="login-title mb-20">{getTranslation("LOGIN_PAGE_EMAIL_VERIFICATION_TITLE")}</span>
                        <div className="login-text mb-20">
                            <span>{getTranslation("LOGIN_PAGE_EMAIL_VERIFICATION_MESSAGE")}</span>
                        </div>

                        <div className="login-links">
                            <span className="pointer underlined login-link" onClick={handleLoginClick}>
                                {getTranslation("LOGIN_PAGE_EMAIL_VERIFICATION_BACK_TO_LOGIN")}
                            </span>
                        </div>
                    </>
                )}

                {!isLoading && !isVerified && (
                    <>
                        <span className="login-title mb-20">{getTranslation("LOGIN_PAGE_EMAIL_VERIFICATION_ERROR_TITLE")}</span>
                        <div className="login-text mb-20">
                            <span>
                                {getTranslation("LOGIN_PAGE_EMAIL_VERIFICATION_ERROR_MESSAGE")}
                            </span>
                        </div>

                        <div className="login-links">
                            <span className="pointer underlined login-link" onClick={() => history.push('/')}>
                                {getTranslation("LOGIN_PAGE_EMAIL_VERIFICATION_ERROR_BACK_TO_LOGIN")}
                            </span>
                        </div>
                    </>
                )}
            </div>
        </LoginLayout>
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(ConfirmEmail);
