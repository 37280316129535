import React, { useCallback, useMemo } from 'react';
import { ReactComponent as PlusIcon } from '../../../../assets/images/plus-icon.svg';
import { isProjectEditable } from '../../../../common/project';
import ProjectCard from './ProjectCard';
import './styles.css';

import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getTranslation } from '../../../../helpers/getLanguage';
import SingleDropdownDialog from '../../../dialogs/SingleDropdownDialog';
import SingleInputDialog from '../../../dialogs/SingleInputDialog';
import WarningModal from '../../../dialogs/WarningModal';
import BackButton from './BackButton';
import Folder from './Folder';

const initialFolderDialogState = {
    selectedFolder: { id: '', folderName: '', archived: false },
    folderRename: false,
    folderRemove: false,
    folderCreate: false,
    transferProject: false,
};

const initialFolderTransferState = { projectId: '', folderId: '' };

const initialErrorTextStat = { renameFolder: '' };

const Projects = ({
    projects,
    projectModalOpen,
    setProjectModalOpen,
    history,
    archived,
    filters,
    handleModalOpen,
    handleDuplicate,
    handleDelete,
    handleArchive,
    isProjectArchived,
    userStatus,
    userId,
    projectsReady,
    handleImport,
    userFeatures,
    folders,
    moveProjectToFolder,
    moveProjectOutOfFolder,
    renameFolder,
    removeFolder,
    archiveFolder,
    activeTab,
    match,
}) => {
    const [currentFolder, setCurrentFolder] = useState(null);
    const [folderDialogs, setFolderDialogs] = useState(initialFolderDialogState);
    const [transferToFolder, setTransferToFolder] = useState(initialFolderTransferState);
    const [isDragging, setIsDragging] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const [textErrors, setTextErrors] = useState(initialErrorTextStat);

    const folderOptions = useMemo(
        () =>
            folders
                ?.map((folder) => ({ value: folder.id, label: folder.name, archived: folder?.archived ?? false }))
                .filter((f) => (activeTab === 2 ? f.archived : !f?.archived)),
        [folders, activeTab]
    );

    useEffect(() => {
        if (currentFolder && history?.location?.pathname == '/') {
            setCurrentFolder(null);
        }

        if (match?.params?.folderId) {
            const foundFolder = folders?.find((f) => f?.id === match?.params?.folderId);

            if (!foundFolder) {
                history.push('/');
                setCurrentFolder(null);
                return;
            }

            if ((!foundFolder.archived && activeTab > 0) || (foundFolder.archived && activeTab < 2)) {
                setCurrentFolder(null);
                history.push('/');
                return;
            }

            setCurrentFolder(foundFolder?.id);
        }
    }, [match, history, activeTab]);

    const handleCloseModal = () => {
        setFolderDialogs(initialFolderDialogState);
        setTextErrors(initialErrorTextStat);
    };

    const handleSelectProjectForTransfer = (projectId) => {
        setFolderDialogs({ ...folderDialogs, transferProject: true });
        setTransferToFolder({ folderId: currentFolder ? null : '', projectId });
    };

    const handleSelectFolderToTransferTo = (folderId) => {
        setTransferToFolder({ ...transferToFolder, folderId });
    };

    const handleMoveFolderContextMenu = () => {
        if (!transferToFolder?.folderId) {
            const foundFolder = folders.find((f) => f?.projects?.includes(transferToFolder.projectId));

            moveProjectOutOfFolder(transferToFolder.projectId, foundFolder.id, handleCloseModal);
            return;
        }

        moveProjectToFolder(transferToFolder.projectId, transferToFolder.folderId, handleCloseModal);
    };

    const handleMoveProjectOutOfFolder = (projectId) => {
        if (!currentFolder) return;

        moveProjectOutOfFolder(projectId, currentFolder);
    };

    const handleRenameFolderOnChange = (e) => {
        setNewFolderName(e.target.value);

        const foundFolder = folders.find((f) => f.name === e.target.value.trim());

        if (foundFolder && foundFolder?.id !== folderDialogs?.selectedFolder?.id) {
            return setTextErrors({ ...textErrors, renameFolder: 'Duplicate folder name' });
        }

        textErrors.renameFolder && setTextErrors({ ...textErrors, renameFolder: '' });
    };

    const showFolderRenameModal = (folderId, folderName) => {
        setNewFolderName(folderName);
        setFolderDialogs({ ...folderDialogs, selectedFolder: { id: folderId, folderName }, folderRename: true });
    };

    const handleRenameFolder = () => {
        renameFolder(folderDialogs?.selectedFolder?.id, newFolderName, handleCloseModal);
    };

    const showFolderArchiveModal = (folderId, folderName, archived) => {
        setFolderDialogs({
            ...folderDialogs,
            selectedFolder: { id: folderId, folderName, archived },
            folderArchive: true,
        });
    };
    const showFolderUnarchiveModal = (folderId, folderName) => {
        setFolderDialogs({ ...folderDialogs, selectedFolder: { id: folderId, folderName }, folderUnarchive: true });
    };

    const showFolderRemoveModal = (folderId, folderName) => {
        setFolderDialogs({ ...folderDialogs, selectedFolder: { id: folderId, folderName }, folderRemove: true });
    };

    const handleFolderRemoval = (folderId) => {
        removeFolder(folderId, handleCloseModal);
    };

    const handleSetCurrentFolder = (folderId) => {
        if (!folderId) {
            history.push('/');
        } else {
            history.push(`/folders/${folderId}`);
        }

        setCurrentFolder(folderId);
    };

    const projectCards = useCallback(
        projects?.map((project, index) => {
            const projectComponent = (
                <ProjectCard
                    key={index}
                    project={project}
                    userFeatures={userFeatures}
                    handleImport={handleImport}
                    projectModalOpen={projectModalOpen}
                    setProjectModalOpen={setProjectModalOpen}
                    history={history}
                    handleModalOpen={handleModalOpen}
                    handleDuplicate={handleDuplicate}
                    handleDelete={handleDelete}
                    handleArchive={handleArchive}
                    isProjectArchived={isProjectArchived}
                    disabled={!userStatus.premium}
                    canEdit={isProjectEditable(project, userId)}
                    order={index}
                    {...{ setIsDragging, handleSelectProjectForTransfer }}
                />
            );
            if (activeTab && activeTab === 1) {
                return projectComponent;
            }

            // If in folder && currentFolder includes this project
            if (currentFolder && folders?.find((f) => f.id === currentFolder)?.projects?.includes(project.id)) {
                return projectComponent;
            }

            // If any of the folders include the project, do not display it
            if (folders?.some((folder) => folder.projects.includes(project.id))) {
                return null;
            }

            return !currentFolder && projectComponent;
        }),
        [projects, folders, currentFolder, activeTab]
    );

    const folderCards = useCallback(() => {
        if (activeTab === 1) {
            return null;
        }

        return folders?.map((folder, index) => {
            if ((folder.archived && activeTab === 0) || (activeTab === 2 && !folder.archived)) {
                return null;
            }

            return (
                <Folder
                    folderId={folder.id}
                    folderName={folder.name}
                    setCurrentFolder={handleSetCurrentFolder}
                    projectCount={folder?.projects?.length ?? 0}
                    archived={folder?.archived}
                    {...{
                        moveProjectToFolder,
                        showFolderRenameModal,
                        showFolderRemoveModal,
                        showFolderArchiveModal,
                        showFolderUnarchiveModal,
                    }}
                    key={index}
                />
            );
        });
    }, [folders, projects, currentFolder, activeTab]);

    const showButton = projectsReady && !projects.length && !archived && !filters.filterBy && !filters.search.trim();

    return (
        <div className='flex flex-wrap projects-wrapper width-100 height-100'>
            {showButton && userStatus.premium && (
                <div
                    className='add-project flex-column flex-center pointer'
                    onClick={() => setProjectModalOpen(!projectModalOpen)}
                >
                    <PlusIcon className='add-project-icon' />
                    <span>{getTranslation('ADD_A_PROJECT')}</span>
                </div>
            )}
            <DndProvider backend={HTML5Backend}>
                {currentFolder ? (
                    <BackButton
                        setCurrentFolder={handleSetCurrentFolder}
                        moveProjectOutOfFolder={handleMoveProjectOutOfFolder}
                        {...{ isDragging }}
                    />
                ) : (
                    folderCards()
                )}
                {!showButton && projectCards}
                {folderDialogs.folderRename && (
                    <SingleInputDialog
                        value={newFolderName}
                        onChange={handleRenameFolderOnChange}
                        onClick={handleRenameFolder}
                        handleCloseModal={handleCloseModal}
                        titleText={getTranslation('FOLDER_DIALOG_RENAME_TITLE')}
                        label={getTranslation('FOLDER_DIALOG_RENAME_LABEL')}
                        buttonText={getTranslation('GENERAL_BUTTON_TEXT_SAVE')}
                        errorMessage={textErrors.renameFolder}
                    />
                )}
                {folderDialogs.folderRemove && (
                    <WarningModal
                        closeDialog={handleCloseModal}
                        onConfirm={() => handleFolderRemoval(folderDialogs.selectedFolder.id)}
                        onCancel={handleCloseModal}
                        message={`${getTranslation('FOLDER_DIALOG_DELETE_TITLE')} ${
                            folderDialogs?.selectedFolder?.folderName
                        }?`}
                        buttonText={getTranslation('GENERAL_BUTTON_TEXT_DELETE')}
                        textContainerClass='folder-delete-confirmation-modal-text'
                        className={'folder-delete-popup'}
                        rightButtonText={'GENERAL_BUTTON_TEXT_YES'}
                        leftButtonText={'GENERAL_BUTTON_TEXT_NO'}
                        closeOnClickOutside
                    />
                )}
                {folderDialogs.transferProject && (
                    <SingleDropdownDialog
                        onClick={handleMoveFolderContextMenu}
                        handleCloseModal={handleCloseModal}
                        titleText={getTranslation('FOLDER_DIALOG_TRANSFER_PROJECT_TO')}
                        buttonText={getTranslation('GENERAL_BUTTON_TEXT_CONFIRM')}
                        options={currentFolder ? [{ value: null, label: '--MOVE TO ROOT--' }] : folderOptions}
                        value={transferToFolder?.folderId}
                        onChange={handleSelectFolderToTransferTo}
                        z
                    />
                )}
                {folderDialogs.folderArchive && (
                    <WarningModal
                        closeDialog={handleCloseModal}
                        onConfirm={() => archiveFolder(folderDialogs.selectedFolder.id, handleCloseModal)}
                        onCancel={handleCloseModal}
                        message={
                            folderDialogs.selectedFolder.archived
                                ? `${getTranslation('FOLDER_DIALOG_UNARCHIVE_TITLE')} ${
                                      folderDialogs?.selectedFolder?.folderName
                                  }?`
                                : `${getTranslation('FOLDER_DIALOG_ARCHIVE_TITLE')} ${
                                      folderDialogs?.selectedFolder?.folderName
                                  }?`
                        }
                        buttonText={getTranslation('GENERAL_BUTTON_TEXT_DELETE')}
                        textContainerClass='folder-delete-confirmation-modal-text'
                        className={'folder-delete-popup'}
                        rightButtonText={'GENERAL_BUTTON_TEXT_YES'}
                        leftButtonText={'GENERAL_BUTTON_TEXT_NO'}
                        closeOnClickOutside
                    />
                )}
            </DndProvider>
        </div>
    );
};

export default Projects;
