import React, { useState, useEffect } from 'react';
import Dialog from '../Dialog';
import './styles.css';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { MODES, BUTTON_TEXT, MODAL_TITLE, STATE_BY_MODE, CONFIRMATION_TEXT, CARD_ELEMENT_OPTIONS } from './constants';

import {
    handleCreatingSubscription,
    resetSubscription,
    updatePaymentMethod,
    handleRetrySubscriptionPayment,
    handleCreateOpenAISubscription,
} from '../../../actions/stripeActions';
import { getUserRoles } from '../../../actions/authActions';
import { getOrganization } from '../../../api/users';
import { getUserSubscriptionStatus } from '../../../api/stripe';

import CustomInput from '../../inputs/CustomInput';
import ConfirmationModal from '../ConfirmationModal';

import CloseIcon from '../../../assets/images/close-icon.svg';

import { getTranslation } from '../../../helpers/getLanguage';

const getPaymentTextTotal = ({ total, discountTotal, credits }) => {
    const totalWithDiscount = (total + (credits?.price || 0) - (discountTotal || 0)).toFixed(2);

    return `${getTranslation('CHECKOUT_DIALOG_TOTAL')} $${totalWithDiscount}`;
};

const getPaymentText = (
    { quantity, interval, intervalCount, total, discount, openAIRequests, credits },
    language,
    isOpenAISubscription
) => {
    const appliedDiscount = discount || 0;
    const totalPrice = (total + (credits?.price || 0)).toFixed(2);
    const period = interval + (intervalCount > 1 && language === 'en' ? 's' : '');

    let paymentText = `${getTranslation(
        isOpenAISubscription ? 'CHECKOUT_DIALOG_REQUESTS_PLAN' : 'CHECKOUT_DIALOG_PREMIUM_PLAN'
    )} x ${isOpenAISubscription ? openAIRequests : quantity} ${
        isOpenAISubscription ? '' : getTranslation('CHECKOUT_DIALOG_FOR')
    } ${isOpenAISubscription ? '' : intervalCount} ${isOpenAISubscription ? '' : period}`;

    if (discount > 0)
        paymentText += ` $${totalPrice} ${getTranslation('CHECKOUT_DIALOG_WITH_DISCOUNT')} ${appliedDiscount}%`;

    return paymentText;
};

const CheckoutModal = ({
    closeDialog,
    auth,
    handleCreatingSubscription,
    socketId,
    handleRetrySubscriptionPayment,
    handleCreateOpenAISubscription,
    getUserRoles,
    updatePaymentMethod,
    subscriptionState,
    resetSubscription,
    getSubscriptionInfo = () => {},
    subscriptionInfo = {},
    subscriptionDetails = {},
    mode = MODES.checkout,
    isOpenAISubscription,
    subscriptionId = '',
    setDefaultFields = () => {},
}) => {
    const { user } = auth;
    const { language } = auth.userInfo;
    const [fields, setFields] = useState({
        organization: subscriptionDetails.organizationName || '',
        name: subscriptionDetails.name || user.name,
        lastName: subscriptionDetails.lastName || user.lastName,
        email: subscriptionDetails.email || user.email,
    });
    const [stripeError, setStripeError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const { success, errors, isLoading } = subscriptionState[STATE_BY_MODE[isOpenAISubscription ? 'openai' : mode]];

    const stripe = useStripe();
    const elements = useElements();

    const handleChange = (e) => {
        const { value, name } = e.target;
        setFields({ ...fields, [name]: value });
    };

    useEffect(() => {
        if (!subscriptionDetails.organizationName) {
            const params = {};
            const onSuccess = (response) => setFields((fields) => ({ ...fields, organization: response }));

            getOrganization(params, onSuccess);
        }
    }, [subscriptionDetails.organizationName]);

    useEffect(() => {
        if (mode === MODES.checkout) {
            const params = {};
            const onSuccess = (response) => {
                if (response.incomplete) {
                    setConfirmationText(CONFIRMATION_TEXT.incomplete);
                    setModalOpen(true);
                } else if (response.personal && subscriptionInfo.quantity === 1) {
                    setConfirmationText(CONFIRMATION_TEXT.personal);
                    setModalOpen(true);
                }
            };

            !isOpenAISubscription && getUserSubscriptionStatus(params, onSuccess);
        }
    }, []);

    const handleCloseDialog = () => {
        if (!isLoading) {
            closeDialog && closeDialog();
        }
    };

    useEffect(() => {
        displayError(errors);

        return () => {
            setStripeError('');
        };
    }, [errors]);

    useEffect(() => {
        if (success) {
            getSubscriptionInfo();
            setConfirmationText(CONFIRMATION_TEXT[mode]);
            setModalOpen(true);
            resetSubscription();
            getUserRoles();
        }
    }, [success, setModalOpen, getUserRoles, mode]);

    const handleSubmit = async () => {
        const { quantity, interval, promoCode, credits, planId, key } = subscriptionInfo;
        const cardElement = elements.getElement(CardElement);

        if (isOpenAISubscription) {
            const [empty, creditsId] = key.split('OpenAI-');

            return handleCreateOpenAISubscription({
                cardElement,
                interval,
                quantity,
                stripe,
                promoCode,
                number: subscriptionInfo.openAIRequests,
                creditsId,
                socketId,
            });
        }

        if (mode === MODES.checkout) {
            const latestInvoicePaymentIntentStatus = localStorage.getItem('latestInvoicePaymentIntentStatus');

            if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
                const invoiceId = localStorage.getItem('latestInvoiceId');
                const isPaymentRetry = true;
                handleCreatingSubscription({
                    isPaymentRetry,
                    invoiceId,
                    cardElement,
                    quantity,
                    stripe,
                    socketId,
                    creditsId: credits?.key,
                    planId,
                });
            } else {
                handleCreatingSubscription({
                    cardElement,
                    interval,
                    quantity,
                    stripe,
                    fields,
                    promoCode,
                    socketId,
                    creditsId: credits?.key,
                    planId,
                });
            }
        }
        if (mode === MODES.update) {
            updatePaymentMethod({ subscriptionId, cardElement, stripe });
        }
        if (mode === MODES.payment) {
            handleRetrySubscriptionPayment({ cardElement, subscriptionId, stripe });
        }
    };

    const hasEmptyFields =
        Object.keys(fields).filter((key) => {
            const value = fields[key];
            if (key === 'organization') {
                return false;
            }
            return !value;
        }).length > 0;
    const disabledClass = isLoading || hasEmptyFields ? ' disabled' : '';

    const closeConfirmationModal = () => {
        setConfirmationText('');
        setModalOpen(false);
        handleCloseDialog();
    };

    const displayError = (e) => {
        if (!e) {
            setStripeError('');
            return;
        }

        if (typeof e === 'string') {
            setStripeError(e);
            return;
        }

        if (e.response && e.response.data) {
            if (e.response.data.message) {
                setStripeError(e.response.data.message);
            }
            if (e.response.data.error && e.response.data.error.message) {
                setStripeError(e.response.data.error.message);
            }

            return;
        }

        if (e.message && e.message.length) {
            setStripeError(e.message);
            return;
        }

        setStripeError((e.error && e.error.message) || '');
    };

    const modalTitle = MODAL_TITLE[mode];
    const buttonTitle = BUTTON_TEXT[mode];

    const paymentText = subscriptionId ? '' : getPaymentText(subscriptionInfo, language, isOpenAISubscription);
    const paymentTextTotal = getPaymentTextTotal(subscriptionInfo);

    return (
        <Dialog closeDialog={handleCloseDialog} closeOnClickOutside={false} closeOnEscape={!isLoading}>
            <div className='checkout-dialog-wrapper'>
                <div className='checkout-dialog-header flex'>
                    <div className='flex align-center'>
                        <span className='checkout-dialog-title'>{modalTitle}</span>
                    </div>
                    <img src={CloseIcon} alt='Close' className='pointer' onClick={() => handleCloseDialog()} />
                </div>

                <div className='checkout-dialog-body flex-column'>
                    {paymentText.length > 0 && (
                        <div className='billing-dialog-total flex-column'>
                            <span className='billing-dialog-label'>{paymentText}</span>
                            <span className='billing-dialog-label total'>
                                <b>{paymentTextTotal}</b>
                            </span>
                        </div>
                    )}

                    <CustomInput
                        value={fields.name}
                        onChange={handleChange}
                        name='name'
                        disabled={mode !== MODES.checkout}
                        containerClass='flex-column mb-10'
                        label={getTranslation('CUSTOM_INPUT_FIRST_NAME')}
                    />
                    <CustomInput
                        value={fields.lastName}
                        onChange={handleChange}
                        name='lastName'
                        disabled={mode !== MODES.checkout}
                        containerClass='flex-column mb-10'
                        label={getTranslation('CUSTOM_INPUT_LAST_NAME')}
                    />
                    <CustomInput
                        value={fields.email}
                        onChange={handleChange}
                        name='email'
                        disabled={mode !== MODES.checkout}
                        containerClass='flex-column mb-10'
                        label={getTranslation('CUSTOM_INPUT_EMAIL')}
                    />
                    <CustomInput
                        value={fields.organization}
                        onChange={handleChange}
                        name='organization'
                        disabled={mode !== MODES.checkout}
                        containerClass='flex-column mb-30'
                        placeholder='(Optional)'
                        label={getTranslation('CUSTOM_INPUT_INSTITUTION_ORG_NAME')}
                    />

                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                    {stripeError && <span className='stripe-error-message mb-20'>{stripeError}</span>}

                    <div className='checkout-dialog-buttons'>
                        <button
                            className={'checkout-dialog-button dark-blue-btn' + disabledClass}
                            onClick={handleSubmit}
                        >
                            {buttonTitle}
                        </button>
                    </div>
                </div>
            </div>
            {modalOpen && (
                <ConfirmationModal
                    closeDialog={closeConfirmationModal}
                    closeOnClickOutside={false}
                    hideCloseIcon
                    autoFocus={true}
                    buttonText={getTranslation('CONFIRMATION_MODAL_DEFAULT_BUTTON_TEXT')}
                    message={confirmationText}
                    onConfirm={closeConfirmationModal}
                />
            )}
        </Dialog>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    subscriptionState: state.subscription,
    socketId: state.webSocket.current.id,
});

const mapDispatchToProps = {
    handleCreatingSubscription,
    getUserRoles,
    resetSubscription,
    updatePaymentMethod,
    handleRetrySubscriptionPayment,
    handleCreateOpenAISubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModal);
