import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Popover } from 'react-tiny-popover';
import { Draggable } from 'react-beautiful-dnd';

import ComponentIcon from '../../../assets/images/solid/component-icon-filled.svg';
import StepIcon from '../../../assets/images/solid/icon-step.svg';
import MenuIcon from '../../../assets/images/menu-icon.svg';
import HiddenIcon from '../../../assets/images/hidden-component-icon.svg';

import Dropdown from '../DropdownModal/Dropdown';
import { getTranslation } from '../../../helpers/getLanguage';
import Tooltip from '../../common/Tooltip';

const ratingColors = ['#daf1e1', '#b5e3c3', '#91d5a5', '#6cc687', '#5ac57a'];
const ideaColor = [
    '#ffffff',
    '#fde6e1',
    '#fff4de',
    { 1: '#e5efed', 2: '#d1ead8', 3: '#b9e6c8', 4: '#97ddad', 5: '#78d495' },
];

const calculateColorAndRating = (array) => {
    const green = array.filter((details) => details.status === 3);
    const yellow = array.filter(details => details.status === 1);
    const red = array.filter((details) => (details.feasibleRating === 0 || details.rating === 0));

    if (green.length) {
        let max = 0;
        green.forEach((idea) => {
            const { rating, feasibleRating } = idea;

            if (feasibleRating === undefined || feasibleRating === -1) {
                return rating > max ? (max = rating) : '';
            }

            const calculatedRating = Math.round((rating + feasibleRating) / 2);

            return calculatedRating > max ? (max = calculatedRating) : '';
        });
        const ratingColor = ratingColors[max];

        return { color: ideaColor[3][max], rating: max, ratingColor, greenCount: green.length };
    }; 

    if (red.length) {
        return { color: ideaColor[1] };
    };

    if (yellow.length) {
        return { color: ideaColor[2] };
    };

    return { color: ideaColor[0] };
};

const SubtractComponentCard = ({
    index,
    component,
    isModalOpen,
    handleDelete,
    handleDisable,
    handleModalOpen,
    activeComponent,
    handleDuplicate,
    setActiveComponent,
    subtractionListsRef,
    replacementsMatrixList,
    isProcessType,
}) => {
    const ref = useRef(null);
    const modalRef = useRef(null);
    const [openIdea, setOpenIdea] = useState(false);
    const [ideaOptions, setIdeaOptions] = useState({ color: '', rating: 0 });
    const [hover, setHover] = useState(false);
    const [modalMenuOpen, setModalMenuOpen] = useState(false);
    const [coordinates, setCoordinates] = useState({});
    const isActive = component.id === activeComponent;
    const activeClass = component.disabled ? ' disabled' : '';
    const cardClassList =
        'subtract-component-card compact flex align-center pointer green' +
        activeClass +
        (isActive || openIdea || hover ? ' active-component' : '') +
        ((isActive || openIdea || hover) && index === 0 ? ' top-2' : '');
    const componentCardStyles = !component.disabled
        ? { backgroundColor: ideaOptions.color }
        : { backgroundColor: '#d6dbed' };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    });

    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target) && !isModalOpen && activeComponent) {
            const { screenX, screenY, clientX, clientY, bubbles } = e;
            const isTargetElem = subtractionListsRef.current.contains(e.target);
            const newClick = new MouseEvent('click', { bubbles, screenX, screenY, clientX, clientY });

            setActiveComponent(null);

            if (!isTargetElem) return;

            document.elementFromPoint(clientX, clientY).dispatchEvent(newClick);
        }
    };

    const filteredReplacementsMatrix = useMemo(
        () =>
            replacementsMatrixList.filter(
                (details) => details.status > 0 && component.id === details.idRemoteComponent
            ),
        [replacementsMatrixList, component.id]
    );

    useEffect(() => {
        setIdeaOptions(calculateColorAndRating(filteredReplacementsMatrix));
    }, [filteredReplacementsMatrix]);

    const items = [
        {
            value: isProcessType ? 'PAGE_COMPONENT_MENU_ITEM_EDIT_STEP' : 'PAGE_COMPONENT_MENU_ITEM_EDIT_COMPONENT',
            action: () => handleModalOpen({ element: component }, true),
        },
        {
            value: isProcessType ? 'PAGE_COMPONENT_MENU_DUPLICATE_STEP' : 'PAGE_COMPONENT_MENU_DUPLICATE_COMPONENT',
            action: () => handleDuplicate(component),
        },
        {
            value: component.disabled
                ? 'PAGE_COMPONENT_MENU_ITEM_ENABLE_COMPONENT'
                : 'PAGE_COMPONENT_MENU_ITEM_DISABLE_COMPONENT',
            action: () => handleDisable(component),
        },
        {
            value: isProcessType ? 'PAGE_COMPONENT_MENU_ITEM_DELETE_STEP' : 'PAGE_COMPONENT_MENU_ITEM_DELETE_COMPONENT',
            action: () => handleDelete(component.id),
        },
    ];

    const handleClick = () => {
        setOpenIdea(!openIdea);
    };

    const handleOpenDetails = (edit = false, detailsId) => {
        if (detailsId) {
            handleModalOpen('createIdea', component, edit, detailsId, component.id);
        } else {
            handleModalOpen('replacement', component, edit, detailsId, component.id);
        }
    };

    useEffect(() => {
        const handleClose = () => {
            if (modalMenuOpen) {
                setModalMenuOpen(false);
            }
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Escape') handleClose();
        };

        const handleMouseClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) handleClose();
        };

        document.addEventListener('mousedown', handleMouseClick, true);
        document.addEventListener('keydown', handleKeyDown, true);
        return () => {
            document.removeEventListener('mousedown', handleMouseClick, true);
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [modalMenuOpen]);

    const handleItemClick = (item) => {
        item.action();
        setModalMenuOpen(false);
    };

    const menuItems = items
        .filter((item) => !item.hidden)
        .map((item, index) => {
            if (!item.hidden) {
                return (
                    <div
                        key={index}
                        className='card-modal-option pointer'
                        onMouseDown={(e) => e.stopPropagation()}
                        onMouseUp={(e) => {
                            e.stopPropagation();
                            handleItemClick(item);
                            setModalMenuOpen(false);
                        }}
                    >
                        <span>{getTranslation(item.value)}</span>
                    </div>
                );
            }
            return null;
        });

    const handleMenuClick = (e) => {
        const rect = e.target.parentElement.getBoundingClientRect() || {};
        const { right, bottom } = rect;

        const rectLeft = right - 189;
        const rectTop = bottom;
        setCoordinates({ left: rectLeft, top: rectTop });

        setModalMenuOpen(!modalMenuOpen);
    };

    return (
        <div>
            <Draggable draggableId={component.id} index={index}>
                {(provided) => {
                    const style = {
                        backgroundColor: componentCardStyles.backgroundColor,
                        ...provided.draggableProps.style,
                    };
                    return (
                        <div
                            className={cardClassList}
                            onClick={handleClick}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onMouseEnter={() => {
                                setHover(true);
                            }}
                            onMouseLeave={() => {
                                setHover(false);
                            }}
                            style={style}
                        >
                            <Popover
                                isOpen={openIdea}
                                reposition={false}
                                ref={ref}
                                content={
                                    <Dropdown
                                        handleOpenDetails={handleOpenDetails}
                                        replacementsMatrixList={filteredReplacementsMatrix}
                                        component={component}
                                        setOpenIdea={setOpenIdea}
                                    />
                                }
                            >
                                <div className='subtract-component-card-header-compact flex justify-space-between align-center width-100'>
                                    <div className='flex align-center subtract-component-card-title-container compact'>
                                        <img
                                            className={isProcessType ? 'icon-32px' : 'icon-17px'}
                                            src={isProcessType ? StepIcon : ComponentIcon}
                                            alt={isProcessType ? 'step-icon' : 'component-icon'}
                                        />

                                        <Tooltip
                                            containerClass='replacement-component-card-title'
                                            position='top'
                                            innerTextClass=''
                                            message={component.name}
                                            innerText={component.name}
                                            tooltipMessageClass='tooltip-message white'
                                        />
                                    </div>
                                    <div className='subtract-component-card-rating-menu-wrapper'>
                                        {component.disabled ? (
                                            <img className='link-icon' src={HiddenIcon} alt='link-icon' />
                                        ) : (
                                            ideaOptions.rating && (
                                                <>
                                                    {hover && (
                                                        <div className='green-ideas-count'>
                                                            <span>{ideaOptions.greenCount || ''}</span>
                                                        </div>
                                                    )}
                                                    <div
                                                        className='report-idea-score flex flex-center'
                                                        style={{ backgroundColor: ideaOptions.ratingColor }}
                                                    >
                                                        <span>{ideaOptions.rating}</span>
                                                    </div>
                                                </>
                                            )
                                        )}
                                        <div className='flex align-center'>
                                            <button
                                                className='subtract-component-card-menu-button compact flex-center'
                                                onClick={(e) => e.stopPropagation()}
                                                onMouseDown={handleMenuClick}
                                            >
                                                <img className='menu-icon' src={MenuIcon} alt='menu-icon'></img>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    );
                }}
            </Draggable>
            {modalMenuOpen && (
                <div ref={modalRef} className='card-modal-container' style={{ ...coordinates }}>
                    <div className='menu-items-container' onMouseDown={(e) => e.stopPropagation()}>
                        {menuItems}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubtractComponentCard;
