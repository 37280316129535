import React from 'react';

import { ReactComponent as CheckmarkIcon } from '../../../../assets/images/checkmark-circle.svg';

import { getTranslation } from '../../../../helpers/getLanguage';

import './styles.css';

const EurekaTabs = ({ 
    currentTab, 
    labels, 
    isCompletedIdea,
    changeCurrentTab 
}) => {
    const tabs = labels.map((label, index) => {
        const activeClass = index <= currentTab || isCompletedIdea ? ' active' : '';
        const pointerClass = ' pointer';
        const labelClass = index <= currentTab ? ' active' : '';
        const completedIdea = isCompletedIdea ? ' completed-idea-button' : '';

        return (
            <div key={index} className='flex align-center eureka-tab'>
                <div className='eureka-tabs-line-container'>
                    <div className={'eureka-tabs-line' + activeClass}></div>
                </div>
                <div 
                    className={'subtraction-tabs-circle' + activeClass + pointerClass}
                    onClick={() => changeCurrentTab(index)}
                >
                    <CheckmarkIcon className={isCompletedIdea ? ' completed' : ''} color={'#3e6fd9'} fill='white' />

                    <button className={'eureka-tab-button' + completedIdea}>
                        <div>
                            <span className={'subtraction-tab-label' + labelClass}>{getTranslation(label)}</span>
                        </div>
                    </button>
                </div>
            </div>
        );
    });

    return <div className='flex eureka-tabs-container'>{tabs}</div>;
};

export default EurekaTabs;
