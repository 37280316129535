import React from 'react';

const defaultColors = ['#3e6fd9', '#cdd1e5'];

const DonutDiagram = ({ values, colors = defaultColors, highlightLastValue = false }) => {
    const total = values.reduce((p, n) => p + n, 0);
    if (total === 0) {
        return null;
    }

    const valuesPercent = values.map(value => (value / total) * 100);

    const sections = [];
    const highlightedSections = [];
    const dividers = [];
    let offset = -90;
    let dashOffset = 0;

    if (valuesPercent.filter(value => value === 0).length === valuesPercent.length - 1) {
        const index = valuesPercent.findIndex(value => value);
        sections.push(
            <circle
                key={0}
                cx="16"
                cy="16"
                r="16"
                strokeDasharray={`100 0`}
                stroke={colors[index] || '#ffffff'}
            ></circle>
        );
    } else {
        valuesPercent
            .map(value => Math.ceil(value))
            .forEach((value, index) => {
                const elem = (
                    <circle
                        key={index}
                        cx="16"
                        cy="16"
                        r="16"
                        strokeDasharray={`${value} 100`}
                        strokeDashoffset={dashOffset}
                        stroke={colors[index] || '#ffffff'}
                    ></circle>
                );

                dashOffset -= value;

                if (highlightLastValue && index + 1 === values.length) {
                    highlightedSections.push(elem);
                    offset += Math.floor((360 * value) / 100 / 2);
                } else {
                    sections.push(elem);
                }
            });

        dashOffset = 0.5;

        dividers.push(
            valuesPercent
                .map(value => Math.ceil(value))
                .map((value, index) => {
                    const elem = (
                        <circle
                            key={index}
                            cx="16"
                            cy="16"
                            r="16"
                            strokeDasharray={`0.5 100`}
                            strokeDashoffset={dashOffset}
                            stroke="#ffffff"
                        ></circle>
                    );
                    dashOffset -= value;

                    return elem;
                })
        );
    }

    return (
        <div className="flex align-center">
            <div className="donut-diagram-container flex-center">
                <span className="donut-diagram-label">{total}</span>
                <svg className="donut-svg-chart" viewBox="0 0 32 32" style={{ transform: `rotate(${offset}deg)` }}>
                    <g strokeWidth="5">
                        {sections}
                        {dividers}
                    </g>
                </svg>

                {highlightedSections.length > 0 && (
                    <svg
                        className="donut-svg-chart-highlighted"
                        viewBox="0 0 32 32"
                        style={{ transform: `rotate(${offset}deg)` }}
                    >
                        <g strokeWidth="8">
                            {highlightedSections}
                            {dividers}
                        </g>
                    </svg>
                )}
            </div>
        </div>
    );
};

export default DonutDiagram;
