import React from 'react';
import './styles.css';

import InvitationCard from './InvitationCard';

const Invitations = ({ invitations, handleInvitationClick, userStatus }) => {
    const invitationCards = invitations.map((invitation, index) => (
        <InvitationCard
            key={index}
            invitation={invitation}
            handleInvitationClick={handleInvitationClick}
            disabled={!userStatus.premium}
        />
    ));

    return <div className="flex-wrap">{invitations.length > 0 && invitationCards}</div>;
};

export default Invitations;
