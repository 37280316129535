import React from 'react';
import Dialog from '../Dialog';
import CloseIcon from '../../../assets/images/close-icon.svg';

const TutorialVideoModal = ({ setIsOpenModal, videoData }) => {
    return (
        <Dialog closeDialog={() => setIsOpenModal(false)} closeOnClickOutside={false}>
            <div className='modal-video-content'>
                <div className='header-modal-video'>
                    <span className='modal-video-title'>{videoData.displayName}</span>
                    <img
                        src={CloseIcon}
                        alt=''
                        className='pointer modal-video-button-exit'
                        onClick={() => setIsOpenModal(false)}
                    />
                </div>
                <video className='video' controls autoPlay>
                    <source src={videoData.url} type='video/mp4' />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Dialog>
    );
};

export default TutorialVideoModal;
