import React from 'react';

import CustomToggle from '../inputs/CustomToggle';
import CustomSearchInput from '../inputs/CustomSearchInput';
import ViewTutorialButtonWithModal from '../common/ViewTutorialButtonWithModal';

import { getTranslation } from '../../helpers/getLanguage';

const SubtractionHeader = ({ inputValues, handleChange, isSorted, setSorted, sortLabel, projectType }) => {
    const isProcessType = projectType === 'process';

    return (
        <div className='subtraction-header-container width-100'>
            <div className='flex-column subtraction-header-container-tutorial'>
                <div style={{ flexGrow: 1, marginBottom: '10px' }}>
                    <ViewTutorialButtonWithModal name='Subtraction' />
                </div>
                <span className='components-dashboard-title'>
                    {getTranslation(isProcessType ? 'PAGE_SUBTRACTION_TITLE_STEP' : 'PAGE_SUBTRACTION_TITLE_COMPONENT')}
                </span>
            </div>

            <div className='flex subtraction-header-container-settings'>
                <CustomSearchInput
                    name='search'
                    placeholder={getTranslation(
                        isProcessType ? 'PAGE_STEPS_SEARCH_COMPONENTS' : 'PAGE_COMPONENT_SEARCH_COMPONENTS'
                    )}
                    value={inputValues.search}
                    onChange={handleChange}
                    containerClass='search-input-container-subtraction-components mb-10'
                />

                <div className='toggle-container flex align-center'>
                    <CustomToggle
                        value={isSorted}
                        setValue={(e) => setSorted(e)}
                        label={sortLabel}
                        labelClass='custom-toggle-label-default sort-toggle'
                        left
                    />
                </div>
            </div>
        </div>
    );
};

export default SubtractionHeader;
