import React from 'react';
import './styles.css';

import ChangeUserPassword from './ChangeUserPassword';
import ActivateCodeForUser from './ActivateCodeForUser';
import ActivateMultipleCodes from './ActivateMultipleCodes';
import ChangeUserRoles from './ChangeUserRoles';
import DeleteUser from './DeleteUser';
import VerifyEmail from './VerifyEmail';

const ManageUsers = ({ language }) => {
    return (
        <div className='flex-column manage-user-wrapper'>
            <div className='flex flex-wrap'>
                <ChangeUserPassword />
                <ChangeUserRoles />
                <DeleteUser />
                <VerifyEmail />
                <ActivateCodeForUser language={language} />
                <ActivateMultipleCodes />
            </div>
        </div>
    );
};

export default ManageUsers;
