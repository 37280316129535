import React from 'react';
import './styles.css';

import PromoCodesTable from './PromoCodesTable';
import CustomDropdown from '../../../inputs/CustomDropdown';
import CustomSearchInput from '../../../inputs/CustomSearchInput';

import { getTranslation } from '../../../../helpers/getLanguage';

const activationOptions = [
    { value: '', label: "PROMO_CODES_ACTIVATION_OPTIONS_ALL_LICENSES" },
    { value: 'empty', label: 'PROMO_CODES_ACTIVATION_OPTIONS_ALL_LICENSES' },
    { value: 'partly', label: 'PROMO_CODES_ACTIVATION_OPTIONS_PARTLY_ACTIVATED' },
    { value: 'full', label: 'PROMO_CODES_ACTIVATION_OPTIONS_FULLY_ACTIVATED' }
];

const discountOptions = [
    { value: '', label: 'PROMO_CODES_DISCOUNT_OPTIONS_ALL_DISCOUNTS' },
    { value: 'fullPrice', label: 'PROMO_CODES_DISCOUNT_OPTIONS_NO_DISCOUNTS' },
    { value: 'discounted', label: 'PROMO_CODES_DISCOUNT_OPTIONS_WITH_DISCOUNTS' },
    { value: 'onlyDiscounted', label: 'PROMO_CODES_DISCOUNT_OPTIONS_WITH_DISCOUNTS_NO_FREE' },
    { value: 'free', label: 'PROMO_CODES_DISCOUNT_OPTIONS_FREE' }
];

const durationOptions = [
    { value: '', label: 'PROMO_CODES_DURATION_OPTIONS_ALL_DURATION_TYPES' },
    { value: 'day', label: 'PROMO_CODES_DURATION_OPTIONS_DAILY' },
    { value: 'week', label: 'PROMO_CODES_DURATION_OPTIONS_WEEKLY' },
    { value: 'month', label: 'PROMO_CODES_DURATION_OPTIONS_MONTHLY' },
    { value: 'year', label: 'PROMO_CODES_DURATION_OPTIONS_YEARLY' }
];

const renewalOptions = [
    { value: '', label: 'PROMO_CODES_RENEWAL_OPTIONS_ENABLED_AND_DISABLED' },
    { value: 'yes', label: 'PROMO_CODES_RENEWAL_OPTIONS_ENABLED_ONLY' },
    { value: 'no', label: 'PROMO_CODES_RENEWAL_OPTIONS_DISABLED_ONLY' }
];

const generationOptions = [
    { value: '', label: 'PROMO_CODES_GENERATION_OPTIONS_ALL_CODES' },
    { value: 'admin', label: 'PROMO_CODES_GENERATION_OPTIONS_GENERATED_BY_ADMIN' },
    { value: 'system', label: 'PROMO_CODES_GENERATION_OPTIONS_GENERATED_BY_SYSTEM' }
];

const archivedOptions = [
    { value: 'unarchived', label: 'PROMO_CODES_ARCHIVED_OPTIONS_EXCLUDE_HIDDEN' },
    { value: '', label: 'PROMO_CODES_ARCHIVED_OPTIONS_INCLUDE_HIDDEN' },
    { value: 'archived', label: 'PROMO_CODES_ARCHIVED_OPTIONS_ONLY_HIDDEN' }
];

const PromoCodes = ({
    openPromoCodeModal,
    containerHeight,
    containerRef,
    promoCodes,
    sort,
    handleSortChange,
    filters,
    setFilters,
    resetFilters
}) => {
    return (
        <div className="flex-column">
            <div className="promo-codes-inputs flex flex-column">
                <div className="flex flex-wrap">
                    <div className="promo-codes-input-container flex align-center">
                        <CustomDropdown
                            value={filters.activation}
                            handleChange={e => setFilters({ ...filters, activation: e })}
                            options={activationOptions}
                        />
                    </div>

                    <div className="promo-codes-input-container flex align-center">
                        <CustomDropdown
                            value={filters.discounts}
                            handleChange={e => setFilters({ ...filters, discounts: e })}
                            options={discountOptions}
                        />
                    </div>

                    <div className="promo-codes-input-container flex align-center">
                        <CustomDropdown
                            value={filters.durationType}
                            handleChange={e => setFilters({ ...filters, durationType: e })}
                            options={durationOptions}
                        />
                    </div>

                    <div className="promo-codes-input-container flex align-center">
                        <CustomDropdown
                            value={filters.isActive}
                            handleChange={e => setFilters({ ...filters, isActive: e })}
                            options={renewalOptions}
                        />
                    </div>

                    <div className="promo-codes-input-container flex align-center">
                        <CustomDropdown
                            value={filters.generation}
                            handleChange={e => setFilters({ ...filters, generation: e })}
                            options={generationOptions}
                        />
                    </div>

                    <div className="promo-codes-input-container flex align-center">
                        <CustomDropdown
                            value={filters.archived}
                            handleChange={e => setFilters({ ...filters, archived: e })}
                            options={archivedOptions}
                        />
                    </div>
                </div>

                <div className="flex">
                    <CustomSearchInput
                        placeholder={getTranslation("SEARCH_NAME_COMMENT_OR_CODE")}
                        value={filters.search}
                        containerClass="custom-search-input-promo-codes"
                        onChange={e => setFilters({ ...filters, search: e.target.value })}
                    />

                    <button
                        className="promo-code-button dark-blue-btn flex align-center justify-space-between"
                        onClick={resetFilters}
                    >
                        <span>{getTranslation("RESET_FILTERS_BUTTON")}</span>
                    </button>

                    <button
                        className="promo-code-button dark-blue-btn flex align-center justify-space-between"
                        onClick={() => openPromoCodeModal()}
                    >
                        <span>{getTranslation("CREATE_NEW_CODE")}</span>
                    </button>
                </div>
            </div>

            <PromoCodesTable
                containerHeight={containerHeight}
                containerRef={containerRef}
                promoCodes={promoCodes}
                sort={sort}
                handleSortChange={handleSortChange}
                openPromoCodeModal={openPromoCodeModal}
            />
        </div>
    );
};

export default PromoCodes;
