import React from 'react';

import TabHeader from '../../common/TabHeader';

const DashboardTabsHeader = ({
    activeTab,
    setActiveTab,
    activeProjects,
    invitedProjects,
    archivedProjects,
    labels
}) => {
    const projectsCount = [activeProjects, invitedProjects, archivedProjects];

    const tabs = labels.map((label, index) => (
        <TabHeader
            itemCount={projectsCount[index]}
            key={index}
            index={index}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            label={label}
        />
    ));
    return <div className="project-dashboard-tabs flex mb-20">{tabs}</div>;
};

export default DashboardTabsHeader;
