import React from 'react';
import './styles.css';
import { ReactComponent as CheckmarkIcon } from '../../../assets/images/checkmark-circle.svg';

import Tooltip from '../../common/Tooltip';
import { getTranslation } from '../../../helpers/getLanguage';

const defaultColor = '#3e6fd9';
const defaultBackground = '#f7f8fa';

const CustomRadioButtons = ({
    value,
    options,
    label = '',
    tooltip = '',
    setValue,
    canUnset = true,
    optionTextClass = 'default-radio-option-text',
    optionHeight = 0
}) => {
    const handleItemClick = selected => {
        if (canUnset && typeof value !== 'object' && value === selected) {
            setValue(null);
        } else {
            setValue(selected);
        }
    };

    const selectOptions = options.map((option, index) => {
        const isActive = value && value instanceof Array ? value.includes(option.value) : option.value === value;
        const activeClass = isActive ? ' active' : '';
        const optionStyle = { color: option.color || defaultColor };
        optionHeight && (optionStyle.height = optionHeight);

        if (isActive) {
            optionStyle.borderColor = option.color || defaultColor;
            optionStyle.backgroundColor = option.backgroundColor || defaultBackground;

            return (
                <div
                    key={index}
                    className={'custom-radio-option flex-center pointer' + activeClass}
                    onClick={() => handleItemClick(option.value)}
                    style={optionStyle}
                >
                    {option.icon ? (
                        <option.icon color={option.color || defaultColor} />
                    ) : (
                        <CheckmarkIcon color={option.color || defaultColor} fill="white" />
                    )}
                    <span
                        className={'custom-radio-option-span ' + optionTextClass}
                        style={{ color: option.color || defaultColor }}
                    >
                        {getTranslation(option.label)}
                    </span>
                </div>
            );
        }

        return (
            <div
                key={index}
                className={'custom-radio-option flex-center pointer' + activeClass}
                onClick={() => handleItemClick(option.value)}
                style={optionStyle}
            >
                <div className="radio-button-checkmark"></div>
                <span className={'custom-radio-option-span ' + optionTextClass}>{getTranslation(option.label)}</span>
            </div>
        );
    });

    return (
        <div className="flex-column width-100">
            {label.length > 0 && (
                <div className="flex-center">
                    <span className="custom-radio-label">{label}</span>
                    {tooltip.length > 0 && <Tooltip message={tooltip} containerClass="radio-button-tooltip" />}
                </div>
            )}

            <div className="flex">{selectOptions}</div>
        </div>
    );
};

export default CustomRadioButtons;
