import React from 'react';
import './styles.css';

import { getTranslation } from '../../../helpers/getLanguage';

const CustomToggle = ({
    value,
    setValue,
    label = '',
    labelClass = 'custom-toggle-label-default',
    disabled = false,
    left = false
}) => {
    const activeClass = value ? ' active' : '';
    const statusClass = disabled ? ' disabled not-allowed' : ' pointer';

    const handleSetValue = value => {
        if (!disabled) setValue(value);
    };

    return (
        <>
            {left &&
                (label.length > 0 && (
                    <span className={labelClass + statusClass + activeClass} onClick={() => handleSetValue(!value)}>
                        {getTranslation(label)}
                    </span>
                ))}
            <div className={'flex-center custom-toggle-container' + statusClass} onClick={() => handleSetValue(!value)}>
                <div className={'custom-toggle-rectangle' + activeClass}>
                    <div className={'custom-toggle-circle' + activeClass}></div>
                </div>
            </div>
            {!left &&
                (label.length > 0 && (
                    <span className={labelClass + statusClass + activeClass} onClick={() => handleSetValue(!value)}>
                        {getTranslation(label)}
                    </span>
                ))}
        </>
    );
};

export default CustomToggle;
