import React from 'react';
import classnames from "classnames";

import ConsumerBenefit from '../../../assets/images/consumer-benefit.svg';
import CompanyBenefit from '../../../assets/images/company-benefit.svg';
import OtherBenefit from '../../../assets/images/other-benefit.svg';

import { getTranslation } from '../../../helpers/getLanguage';

const benefitIcons = {
    consumer: ConsumerBenefit,
    company: CompanyBenefit,
    other: OtherBenefit
};

const BenefitOption = ({ option, onMouseUp, first, isOpen, isActive }) => {
    return (
        <div
            className={classnames('custom-dropdown-container-option flex align-center width-100 pointer', { first }, { open: isOpen }, { active: isActive })}
            onMouseUp={() => onMouseUp(option.value)}
        >
            <div className="flex align-center custom-dropdown-option benefit-option">
                {benefitIcons[option.value] && <img src={benefitIcons[option.value]} alt="" />}
                <span>{getTranslation(option.label)}</span>
            </div>
        </div>
    );
};

export default BenefitOption;
