import React from 'react';

import DependencyTabs from './DependencyTabs';
import CustomToggle from '../../../inputs/CustomToggle';
import Tooltip from '../../../common/Tooltip';
import CellsIcon from '../../../../assets/images/nav-attribute-dependency.svg';
import ComponentIcon from '../../../../assets/images/solid/component-icon.svg';
import StepIcon from '../../../../assets/images/solid/icon-step.svg';
import AttributeIcon from '../../../../assets/images/solid/attribute-icon.svg';
import CloseIcon from '../../../../assets/images/close-icon.svg';

import { getTranslation } from '../../../../helpers/getLanguage';

const DependencyModalHeader = ({
    closeDialog,
    dependency,
    currentTab,
    reversed,
    handleSetReversed,
    reverseEnabled,
    productType,
    header,
    getDependencyHeader,
    changeCurrentTab
}) => {

    const getCurrentProductType = () => {
        switch (productType) {
            case ('process'):
                return getTranslation('PRODUCT_TYPE_PROCESS');
            case ('service'):
                return getTranslation('PRODUCT_TYPE_SERVICE');
            default: 
                return getTranslation('PRODUCT_TYPE_PRODUCT');
        };
    };

    const tabsLabels = [
        <span style={{ color: 'inherit' }}>
            {`${getTranslation('DEPENDENCY_MODAL_TABS_DESCRIBE_DEPENDENCY')} ${getCurrentProductType()}`}
        </span>,
        <span style={{ color: 'inherit' }}>
            {getTranslation('DEPENDENCY_MODAL_TABS_LIST_BENEFITS')}
        </span>,
        <span style={{ color: 'inherit' }}>
            {getTranslation('IDEA_MODAL_TABS_LIST_IMPLEMENTATION_CHALLENGES')}
        </span>
    ];

    return (
        <div className='matrix-dialog-header flex-column'>
            <div className='matrix-dialog-title-container flex mb-20'>
                <div className='flex align-center'>
                    <img className='matrix-dialog-header-icon' src={CellsIcon} alt='' />
                    <span className='matrix-dialog-title'>{getTranslation('DEPENDENCY_MODAL_TITLE')}</span>
                </div>

                <div className='flex align-center matrix-dialog-controls'>
                    {reverseEnabled && (
                        <div className='flex align-center'>
                            <CustomToggle
                                gray
                                value={reversed}
                                setValue={(e) => handleSetReversed(e)}
                                label={getTranslation('DEPENDENCY_MODAL_REVERSE_LABEL')}
                            />
                        </div>
                    )}
                    <img src={CloseIcon} alt='' className='pointer' onClick={() => closeDialog()} />
                </div>
            </div>

            <div className='flex align-center justify-space-between matrix-dependency mb-10'>
                <div className='dependency-container flex'>
                    <div className='dependency-attribute flex-center'>
                        <img src={AttributeIcon} alt='' />
                        <Tooltip
                            containerClass='dependency_tooltip ellipsed-text'
                            position='top'
                            innerTextClass=''
                            message={header.secondAttribute}
                            innerText={header.secondAttribute}
                            tooltipMessageClass='tooltip-message white'
                        />
                    </div>

                    <div className='dependency-component flex-center'>
                        <img src={productType === 'process' ? StepIcon : ComponentIcon} alt='' />
                        <Tooltip
                            containerClass='dependency_tooltip ellipsed-text'
                            position='top'
                            innerTextClass=''
                            message={header.secondComponent}
                            innerText={header.secondComponent}
                            tooltipMessageClass='tooltip-message white'
                        />
                    </div>
                </div>

                <div className='dependency-influence'></div>

                <div className='dependency-container flex'>
                    <div className='dependency-attribute flex-center'>
                        <img src={AttributeIcon} alt='' />
                        <Tooltip
                            containerClass='dependency_tooltip ellipsed-text'
                            position='top'
                            innerTextClass=''
                            message={header.firstAttribute}
                            innerText={header.firstAttribute}
                            tooltipMessageClass='tooltip-message white'
                        />
                    </div>

                    <div className='dependency-component flex-center'>
                        <img src={productType === 'process' ? StepIcon : ComponentIcon} alt='' />
                        <Tooltip
                            containerClass='dependency_tooltip ellipsed-text'
                            position='top'
                            innerTextClass=''
                            message={header.firstComponent}
                            innerText={header.firstComponent}
                            tooltipMessageClass='tooltip-message white'
                        />
                    </div>
                </div>
            </div>

            <div className='flex-center'>{getDependencyHeader()}</div>

            <DependencyTabs
                currentTab={currentTab}
                dependency={dependency}
                labels={tabsLabels}
                changeCurrentTab={changeCurrentTab}
            />
        </div>
    );
};

export default DependencyModalHeader;
