import React, { useState, useEffect, useRef } from 'react';

import ComponentIcon from '../../../assets/images/solid/component-icon-filled.svg';
import MenuIcon from '../../../assets/images/menu-icon.svg';
import { getTranslation } from '../../../helpers/getLanguage';

const ratingColors = ['#cbebd4', '#b5e3c3', '#91d5a5', '#6cc687', '#5ac57a'];
const ideaColor = ['#fff2d9', '#fde0da', { 1: '#e2eee7', 2: '#d1ead8', 3: '#b9e6c8', 4: '#97ddad', 5: '#6cd18c' }, '#f8f9fa'];

const calculateColorAndRating = (idea) => {
    const { status, rating, feasibleRating } = idea;

    if (status === 3) {
        const sum = rating + feasibleRating;
        const calculatedRating = Math.round(sum / 2);
        return { color: ideaColor[2][calculatedRating], rating: calculatedRating, ratingColor: ratingColors[rating] };
    };

    if (feasibleRating === 0 || rating === 0) {
        return { color: ideaColor[1] };
    };

    if (status === 1) {
        return { color: ideaColor[0] };
    };

    return { color:ideaColor[3] };
};

const initialClassList = 'eureka-idea-container';

const EurekaIdeaCard = ({ handleModalOpen, idea, index, deleteEureka }) => {
    const modalRef = useRef(null);
    const [ideaOptions, setIdeaOptions] = useState({ color: '', rating: 0 });
    const [modalMenuOpen, setModalMenuOpen] = useState(false);
    const [coordinates, setCoordinates] = useState({});
    const [classList, setClassList] = useState(initialClassList);

    useEffect(() => {
        setIdeaOptions(calculateColorAndRating(idea));
    }, [idea]);

    const handleClick = () => {
        handleModalOpen('eureka', idea.id, true);
    };

    const items = [
        {
            value: 'EUREKA_PAGE_EDIT_IDEA',
            action: handleClick,
        },
        {
            value: 'EUREKA_PAGE_DELETE_IDEA',
            action: () => deleteEureka(idea.id),
        },
    ];

    useEffect(() => {
        const handleClose = () => {
            if (modalMenuOpen) {
                setModalMenuOpen(false);
            }
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Escape') handleClose();
        };

        const handleMouseClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) handleClose();
        };

        document.addEventListener('mousedown', handleMouseClick, true);
        document.addEventListener('keydown', handleKeyDown, true);
        return () => {
            document.removeEventListener('mousedown', handleMouseClick, true);
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [modalMenuOpen]);

    const handleItemClick = (item) => {
        item.action();
        setModalMenuOpen(false);
    };

    const menuItems = items
        .filter((item) => !item.hidden)
        .map((item, index) => {
            if (!item.hidden) {
                return (
                    <div
                        key={index}
                        className='card-modal-option pointer'
                        onMouseDown={(e) => e.stopPropagation()}
                        onMouseUp={(e) => {
                            e.stopPropagation();
                            handleItemClick(item);
                            setModalMenuOpen(false);
                        }}
                    >
                        <span>{getTranslation(item.value)}</span>
                    </div>
                );
            }
            return null;
        });

    const handleMenuClick = (e) => {
        const rect = e.target.parentElement.getBoundingClientRect() || {};
        const { right, bottom } = rect;

        const rectLeft = right - 160;
        const rectTop = bottom;
        setCoordinates({ left: rectLeft, top: rectTop });

        setModalMenuOpen(!modalMenuOpen);
    };

    return (
        <>
            <div
                className={classList}
                onClick={handleClick}
                style={{ backgroundColor: ideaOptions.color }}
                onMouseEnter={() => setClassList(initialClassList + ' active-eureka-idea')}
                onMouseLeave={() => setClassList(initialClassList)}
            >
                <div className='flex'>
                    <div className='eureka-idea-card-title-container compact'>
                        <img src={ComponentIcon} alt='eureka-component-icon' />
                    </div>
                    <span className='eureka-idea-name'>{idea.name || `Potential idea #${index + 1}`}</span>
                    <div className='eureka-idea-score-container'>
                        {ideaOptions.rating && (
                            <div
                                className='eureka-idea-score flex flex-center'
                                style={{ backgroundColor: ideaOptions.ratingColor }}
                            >
                                <span>{ideaOptions.rating}</span>
                            </div>
                        )}
                    </div>
                    <div className='flex align-center'>
                        <button
                            className='subtract-component-card-menu-button compact flex-center'
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={handleMenuClick}
                        >
                            <img className='menu-icon' src={MenuIcon} alt='menu-icon'></img>
                        </button>
                    </div>
                </div>
            </div>
            {modalMenuOpen && (
                <div ref={modalRef} className='card-modal-container' style={{ ...coordinates }}>
                    <div className='menu-items-container' onMouseDown={(e) => e.stopPropagation()}>
                        {menuItems}
                    </div>
                </div>
            )}
        </>
    );
};

export default EurekaIdeaCard;
