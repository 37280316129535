export const listOfInitialColors = [
    /* initialsBackgroundColor - initialsColor */
    ['#acb8ff', '#2d42b6'],
    ['#95e5bd', '#10b964'],
    ['#b4fffb', '#00a5bb'],
    ['#af96da', '#5727aa'],
    ['#f2acff', '#87149b'],
    ['#78bef7', '#006fca'],
    ['#89daff', '#0089c9'],
    ['#ffd089', '#da8300'],
    ['#fde8e9', '#f1666a'],
    ['#f0ffbb', '#a4c900'],
];
