import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTutorialUrls } from '../../../actions/globalConfigurationsActions';
import { ReactComponent as VideoIcon } from '../../../assets/images/icon-video-tutorial.svg';
import TutorialVideoModal from '../../dialogs/TutorialVideoModal';

import { getTranslation } from '../../../helpers/getLanguage';

import { getUrlFromConfig } from '../../../helpers/getUrlFromConfig';
import './styles.css';

const ViewTutorialButtonWithModal = ({ name = '', className = '', getTutorialUrls, tutorialUrls, language }) => {
    const [videoData, setVideoData] = useState({ url: '', displayName: '' });
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isShowButton, setIsShowOpen] = useState(false);

    useEffect(() => {
        if (!tutorialUrls.length) getTutorialUrls();
    }, []);

    useEffect(() => {
        const foundTutorial = tutorialUrls.find((element) => element.name === name && element.language === language);

        if (foundTutorial && foundTutorial.filename) {
            const newUrl = getUrlFromConfig(`video/${foundTutorial.name}-${foundTutorial.language}.mp4`);

            setVideoData({ url: newUrl, displayName: foundTutorial.displayName });
            setIsShowOpen(true);
        } else {
            setIsShowOpen(false);
        }
    }, [tutorialUrls, language]);

    if (!tutorialUrls.length || !videoData.url) {
        return null;
    }

    const buttonClassName = 'view-tutorial-button flex align-center justify-space-between ' + className;

    return (
        <>
            {isShowButton && 
                <button className={buttonClassName} onClick={() => setIsOpenModal(true)}>
                    <span>{getTranslation('VIEW_TUTORIAL')}</span>
                    <VideoIcon />
                </button>
            }
            {isOpenModal && <TutorialVideoModal setIsOpenModal={setIsOpenModal} videoData={videoData} />}
        </>
    );
};

const mapStateToProps = (state) => ({
    language: state.auth.userInfo.language,
    tutorialUrls: state.globalConfigurations.tutorialUrls,
});

const mapDispatchToProps = {
    getTutorialUrls,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTutorialButtonWithModal);
