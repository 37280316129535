import { combineReducers } from 'redux';
import authReducer from './authReducers.js';
import passwordRecoveryReducer from './passwordRecoveryReducers';
import projectReducer from './projectReducers';
import preferencesReducer from './preferencesReducers';
import stripeReducer from './stripeReducers';
import promoCodeReducers from './promoCodesReducers';
import usersReducers from './usersReducers';
import adminReducers from './adminReducers';
import globalConfigurationsReducers from './globalConfigurationsReducers';
import currentIssuesReducers from './currentIssueReducers';
import websocketReducers from './websocketReducers';
import prefabReducer from './prefabReducer';

export default combineReducers({
    auth: authReducer,
    recovery: passwordRecoveryReducer,
    project: projectReducer,
    preferences: preferencesReducer,
    subscription: stripeReducer,
    promoCodes: promoCodeReducers,
    users: usersReducers,
    adminInfo: adminReducers,
    globalConfigurations: globalConfigurationsReducers,
    currentIssues: currentIssuesReducers,
    webSocket: websocketReducers,
    prefabs: prefabReducer,
});
